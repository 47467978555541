<template>
  <weblayout>
    <div class="banner-blog small-inner-banner">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="text-center my-0 mx-auto">
            <div class="text-h3 font-weight-medium white--text">Contact Us</div>
            <v-breadcrumbs large :items="items">
              <template v-slot:divider>
                <v-icon color="white">mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </div>
        </div>
      </v-container>
    </div>
    <v-container class="my-16">
      <v-row>
        <v-col cols="12" md="6">
          <div class="about-wrap">
            <div class="m-title">We Love to Hear From You</div>

            <div>
              <p>
                Contact BiofuelBid for all your bio briquette needs. Our online
                platform offers a simple and efficient way to buy and sell
                biofuel products. Reach out to our friendly team for assistance
                with any queries or concerns you may have. Join us today and
                start bidding!
              </p>
            </div>

            <v-row>
              <v-col>
                <div>
                  <h3 class="mb-2">Registered Address</h3>
                  <div>
                    C243, Second Floor, SoBo Center, <br />
                    Gala Gymkhana Road, South Bopal, <br />
                    Ahmedabad, 380058.
                  </div>
                </div>
              </v-col>
              <v-col>
                <div>
                  <h3 class="mb-2">Contact Information</h3>
                  <div>
                    <div class="mb-1">
                      <a
                        class="black--text text-decoration-none"
                        href="tel:+917600616767"
                        >+91 76006 16767</a
                      >
                    </div>
                    <div>
                      <a
                        class="black--text text-decoration-none"
                        href="mailto:support@biofuelbid.com"
                        >support@biofuelbid.com</a
                      >
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="12">
                <h3 class="mb-2">Social Media</h3>
                <div class="mt-2">
                  <v-btn icon>
                    <v-icon color="#421E06"> mdi-facebook </v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon color="#421E06"> mdi-instagram </v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon color="#421E06"> mdi-whatsapp </v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon color="#421E06"> mdi-gmail </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submitContactUs)">
              <div class="contact-form">
                <div class="m-title">Leave a <strong> Message</strong></div>
                <v-row class="mt-3">
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <label for="">Name <span class="required">*</span></label>
                      <v-text-field
                        outlined
                        dense
                        :error-messages="errors"
                        v-model="name"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <label for=""
                        >Email <span class="required">*</span></label
                      >
                      <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-email-outline"
                        v-model="email"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Subject"
                      rules="required"
                    >
                      <label for=""
                        >Subject <span class="required">*</span></label
                      >
                      <v-text-field
                        outlined
                        dense
                        :error-messages="errors"
                        v-model="subject"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Message"
                      rules="required"
                    >
                      <v-textarea
                        class="ma-0 pa-0"
                        placeholder="Type Message Here"
                        dense
                        v-model="message"
                        :error-messages="errors"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <label for="">Document</label>
                    <v-file-input
                      truncate-length="15"
                      outlined
                      dense
                      prepend-inner-icon="mdi-attachment"
                      prepend-icon=""
                      v-model="document"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <vue-recaptcha
                      ref="recaptcha"
                      :sitekey="recaptchaSiteKey"
                      @verify="onVerify"
                      @expired="onExpired"
                      @error="onError"
                      :loadRecaptchaScript="true"
                    >
                    </vue-recaptcha>
                    <p v-if="recpatchaError != ''" class="required">
                      {{ recpatchaError }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn
                      rounded
                      color="#9da73e"
                      dark
                      class="px-6"
                      type="submit"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </form>
          </validation-observer>
        </v-col>
      </v-row>
    </v-container>
  </weblayout>
</template>

<script>
import Weblayout from "../layout/weblayout.vue";
import VueRecaptcha from "vue-recaptcha";
import { required, email, max, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { UUID, AUTHENTIACTIONKEY, generateTheMetaInfo, encryptPayload } from "@/utils/helper";
setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});
extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
extend("min", {
  ...min,
  message: "{_field_} may not be greater than {length} characters",
});
extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  components: {
    Weblayout,
    ValidationObserver,
    ValidationProvider,
    VueRecaptcha,
  },
  name: "contactus",
  inject: ["mySpinner"],
  methods: {
    async submitContactUs() {
      if (this.isRecatchaVerified) {
        this.mySpinner.val = true;
        try {
          let formData = new FormData();
          const data = {
            uuid : UUID,
            authentication_key : AUTHENTIACTIONKEY,
            name : this.name,
            email : this.email,
            subject : this.subject,
            message : this.message,
            captcha : this.recaptchaToken
          }
          if (this.document != null) {
            formData.append("document", this.document);
          }
          const encryptData = encryptPayload(data)
          formData.append("bfb_data", encryptData.bfb_data);
          const response = await this.$store.dispatch(
            "submitContactUs",
            formData
          );
          if (response.http_status === 200) {
            this.name = "";
            this.email = "";
            this.subject = "";
            this.message = "";
            this.document = null;
            this.$refs.observer.reset();
            this.$toasted.success(response.message);
            this.$refs.recaptcha.reset();
            this.isRecatchaVerified = false;
            this.mySpinner.val = false;
            this.recpatchaError = "";
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
            this.$refs.recaptcha.reset();
            this.isRecatchaVerified = false;
            this.recpatchaError = "";
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
          this.$refs.recaptcha.reset();
          this.isRecatchaVerified = false;
          this.recpatchaError = "";
        }
      } else {
        this.recpatchaError = "Please complete the reCAPTCHA to proceed.";
      }
    },
    onExpired() {
      this.recaptchaToken = "";
      this.isRecatchaVerified = false;
      this.$refs.recaptcha.reset();
    },
    onVerify(response) {
      this.recaptchaToken = response;
      if (this.recaptchaToken != "") {
        this.isRecatchaVerified = true;
        this.recpatchaError = "";
      } else {
        this.isRecatchaVerified = false;
      }
    },
    onError() {
      this.recaptchaToken = "";
      this.$refs.recaptcha.reset();
      this.isRecatchaVerified = false;
    },
  },
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      document: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Contact Us",
          disabled: true,
          href: "/contactus",
        },
      ],
      recaptchaSiteKey: "6LcX8WUlAAAAAKjmy52YmpKJ34D_sEtC3S7NJgXT",
      recaptchaToken: "",
      isRecatchaVerified: false,
      recpatchaError: "",
    };
  },
  metaInfo() {
    return generateTheMetaInfo("ContactUs");
  },
};
</script>

<style>
</style>