<template>
  <weblayout>
    <div class="banner-blog">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="title-of-blog">
            Nation Bioenergy Mission 2018 : A Gateway for Biofuels in India
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
                Darshan Baraiya
                <div class="grey--text body-2">On 07/03/2022 - 16:18PM</div>
              </div>
            </div>

           
          </div>

          <div class="img-of-blog">
            <!-- <div class="next-prev-btn justify-end">
              <v-btn text dark>
                <v-icon> mdi-chevron-left </v-icon>
                Prev Blog
              </v-btn>
              <v-btn text dark>
                Next Blog
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </div> -->
            <img
              style="max-width: 600px"
              src="@/assets/images/blog1.jpg"
              alt=""
            />
          </div>
        </div>
      </v-container>
    </div>

    <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12">
            <div class="about-wrap">
              <p>
                The National Bioenergy Mission is a flagship program of the
                Indian government that aims to promote the use of biofuels and
                develop a sustainable ecosystem for the production and use of
                bioenergy in India. Here are some key highlights of the program:
              </p>

              <p class="mt-10"><strong>1. Objectives: </strong></p>

              <p>
                The main objectives of the National Bioenergy Mission are to
                promote the use of biofuels, reduce greenhouse gas emissions,
                enhance energy security, and generate employment in rural areas.
              </p>

              <p class="mt-10"><strong>2. Funding: </strong></p>

              <p>
                The program is funded by the Indian government and has a budget
                of Rs 10,000 crore (approximately USD 1.4 billion) for the
                period 2018-2023.
              </p>

              <p class="mt-10">
                <strong
                  >3. Focus on agricultural and forestry residues:
                </strong>
              </p>

              <p>
                The program focuses on the use of agricultural and forestry
                residues as feedstock for bioenergy production, which helps to
                reduce waste and improve soil health.
              </p>

              <p class="mt-10">
                <strong>4. Research and development: </strong>
              </p>

              <p>
                The National Bioenergy Mission supports research and development
                activities to improve the efficiency and cost-effectiveness of
                bioenergy technologies, and to develop new feedstocks for
                bioenergy production.
              </p>

              <p class="mt-10"><strong>5. Capacity building: </strong></p>

              <p>
                The program provides training and capacity building to
                stakeholders involved in the bioenergy sector, including
                farmers, entrepreneurs, and scientists.
              </p>

              <p class="mt-10"><strong>6. Enabling environment: </strong></p>

              <p>
                The National Bioenergy Mission aims to create an enabling
                environment for the sustainable production and use of biofuels,
                including through the development of supportive policies,
                regulations, and standards.
              </p>

              <p class="mt-10">
                <strong>7. Integration with other programs: </strong>
              </p>

              <p>
                The program is integrated with other government initiatives,
                such as the Pradhan Mantri Fasal Bima Yojana (Crop Insurance
                Scheme) and the Soil Health Card Scheme, to promote sustainable
                agriculture and improve the availability of feedstock for
                bioenergy production.
              </p>
              <p>
                India has several policies and initiatives to promote the use of
                biomass-based fuels, including biocoal, as a part of its efforts
                to increase the share of renewable energy in its energy mix and
                reduce greenhouse gas emissions. The National Bioenergy Mission
                was launched in 2018 by the Indian government to promote the use
                of biofuels, including biocoal, and to develop an ecosystem for
                the production of biofuels in India. The mission aims to promote
                the use of agricultural and forestry residues for energy
                production, and to establish an enabling environment for the
                sustainable production and use of biofuels.
              </p>
              <p>
                The Indian government has also set targets for the production
                and use of biofuels, including biocoal. The National Policy on
                Biofuels, which was revised in 2018, aims to achieve a target of
                20% ethanol blending and 5% biodiesel blending with fossil fuels
                by 2030. The policy also provides incentives and subsidies for
                the production of biofuels, including biocoal.
              </p>
              <p>
                In addition to national policies, several Indian states have
                also introduced policies and incentives to promote the use of
                biomass-based fuels, including biocoal. For example, the state
                of Maharashtra has launched a program to promote the use of
                biocoal as a fuel source for power generation, and the state of
                Andhra Pradesh has introduced a policy to promote the use of
                biomass for energy production
              </p>
              <p>
                India has recognized the potential of biocoal and other
                biomass-based fuels as a means of reducing greenhouse gas
                emissions and promoting sustainable development. The government
                has introduced several policies and initiatives to support the
                development of a biofuel industry in the country, including the
                use of biocoal
              </p>
              <p>
                Overall, the National Bioenergy Mission is a comprehensive
                program that aims to promote the use of biofuels and create a
                sustainable ecosystem for the production and use of bioenergy in
                India. The program has the potential to contribute significantly
                to India's energy security and sustainability goals, while also
                creating employment opportunities in rural areas.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-divider></v-divider>
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
                Darshan Baraiya
                <div class="grey--text body-2">07/03/2022</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </weblayout>
</template>
  
  <script>
import weblayout from "../layout/weblayout.vue";

export default {
  components: { weblayout },
  data() {
    return {
      tab: null,
      items: [
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
      ],
    };
  },
};
</script>
  
  <style>
</style>