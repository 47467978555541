import moment from 'moment'
import CryptoJS from 'crypto-js'
import metaDetails from './metaDetails.json'

// const API_URL = 'http://localhost/biofuelbid-bl/public/'
const API_URL = 'https://test-api.biofuelbid.com/public/'
const PUSHER_KEY = '669c4f4ea3189e2ad0a2'

// const API_URL = 'https://api.biofuelbid.com/public/'
// const PUSHER_KEY = '73bcb58d0c565a871de4'

const APPLICATION_LOGIN_KEY = 'A773CBA5-E65E-4A60-88DD-BCB8A0D3C421-78B1E72A-6F8E-47E5-A68D-4140723E48A6-77767400-5E7D-4FC6-BA1B-B0198D8E9C96-8CC10976-5CE9-4C8C-A3B6-E31FE8940C2E-C44C7EAC-46C2-4E5B-841B-C00514881F53-A27ADD1A-C225-4B46-9577-FA29033E4B47-F590AEE6-A514-4DA2-8E42-C0DDF66D9EB0-CDB7E11F-BDE9-4ACC-9EE8-2658C7C482C7-DF0A4C69-4E68-4AB2-B4F5-C102C407C7E3-96D892D4-A768-4742-8B78-5A640C4D993B'
const APPLICATION_SECRET_KEY = '5C3B277B-CAC0-4FFE-830D-8CF2F3ADAA44-93661D95-DAD6-424E-B658-986207EA5C43'
const APPLICATION_LOGIN_KEY_BODY = 'BE18F375-01DC-47DD-ACE5-7DED43EFBA0C-4B442DED-4F8C-46A1-8737-1AAA8A635E78-79AAB964-5F4E-416C-AC0C-F2AC32A82A60-F0F0649B-72F9-4E17-A294-F05BF27940EA-871F6A3E-7989-419B-9A90-31C005B6145E-1621A57B-5F25-4412-9389-9D5E10579FB8-D070359B-D670-469C-8554-02C775A07757-BCC1EB30-4F25-4329-A853-A56C5763A93E-E2B5DD6E-9D46-4416-AB97-A85D1FC46671-63B3C44E-CDD6-4580-ACD9-F3BB993EE4BF'
const APPLICATION_SECRET_KEY_BODY = 'A5C60F07-618A-4172-87CB-AB43D74FCB58-20343249-736D-47CF-BB33-F201FF1D4166'

const encode = (myString) => {
  const encodedWord = CryptoJS.enc.Utf8.parse(myString); // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
  return encoded;
}

const plainText = moment().format('MMDDYYYY_HHMMSS')
const UUID = encode(plainText)
const APPKEY = encode(APPLICATION_LOGIN_KEY + '||' + plainText + '||' + APPLICATION_SECRET_KEY)
const AUTHENTIACTIONKEY = encode(APPLICATION_LOGIN_KEY_BODY + '||' + plainText + '||' + APPLICATION_SECRET_KEY_BODY)


const COMMONPAYLOAD = {
  uuid: UUID,
  //   authentication_key: AUTHENTIACTIONKEY
}


const OTPCOMMONPAYLOAD = {
  uuid: UUID,
  //   authentication_key: AUTHENTIACTIONKEY,
  client_id: '98655486-341f-4bf5-b704-8a07e0d6c272',
  client_secret: 'GlNdk5i4igsL2WjDgOHI1D2656q82STU9AVch672',
}

// pagination 

const PAGINATIONOPTIONS = {
  page: 1,
  itemsPerPage: 20,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  mustSort: false,
  multiSort: false
}

const FOOTERPAGELIST = {
  'items-per-page-options': [5, 50, 100, 150]
}

const ADMINMENU = [
  {
    icon: "mdi-view-dashboard-outline",
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: "mdi-account-outline",
    title: "User",
    to: "/userlist",
  },
  {
    icon: "mdi-shape-outline",
    title: "Category",
    to: "/categoryList",
  },
  {
    icon: "mdi-book-edit-outline",
    title: "Auctions",
    to: "/adminAuctionList",
  },
  {
    icon: "mdi-book-edit-outline",
    title: "Accounts",
    child_modules: [
      {
        icon: "mdi-book-edit-outline",
        title: "Direct PO",
        to: "/directPurchaseOrdersList"
      },
      {
        icon: "mdi-book-edit-outline",
        title: "Sales Invoices",
        to: "/salesInvoicesList"
      },
      {
        icon: "mdi-book-edit-outline",
        title: "Purchase Invoices",
        to: "/purchaseInvoicesList"
      },
      // {
      //   icon: "mdi-book-edit-outline",
      //   title: "Quotations",
      //   to: "/quotations"
      // },
    ]
  },
  // {
  //   icon: "mdi-file-document-multiple-outline",
  //   title: "Purchase Orders",
  //   to: "/purchaseorder",
  // },
  {
    icon: "mdi-post-outline",
    title: "Blog",
    to: "/Bloglist",
  },
  {
    icon: "mdi-view-module-outline",
    title: "User Module",
    to: "/usermodulelist",
  },
  {
    icon: "mdi-account-check-outline",
    title: "Role Access",
    to: "/roleaccess",
  },
  {
    icon: "mdi-phone",
    title: "Contact Us",
    to: "/contactUsList",
  },
  {
    icon: "mdi-tag-multiple-outline",
    title: "Tags",
    to: "/tagsList",
  },
  {
    icon: 'mdi-file-document-multiple-outline',
    title: 'Items',
    to: "/itemslist",
  },
  {
    icon: "mdi-chat-question-outline",
    title: "Enquiry",
    to: "/enquirylist",
  }
]

const BUYERMENU = [
  {
    icon: "mdi-view-dashboard-outline",
    title: "Dashboard",
    to: "/dashboard",
  },
  {
    icon: "mdi-book-edit-outline",
    title: "Auctions",
    child_modules: [
      {
        icon: "mdi-book-edit-outline",
        title: "My Auction",
        to: "/myAuction"
      },
      {
        icon: "mdi-book-edit-outline",
        title: "Participated Auction",
        to: "/auctionList"
      },
    ]
  },
  {
    icon: "mdi-file-document-multiple-outline",
    title: "Purchase Orders",
    to: "/purchaseorder",
  },
]

const PARTNERMENU = [
  {
    icon: "mdi-view-dashboard-outline",
    title: "Dashboard",
    to: "/partner-dashboard",
  },
  {
    icon: "mdi-account-outline",
    title: "Your Partners",
    to: "/yourPartner",
  },
]

const getEncodedString = (data) => {
  // console.log(key)
  // return CryptoJS.AES.encrypt(JSON.stringify(key),UUID).toString()
  var Key = CryptoJS.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");  // 1. Replace C by CryptoJS
  var IV = CryptoJS.enc.Utf8.parse("0123456789ABCDEF");
  var encryptedText = CryptoJS.AES.encrypt(JSON.stringify(data), Key, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  //return encryptedText.toString(CryptoJS.format.Hex);
  return encryptedText.toString(CryptoJS.format.Base64);
}

const getDecodedString = (data) => {

  var C = CryptoJS;
  var Key = C.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");
  var IV = C.enc.Utf8.parse("0123456789ABCDEF");
  var decryptedText = C.AES.decrypt(data, Key, {
    iv: IV,
    mode: C.mode.CBC,
    padding: C.pad.Pkcs7
  });
  return decryptedText.toString(CryptoJS.enc.Utf8);
}

const getEncodedStringBase64 = (key) => {
  const encodedWord = CryptoJS.enc.Utf8.parse(key)
  return CryptoJS.enc.Base64.stringify(encodedWord)
}

const getDecodedStringBase64 = (key) => {
  const bytes = CryptoJS.enc.Base64.parse(key)
  return CryptoJS.enc.Utf8.stringify(bytes)
}

const getDecodedToken = () => {
  let token = localStorage.getItem('access_token')
  if (token) {
    var C = CryptoJS;
    var Key = C.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");
    var IV = C.enc.Utf8.parse("0123456789ABCDEF");
    var decryptedText = C.AES.decrypt(token, Key, {
      iv: IV,
      mode: C.mode.CBC,
      padding: C.pad.Pkcs7
    });
    return JSON.parse(decryptedText.toString(CryptoJS.enc.Utf8));
  } else {
    return null
  }

}
const getDecodedRefreshToken = () => {
  let token = localStorage.getItem('refresh_token')
  if (token) {
    var C = CryptoJS;
    var Key = C.enc.Utf8.parse("6il7YCRSqIOB9NooY225lPKQ0KuAF/nkFX6cY3vJkS0=");
    var IV = C.enc.Utf8.parse("0123456789ABCDEF");
    var decryptedText = C.AES.decrypt(token, Key, {
      iv: IV,
      mode: C.mode.CBC,
      padding: C.pad.Pkcs7
    });
    return JSON.parse(decryptedText.toString(CryptoJS.enc.Utf8));
  } else {
    return null
  }

}
const AUTHTOKEN = getDecodedToken()
const AUTHREFRESHTOKEN = getDecodedRefreshToken()

const token = getDecodedToken();

const FORMDATAHEADER = {
  'Content-Type': 'multipart/form-data',
  authorization: 'Bearer ' + token,
  //   AppKey: APPKEY
}

const FILEDOWNALOADHEADER = {
  authorization: 'Bearer ' + token
}

const logoutUserFromaxios = () => {
  this.$router.push({ path: "/login" });
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("is_admin");
  localStorage.removeItem("is_buyer");
  localStorage.removeItem("is_seller");
  localStorage.removeItem("is_trader");
  localStorage.removeItem("last_password_changed_at");
  localStorage.removeItem("last_name");
  localStorage.removeItem("userid");
  localStorage.removeItem("useremail");
  localStorage.removeItem("userphone");
  localStorage.removeItem("registration_number");
  localStorage.removeItem("otp_key");
  localStorage.removeItem("first_name");
  localStorage.removeItem("branches");
  localStorage.removeItem("selectedBranch");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_type");
  this.$router.push({ path: "/login" });
}

const getMetaTagDetails = (module = '', tag = '') => {
  if (metaDetails.hasOwnProperty(module)) {
    if (metaDetails[module].hasOwnProperty(tag)) {
      return metaDetails[module][tag]
    }
  }
}

const generateTheMetaInfo = (module) => {
  if (module != '') {
    return {
      title: getMetaTagDetails(module, 'title'),
      meta: [
        {
          name: 'title',
          content: getMetaTagDetails(module, 'title')
        },
        {
          name: 'description',
          content: getMetaTagDetails(module, 'description')
        },
        {
          name: 'keywords',
          content: getMetaTagDetails(module, 'keywords')
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:type',
          content: getMetaTagDetails(module, 'type')
        },
        {
          property: 'og:title',
          content: getMetaTagDetails(module, 'title')
        },
        {
          property: 'og:description',
          content: getMetaTagDetails(module, 'description')
        },
        {
          property: 'og:keywords',
          content: getMetaTagDetails(module, 'keywords')
        },
        {
          property: 'og:url',
          content: getMetaTagDetails(module, 'url')
        },
        {
          property: 'og:image',
          content: getMetaTagDetails(module, 'image')
        },
        {
          property: 'og:image:alt',
          content: getMetaTagDetails(module, 'image_alt')
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:title',
          content: getMetaTagDetails(module, 'title')
        },
        {
          name: 'twitter:description',
          content: getMetaTagDetails(module, 'description')
        },
        {
          name: 'twitter:keywords',
          content: getMetaTagDetails(module, 'keywords')
        },
      ]
    }
  }
}

const secretKey = "IQvhYFL6iVxSrKXiq1KI0pNysrylLsyW";

const encryptPayload = (object) => {
  let iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the data
  let encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(object),
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  let data = { bfb_data: iv.toString() + encryptedData.ciphertext.toString() }
  // Encrypted data as object
  return data;

}
const decryptPayload = (response) => {
  const encryptedString = response?.data
  let iv = CryptoJS.enc.Hex.parse(encryptedString.substr(0, 32));
  let ciphertext = CryptoJS.enc.Hex.parse(encryptedString.substr(32));
  let decryptedData = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  let obj = JSON.parse(
    decryptedData.toString(CryptoJS.enc.Utf8)
  );
  response.data = obj
  return response

}


export {
  UUID,
  APPKEY,
  AUTHENTIACTIONKEY,
  COMMONPAYLOAD,
  OTPCOMMONPAYLOAD,
  PAGINATIONOPTIONS,
  FOOTERPAGELIST,
  ADMINMENU,
  BUYERMENU,
  PARTNERMENU,
  API_URL,
  PUSHER_KEY,
  FORMDATAHEADER,
  FILEDOWNALOADHEADER,
  getEncodedString,
  getDecodedString,
  getDecodedStringBase64,
  getEncodedStringBase64,
  getDecodedToken,
  getDecodedRefreshToken,
  AUTHTOKEN,
  AUTHREFRESHTOKEN,
  logoutUserFromaxios,
  getMetaTagDetails,
  generateTheMetaInfo,
  encryptPayload,
  decryptPayload
}