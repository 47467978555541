<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-account-group-outline
            </v-icon>
            User
          </div>
          <!-- <div> -->
            <!-- <DatePicker 
              v-model="dateFilter" a
              range 
              @change="fetchUserList" 
              format="YYYY-MM-DD" 
              valueType="YYYY-MM-DD"
              clearable
              placeholder="Date Filter"
            />
          </div> -->
          <!-- <div>
            <v-text-field 
              outlined 
              dense  
              v-model="search"
              @keypress.enter="fetchUserList"
              placeholder="Search..."
            ></v-text-field>
          </div> -->
          <div>
            <v-select
              :items="userStatusList"
              v-model="userStatus"
              label="Status"
              outlined
              dense
              chips
              multiple
              @change="fetchUserList"
              class="d-inline-block mr-2 mb-0"
            ></v-select>
          </div>
          <div>
            <v-select
              :items="userTypeList"
              v-model="userType"
              label="Type"
              outlined
              dense
              chips
              multiple
              @change="fetchUserList"
              class="d-inline-block mr-2 mb-0"
            ></v-select>
          </div>
          <div class="btns-right">
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              class="px-2"
              @click="openAddUserDialog"
            >
              <v-icon> mdi-plus </v-icon>
              Add User
            </v-btn>
          </div>
        </div>

        <div class="four-box-of-status">
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="userListData"
                :options.sync="options"
                :server-items-length="total"
                class="elevation-0"
                :footer-props="footerList"
                dense
              >
              <template v-slot:[`item.type`]="{ item }">
                {{ item.is_seller == 1 ? "Seller" : ""}}
                {{ item.is_admin == 1 ? "Admin" : ""}}
                {{ item.is_trader == 1 ? "Trader" : ""}}
                {{ item.is_buyer == 1 ? "Buyer" : ""}}
                {{ item.is_partner == 1 ? "Partner" : "" }}

              </template>
                <!-- <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="showApproveModal(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-checkbox-marked-circle </v-icon>
                  </v-btn>
                  <v-btn fab small elevation="0" color="transparent" @click="showRejectModal(item.id)">
                    <v-icon color="red"> mdi-close-outline </v-icon>
                  </v-btn>
                
                </template> -->

                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="gotoEdit(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <!-- <v-btn fab small elevation="0" color="transparent">
                    <v-icon color="red"> mdi-delete-outline </v-icon>
                  </v-btn> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container-fluid>
    <!-- <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="text-h6">
         Are you sure want to delete ?
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet consectetur.
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>

          <v-btn color="grey darken-1" text @click="dialog = false">
            No
          </v-btn>

          <v-btn color="red darken-1" text @click="dialog = false" class="font-weight-bold">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <ConformationBox 
      v-if="isShowApprove"
      :title="'Confirmation'"
      message="Are you sure want to approve ?"
      confirmText="Approve"
      @confirm="confirmApprove"
      @cancel="closeApprovModal"
  />
  <ConformationBox 
      v-if="isShowReject"
      :title="'Confirmation'"
      message="Are you sure want to reject ?"
      confirmText="Reject"
      @confirm="confirmReject"
      @cancel="closeRejectModal"
  />
  <AddUser 
    v-if="isAddUser"
    @cancel="closeAddUserModal"
  />
  </dashlayout>
</template>

<script>
import dashlayout from "../layout/dashlayout.vue";
import {FOOTERPAGELIST , PAGINATIONOPTIONS,getEncodedStringBase64} from '@/utils/helper'
import ConformationBox from "@/components/common/ConformationBox.vue";
import AddUser from "./AddUser.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { dashlayout,ConformationBox ,AddUser,DatePicker},
  name: "dashboard",
  inject : ['mySpinner'],
  created() {
     this.fetchUserList(); 
  }, 
  watch : {
      options: {
          handler(newVal,oldVal) {
              if(JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                  this.fetchUserList()
              }
          },
          deep: true
      },
  },
  methods: {
    gotoEdit(id) {
      this.$router.push({path:`/useraddedit/${getEncodedStringBase64(id)}`})
    },
    openAddUserDialog() {
      this.isAddUser = true
    },
    closeAddUserModal() {
      this.isAddUser = false
      this.fetchUserList()
    },
    async fetchUserList() {
      this.mySpinner.val =true
      try {
          const response = await this.$store.dispatch("getUserList", {
            ...this.options,
            action : 'LIST',
            // search : this.search,
            status : this.userStatus,
            type: this.userType,
            // start_date : this.dateFilter.length > 0 ? this.dateFilter[0] != null ? this.dateFilter[0] : "" : "",
            // end_date : this.dateFilter.length > 0 ? this.dateFilter[1] != null ? this.dateFilter[1] : "" : "",
          });
          if (response.http_status === 200) {
          this.userListData = response.data.data;
          this.total = response.data.total;
          this.mySpinner.val =false
          } else {
          this.$toasted.error(response.message);
          this.mySpinner.val =false
          }
      } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val =false
      }
    },
    showApproveModal(id) {
      this.isShowApprove = true;
      this.isApproveId = id
    },
    showRejectModal(id) {
      this.isShowReject = true;
      this.isRejectId = id
    },
    async confirmApprove() {
      this.mySpinner.val =true
      try {
          const response = await this.$store.dispatch("updateUserStatus", {user_id : this.isApproveId,status : 'Approved'});
          if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val =false
          this.isShowApprove = false;
          this.fetchUserList()
      this.isApproveId = ''
          } else {
          this.$toasted.error(response.message);
          this.mySpinner.val =false
          this.isShowApprove = false;
          this.isApproveId = ''
          }
      } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val =false
      }
    },
    closeApprovModal() {
      this.isShowApprove = false;
      this.isApproveId = ''
    },
    closeRejectModal() {
      this.isShowReject = false;
      this.isRejectId = ''
    },
    async confirmReject() {
      this.mySpinner.val = true
      try {
        const response = await this.$store.dispatch("updateUserStatus", {user_id : this.isRejectId,status : 'Rejected'});
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false
          this.isShowReject = false
          this.isRejectId = ''
          this.fetchUserList()
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false
          this.isShowReject = false
          this.isRejectId = ''
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val =false
      }
    }
  },
  data: () => ({
    dialog:false,
    isShowApprove : false,
    isShowReject : false,
    isApproveId : '',
    isRejectId :'',
    headers: [
      { text: "Name", value: "first_name" },
      { text: "Phone", value: "phone" },
      { text: "Email", value: "email" },
      { text: "Status", value: "status" },
      { text: "Type", value: "type", sortable: false },
      { text: "Capacity", value: "monthly_capacity", sortable: false },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],

    userListData : [],
    total : 0,
    options : PAGINATIONOPTIONS,
    footerList : {
      'items-per-page-options':[10,20,50,100]
    },
    isAddUser : false,
    userTypeList: ['Buyer', 'Seller', 'Admin', 'Partner'],
    userStatusList : ['New', 'Verification Link Sent', 'Verified', 'Approved', 'Rejected', 'Suspended', 'Locked', 'Archived'],
    userStatus : ['New','Approved'],
    userType: ['Seller'],
    search : '',
    dateFilter : []
  }),
};
</script>

<style>
</style>