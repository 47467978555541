<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-tag-multiple-outline
            </v-icon>
            Tags
          </div>
          <div class="btns-right">
            <v-text-field v-model="search" outlined type="text" dense placeholder="Search..."
                      @keypress.enter="fetchUserModuleList" class="d-inline-block mr-2 mb-0"></v-text-field>
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              class="px-2"
              @click="addEditModule=true"
            >
              <v-icon> mdi-plus </v-icon>
              Add Tag
            </v-btn>
          </div>
        </div>

        <div class="four-box-of-status">
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="userModuleData"
                :options.sync="options"
                :server-items-length="total"
                class="elevation-0"
                :footer-props="footerList"
                dense
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="editUserModule(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="deleteModule(item.id)"
                  >
                    <v-icon color="red"> mdi-delete-outline </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container-fluid>
    
    <!-- <v-dialog v-model="addEditModule" width="450" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between"> Add Module
        <v-btn fab x-small elevation="0" color="transparent" @click="addEditModule=false">
          <v-icon color="white">
            mdi-close
          </v-icon>
        </v-btn>  
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Name</label>
              <v-text-field
              v-model="name"
                outlined
                dense
                hide-details=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label for="">Slug</label>
              <v-text-field
              v-model="slug"
                outlined
                dense
                hide-details=""
              ></v-text-field>
            </v-col>
           
            <v-col cols="12" md="6">
              <label for="">Icon</label>
              <v-text-field
              v-model="icon"
                outlined
                dense
                hide-details=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label for="">order</label>
              <v-text-field
              v-model="order"
                outlined
                dense
                hide-details=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label for="">Parent Module</label>
               <v-select
                :items="items"
                outlined
                dense
                chips
                multiple
                hide-details=""
               ></v-select>
            </v-col>
            <v-col cols="12" md="6">
               <v-switch inset v-model="visiableNav" label="Visible on Nav" hide-details="" class="mt-0"></v-switch>
            </v-col>
            
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <div class="btns-right">
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              large
              @click="addEditModule=false"
            >
              Add Module
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <AddEditTags 
      v-if="addEditModule" 
      @cancel="closeDialog"
      @success="addUpdateSuccessFull"
      :id="editUserModuleId"
      />
    <ConformationBox 
      v-if="isShowDeleteDialog"
      :title="'Confirmation'"
      message="Are you sure want to delete ?"
      @confirm="confirmDeleteForm"
      @cancel="closeDeleteFormModal"
  />
  </dashlayout>
</template>

<script>
import dashlayout from "../layout/dashlayout.vue";
import AddEditTags from "@/components/dashboard/AddEditTags.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import {FOOTERPAGELIST , PAGINATIONOPTIONS} from '@/utils/helper'
export default {
  components: { dashlayout, AddEditTags, ConformationBox },
  name: "BlogList",
  inject : ['mySpinner'],
  
  created() {
     this.fetchUserModuleList(); 
  }, 
  watch : {
      options: {
          handler(newVal,oldVal) {
              if(JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                  this.fetchUserModuleList()
              }
          },
          deep: true
      },
  },
  data: () => ({
    dialog: false,
    addEditModule: false,
    
    options : PAGINATIONOPTIONS,
    total : 0 ,
    userModuleData : [],
    footerList : {
      'items-per-page-options':[10,20,50,100]
    },
    search: '',
    name : '',
    slug  : '',
    icon : '',
    order : '',
    parentModule: '',
    visiableNav : false,
    isShowDeleteDialog : false,

    deleteUserModuleId : '',
    editUserModuleId : '',

    items: ["user 1", "user 2", "user 3","user 4"],
    headers: [
      { text: "Name", value: "name" },
      { text: "Meta title", value: "meta_title" },
      { text: "Meta keyword", value: "meta_keyword" },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
    details: [
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
    ],
  }),
  methods: {
    async fetchUserModuleList() {
      try {
          this.mySpinner.val = true;
          const response = await this.$store.dispatch("getAdminTagList", {
            ...this.options,
            search: this.search,
            action : 'LIST'
          });
          if (response.http_status === 200) {
          this.userModuleData = response.data.data;
          this.total = response.data.total;
          // this.$toasted.success(response.message);
          this.mySpinner.val = false;
          } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          }
      } catch (e) {
          this.mySpinner.val = false;
          this.$toasted.error(e.message);
      }
    },
    addUpdateSuccessFull() {
      this.addEditModule = false;
      this.fetchUserModuleList(); 
      this.editUserModuleId = ''
    },
    closeDialog() {
      this.addEditModule = false;
      // this.fetchUserModuleList(); 
      this.editUserModuleId = ''
    },
    deleteModule(id) {
      this.isShowDeleteDialog = true
      this.deleteUserModuleId = id
    },
    closeDeleteFormModal() {
      this.isShowDeleteDialog = false
      this.deleteUserModuleId = ''
    },
    async confirmDeleteForm() {
      try {
          this.mySpinner.val = true
          const response = await this.$store.dispatch("deleteAdminTag", {id : this.deleteUserModuleId});
          if (response.http_status === 200) {
              this.isShowDeleteDialog = false
              this.deleteUserModuleId = ''
              this.fetchUserModuleList()
              this.$toasted.success(response.message);
              this.mySpinner.val = false;
          } else {
              this.isShowDeleteDialog = false
              this.deleteUserModuleId = ''
              this.$toasted.error(response.message);
              this.mySpinner.val = false;
          }
      } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
      }
    },
    editUserModule(id) {
      this.editUserModuleId = id
      this.addEditModule = true

    }
  },
};
</script>