<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-post-outline
            </v-icon>
            {{ $route.params.id != undefined ? "Edit" : "Add" }} Blog
          </div>
          <div class="btns-right">
            <v-btn elevation="0" color="#9DA73E" dark text to="/Bloglist">
              <v-icon class="mr-2"> mdi-arrow-left </v-icon>
              Back
            </v-btn>
          </div>
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(adminAddEditBlogs)">
            <div class="four-box-of-status">
              <v-row>
                <v-col cols="12" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <label for="">Title <span class="required">*</span></label>
                    <v-text-field
                      v-model="title"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Content"
                        rules="required"
                      >
                        <label for=""
                          >Content <span class="required">*</span></label
                        >
                        <ckeditor v-model="content"></ckeditor>
                        <p v-if="errors.length > 0" class="required">
                          {{ errors[0] }}
                        </p>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Summary"
                        rules="required"
                      >
                        <label for=""
                          >Summary <span class="required">*</span></label
                        >
                        <textarea
                          v-model="summary"
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>

                        <p v-if="errors.length > 0" class="required">
                          {{ errors[0] }}
                        </p>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Meta Title"
                    rules="required"
                  >
                    <label for=""
                      >Meta Title <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="metaTitle"
                      outlined
                      dense
                      type="text"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4" style="margin-top: 6px;">
                  <div class="d-flex justify-space-between align-baseline">
                    <label for="">Tags</label>
                    <!-- <v-btn color="#9da73e" text small @click="addTags=true">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                  Add Tag
                </v-btn> -->
                  </div>
                  <v-autocomplete
                    :items="tagList"
                    multiple
                    outlined
                    dense
                    chips
                    clearable
                    deletable-chips
                    hide-selected
                    disable-lookup
                    v-model="tags"
                    item-value="id"
                    item-text="name"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Meta Description"
                        rules="required"
                      >
                        <label for=""
                          >Meta Description
                          <span class="required">*</span></label
                        >
                        <textarea
                          v-model="metaDescription"
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                        <p v-if="errors.length > 0" class="required">
                          {{ errors[0] }}
                        </p>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Meta Keyword"
                        rules="required"
                      >
                        <label for=""
                          >Meta Keyword <span class="required">*</span></label
                        >
                        <textarea
                          v-model="metaKeyword"
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                        <p v-if="errors.length > 0" class="required">
                          {{ errors[0] }}
                        </p>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Blog image"
                    :rules="id == undefined ? 'required' : ''"
                  >
                    <label for=""
                      >Blog image
                      <span v-if="id == undefined" class="required">*</span></label
                    >
                    <v-file-input
                      truncate-length="15"
                      outlined
                      dense
                      prepend-inner-icon="mdi-attachment"
                      prepend-icon=""
                      v-model="blogImage"
                      :error-messages="errors"
                    ></v-file-input>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-4"
                    type="submit"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </form>
        </validation-observer>
      </div>
    </v-container-fluid>
    <v-dialog v-model="addTags" width="450" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          Add Tag
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="addTags = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Tag Name <span class="required">*</span></label>
              <v-text-field
                outlined
                dense
                :error-messages="errors"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <div class="btns-right">
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              class="px-4"
              type="submit"
            >
              Add
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashlayout>
</template>
  
  <script>
import dashlayout from "../layout/dashlayout.vue";
import CKEditor from "ckeditor4-vue";
import { required, digits, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import {
  UUID,
  AUTHENTIACTIONKEY,
  getDecodedStringBase64,
  encryptPayload,
} from "@/utils/helper";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
export default {
  components: { dashlayout, CKEditor, ValidationObserver, ValidationProvider },
  name: "blogaddedit",
  inject: ["mySpinner"],
  created() {
    this.id = this.$route.params.id;
    if (this.$route.params.id != "" && this.$route.params.id != undefined) {
      this.getBlogDetails();
    }
    this.getTagListDropdownData();
  },
  methods: {
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
    async adminAddEditBlogs() {
      const data = {
        authentication_key: AUTHENTIACTIONKEY,
        uuid: UUID,
        title: this.title,
        content: this.content,
        summary: this.summary,
        meta_title: this.metaTitle,
        meta_description: this.metaDescription,
        meta_keyword: this.metaKeyword,
        blog_tags: this.tags,
      };

      let url = "createAdminBlog";
      if (this.$route.params.id != undefined) {
        url = "updateAdminBlog";
        data.id = getDecodedStringBase64(this.$route.params.id);
      }
      const encryptData = encryptPayload(data);
      let formData = new FormData();
      formData.append("header_image", this.blogImage);
      formData.append("bfb_data", encryptData.bfb_data);
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(url, formData);
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.$router.push('/Bloglist')
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getTagListDropdownData() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getTagListDropdown", {
          action: "ALL_TAGS",
        });
        if (response.http_status === 200) {
          this.tagList = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.mySpinner.val = false;
        this.$toasted.error(e.message);
      }
    },
    async getBlogDetails() {
      try {
        const response = await this.$store.dispatch("viewAdminBlog", {
          action: "VIEW",
          id: getDecodedStringBase64(this.$route.params.id),
        });
        if (response.http_status === 200) {
          this.title = response.data.title;
          this.content = response.data.content;
          this.summary = response.data.summary;
          this.metaTitle = response.data.meta_title;
          this.metaKeyword = response.data.meta_keyword;
          this.metaDescription = response.data.meta_description;
          // this.blogImage = response.data.header_image
          this.tags =
            response.data.tags.length > 0
              ? response.data.tags.map((data) => data.id)
              : [];
          // this.$toasted.success(response.message)
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
  data: () => ({
    addTags: false,
    items: ["Programming", "Design", "Vue", "Vuetify"],
    id: "",
    title: "",
    content: "",
    summary: "",
    metaTitle: "",
    tags: "",
    tagList: [],
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    blogImage: "",
  }),
};
</script>
  
  <style>
</style>