<template>
    <dashlayout>
      <v-container-fluid fluid>
        <div class="dash-bg">
          <div class="box-title-with-btn">
            <div class="box-title">
              <v-icon size="24" color="#9DA73E" class="mr-2">
                mdi-account-group-outline
              </v-icon>
              User
            </div>
            <!-- <div>
              <DatePicker 
                v-model="dateFilter" a
                range 
                @change="fetchUserList" 
                format="YYYY-MM-DD" 
                valueType="YYYY-MM-DD"
                clearable
                placeholder="Date Filter"
              />
            </div> -->
            <div>
              <v-text-field 
                outlined 
                dense  
                v-model="search"
                @keypress.enter="fetchUserList"
                placeholder="Search..."
              ></v-text-field>
            </div>
            <!-- <div>
              <v-select
                :items="userStatusList"
                v-model="userStatus"
                label="Status"
                outlined
                dense
                chips
                multiple
                @change="fetchUserList"
                class="d-inline-block mr-2 mb-0"
              ></v-select>
            </div> -->
            <!-- <div class="btns-right">
              <v-btn
                elevation="0"
                color="#9DA73E"
                dark
                class="px-2"
                @click="openAddUserDialog"
              >
                <v-icon> mdi-plus </v-icon>
                Add User
              </v-btn>
            </div> -->
          </div>
  
          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="userListData"
                  :options.sync="options"
                  :server-items-length="total"
                  class="elevation-0"
                  :footer-props="footerList"
                  dense
                >
                <template v-slot:[`item.type`]="{ item }">
                    {{ item.is_seller == 1 ? "Seller" : "" }}
                    {{ item.is_admin == 1 ? "Admin" : "" }}
                    {{ item.is_trader == 1 ? "Trader" : "" }}
                    {{ item.is_buyer == 1 ? "Buyer" : "" }}
                    {{ item.is_partner == 1 ? "Partner" : "" }}
                  </template>
                 

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      fab
                      small
                      elevation="0"
                      color="transparent"
                      @click="gotoEdit(item.id)"
                    >
                      <v-icon color="#8f9742"> mdi-eye </v-icon>
                    </v-btn>
                   
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container-fluid>

      <!-- <ConformationBox 
        v-if="isShowApprove"
        :title="'Confirmation'"
        message="Are you sure want to approve ?"
        confirmText="Approve"
        @confirm="confirmApprove"
        @cancel="closeApprovModal"
    />
    <ConformationBox 
        v-if="isShowReject"
        :title="'Confirmation'"
        message="Are you sure want to reject ?"
        confirmText="Reject"
        @confirm="confirmReject"
        @cancel="closeRejectModal"
    /> -->
    </dashlayout>
  </template>
  
  <script>

  import dashlayout from '@/components/layout/dashlayout.vue';
  import {FOOTERPAGELIST , PAGINATIONOPTIONS,getEncodedStringBase64} from '@/utils/helper'
  import ConformationBox from "@/components/common/ConformationBox.vue";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  export default {
    components: { dashlayout,ConformationBox ,DatePicker},
    name: "dashboard",
    inject : ['mySpinner'],
    created() {
       this.fetchUserList(); 
    }, 
    watch : {
        options: {
            handler(newVal,oldVal) {
                if(JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.fetchUserList()
                }
            },
            deep: true
        },
    },
    methods: {
      gotoEdit(id) {
        this.$router.push({path:`/userView/${getEncodedStringBase64(id)}`})
      },
      async fetchUserList() {
        this.mySpinner.val =true
        try {
            const response = await this.$store.dispatch("partnerYourPartnerList", {
              ...this.options,
              action : 'LIST',
              search : this.search,
              status : this.userStatus,
              start_date : this.dateFilter.length > 0 ? this.dateFilter[0] != null ? this.dateFilter[0] : "" : "",
              end_date : this.dateFilter.length > 0 ? this.dateFilter[1] != null ? this.dateFilter[1] : "" : "",
            });
            if (response.http_status === 200) {
            this.userListData = response.data.data;
            this.total = response.data.total;
            this.mySpinner.val =false
            } else {
            this.$toasted.error(response.message);
            this.mySpinner.val =false
            }
        } catch (e) {
            this.$toasted.error(e.message);
            this.mySpinner.val =false
        }
      }
    },
    data: () => ({
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Registration Number", value: "registration_number" },
        { text: "Action", value: "action", align: "right" }
      ],

      userListData : [],
      total : 0,
      options : PAGINATIONOPTIONS,
      footerList : {
        'items-per-page-options':[10,20,50,100]
      },
      isAddUser : false,
      userStatusList : ['New', 'Verification Link Sent', 'Verified', 'Approved', 'Rejected', 'Suspended', 'Locked', 'Archived'],
      userStatus : ['New','Approved'],
      search : '',
      dateFilter : []
    }),
  };
  </script>
  
  <style>
  </style>