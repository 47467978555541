<template>
    <v-dialog v-model="addEditModule" width="500" persistent>
      <v-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(addEditUserModule)">
            <v-card-title class="dialog-title justify-space-between">
              {{ id !== "" ? "Edit" : "Add" }} Tag
              <v-btn
                fab
                x-small
                elevation="0"
                color="transparent"
                @click="closeDialog"
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
  
            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Module name"
                    rules="required"
                  >
                    <label for="">Name <span class="required">*</span></label>
                    <v-text-field
                      v-model="name"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Module title"
                    rules="required"
                  >
                    <label for=""
                      >Meta Title <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="title"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
  
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Module Keyword"
                    rules="required"
                  >
                    <label for=""
                      >Meta Keyword <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="metaKeyword"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Meta Description"
                    rules="required"
                  >
                    <label for=""
                      >Meta Description <span class="required">*</span></label
                    >
                    <v-textarea
                      v-model="description"
                      class="textarea_error"
                      rows="4"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-4">
              <v-spacer></v-spacer>
              <div class="btns-right">
                <v-btn elevation="0" color="#9DA73E" dark type="submit">
                  {{ id !== "" ? "Edit" : "Add" }} Tag
                </v-btn>
              </div>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </template>
   
  <script>
  import { required, digits, email, max, regex } from "vee-validate/dist/rules";
  import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
  } from "vee-validate";
  
  setInteractionMode("eager");
  
  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits. ({_value_})",
  });
  
  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });
  
  extend("max", {
    ...max,
    message: "{_field_} may not be greater than {length} characters",
  });
  
  extend("regex", {
    ...regex,
    message: "{_field_} {_value_} does not match {regex}",
  });
  
  extend("email", {
    ...email,
    message: "Email must be valid",
  });
  export default {
    props: {
      id: "",
    },
    inject: ["mySpinner"],
    data: () => ({
      name: "",
      title: "",
      metaKeyword: "",
      description: "",
      addEditModule: true,
    }),
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    async created() {
      if (this.id != "") {
        this.getUserModuleDetails();
      }
    },
    methods: {
      async addEditUserModule() {
        this.mySpinner.val = true;
        try {
          if (this.id != "") {
            const payload = {
              name: this.name,
              meta_title: this.title,
              meta_keyword: this.metaKeyword,
              meta_description: this.description,
              id: this.id,
            };
            const response = await this.$store.dispatch(
              "updateAdminTag",
              payload
            );
            if (response.http_status === 200 || response.http_status === 201) {
              this.$toasted.success(response.message);
              this.$emit("success");
              this.mySpinner.val = false;
            } else {
              this.$toasted.error(response.message);
              this.mySpinner.val = false;
            }
          } else {
            const payload = {
              name: this.name,
              meta_title: this.title,
              meta_keyword: this.metaKeyword,
              meta_description: this.description,
            };
            const response = await this.$store.dispatch(
              "createAdminTag",
              payload
            );
            if (response.http_status === 200 || response.http_status === 201) {
              this.$toasted.success(response.message);
              this.$emit("success");
              this.mySpinner.val = false;
            } else {
              this.$toasted.error(response.message);
              this.mySpinner.val = false;
            }
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      },
      // close dialog
      closeDialog() {
        this.$emit("cancel");
      },
      async getUserModuleDetails() {
        this.mySpinner.val = true;
        try {
          const payload = {
            id: this.id,
            action: "VIEW",
          };
          const response = await this.$store.dispatch("viewAdminTag", payload);
          if (response.http_status === 200 || response.http_status === 201) {
            this.name = response.data.name;
            this.title = response.data.meta_title;
            this.metaKeyword = response.data.meta_keyword;
            this.description = response.data.meta_description;
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      },
    },
  };
  </script>
   
  <style></style>