<template>
  <AuthLayout>
    <div>
      <div class="login-wrapper">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>

        <div class="title-of-auth mb-4">
          <h2>Register</h2>
        </div>

        <div class="form-part">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(registerUser)">
              <v-row>
                <v-col cols="6" md="12" sm="12" class="pb-0">
                  <validation-provider v-slot="{ errors }" name="First Name" rules="required">
                    <label for="">First Name <span class="required">*</span></label>
                    <v-text-field v-model="first_name" outlined dense :error-messages="errors" ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="12" sm="12" class="pb-0">
                  <validation-provider v-slot="{ errors }" name="Last Name" rules="required">
                    <label for="">Last Name <span class="required">*</span></label>
                    <v-text-field v-model="last_name" outlined dense :error-messages="errors" ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                    <label for="">Email <span class="required">*</span></label>
                    <v-text-field outlined dense :error-messages="errors" v-model="email"
                      ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" name="Phone Number" :rules="{
                    required: true,
                    digits: 10,
                  }">
                    <label for="">Mobile <span class="required">*</span></label>
                    <v-text-field v-model="phoneNumber" outlined dense :error-messages="errors"
                      ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <v-btn rounded dark color="#9DA73E" block large class="cta" type="submit">
                    Send OTP
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <div class="text-center mb-0">
                    Already have an account? <div style="color: #9DA73E; font-weight: 500;" class="pointer d-inline"
                      v-ripple>

                      <span @click="goToLogin">Login</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from './layout/auth.vue';
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

export default {
  name: 'registraionpage',
  inject : ['mySpinner'],
  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    first_name: '',
    last_name: '',
    phoneNumber: '',
    email: ''
  }),
  methods: {
    goToLogin() {
      this.$router.push({ path: '/login' })
    },
    async registerUser() {
      this.mySpinner.val = true;
      try {
        const payload = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phoneNumber,
          user_type: "Seller"
        }
        const res = await this.$store.dispatch('handleRegisterUser', payload)
        if (res.http_status === 200 || res.http_status === 201) {
          this.$toasted.success(res.message)
          let reference = this.$route.params.id;
          localStorage.setItem('reference_by', reference)
          localStorage.setItem('otp_key', res.data.otp_key)
          this.mySpinner.val = false;
          this.$router.push({ path: '/registerotp' })
        } else {
          this.$toasted.error(res.message)
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message)
        this.mySpinner.val = false;
      }
    }
  }
}
</script>

<style>

</style>