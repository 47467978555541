import axiosServices from "@/utils/axios";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    
})

const getters  = {

}

const actions = {
    getAdminContactUsList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('admin/contact_us/get', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}