<template>
  <v-dialog v-model="isWontheAuction" persistent width="1000">
        <v-card>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <div class="box-title-with-btn mb-0">
                  <div class="box-title mt-3">
                    <v-icon size="24" color="#9DA73E" class="mr-2">
                      mdi-gavel
                    </v-icon>
                    Auction Details
                  </div>
                  <v-btn
                    fab
                    x-small
                    elevation="0"
                    color="transparent"
                    @click="orderdetails = false"
                  >
                    <v-icon color="black"> mdi-close </v-icon>
                  </v-btn>
                </div>
              </v-col>
  
              <v-col cols="12" md="12" class="pt-0 pb-0">
                <label for="">Highest Bid</label>
  
                <div class="auction-list-wrap mt-2">
                  <div class="auction-box">
                    <div class="auction-left-part">
                      <v-avatar
                        class="mr-3 white--text font-weight-bold bounce-in-fwd"
                        color="primary"
                        size="40"
                        >H1</v-avatar
                      >
  
                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-2">Bid</div>
                        <div class="last-bid-box">₹ 1200.00</div>
                      </div>
                    </div>
                    <div class="auction-right-part">
                    </div>
                  </div>
                </div>
              </v-col>
  
              <!-- <v-col cols="12" md="12" v-if="commission_invoice_details">
                <table class="hm-p table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th style="width: 50px">#</th>
                      <th><h4>Item Name</h4></th>
                      <th style="width: 100px">
                        <h4>
                          SSN<br />
                          Code
                        </h4>
                      </th>
                      <th style="width: 80px">
                        <h4>
                          Taxable <br />
                          Amount
                        </h4>
                      </th>
                      <th style="width: 80px" v-if="commission_invoice_details.cgst !=''">
                        <h4>
                          CGST
                          <hr />
                          Amount
                        </h4>
                      </th>
                      <th style="width: 80px" v-if="commission_invoice_details.sgst !=''">
                        <h4>
                          SGST<br />
                          <hr />
                          Amount
                        </h4>
                      </th>
                      <th style="width: 80px" v-if="commission_invoice_details.igst !=''">
                        <h4>
                          IGST<br />
                          <hr />
                          Amount
                        </h4>
                      </th>
                      <th style="width: 120px"><h4>TOTAL</h4></th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.item_name }}</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.ssn_code }}</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.taxable_amount }}</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.ssn_code }}</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.cgst }}</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.sgst != '' ? commission_invoice_details.sgst : commission_invoice_details.igst}}</td>
                      <td>{{ commission_invoice_details && commission_invoice_details.gross_commission_amount }}</td>
                    </tr>
  
                    <tr style="background-color: #f7f7f7">
                      <td></td>
                      <td><strong>Total</strong></td>
                      <td>&nbsp;</td>
                      <td><strong>{{ commission_invoice_details && commission_invoice_details.taxable_amount }} </strong></td>
                      <td><strong>{{ commission_invoice_details && commission_invoice_details.cgst }}</strong></td>
                      <td><strong>{{ commission_invoice_details && commission_invoice_details.sgst != '' ? commission_invoice_details.sgst : commission_invoice_details.igst}}</strong></td>
                      <td>{{ commission_invoice_details && commission_invoice_details.gross_commission_amount }}</td>
                    </tr>
                  </tbody>
                </table>
  
                <table
                  class="hm-p table-bordered ml-auto"
                  style="width: fit-content; margin-top: 15px"
                >
                  <tbody>
                    <tr style="background-color: #f7f7f7">
                      <td style="text-align: right">
                        <strong>Biofuel Commission</strong>
                      </td>
                      <td style="width: 80px"><strong>{{commission_invoice_details && commission_invoice_details.service_charge_percentage}}</strong></td>
                      <td style="font-size: 18px; width: 120px; color: #9da648">
                        <strong>₹ {{commission_invoice_details && commission_invoice_details.total_commission_amount}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
  
                <div class="text-right mt-4 mb-2">
                  <v-btn
                    @click="paymentdialog=true"
                    elevation="0"
                    color="#9DA73E"
                    dark
                    large
                    class="px-5 ml-2"
                    style="min-width: 120px"
                  >
                    Pay ₹ {{commission_invoice_details && commission_invoice_details.total_commission_amount}}
                  </v-btn>
                </div>
  
                <v-alert
                  dense
                  outlined
                  color="warning"
                  prominent
                  class="mb-0 mt-3"
                >
                  After your order is confirmed, we will display Seller's name and
                  all relevant details.
                </v-alert>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  props : ["commission_invoice_details","bidDetails"], 
   data() {
        return {
            isWontheAuction : true,
        }
    }
}
</script>

<style>

</style>