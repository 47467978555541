<template>
    <v-app>
      <v-main>
        <div class="main-bg">
          <slot></slot>
        </div>
      </v-main>
  </v-app>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style>
  </style>