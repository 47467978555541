<template>
  <v-row justify="center">
    <v-dialog v-model="isShow" width="450" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          View Message
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="closeDialog"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
              <textarea class="custom-textarea" v-model="contactUsMessage" name="" id="" cols="30" rows="8"></textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
 
<script>
export default {
  inject: ["mySpinner"],
  props: {
    contactUsMessage: "",
  },
  data: () => ({
    message: "",
    isShow: true,
  }),

  methods: {
    // close dialog
    closeDialog() {
      this.$emit("cancel");
    },
  },
};
</script>
 
<style></style>