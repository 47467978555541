<template>
  <DashLayout>
    <div>
      <v-container-fluid fluid>
        <div class="dashboard-title">
          Registration No. : {{ registration_number }}
          <v-btn
            elevation="0"
            color="#9DA73E"
            dark
            class="px-2"
            @click="openInviteLinkDialog"
          >
            <v-icon> mdi-share </v-icon>
            Invite Link
          </v-btn>
        </div>

        <div class="dash-bg mb-4">
          <div class="box-title-with-btn">
            <div class="box-title">
              <v-icon size="24" color="#9DA73E" class="mr-2">
                mdi-account-group-outline
              </v-icon>
              User
            </div>
            <!-- <div>
              <DatePicker
                v-model="dateFilter"
                range
                @change="fetchUserList"
                format="YYYY-MM-DD"
                valueType="YYYY-MM-DD"
                clearable
                placeholder="Date Filter"
              />
            </div> -->
            <!-- <div>
              <v-text-field
                outlined
                dense
                v-model="search"
                @keypress.enter="fetchUserList"
                placeholder="Search..."
              ></v-text-field>
            </div> -->
            <!-- <div>
              <v-select
                :items="userStatusList"
                v-model="userStatus"
                label="Status"
                outlined
                dense
                chips
                multiple
                @change="fetchUserList"
                class="d-inline-block mr-2 mb-0"
              ></v-select>
            </div> -->
          </div>

          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="userListData"
                  :options.sync="options"
                  :server-items-length="total"
                  class="elevation-0"
                  :footer-props="footerList"
                  dense
                >
                  <template v-slot:[`item.type`]="{ item }">
                    {{ item.is_seller == 1 ? "Seller" : "" }}
                    {{ item.is_admin == 1 ? "Admin" : "" }}
                    {{ item.is_trader == 1 ? "Trader" : "" }}
                    {{ item.is_buyer == 1 ? "Buyer" : "" }}
                    {{ item.is_partner == 1 ? "Partner" : "" }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      fab
                      small
                      elevation="0"
                      color="transparent"
                      @click="gotoEdit(item.id)"
                    >
                      <v-icon color="#8f9742"> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="dash-bg mb-4">
          <div class="blog-wrap">
            <v-row>
              <v-col cols="12">
                <div class="box-title text-left mb-0">
                  <v-icon size="30" color="#9DA73E" class="mr-2">
                    mdi-chart-box-outline
                  </v-icon>
                  Products
                </div>
              </v-col>
              <v-col cols="12">
                <VueSlickCarousel
                  v-bind="settings"
                  v-if="categoryData.length > 0"
                >
                  <div v-for="item in categoryData" :key="item.id">
                    <v-card class="mx-auto blog-box">
                      <v-img
                        class="white--text align-end"
                        height="200px"
                        :src="item.image_path"
                      >
                      </v-img>

                      <div class="titile-and-price-section main-title">
                        <div class="left-t">
                          <div class="card-title">{{ item.name }}</div>
                        </div>
                        <div class="right-pr text-h5 font-weight-bold">
                          ₹ {{ item.price }} / MT
                          <v-chip
                            color="#d7dbb6"
                            class="d-block ml-auto"
                            small
                            style="width: fit-content"
                            >{{ currentMonthYear }}</v-chip
                          >
                        </div>
                      </div>

                      <div class="titile-and-price-section">
                        <div class="left-t">
                          <span>CV Value</span>
                        </div>
                        <div class="right-pr font-weight-bold">
                          <span>{{ item.cv_value }}</span>
                        </div>
                      </div>

                      <v-divider></v-divider>

                      <v-card-text class="text--primary">
                        <div class="text-right">
                          <span>Price can be vary </span>
                          <a href="/termcondition">Term & Condition</a>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </VueSlickCarousel>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="dash-bg mb-4">
          <div class="blog-wrap">
            <v-row>
              <v-col cols="12">
                <div class="box-title text-left mb-0">
                  <v-icon size="30" color="#9DA73E" class="mr-2">
                    mdi-post-outline
                  </v-icon>
                  Blogs
                </div>
              </v-col>

              <v-col cols="12">
                <VueSlickCarousel v-bind="settings" v-if="blogData.length > 0">
                  <template v-if="blogData.length > 0">
                    <div v-for="data in blogData" :key="data.slug">
                      <BlogCard :blogInfo="data" :key="data.id" />
                    </div>
                  </template>
                </VueSlickCarousel>
              </v-col>
              <template v-if="blogData.length == 0">
                <v-row>
                  <v-col cols="12" class="pb-10">
                    <h3 class="text-center">No Blog Found</h3>
                  </v-col>
                </v-row>
              </template>
              <v-col cols="12" v-if="blogData.length > 0">
                <div class="text-center">
                  <v-btn to="/blogs" color="#9da73e" dark> View All </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container-fluid>
    </div>
    <v-dialog v-model="isInviteLink" width="500" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          Invite
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="closeDialog"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    height="45"
                    @click="sharebyFacebook"
                  >
                    <v-icon large color="#9DA73E" v-bind="attrs" v-on="on">
                      mdi-facebook
                    </v-icon>
                  </v-btn>
                </template>
                <span>Facebook</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    height="45"
                    @click="sharebyTwitter"
                  >
                    <v-icon large color="#9DA73E" v-bind="attrs" v-on="on">
                      mdi-twitter
                    </v-icon>
                  </v-btn>
                </template>
                <span>Twitter</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    height="45"
                    @click="sharebyWhatsapp"
                  >
                    <v-icon large color="#9DA73E" v-bind="attrs" v-on="on">
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>
                </template>
                <span>Whatsapp</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    height="45"
                    @click="sharebyGmail"
                  >
                    <v-icon large color="#9DA73E" v-bind="attrs" v-on="on">
                      mdi-gmail
                    </v-icon>
                  </v-btn>
                </template>
                <span>Gmail</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    color="transparent"
                    height="45"
                    @click="copyLink"
                  >
                    <v-icon large color="#9DA73E" v-bind="attrs" v-on="on">
                      mdi-link-variant
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copy Link</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </DashLayout>
</template>

<script>
import DashLayout from "@/components/layout/dashlayout.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import BlogCard from "../blogs/BlogCard.vue";
import {
  FOOTERPAGELIST,
  PAGINATIONOPTIONS,
  getEncodedStringBase64,
  getDecodedString,
} from "@/utils/helper";

export default {
  name: "dashboard",
  inject: ["mySpinner"],
  data() {
    return {
      settings: {
        vertical: false,
        dots: true,
        // dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Registration Number", value: "registration_number" },
        { text: "Action", value: "action", align: "right" },
      ],
      // userStatusList: [
      //   "New",
      //   "Verification Link Sent",
      //   "Verified",
      //   "Approved",
      //   "Rejected",
      //   "Suspended",
      //   "Locked",
      //   "Archived",
      // ],
      // userStatus: ["New", "Approved"],
      // dateFilter: [],
      search: "",
      blogData: [],
      categoryData: [],
      currentMonthYear: "",
      // viewAuctionDetails: false,
      registeration_id: "",
      urlToShare: "",
      message: `I'm excited to share something groundbreaking with you – BiofuelBid, a game-changer in the bio coal industry.

BiofuelBid is a cutting-edge online platform connecting bio coal buyers and sellers through transparent auctions. Our goal is to revolutionize the way bio coals are traded, offering a range of benefits`,

      // liveAuctionList: [],
      // upcomingAutcionList: [],

      // singleAuctionData: null,
      // countTimerArray: [],

      userListData: [],
      total: 0,
      registration_number: "",
      isInviteLink: false,
      options: PAGINATIONOPTIONS,
      //   options: {
      //     page: 1,
      //     itemsPerPage: 10
      //   },
      footerList: {
        "items-per-page-options": [10, 20, 50, 100],
      },
    };
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchUserList();
        }
      },
      deep: true,
    },
  },

  components: {
    DashLayout,
    VueSlickCarousel,
    BlogCard,
    // ShareNetwork
  },
  created() {
    this.getBlogList();
    this.getCategoryList();
    this.fetchUserList();

    this.registeration_id = localStorage.getItem("registration_number");

    this.urlToShare =
      window.location.origin + `/register/${this.registeration_id}`;

    this.registration_number = getDecodedString(this.registeration_id).replace(
      /["']/g,
      ""
    );
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date = new Date();

    this.currentMonthYear = months[date.getMonth()] + " " + date.getFullYear();
  },
  methods: {
    sharebyFacebook() {
      const encodedMessage = encodeURIComponent(this.message);
      const routeData = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.urlToShare
      )}&quote=${encodedMessage}`;
      window.open(routeData);
    },
    sharebyWhatsapp() {
      const WhatsAppShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        this.message + "\n" + "\n" + this.urlToShare
      )}`;

      window.open(WhatsAppShareUrl, "_blank");
    },
    sharebyGmail() {
      const mailtoLink = `mailto:${encodeURIComponent(
        ""
      )}?subject=${encodeURIComponent(
        "Referral link of BioFuelBid"
      )}&body=${encodeURIComponent(
        this.message + "\n" + "\n" + this.urlToShare
      )}`;

      // Open the user's default email client
      window.location.href = mailtoLink;
    },
    sharebyTwitter() {
      const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        `I'm excited to share something groundbreaking with you – BiofuelBid, a game-changer in the bio coal industry.
BiofuelBid is a cutting-edge online platform connecting bio coal buyers and sellers through transparent auctions.` +
          "\n" +
          "\n" +
          this.urlToShare
      )}`;

      window.open(twitterIntentUrl, "_blank");
    },
    copyLink() {
      navigator.clipboard.writeText(this.urlToShare);
      this.$toasted.success("Link Copied");
    },
    openInviteLinkDialog() {
      this.isInviteLink = true;
    },
    closeDialog() {
      this.isInviteLink = false;
    },
    gotoEdit(id) {
      this.$router.push({ path: `/userView/${getEncodedStringBase64(id)}` });
    },
    async fetchUserList() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("partnerGetUserList", {
          ...this.options,
          action: "LIST",
          // search: this.search,
        });
        if (response.http_status === 200) {
          this.userListData = response.data.users.latest_five;
          this.total = response.data.total;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getBlogList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getDashboardBlogList", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.blogData = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getCategoryList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getSliderCategoryList", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.categoryData = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>

<style></style>
