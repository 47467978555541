<template>
    <v-dialog v-model="addEditModule" width="700" persistent>
        <v-card>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(submitContactUs)">
                            <v-card-title class="dialog-title justify-space-between"> {{ id !== '' ? 'Edit' : 'Add' }} Enquiry
                        <v-btn fab x-small elevation="0" color="transparent" @click="closeDialog">
                            <v-icon color="white">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pt-4">
                                <!-- <div class="m-title">Leave a <strong> Enquiry</strong></div> -->
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="First name" rules="required">
                                            <label for="">First Name <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors" readonly
                                                v-model="firstName"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Last name" rules="required">
                                            <label for="">Last Name <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors" readonly
                                                v-model="lastName"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                                            <label for="">Email <span class="required">*</span></label>
                                            <v-text-field outlined dense prepend-inner-icon="mdi-email-outline"
                                                v-model="email" readonly :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Phone Number" :rules="{
                                            required: true,
                                            digits: 10,
                                        }">
                                            <label for="">Mobile <span class="required">*</span></label>
                                            <v-text-field v-model="phoneNumber" outlined dense readonly
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Company Name" rules="required">
                                            <label for="">Company Name <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors" readonly
                                                v-model="companyName"></v-text-field> 
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Address line1" rules="required">
                                            <label for="">Address line1 <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors" readonly
                                                v-model="address1"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <label for="">Address line2</label>
                                        <v-text-field outlined dense readonly v-model="address2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Landmark / Area" rules="required">
                                            <label for="">Landmark / Area <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors" readonly
                                                v-model="landmark"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="State" rules="required">
                                            <label for="">State<span class="required">*</span></label>
                                            <v-select :items="stateItem" v-model="state" outlined dense readonly
                                                :error-messages="errors" item-value="id" item-text="name"
                                                @change="getCityDropdownData"></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="City" rules="required">
                                            <label for="">City<span class="required">*</span></label>
                                            <v-autocomplete :items="cityItem" v-model="city" outlined dense readonly
                                                :error-messages="errors" item-value="id" item-text="name"></v-autocomplete>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Country" rules="required">
                                            <label for="">Country<span class="required">*</span></label>
                                            <v-select :items="countryItem" readonly v-model="country" outlined dense 
                                                :error-messages="errors" item-value="id" item-text="name">
                                            </v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Pincode" rules="required">
                                            <label for="">pincode<span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors" readonly
                                                v-model="pinCode"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Material" rules="required">
                                            <label for="">Material Type <span class="required">*</span></label>
                                            <v-select :items="materialTypeList" v-model="materialType" item-value="id" readonly
                                                item-text="name" outlined dense :error-messages="errors"></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" name="Quantity" rules="required">
                                            <label for="">Quantity <span class="required">*</span></label>
                                            <v-text-field v-model="quantity" outlined type="number" readonly dense suffix="Tonne"
                                                :min="1" :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" name="Expected/Base Price"
                                            rules="required">
                                            <label for="">Expected/Base Price<span class="required">*</span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" size="18">
                                                            mdi-information-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Please enter per ton price</span>
                                                </v-tooltip>
                                            </label>

                                            <v-text-field v-model="expected_price" outlined type="number" dense suffix="" readonly
                                                :error-messages="errors" :min="0"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Buy / Sell" rules="required">
                                            <label for="">Inquiry For<span class="required">*</span></label>
                                            <v-select :items="['buy','sell']" v-model="buyOrSell" readonly
                                                 outlined dense :error-messages="errors"></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <label for="">Description</label>
                                        <textarea class="ma-0 pa-2" cols="2" placeholder="Type Message Here" dense readonly
                                            v-model="message"></textarea> 
                                    </v-col>
                                    <!-- <v-col cols="12" md="12">
                                        <v-btn rounded color="#9da73e" dark class="px-6" type="submit">
                                            Submit
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                    </v-card-text>
                        </form>
                    </validation-observer>
        </v-card>
    </v-dialog>
</template>
 
<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})
export default {
    props: {
        id: ''
    },
    inject: ['mySpinner'],
    data: () => ({
        firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            addressline1: "",
            addressline2: "",
            address2: '',
            address1: '',
            landmark: "",
            stateItem: [],
            state: "",
            cityItem: [],
            city: "",
            countryItem: [],
            country: 101,
            pinCode: "",
            materialTypeList: [],
            materialType: "",
            quantity: "",
            expected_price: "",
            message: "",
        addEditModule: true
    }),
    components: {
        ValidationObserver,
        ValidationProvider
    },
    async created() {
        if (this.id != '') {
            this.getDropdownData()
            this.getUserModuleDetails()
        }

    },
    methods: {
        // async addEditUserModule() {
        //     this.mySpinner.val = true;
        //     try {
        //         if (this.id != '') {
        //             const payload = {
        //                 name: this.name,
        //                 meta_title: this.title,
        //                 meta_keyword: this.metaKeyword,
        //                 meta_description: this.description,
        //                 id: this.id,
        //             }
        //             const response = await this.$store.dispatch("updateAdminTag", payload);
        //             if (response.http_status === 200 || response.http_status === 201) {
        //                 this.$toasted.success(response.data.message);
        //                 this.$emit('success')
        //                 this.mySpinner.val = false;
        //             } else {
        //                 this.$toasted.error(response.message);
        //                 this.mySpinner.val = false;
        //             }
        //         } else {
        //             const payload = {
        //                 name: this.name,
        //                 meta_title: this.title,
        //                 meta_keyword: this.metaKeyword,
        //                 meta_description: this.description,
        //             }
        //             const response = await this.$store.dispatch("createAdminTag", payload);
        //             if (response.http_status === 200 || response.http_status === 201) {
        //                 this.$toasted.success(response.data.message);
        //                 this.$emit('success')
        //                 this.mySpinner.val = false;
        //             } else {
        //                 this.$toasted.error(response.message);
        //                 this.mySpinner.val = false;
        //             }
        //         }

        //     } catch (e) {
        //         this.$toasted.error(e.message);
        //         this.mySpinner.val = false;
        //     }
        // },
        // close dialog
        closeDialog() {
            this.$emit('cancel')
        },
        async getUserModuleDetails() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    id: this.id,
                    action: 'VIEW',
                }
                const response = await this.$store.dispatch("getEnquiry", payload);
                if (response.http_status === 200 || response.http_status === 201) {
                    this.firstName = response.data.first_name,
                    this.lastName = response.data.last_name,
                    this.email = response.data.email,
                    this.phoneNumber = response.data.phone,
                    this.companyName = response.data.company_name,
                    this.address2 = response.data.address_line_2,
                    this.address1 = response.data.address_line_1,
                    this.landmark = response.data.district,
                    this.pinCode = response.data.pin_code,
                    this.city = response.data.city_id,
                    this.state = response.data.state_id,
                    this.materialType = response.data.category_id,
                    this.quantity = response.data.quantity,
                    this.expected_price = response.data.expected_price,
                    this.message = response.data.description
                    this.buyOrSell = response.data.enquiry_for
                    this.getCityDropdownData()
                    this.$toasted.success(response.data.message);
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
        async getDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    module:
                        "USER_TYPE,MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST,STATE_LIST",
                    country_id: 101,
                };
                const response = await this.$store.dispatch("loadDynamicData", payload);
                if (response.http_status === 200) {
                    this.monthlyCapacityList = response.data.monthly_capacity;
                    this.materialTypeList = response.data.material_type;
                    this.userTypeList = response.data.user_type;
                    this.countryItem = response.data.country_list;
                    this.stateItem = response.data.state_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
        async getCityDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    module: "CITY_LIST",
                    country_id: 101,
                    state_id: this.state,
                };
                const response = await this.$store.dispatch("loadDynamicData", payload);
                if (response.http_status === 200) {
                    this.cityItem = response.data.city_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
    }
}
</script>
 
<style></style>