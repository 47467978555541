<template>
  <weblayout>
    <div class="banner-blog small-inner-banner">
        <v-container>
          <div class="wrapper-of-banner">
            <div class="text-center my-0 mx-auto">
              <div class="text-h3 font-weight-medium white--text">Blogs</div>
              <v-breadcrumbs large :items="items">
                <template v-slot:divider>
                  <v-icon color="white">mdi-forward</v-icon>
                </template>
              </v-breadcrumbs>
            </div>
          </div>
        </v-container>
      </div>
    <v-container class="my-16 mt-6">
      <div class="blog-wrap">
        <v-row>
          <v-col cols="12" class="pt-0 justify-end d-flex">
            <v-text-field
              dense
              outlined
              rounded
              placeholder="Search"
              name="name"
              id="id"
              prepend-inner-icon="mdi-magnify"
              style="max-width: 250px;"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-for="(data,i) in blogData" :key="data.slug">
            <!-- <v-card class="mx-auto blog-box">
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/images/blog1.jpg"
              >
                <v-card-title  >Nation Bioenergy Mission 2018 : A Gateway for Biofuels in India</v-card-title>
              </v-img>


              <v-card-text class="text--primary">
                <div>The National Bioenergy Mission is a flagship program of the Indian government that aims to promote the use of biofuels and develop a sustainable ecosystem for the production and use of bioenergy in India. Here are some key highlights of the program <br> </div>
              </v-card-text>

              <v-card-actions>
                <v-btn color="#9da73e" text @click="blog1"> Explore </v-btn>
              </v-card-actions>
            </v-card> -->
            <BlogCard :blogInfo="data"/>
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-card class="mx-auto blog-box">
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/images/biofuel-policy.png" 
              >
                <v-card-title  >Biofuels & Government Policies around it</v-card-title>
              </v-img>


              <v-card-text class="text--primary">
                <div>Biomass power & cogeneration programme is implemented with the main objective of promoting technologies for optimum use of country’s biomass resources for grid power generation.  Biomass materials used for power generation include groundnut shells... </div>
              </v-card-text>

              <v-card-actions>
                <v-btn color="#9da73e" text @click="blog2"> Explore </v-btn>
              </v-card-actions>
            </v-card>
            
          </v-col> -->
          <!-- <v-col cols="12" md="3">
            <v-card class="mx-auto blog-box">
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/images/what_is_biofuel.png" 
              >
                <v-card-title  >What is BioFuels and its importance in Indian Context</v-card-title>
              </v-img>


              <v-card-text class="text--primary">
                <div>Biocoal also known as torrefied biomass, is a solid biofuel produced through a thermal process called torrefaction. Torrefaction is a heat treatment process applied to biomass such as wood, straw, and other agricultural residues, to remove moisture ...</div>
              </v-card-text>

              <v-card-actions>
                <v-btn color="#9da73e" text @click="blog3"> Explore </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="mx-auto blog-box">
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/images/carbon_credit.png" 
              >
                <v-card-title  >Carbon Credit : A Tool to incentive use of Biofuel</v-card-title>
              </v-img>


              <v-card-text class="text--primary">
                <div>Carbon credits and carbon markets are a component of national and international attempts to mitigate the growth in concentrations of greenhouse gases in the atmosphere. A carbon credit (often called a carbon offset) is a credit for greenhouse ... </div>
              </v-card-text>

              <v-card-actions>
                <v-btn color="#9da73e" text @click="blog4"> Explore </v-btn>
              </v-card-actions>
            </v-card>
          </v-col> -->
          <template v-if="blogData.length == 0">
                <v-row>
            <v-col cols="12" class="pb-10">
                <h3 class="text-center">No Blog Found</h3>
                </v-col>
                </v-row>
              </template>
          <v-col cols="12" v-if="blogData.length > 0">
            <div class="text-center">
              <v-btn to="/blogs" color="#9da73e" dark> View More </v-btn>
            </div>
          </v-col>
       
        </v-row>
      </div>
    </v-container>
  </weblayout>
</template>

<script>
import BlogCard from '../dashboard/blogs/BlogCard.vue';
import weblayout from "../layout/weblayout.vue";

export default {
components: { weblayout, BlogCard},
inject : ['mySpinner'],
data() {
  return {
    tab: null,
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Blogs",
        disabled: true,
        href: "/blogs",
      },
    ],
    blogData : []
  };
},
created() {
  this.fetchBlogList()
},
methods: {
  gohome(){
    this.$router.push('/')
  },
  blog1(){
    this.$router.push('/nation-bioenergy-mission-2018')
  },
  blog2(){
    this.$router.push('/biofiels-and-government-policies-around-it')
  },
  blog3(){
    this.$router.push('/what-is-biofuel')
  },
  blog4(){    
    this.$router.push('/carbon-credit-a-tool-to-incentive-use-of-biofuel')
  },

  async fetchBlogList() {
        try {
            this.mySpinner.val = true;
            const response = await this.$store.dispatch("getBlogList", {...this.options,action : 'LIST'});
            if (response.http_status === 200) {
            this.blogData = response.data.data;
            this.total = response.data.total;
            // this.$toasted.success(response.message);
            this.mySpinner.val = false;
            } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
            }
        } catch (e) {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
        }
      }
},
};
</script>

<style>
</style>