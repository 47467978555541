import axiosServices from "@/utils/axios";
import { COMMONPAYLOAD, OTPCOMMONPAYLOAD } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({

})

const getters = {

}

const actions = {
    getInvoices({ commit }, inputPage) {
        const payLoad = {
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0],
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            ...COMMONPAYLOAD,
            search: inputPage.search,
            action: inputPage.action,
            status: inputPage.status,
            start_date: inputPage.start_date,
            end_date: inputPage.end_date,
        }
        return axiosServices
            .post('admin/accounts/invoices/get', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewInvoiceOrderUpdated({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/invoices/get', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateInvoicesUpdated({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/invoices/add', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createInvoicesUpdated({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/invoices/add', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    cancelInvoices({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/invoices/change_status_updated', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    purchaseInvoicePayment({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase/invoices/add_payment', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    purchaseInvoiceGetPayment({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase/invoices/get_payment', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    salesInvoiceAddPayment({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/invoices/add_payment', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    salesInvoiceGetPayment({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/invoices/get_payment', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getPurchaseInvoices({ commit }, inputPage) {
        const payLoad = {
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0],
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            ...COMMONPAYLOAD,
            search: inputPage.search,
            action: inputPage.action,
            status: inputPage.status,
            start_date: inputPage.start_date,
            end_date: inputPage.end_date,
        }
        return axiosServices
            .post('admin/accounts/purchase/invoices/get', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewPurchaseInvoiceOrder({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase/invoices/get', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updatePurchaseInvoices({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase/invoices/add', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createPurchaseInvoices({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase/invoices/add', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    cancelPurchaseInvoices({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase/invoices/change_status_updated', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },

    // getInvoices({ commit }, inputPage) {
    //     const payLoad = {
    //         sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
    //         order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0],
    //         show_records: inputPage.itemsPerPage,
    //         page: inputPage.page,
    //         ...COMMONPAYLOAD,
    //         search: inputPage.search,
    //         action: inputPage.action,
    //         status: inputPage.status,
    //         start_date: inputPage.start_date,
    //         end_date: inputPage.end_date,
    //     }
    //     return axiosServices
    //         .post('/admin/accounts/get-invoices', payLoad)
    //         .then((response) => {
    //             if (response && response.data) {
    //                 return response.data
    //             }
    //         })
    //         .catch((error) => {
    //             if (error && error.response && error.response.data)
    //                 throw error.response.data
    //         })
    // },
    // deleteInvoices({ commit }, payload) {
    //     return axiosServices
    //         .post('admin/accounts/invoices/delete', { ...payload, ...COMMONPAYLOAD})
    //         .then((response) => {
    //             if (response && response.data) {
    //                 return response.data
    //             }
    //         })
    //         .catch((error) => {
    //             if (error && error.response && error.response.data)
    //                 throw error.response.data
    //         })
    // },
    // viewInvoiceOrder({ commit }, payload) {
    //     return axiosServices
    //         .post('admin/accounts/get-invoices', { ...payload, ...COMMONPAYLOAD })
    //         .then((response) => {
    //             if (response && response.data) {
    //                 return response.data
    //             }
    //         })
    //         .catch((error) => {
    //             if (error && error.response && error.response.data)
    //                 throw error.response.data
    //         })
    // },
    
    // updateInvoices({ commit }, payload) {
    //     return axiosServices
    //         .post('admin/accounts/invoices', { ...payload, ...COMMONPAYLOAD })
    //         .then((response) => {
    //             if (response && response.data) {
    //                 return response.data
    //             }
    //         })
    //         .catch((error) => {
    //             if (error && error.response && error.response.data)
    //                 throw error.response.data
    //         })
    // },
    // createInvoices({ commit }, payload) {
    //     return axiosServices
    //         .post('admin/accounts/invoices', { ...payload, ...COMMONPAYLOAD })
    //         .then((response) => {
    //             if (response && response.data) {
    //                 return response.data
    //             }
    //         })
    //         .catch((error) => {
    //             if (error && error.response && error.response.data)
    //                 throw error.response.data
    //         })
    // },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}