<template>
  <v-dialog v-model="addEditModule" width="450" persistent>
    <v-card>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addEditUserModule)">
          <v-card-title class="dialog-title justify-space-between">
            {{ id !== "" ? "Edit" : "Add" }} Module
            <v-btn
              fab
              x-small
              elevation="0"
              color="transparent"
              @click="closeDialog"
            >
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Module name"
                  rules="required"
                >
                  <label for="">Name <span class="required">*</span></label>
                  <v-text-field
                    v-model="name"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" v-if="id !== ''">
                <label for="">Slug</label>
                <v-text-field
                  v-model="slug"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label for="">Icon</label>
                <v-text-field v-model="icon" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <label for="">Order <span class="required">*</span></label>
                  <v-text-field
                    v-model="order"
                    outlined
                    dense
                    type="number"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <label for="">Parent Module</label>
                <v-select
                  :items="items"
                  outlined
                  dense
                  item-text="module_name"
                  item-value="id"
                  v-model="parentModule"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  inset
                  v-model="visibleNav"
                  label="Visible on Nav"
                  class="mt-0"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <div class="btns-right">
              <v-btn elevation="0" color="#9DA73E" dark large type="submit">
                {{ id !== "" ? "Edit" : "Add" }} Module
              </v-btn>
            </div>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  props: {
    id: "",
  },
  inject: ["mySpinner"],
  data: () => ({
    name: "",
    slug: "",
    icon: "",
    order: "",
    parentModule: "",
    visibleNav: false,
    items: [],
    addEditModule: true,
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  async created() {
    if (this.id != "") {
      this.getUserModuleDetails();
    }
    this.mySpinner.val = true;
    try {
      const payload = { module: "USER_MODULE" };
      const response = await this.$store.dispatch("loadDynamicData", payload);
      if (response.http_status === 200) {
        this.items = response.data.user_module;
        this.mySpinner.val = false;
      } else {
        this.$toasted.error(response.message);
        this.mySpinner.val = false;
      }
    } catch (e) {
      this.$toasted.error(e.message);
      this.mySpinner.val = false;
    }
  },
  methods: {
    async addEditUserModule() {
      this.mySpinner.val = true;
      try {
        if (this.id != "") {
          const payload = {
            module_name: this.name,
            module_order_no: this.order,
            visible_to_navbar: this.visibleNav,
            icon_class: this.icon,
            product_type: "Admin",
            parent_module_id:
              this.parentModule != "" ? this.parentModule.id : "",
            id: this.id,
          };
          const response = await this.$store.dispatch(
            "updateUserModule",
            payload
          );
          if (response.http_status === 200 || response.http_status === 201) {
            this.$toasted.success(response.message);
            this.$emit("cancel");
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } else {
          const payload = {
            module_name: this.name,
            module_order_no: this.order,
            visible_to_navbar: this.visibleNav,
            icon_class: this.icon,
            product_type: "Admin",
            parent_module_id:
              this.parentModule != "" ? this.parentModule.id : "",
          };
          const response = await this.$store.dispatch(
            "createUserModule",
            payload
          );
          if (response.http_status === 200 || response.http_status === 201) {
            this.$toasted.success(response.message);
            this.$emit("cancel");
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    // close dialog
    closeDialog() {
      this.$emit("cancel");
    },
    async getUserModuleDetails() {
      this.mySpinner.val = true;
      try {
        const payload = {
          id: this.id,
          action: "VIEW",
        };
        const response = await this.$store.dispatch("viewUserModule", payload);
        if (response.http_status === 200 || response.http_status === 201) {
          this.name = response.data.module_name;
          this.slug = response.data.module_slug;
          this.icon = response.data.icon_class;
          this.order = response.data.module_order_no;
          this.visibleNav = response.data.visible_to_navbar == 0 ? false : true;
          this.parentModule = response.data.parent_module_id;
          this.$toasted.success(response.data.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>

<style>
</style>