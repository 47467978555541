<template>
    <v-card class="mx-auto blog-box">
        <v-img class="white--text align-end" height="200px" :src="blogInfo.header_image_full_path">
            <v-card-title>{{ blogInfo.title }}</v-card-title>
        </v-img>
        <!-- <v-card-subtitle class="pb-0"> Number 10 </v-card-subtitle> -->
        <v-card-text class="text--primary">
            <div>
                {{ blogInfo.meta_description }}
                <!-- The National Bioenergy Mission is a flagship program
                of the Indian government that aims to promote the use
                of biofuels and develop a sustainable ecosystem for
                the production and use of bioenergy in India. Here are
                some key highlights of the program -->
            </div>
        </v-card-text>

        <v-card-actions>
            <v-btn color="#9da73e" text @click="viewBlogDetails(blogInfo)">
                Explore
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        blogInfo : ''
    },
    methods : {
        blog1() {
            this.$router.push("/nation-bioenergy-mission-2018");
        },
        viewBlogDetails(data) {
            this.$router.push({path:`/blogs/${data.slug}/${data.id}`})
        }
    }

}
</script>

<style></style>