<template>
  <AuthLayout>
    <div>
      <div class="login-wrapper">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>

        <div class="title-of-auth mb-4">
          <h2>Login</h2>
        </div>

        <div class="form-part">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(loginUser)">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <label for=""
                      >Email / Mobile <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="email"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <label for=""
                      >Password<span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="userpassword"
                      outlined
                      dense
                      :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isShow ? 'text' : 'password'"
                      @click:append="isShow = !isShow"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                  <div class="text-right">
                    <v-btn
                      text
                      small
                      right
                      color="#7A832A"
                      to="/resetPasswordEmail"
                    >
                      Forgot Password ?
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="recaptchaSiteKey"
                    @verify="onVerify"
                    @expired="onExpired"
                    @error="onError"
                    :loadRecaptchaScript="true"
                  >
                  </vue-recaptcha>
                  <p v-if="recpatchaError != ''" class="required">
                    {{ recpatchaError }}
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    rounded
                    dark
                    color="#9DA73E"
                    block
                    large
                    class="cta"
                    type="submit"
                  >
                    Login
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <div class="text-center mb-0">
                    Don't have an account? Register as a: <br />
                    <div
                      style="color: #9da73e; font-weight: 500"
                      class="pointer d-inline"
                      v-ripple
                    >
                      <span @click="goToRegister">Buyer/Seller User</span>
                    </div>
                    <br />
                    <div
                      style="color: #9da73e; font-weight: 500"
                      class="pointer d-inline"
                      v-ripple
                    >
                      <span @click="goToPartnerRegister">Partner User</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

    <script>
import AuthLayout from "./layout/auth.vue";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import {
  getDecodedStringBase64,
  getEncodedString,
  getEncodedStringBase64,
} from "../utils/helper";
import VueRecaptcha from "vue-recaptcha";
setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  name: "login",
  inject: ["mySpinner"],
  data: () => ({
    email: "",
    userpassword: "",
    isShow: false,
    recaptchaSiteKey: "6LcX8WUlAAAAAKjmy52YmpKJ34D_sEtC3S7NJgXT",
    recaptchaToken: "",
    isRecatchaVerified: false,
    recpatchaError: "",
    isSeller: false,
    isTrader: false,
    isBuyer: false,
  }),
  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider,
    VueRecaptcha,
  },
  methods: {
    goToRegister() {
      this.$router.push({ path: "/register" });
    },
    goToPartnerRegister() {
      this.$router.push({ path: "/partner-register" });
    },
    async loginUser() {
      if (this.isRecatchaVerified) {
        try {
          this.mySpinner.val = true;
          const payload = {
            username: this.email,
            password: this.userpassword,
            grant_type: "password",
            captcha: this.recaptchaToken,
          };
          const response = await this.$store.dispatch("handleLogin", payload);
          if (response.http_status === 200) {
            localStorage.setItem(
              "access_token",
              getEncodedString(response.data.access_token)
            );
            localStorage.setItem(
              "refresh_token",
              getEncodedString(response.data.refresh_token)
            );
            localStorage.setItem(
              "is_admin",
              getEncodedStringBase64(JSON.stringify(response.data.is_admin))
            );
            localStorage.setItem(
              "is_buyer",
              getEncodedStringBase64(JSON.stringify(response.data.is_buyer))
            );
            localStorage.setItem(
              "is_seller",
              getEncodedStringBase64(JSON.stringify(response.data.is_seller))
            );
            localStorage.setItem(
              "is_trader",
              getEncodedStringBase64(JSON.stringify(response.data.is_trader))
            );
            // localStorage.setItem(
            //   "is_trader",
            //   getEncodedStringBase64(JSON.stringify(response.data.is_trader))
            // );
            localStorage.setItem(
              "last_password_changed_at",
              response.data.last_password_changed_at
            );
            localStorage.setItem("user_id", response.data.id);
            localStorage.setItem("first_name", response.data.first_name);
            localStorage.setItem("last_name", response.data.last_name);
            localStorage.setItem(
              "wallet_balance",
              getEncodedStringBase64(
                JSON.stringify(response.data.wallet_balance)
              )
            );
            localStorage.setItem(
              "branches",
              JSON.stringify(response.data.organization)
            );
            localStorage.setItem(
              "is_partner",
              getEncodedStringBase64(JSON.stringify(response.data.is_partner))
            );
            localStorage.setItem(
              "registration_number",
              getEncodedString(response.data.registration_number)
            );
            var user_type = "";
            if (response.data.is_seller) {
              user_type = "Seller";
            }
            if (response.data.is_buyer) {
              user_type = "Buyer";
            }
            if (response.data.is_admin) {
              user_type = "Admin";
            }
            if (response.data.is_trader) {
              user_type = "Trader";
            }
            if (response.data.is_partner) {
              user_type = "Partner";
            }

            localStorage.setItem("user_type", getEncodedString(user_type));

            // this.$toasted.success(response.message)

            this.mySpinner.val = false;

            if (response.data.is_partner) {
              this.$router.push({ path: "/partner-dashboard" });
            } else {
              this.$router.push({ path: "/dashboard" });
            }

            // localStorage.setItem('otp_key', response.data.otp_key);
            // this.$router.push({ path: '/verifyOtp' })
            // this.$toasted.success(response.message)
            // this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
            this.$refs.recaptcha.reset();
            this.recpatchaError = "";
            this.isRecatchaVerified = false;
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
          this.$refs.recaptcha.reset();
          this.recpatchaError = "";
          this.isRecatchaVerified = false;
        }
      } else {
        this.recpatchaError = "Please complete the reCAPTCHA to proceed.";
      }
    },
    onExpired() {
      this.recaptchaToken = "";
      this.isRecatchaVerified = false;
      this.$refs.recaptcha.reset();
    },
    onVerify(response) {
      this.recaptchaToken = response;
      if (this.recaptchaToken != "") {
        this.isRecatchaVerified = true;
        this.recpatchaError = "";
      } else {
        this.isRecatchaVerified = false;
      }
    },
    onError() {
      this.recaptchaToken = "";
      this.$refs.recaptcha.reset();
      this.isRecatchaVerified = false;
    },
  },
};
</script>

    <style>
</style>