<template>
    <AuthLayout>
    <div>
      <div class="login-wrapper" style="min-width: 400px">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
  
        <div class="title-of-auth mb-4">
          <h2>Registration Successful!</h2>
        </div>
  
        <div class="form-part">
          <v-row>
            <v-col cols="12" class="pb-0">
              <div class="text-center mt-10">
                <div>
                  <v-icon size="60" color="#959e45  ">
                    mdi-check-circle-outline
                  </v-icon>
                </div>
                <div class="mt">
                  <div>Thank you for registering!</div>
  
                  <v-alert class="mt-5 mb-0" dense text icon="mdi-account-clock-outline" color="#e5a900">
                    Your registration is pending for approval.
                  </v-alert>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
            <div class="text-center mb-0">
                <div style="color: #9DA73E; font-weight: 500;" class="pointer d-inline" v-ripple>
                <span @click="goToLogin">Go To Login</span>
                </div>
            </div>
          </v-col>
          </v-row>
        </div>
      </div>
    </div>
    </AuthLayout>
  </template>
  
  <script>
import AuthLayout from './layout/auth.vue';

  export default {
    name :'forapproval',
    components : {
        AuthLayout
    },
    methods : {
        goToLogin() {
            this.$router.push({path : '/login'})
        }
    }
  };
  </script>
  
  <style>
  </style>