<template>
  <weblayout>
    <div class="banner-blog">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="title-of-blog">
            Biofuels & Government Policies around it
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
                Darshan Baraiya
                <div class="grey--text body-2">On 07/03/2022 - 16:18PM</div>
              </div>
            </div>
          </div>

          <div class="img-of-blog">
            <!-- <div class="next-prev-btn justify-end">
              <v-btn text dark>
                <v-icon> mdi-chevron-left </v-icon>
                Prev Blog
              </v-btn>
              <v-btn text dark>
                Next Blog
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </div> -->
            <img
              style="max-width: 600px"
              src="@/assets/images/biofuel-policy.png"
              alt=""
            />
          </div>
        </div>
      </v-container>
    </div>

    <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12">
            <div class="about-wrap">
              <p class="mt-10"><strong>BIOMASS</strong></p>

              <p>
                Biomass has always been an important energy source for the
                country considering the benefits it offers.  It is renewable,
                widely available, carbon-neutral and has the potential to
                provide significant employment in the rural areas.  Biomass is
                also capable of providing firm energy.  About 32% of the total
                primary energy use in the country is still derived from biomass
                and more than 70% of the country’s population depends upon it
                for its energy needs.  Ministry of New and Renewable Energy has
                realised the potential and role of biomass energy in the Indian
                context and hence has initiated a number of programmes for
                promotion of efficient technologies for its use in various
                sectors of the economy to ensure derivation of maximum
                benefits.  For efficient utilization of biomass, bagasse based
                cogeneration in sugar mills and biomass power generation have
                been taken up under biomass power and cogeneration programme.
              </p>
              <p>
                Biomass power & cogeneration programme is implemented with the
                main objective of promoting technologies for optimum use of
                country’s biomass resources for grid power generation.  Biomass
                materials used for power generation include bagasse, rice husk,
                straw, cotton stalk, coconut shells, soya husk, de-oiled cakes,
                coffee waste, jute wastes, groundnut shells, saw dust etc. 
              </p>

              <p class="mt-10"><strong>POTENTIAL FOR BIOMASS</strong></p>
              <p>
                As per a recent study sponsored by MNRE, the current
                availability of biomass in India is estimated at about 750
                million metric tonnes per year.    The Study indicated estimated
                surplus biomass availability at about 230 million metric tonnes
                per annum covering agricultural residues corresponding to a
                potential of about 28 GW.  This apart, about 14 GW additional
                power could be generated through bagasse based cogeneration in
                the country’s 550 Sugar mills, if these sugar mills were to
                adopt technically and economically optimal levels of
                cogeneration for extracting power from the bagasse produced by
                them.
              </p>

              <p class="mt-10"><strong>TECHNOLOGY USED FOR BIOMASS</strong></p>
              <p>Combustion</p>
              <p>
                The thermo chemical processes for conversion of biomass to
                useful products involve combustion, gasification or pyrolysis.
                The most commonly used route is combustion. The advantage is
                that the technology used is similar to that of a thermal plant
                based on coal, except for the boiler.  The cycle used is the
                conventional rankine cycle with biomass being burnt in
                high-pressure boiler to generate steam and operating a turbine
                with the generated steam. The exhaust of the steam turbine can
                either be fully condensed to produce power, or used partly or
                fully for another useful heating activity. The latter mode is
                called cogeneration. In India, cogeneration route finds
                application mainly in industries.
              </p>
              <p>Cogeneration in Sugar and Mills</p>
              <p>
                Sugar industry has been traditionally practicing cogeneration by
                using bagasse as a fuel.  With the advancement in the technology
                for generation and utilization of steam at high temperature and
                pressure, sugar industry can produce electricity and steam for
                their own requirements.  It can also produce significant surplus
                electricity for sale to the grid using same quantity of
                bagasse.  For example, if steam generation temperature/pressure
                is raised from 400oC/33 bar to 485oC/66 bar, more than 80 KWh of
                additional electricity can be produced for each ton of cane
                crushed.  The sale of surplus power generated through optimum
                cogeneration would help a sugar mill to improve its viability,
                apart from adding to the power generation capacity of the
                country. 
              </p>

              <p class="mt-10"><strong>DEPLOYMENT OF BIOMASS</strong></p>
              <p>
                The Ministry has been implementing biomass power/co-generation
                programme since mid-nineties. Over 800 biomass power and
                bagasse/Non-bagasse cogeneration projects aggregating
                to 10205.61 MW capacity have been installed in the country for
                feeding power to the grid. States which have taken leadership
                position in implementation of bagasse cogeneration projects are
                Maharashtra, Karnataka, Uttar Pradesh, Tamil Nadu and Andhra
                Pradesh. The leading States for biomass power projects are
                Chhattisgarh, Madhya Pradesh, Gujarat, Rajasthan and Tamil
                Nadu.  
              </p>

              <p class="mt-10">
                <strong
                  >CENTRAL FINANCIAL ASSISTANCE AND FISCAL INCENTIVES  </strong
                >
              </p>

              <p>
                CFA for Briquette/Pellet manufacturing and Biomass (non-bagasse)
                based projects (Valid upto 31 March 2026).
              </p>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <td class="white--text">Project Type</td>
                      <td class="white--text">Capital Subsidy</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Briquette / Pellet Manufacturing plants</td>
                      <td>
                        Rs. 9 Lakh per MTPH (metric ton/hour) manufacturing
                        capacity (maximum CFA of Rs 45 Lakhs per plant)
                      </td>
                    </tr>
                    <tr>
                      <td>Biomass (Non-bagasse ) cogeneration projects</td>
                      <td>
                        Rs. 40 Lakhs/MW (on Installed Capacity) (maximum CFA of
                        Rs. 5 Crores per project)
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <p class="mt-">
                *Service charges to Implementing/ inspection agencies:
              </p>
              <div>
                i)Implementing agency (IA) i.e. IREDA shall be provided a
                service charge @1% of total CFA.
              </div>
              <div>
                ii)Performance Inspection Agency shall be provided service
                charge of:
              </div>

              <p class="mt-12">
                (a)  Rs. 25,000 per metric ton per hour (maximum Rs. 1 Lakh per
                project) for Briquette/Pellet manufacturing plants, and
              </p>
              <p>
                (b)  Rs.1 Lakh/MW (Maximum Rs.5 Lakh per project) for Biomass
                (non-bagasse) cogeneration projects. 
              </p>

              <p class="mt-10"><strong>Current Status OF SCHEME</strong></p>

              <p>
                As on 31.10.2022, a total capacity of 10205.61 MW has been
                installed in Biomass Power and Cogeneration Sector.
              </p>
              <p>Installed Capacity of Biomass IPP – 1871.11 MW</p>
              <p>Installed Capacity of Bagasse Cogeneration – 7562.45 MW</p>
              <p>Installed Capacity of Non-Bagasse Cogeneration - 772.05 MW</p>
              <p>(Source : MNRE Website of Government of India)</p>

              <p class="mt-10"><strong>BIOGAS</strong></p>
              <p>Brief Introduction:</p>
              <p>
                Biogas is produced when bio-degradable organic materials/wastes
                such as cattle-dung, biomass from farms, gardens, kitchens,
                industry, poultry droppings, night soil and municipals wastes
                are subjected to a scientific process, called Anaerobic
                Digestion (A.D.) in a Biogas Plants. Biogas Plant designs depend
                upon several factors and the feed stock to be processed is of
                paramount importance. Biogas is the mixture of gases (primarily
                methane (CH4) and Carbon di-oxide (CO2) and traces of Hydrogen
                Sulfide (H2S), Moisture) produced by the decomposition/breakdown
                of bio-degradable organic matter in the absence of oxygen from
                raw materials such as agricultural waste, cattle dung, poultry
                droppings, municipal waste, plant material, sewage, green waste
                or food/kitchen waste. Biogas has a calorific value of about
                5000 kcal per m3. The digested slurry produced from Biogas
                Plants as a by-product is a better source of nutrient enriched
                organic manure for use in Agriculture. It not only helps in
                improving the crop yield but also maintain soil health.
              </p>
              <p>
                There is ample potential of setting up biogas plants considering
                the livestock population of 512.06 million, which includes about
                300 million (299.98 million) total population of bovines
                (comprising of cattle, buffalo, mithun and yak). The livestock
                sector contributes about significantly to India’s GDP and will
                continue to increase. The dissemination of biogas technology is
                a boon for Indian farmers with its direct and collateral
                benefits.
              </p>
              <p class="pl-5">
                i.New National Biogas and Organic Manure Programme (NNBOMP), for
                Biogas Plant size ranging from 1 cu.m. to 25 cu.m. per day.
              </p>
              <p class="pl-5">
                ii.Biogas Power Generation (Off-grid) and Thermal energy
                application Programme (BPGTP), for setting up biogas plants in
                the size range of 30 m3 to 2500 m3 per day,  for corresponding
                power generation capacity  range of 3 kW to 250 kW from biogas
                or  raw biogas for thermal energy /cooling applications.
              </p>

              <p>
                Biogas contains about 55-65 % of methane, 35- 44 % of carbon
                dioxide and traces of other gases, such as Hydrogen Sulphide,
                Nitrogen and Ammonia. Biogas, in its raw form, that is without
                any purification, can be used as clean cooking fuel like LPG,
                lighting, motive power and generation of electricity. It can be
                used in diesel engines to substitute diesel up to 80% and up to
                100% replacement of diesel by using 100% Biogas Engines.
                Further, Biogas can be purified and upgraded up to 98% purity of
                methane content to make it suitable to be used as a green and
                clean fuel for transportation or filling in cylinders at high
                pressure of 250 bar or so and called as Compressed Bio-Gas
                (CBG).
              </p>
              <p>
                Initially, Biogas Plants were developed for digesting cattle
                dung. However, over a period of time, technology has been
                developed for the bio-methanation of various types of biomass
                materials and organic wastes. Biogas plant designs are now
                available from 0.5 M3 to 1000 M3 unit size or more and multiples
                of that can be installed for achieving higher Biogas Plant
                sizes, depending upon availability of the raw material such as
                for family/ household, small farmers, dairy farmers and for
                community, institutional and industrial/ commercial
                applications. The unit size of industrial and municipal wastes
                based biogas plants may go up to 15000 M3 to 20000 M3 biogas
                production per day.
              </p>
              <p>Design and approved models:</p>
              <p>
                MNRE has approved various designs of biogas plants and the same
                have become proven ones for field worthiness. Indian standards
                for Biogas Plants, accessories and appliances have been also
                brought out by the MNRE and BIS. The process is a on going one.
                There are 4 types of basic model and 10 types of designs of
                biogas plants approved under the NNBOMP. The details of which
                are available in the scheme Guidelines. All approved designs are
                eligible for financial subsidies and other facilities uniformly
                across the country.  
              </p>
              <p>
                MNRE continuoes to give high priority for the development and
                utilization of biogas as energy in its various forms.  Under the
                National Biogas and Manure Management Programme (NBMMP), about
                50.0 Lakh (5 Million) Family size plants have been installed up
                to 2017-18. The NBMMP scheme has been redesigned, modified and
                renamed as New National Biogas and Organic Manure Programme
                (NNBOMP) and continued from 2018-19 with the aim to enhance the
                biogas production from small Biogas plants of 1 to 25
                M3 capacity. The scheme aims to set up about 2.5 Lakh units of
                Biogas plants of various sizes in the above mentioned capacity
                range with an overall biogas generation of about 8 lakh Cu. M.
                per day. The Government of India, MNRE, has also enhanced the
                CFA for various approved components. Now 100% biogas engines in
                smaller capacity ranges are available, and the surplus biogas
                can be utilized for meeting lighting, small power and
                electricity requirement from a Biogas Plant.
              </p>
              <p>
                For encouraging farmers to use nutrient enriched organic
                bio-manure, the scheme also aims for value addition of the
                biogas plant slurry by linking it with enrichment units such as
                vermicomposting, Phosphate Rich Organic Manure (PROM) plants and
                other organic enrichment facilities as a source of an additional
                income and saving in chemical fertilizers bills of farmers.    
                  
              </p>
              <p>
                Biogas based Power Generation (Off-grid) and Thermal Application
                Programme (BPGTP)
              </p>
              <p>
                Biogas plants are reliable source of decentralized Renewable
                Energy for heating, cooking as well as generating electricity/
                power generation and thermal energy application alternatives in
                our country.  In  order  to  promote  this  Decentralized 
                Renewable Energy Source (DRES) of  power  generation, 
                specifically  in  the  small  capacity range (3 kW to 250 kW)
                and thermal energy for heating/cooling  from the biogas produced
                from Biogas plants of 30 M3 to 2500 M3  size, operated  based 
                on the availability  of required quantity of biodegradable
                organic waste(s).
              </p>
              <p>
                The organic bio-degradable wastes from various sources such as
                cattle dung/ animal wastes, food & kitchen waste, poultry
                dropping waste, agro-industry waste etc. are the feed stock for
                Biogas plants. These plants are especially beneficial for
                meeting Off-grid Power requirements for individual dairy and
                poultry plants, dairy co-operatives for operation of dairy
                equipment and other electrical, thermal and cooling energy
                requirements for plant operation. The installations of such
                biogas systems replaces diesel in DG sets and also reduce the
                electricity bills of the individual farmers/ beneficiary,
                entrepreneurs, dairy farmer, dairy co-operatives thereby helping
                to increase the income of farmers/ end users. The nutreient
                enriched organic bio-manure is another stream of income
                generation from biogas projects and at the same time saving in
                the expenditure of chemical fertilizers by reduction of use of
                chemical fertilizers and other profitable ventures like organic
                farming. 
              </p>
              <p>Biogas plants installed under the scheme meets the electrical or thermal requirements of the beneficiaries and dairy farmers and other organizations. It is used for milk chilling applications and other general applications such as pumping, lighting, irrigation as well as cooking. The farmers can also sell out surplus biogas/ electricity to his neighbours in off-grid mode.</p>
              <p>Impact of biogas power plants:</p>
              <p>Based on the data reported and evaluation done through third party study, the overall impact of the programme implementation was observed to be encouraging. As revealed from the table given below, for a case study of 45 Biogas plants and extrapolated for 163 projects,</p>

              <v-simple-table dense style="width: 500px;">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <td class="white--text">Name</td>
                      <td class="white--text">Numers</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                       <td>Total No. of Plants</td>
                       <td>163</td>
                    </tr>
                    <tr>
                       <td>Annual Energy Cost Savings (In Rs. Lakhs)</td>
                       <td>787</td>
                    </tr>
                    <tr>
                       <td>Annual CO2 Savings (In Tons)</td>
                       <td>9587</td>
                    </tr>
                    <tr>
                       <td>Annual Bio-manure Production (In Tons)</td>
                       <td>32582</td>
                    </tr>
                    <tr>
                       <td>Direct Employment (Man-days)</td>
                       <td>63438</td>
                    </tr>
                    <tr>
                       <td>In-Direct Employment (Man-days)</td>
                       <td>56894</td>
                    </tr>
                   
                  </tbody>
                </template>
              </v-simple-table>


              

              <p class="mt-12">Implementation of BPGTP:</p>
              <p>The BPGTP Scheme is being implemented by the Agriculture and Rural Development Departments of the States and Dairy Cooperatives. However, the programme is also implemented through the State Renewable Energy Agencies (SNAs), Biogas Development and Training Centres (BDTCs), Khadi and Village Industries Commission (KVIC) and National Dairy Development Board (NDDB) in States where Agriculture and State Rural Development Departments are not in a position to implement. The Programme Implementing Agencies (PIAs) may take help of Panchayati Raj Institutions/ Local Bodies (LBs) as an overarching Institutions allowing need based interventions under the community development programme in rural areas as well as areas to cover North Eastern Areas, Forest Fringe Villages, in large population concentration of SC/ ST communities including in tribal areas. </p>
              <p><strong>(Source : MNRE Website of Government of India)</strong></p>

              
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-divider></v-divider>
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
               Darshan Baraiya
                <div class="grey--text body-2">07/03/2022</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </weblayout>
</template>
  
  <script>
import weblayout from "../layout/weblayout.vue";

export default {
  components: { weblayout },
  data() {
    return {
      tab: null,
      items: [
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
      ],
    };
  },
};
</script>
  
  <style>
</style>