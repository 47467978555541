<template>
    <dashlayout>
      <v-container-fluid fluid>
        <div class="dash-bg">
          <div class="box-title-with-btn">
            <div class="box-title">
              <v-icon size="24" color="#9DA73E" class="mr-2">
                mdi-post-outline
              </v-icon>
              Blogs
            </div>
            <div class="btns-right">
             
              <v-btn
                elevation="0"
                color="#9DA73E"
                dark
                class="px-2"
                @click="gotoAdd"
              >
                <v-icon> mdi-plus </v-icon>
                Add Blog
              </v-btn>
            </div>
          </div>
  
          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="blogData"
                  :options.sync="options"
                  :server-items-length="total"
                  class="elevation-0"
                  :footer-props="footerList"
                  dense
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      fab
                      small
                      elevation="0"
                      color="transparent"
                      @click="gotoEdit(item.id)"
                    >
                      <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                    </v-btn>
                    <!-- <v-btn fab small elevation="0" color="transparent" @click="dialog=true">
                      <v-icon color="red"> mdi-delete-outline </v-icon>
                    </v-btn> -->
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container-fluid>
      <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6">
           Are you sure want to delete ?
          </v-card-title>
  
          <v-card-text>
            Lorem ipsum dolor sit amet consectetur.
          </v-card-text>
  
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
  
            <v-btn color="grey darken-1" text @click="dialog = false">
              No
            </v-btn>
  
            <v-btn color="red darken-1" text @click="dialog = false" class="font-weight-bold">
              Yes, Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </dashlayout>
  </template>
  
  <script>
  import dashlayout from "../layout/dashlayout.vue";
  import {FOOTERPAGELIST , PAGINATIONOPTIONS,getEncodedStringBase64} from '@/utils/helper'
  import ConformationBox from "@/components/common/ConformationBox.vue";
  export default {
    components: { dashlayout,ConformationBox },
    name: "BlogList",
    inject : ['mySpinner'],
    created() {
       this.fetchBlogList(); 
    }, 
    watch : {
        options: {
            handler(newVal,oldVal) {
                if(JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.fetchBlogList()
                }
            },
            deep: true
        },
    },
    methods: {
      gotoEdit(id) {
        this.$router.push({path:`/blogaddedit/${getEncodedStringBase64(id)}`})
      },
      gotoAdd() {
        this.$router.push({ path: "/blogaddedit" });
      },

      async fetchBlogList() {
        try {
            this.mySpinner.val = true;
            const response = await this.$store.dispatch("getAdminBlogList", {...this.options,action : 'LIST'});
            if (response.http_status === 200) {
            this.blogData = response.data.data;
            this.total = response.data.total;
            // this.$toasted.success(response.message);
            this.mySpinner.val = false;
            } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
            }
        } catch (e) {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
        }
      }
    },
    data: () => ({
      dialog:false,
      emailer:true,
      verifyemail:true,

      blogData : [],
      total : 0,

      options: PAGINATIONOPTIONS,
      footerList : FOOTERPAGELIST,

      headers: [
        { text: "Title", value: "title" },
        { text: "Meta Description", value: "meta_description" },
        { text: "Meta Keyword", value: "meta_keyword" },
        { text: "Status", value: "status" },
        { text: "Summary", value: "summary" },
        { text: "Meta Title", value: "metaTitle" },
        { text: "Action", value: "action", align: "right", sortable: false },
      ],
      details: [
        {
          title: "Test",
          content: "Lorem Ipsum is simply dummy text of the printing",
          status: "Published",
          summary: "simply dummy text of the printing",
          metaTitle: "Lorem Ipsum is simply dummy",
          action: "",
        },
        {
          title: "Test",
          content: "Lorem Ipsum is simply dummy text of the printing",
          status: "Published",
          summary: "simply dummy text of the printing",
          metaTitle: "Lorem Ipsum is simply dummy",
          action: "",
        },
        {
          title: "Test",
          content: "Lorem Ipsum is simply dummy text of the printing",
          status: "Published",
          summary: "simply dummy text of the printing",
          metaTitle: "Lorem Ipsum is simply dummy",
          action: "",
        },
        {
          title: "Test",
          content: "Lorem Ipsum is simply dummy text of the printing",
          status: "Published",
          summary: "simply dummy text of the printing",
          metaTitle: "Lorem Ipsum is simply dummy",
          action: "",
        },
        
      ],
    }),
  };
  </script>
  
  <style>
  </style>