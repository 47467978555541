import axiosServices from "@/utils/axios";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD ,API_URL,FILEDOWNALOADHEADER} from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    isLoggedIn: false,
    authToken  : null,
    userInfo : null,
})

const getters  = {

}

const actions = {
    handleLogin({ commit },payload) {
        // return axiosServices.post('/getToken',{...payload,...COMMONPAYLOAD}).then(res => {return res})
        return axiosServices
        .post('/get-token', {...payload,...OTPCOMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    handleverifyOtp({ commit },payload) {
        return axiosServices
        .post('/verify-otp', {...payload,...OTPCOMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
        // return axiosServices.post('/verifyOTP',{...payload,...OTPCOMMONPAYLOAD}).then(res => res)
    },
    handleResendOtp({ commit },payload) {
        return axiosServices
        .post('/resend-otp', {...payload,...OTPCOMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    handleRegisterUser({ commit },payload) {
        return axiosServices
        .post('/register', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    handleRegisterOtp({ commit },payload) {
        return axiosServices
        .post('/register/verify-otp', { ...payload, ...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    handleRegisterUserDetails({ commit },payload) {
        return axiosServicesFormData
        .post(`/register/update`, payload)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    loadDynamicData({ commit }, payload) {
        return axiosServices
        .post('/dynamic/data', { ...payload, ...COMMONPAYLOAD })
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    verifyEmail({ commit }, payload) {
        return axiosServices
        .post('/verify-email', { ...payload, ...COMMONPAYLOAD })
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    resetPasswordEmail({ commit }, payload) {
        return axiosServices
        .post('/password/create', { ...payload, ...COMMONPAYLOAD })
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    resetPasswordVerify({ commit }, payload) {
        return axiosServices
        .post('/password/find', { ...payload, ...COMMONPAYLOAD })
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    resetPasswordConfirm({ commit }, payload) {
        return axiosServices
        .post('/password/reset', { ...payload, ...COMMONPAYLOAD })
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getUserList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            search : inputPage.search,
            type: inputPage.type,
            start_date : inputPage.start_date,
            end_date : inputPage.end_date,
            status : inputPage.status,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('/admin/user/get', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateUserStatus({ commit },payload) {
        return axiosServices
            .post('/admin/user/update/status', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    adminUserStatus({ commit },payload) {
        return axiosServices
            .post('admin/user/status/change', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    resendLink({ commit },payload) {
        return axiosServices
            .post('/admin/user/reset-credentials', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateUserPassword({ commit },payload) {
        return axiosServices
            .post('/password/change', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateUserPasswordFromAdmin({ commit },payload) {
        return axiosServices
            .post('/admin/user/password/change', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getUserRegisterData({ commit },payload) {
        return axiosServices
            .post('register/fetch-detail', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    downloadFile({commit},payload) {
        const {path,id} = payload
        return axiosServices
            .get(`get-image/${path}/${id}`, FILEDOWNALOADHEADER)
            .then((response) => {
                if (response) {
                    return response
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    }
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}