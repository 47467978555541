<template>
  <dashlayout>
    <v-container-fluid fluid>
      <v-row>
        <v-col cols="12" md="9">
          <div class="dash-bg">
            <div class="box-title-with-btn mb-0">
              <div class="box-title">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Live Auction
              </div>
              <div
                class="btns-right"
                v-if="!isAuctionComplted && auction_status == 'auction_started'"
              >
                <v-chip
                  class="ma-2 blink-2"
                  color="red lighten-1"
                  text-color="white"
                >
                  <v-avatar left>
                    <v-icon>mdi-access-point-check</v-icon>
                  </v-avatar>
                  Live
                </v-chip>

                <v-chip
                  outlined
                  class="mr-2"
                  color="#9da73e"
                  text-color="#9da73e"
                  style="font-size: 1rem !important; font-weight: bold"
                >
                  <v-icon left> mdi-clock-outline </v-icon>
                  <countdown
                    :time="calculateTime()"
                    @progress="checkTimer"
                    :transform="transformSlotProps"
                  >
                    <template slot-scope="props">
                      {{ props.hours }} : {{ props.minutes }} :
                      {{ props.seconds }}
                    </template>
                  </countdown>
                  &nbsp;left
                </v-chip>
                <template v-if="extendedTimeCount < 3 && isShowExtendedTime">
                  <v-btn
                    @click="extendTime = true"
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-2"
                  >
                    <v-icon class="mr-2"> mdi-clock-plus-outline </v-icon>
                    Extend &nbsp;
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="white"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          size="18"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>

                      <span
                        >The auction time extension is limited to a maximum of
                        three times. Now you have
                        {{ 3 - time_increase_count }} chance to change
                        time.</span
                      >
                    </v-tooltip>
                  </v-btn>
                </template>
              </div>
            </div>

            <v-divider></v-divider>
            <div class="auction-list-wrap mt-4" v-if="lowest_bid.length > 0">
              <div
                style="background-color: white"
                class="auction-box slide-in-elliptic-top-fwd box"
                v-for="(data, index) in lowest_bid"
                :key="index"
              >
                <div class="auction-left-part">
                  <v-avatar
                    class="mr-3 white--text font-weight-bold bounce-in-fwd"
                    color="primary"
                    size="40"
                    >{{
                      `${
                        auctionsInfo && auctionsInfo.auction_for == "buy"
                          ? "L"
                          : "H"
                      }`
                    }}{{ index + 1 }}</v-avatar
                  >
                  <div class="name-of-company mr-4"></div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">
                      ₹ {{ data && data.bid_amount }}
                    </div>
                  </div>

                  <div class="price-up-down">
                    <v-chip
                      color="#F1F4D5"
                      :text-color="data.diff_in_bid >= 0 ? '#9DA73E' : 'red'"
                      label
                    >
                      <v-icon class="mr-2">
                        {{
                          data.diff_in_bid >= 0
                            ? "mdi-trending-up"
                            : "mdi-trending-down"
                        }}
                      </v-icon>
                      {{ data.diff_in_bid }}
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">
                    {{ getUpdatedAuctionTime(data.created_at) }} ago
                  </div>

                  <v-btn
                    @click="confirmOrder(data)"
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-2 ml-2"
                    v-if="
                      data.bidder_user_id == winner_bidder_id &&
                      isAuctionComplted &&
                      auction_status == 'auction_ended'
                    "
                  >
                    <v-icon class="mr-2"> mdi-check </v-icon>
                    Confirm Order
                  </v-btn>
                  <!-- <v-btn @click="confirmOrder(data)" elevation="0" color="#9DA73E" dark class="px-2 ml-2"
                    v-if=" index ==0 && isAuctionComplted ">
                    <v-icon class="mr-2"> mdi-check </v-icon>
                    Confirm Order
                  </v-btn> -->
                </div>
              </div>

              <!-- <div style="background-color: white;" class="auction-box slide-in-elliptic-top-fwd"  v-for="(data,index) in lowest_bid" :key="index">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{`${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}`}}{{ index + 1 }}</v-avatar>
                  <div class="name-of-company mr-4"></div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{data.bid_amount}}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" :text-color="data.diff_in_bid >= 0 ? '#9DA73E' : 'red'" label>
                      <v-icon class="mr-2"> {{data.diff_in_bid >= 0 ? 'mdi-trending-up' : 'mdi-trending-down'}} </v-icon>
                      {{data.diff_in_bid}}
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">{{ getUpdatedAuctionTime(data.created_at) }} ago</div>

                  <v-btn @click="extendTime = true" elevation="0" color="#9DA73E" dark class="px-2 ml-2" v-if="isAuctionComplted && index == 0">
                    <v-icon class="mr-2"> mdi-check </v-icon>
                    Confirm Order
                  </v-btn>
                </div>
              </div> -->

              <!-- <div class="auction-box slide-in-top">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold" color="primary" size="40">H2</v-avatar>
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1200.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div>

              <div class="auction-box">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold" color="primary" size="40">H3</v-avatar>
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1200.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div> -->
            </div>
            <div v-else>
              There are no bids added by the participants in the auction yet.
            </div>
            <v-divider></v-divider>
            <div v-if="status != 'Live' && status != 'Scheduled'">
              <div class="auction-list-wrap mt-4">
                <v-timeline dense clipped>
                  <template v-for="(data, index) in activityData">
                    <template v-if="data.details">
                      <v-timeline-item
                        class="mb-4"
                        small
                        icon="mdi-check"
                        :key="index"
                      >
                        <v-row justify="space-between">
                          <v-col cols="12">
                            <!-- {{ status }}- {{ data.details.type }} -  -->{{
                              data.title
                            }}
                            <v-chip
                              class="white--text ml-3"
                              color="brown"
                              label
                              small
                              outlined
                              v-if="
                                data.details.type == 'auction_ended' ||
                                data.details.type == 'auction_result'
                              "
                            >
                              {{ data.details.bid_amount }} Rs
                            </v-chip>
                            <v-chip
                              class="white--text ml-3"
                              color="brown"
                              label
                              small
                              outlined
                              v-if="
                                data.details.type ==
                                'auctioneer_payment_confirmation'
                              "
                            >
                              {{ data.details.amount }} Rs
                            </v-chip>
                            <div class="time-and-date">
                              {{ data.created_at }}
                            </div>
                            <v-btn
                              color="primary"
                              class="mt-2"
                              v-if="data.details.type == 'auctioneer_confirmed'"
                              :disabled="
                                status == 'Confirmed' &&
                                data.details.open_popup == 'open_popup'
                                  ? false
                                  : true
                              "
                            >
                              Confirm Order
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="mt-2"
                              v-if="
                                data.details.type ==
                                'auctioneer_payment_confirmation'
                              "
                              @click="downloadInvoice"
                            >
                              Download Invoice
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="mt-2"
                              v-if="
                                data.details.type == 'purchase_order_generated'
                              "
                              @click="downloadGeneratedPO(data.details.po_id)"
                            >
                              Download Purchase Order
                            </v-btn>
                            <!-- <v-btn outlined color="primary" class="mt-2 ml-3">
                                View invoice
                              </v-btn> -->
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </template>
                    <template v-else>
                      <v-timeline-item
                        class="mb-4"
                        small
                        icon="mdi-check"
                        :key="index"
                      >
                        <v-row justify="space-between">
                          <v-col cols="7">
                            {{ data.title }}
                            <div class="time-and-date">
                              {{ data.created_at }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </template>
                  </template>
                </v-timeline>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="dash-bg">
            <div class="box-title-with-btn">
              <div class="box-title">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Auction Details
              </div>
            </div>

            <div class="auction-details">
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Name </span>
                <div class="auction-name">
                  {{ auctionsInfo && auctionsInfo.auction_number }}
                </div>
                <div>
                  <span class="auction-name">{{
                    auctionsInfo && auctionsInfo.category_name
                  }}</span>
                  <!-- <span style="color: #9da73e">(Cotton)</span> -->
                  <span class="auction-name"
                    >&nbsp;{{
                      auctionsInfo && auctionsInfo.quantity
                    }}
                    Tonne</span
                  >
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Description </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.auction_description }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Base Price </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.expected_price }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Quantity </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.quantity }} Tonne
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Auction for </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.auction_for }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> EMD Amount </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.range_of_bid_amount }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Auction Time </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && getAuctionDiff }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Start Time </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.start_date_time }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> End Time </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.end_date_time }}
                  </div>
                </div>
              </div>
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Estimated Distance </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.distance }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container-fluid>
    <v-dialog v-model="extendTime" width="450" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          Extent Auction Time
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="extendTime = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(confirmExtendedMin)">
            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Minutes"
                    rules="required"
                  >
                    <label for="">Add Minutes</label>
                    <v-select
                      :items="minList"
                      outlined
                      dense
                      :error-messages="errors"
                      suffix="Min"
                      v-model="extendedMinAdded"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-4">
              <v-spacer></v-spacer>
              <div class="btns-right">
                <v-btn elevation="0" color="#9DA73E" dark large type="submit">
                  Extend
                </v-btn>
              </div>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <div class="pyro" v-if="auctionwon">
      <div class="before"></div>
      <div class="after"></div>
    </div>
    <v-dialog v-model="auctionwon" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-gavel </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">There has been a successful auction</div>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <label for="">{{
                auctionsInfo && auctionsInfo.auction_for == "buy"
                  ? "Lowest Bid"
                  : "Highest Bid"
              }}</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar
                      class="mr-3 white--text font-weight-bold bounce-in-fwd"
                      color="primary"
                      size="40"
                      >{{
                        auctionsInfo && auctionsInfo.auction_for == "buy"
                          ? "L"
                          : "H"
                      }}1</v-avatar
                    >
                    <div class="name-of-company mr-4">No Name</div>

                    <div
                      class="last-bid-section mr-4"
                      v-if="auctionConfirmDetails"
                    >
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">
                        ₹ {{ auctionConfirmDetails.bid_amount }}
                      </div>
                      <v-chip
                        color="#F1F4D5"
                        :text-color="
                          auctionConfirmDetails.diff_in_bid >= 0
                            ? '#9DA73E'
                            : 'red'
                        "
                        label
                      >
                        <v-icon class="mr-2">
                          {{
                            auctionConfirmDetails.diff_in_bid >= 0
                              ? "mdi-trending-up"
                              : "mdi-trending-down"
                          }}
                        </v-icon>
                        {{ auctionConfirmDetails.diff_in_bid }}
                      </v-chip>
                    </div>
                  </div>
                  <div class="auction-right-part">
                    <v-btn
                      @click="confirmAuctionByAuctioneer"
                      elevation="0"
                      color="#9DA73E"
                      dark
                      class="px-2 ml-2"
                    >
                      <v-icon class="mr-2"> mdi-check </v-icon>
                      Confirm Order
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-alert dense outlined color="warning" prominent border="left">
                After your order is confirmed, we will display Seller's name and
                all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowCommitionPopUp" persistent width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mt-3">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-gavel
                  </v-icon>
                  Auction Details
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="12" class="pt-0 pb-0">
              <label for="">{{
                auctionsInfo && auctionsInfo.auction_for == "buy"
                  ? "Lowest Bid"
                  : "Highest Bid"
              }}</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar
                      class="mr-3 white--text font-weight-bold bounce-in-fwd"
                      color="primary"
                      size="40"
                      >{{
                        auctionsInfo && auctionsInfo.auction_for == "buy"
                          ? "L"
                          : "H"
                      }}1</v-avatar
                    >
                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Order Quantity</div>
                      <div class="last-bid-box">
                        {{ auctionsInfo && auctionsInfo.quantity }} &nbsp;Tonne
                      </div>
                    </div>
                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid Amount</div>
                      <div class="last-bid-box">
                        ₹
                        {{
                          lowest_bid &&
                          lowest_bid.length > 0 &&
                          lowest_bid[0].bid_amount
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="last-bid-section mr-4"></div>
                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Total</div>
                    <div class="last-bid-box">
                      ₹ {{ commitionData && commitionData.order_amount }}
                    </div>
                  </div>
                </div>
                <div class="auction-right-part"></div>
              </div>
            </v-col>

            <v-col cols="12" md="12" v-if="commitionData != null">
              <table class="hm-p table-bordered" style="width: 100%">
                <thead>
                  <tr>
                    <th style="width: 50px">#</th>
                    <th><h4>Item Name</h4></th>
                    <th style="width: 100px">
                      <h4>
                        <!-- SSN<br />
                          Code -->
                        SSC
                      </h4>
                    </th>
                    <th style="width: 80px">
                      <h4>
                        Taxable <br />
                        Amount
                      </h4>
                    </th>
                    <template v-if="commitionData.igst == null">
                      <th style="width: 80px">
                        <h4>
                          CGST({{
                            commitionData && commitionData.cgst_percentage
                          }})<br />
                          <hr />
                          Amount
                        </h4>
                      </th>
                      <th style="width: 80px">
                        <h4>
                          SGST({{
                            commitionData && commitionData.sgst_percentage
                          }})<br />
                          <hr />
                          Amount
                        </h4>
                      </th>
                    </template>
                    <th style="width: 80px" v-else>
                      <h4>
                        IGST({{
                          commitionData && commitionData.igst_percentage
                        }})<br />
                        <hr />
                        Amount
                      </h4>
                    </th>
                    <th style="width: 120px"><h4>TOTAL</h4></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      Commission({{
                        auctionsInfo && auctionsInfo.category_name
                      }})
                    </td>
                    <td>{{ commitionData && commitionData.ssn_code }}</td>
                    <td>{{ commitionData && commitionData.taxable_amount }}</td>
                    <td v-if="commitionData.igst == null">
                      {{ commitionData && commitionData.cgst }}
                    </td>
                    <td v-if="commitionData.igst == null">
                      {{ commitionData && commitionData.sgst }}
                    </td>
                    <td v-if="commitionData.igst != null">
                      {{ commitionData && commitionData.igst }}
                    </td>
                    <td>
                      {{
                        commitionData && commitionData.total_commission_amount
                      }}
                    </td>
                  </tr>

                  <tr style="background-color: #f7f7f7">
                    <td></td>
                    <td><strong>Total</strong></td>
                    <td>&nbsp;</td>
                    <td>{{ commitionData && commitionData.taxable_amount }}</td>
                    <!-- <td v-if="commitionData.igst != null">&nbsp;</td> -->
                    <td v-if="commitionData.igst == null">
                      <strong>{{ commitionData && commitionData.cgst }}</strong>
                    </td>
                    <td v-if="commitionData.igst == null">
                      <strong>{{ commitionData && commitionData.sgst }}</strong>
                    </td>
                    <td v-if="commitionData.igst != null">
                      <strong>{{ commitionData && commitionData.igst }}</strong>
                    </td>
                    <td>
                      {{
                        commitionData && commitionData.total_commission_amount
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                class="hm-p table-bordered ml-auto"
                style="width: fit-content; margin-top: 15px"
              >
                <tbody>
                  <tr style="background-color: #f7f7f7">
                    <td style="text-align: right">
                      <strong>Biofuel Commission %</strong>
                    </td>
                    <td style="width: 80px">
                      <strong>{{
                        commitionData && commitionData.service_charge_percentage
                      }}</strong>
                    </td>
                    <td style="font-size: 18px; width: 120px; color: #9da648">
                      <strong
                        >₹
                        {{
                          commitionData && commitionData.gross_commission_amount
                        }}</strong
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="text-right mt-4 mb-2">
                <v-btn
                  @click="paymentdialog = true"
                  elevation="0"
                  color="#9DA73E"
                  dark
                  large
                  class="px-5 ml-2"
                  style="min-width: 120px"
                >
                  Pay ₹
                  {{ commitionData && commitionData.total_commission_amount }}
                </v-btn>
              </div>

              <v-alert
                dense
                outlined
                color="warning"
                prominent
                class="mb-0 mt-3"
              >
                Pay ₹
                {{ commitionData && commitionData.total_commission_amount }}
              </v-alert>

              <v-alert
                dense
                outlined
                color="warning"
                prominent
                class="mb-0 mt-3"
              >
                After your order is confirmed, we will display Seller's name and
                all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentdialog" persistent width="400">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mb-0">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-account-credit-card-outline
                  </v-icon>
                  Payment
                </div>
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="paymentdialog = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="12">
              <div class="d-flex justify-space-between align-center">
                <div for="" style="font-size: 1rem">Wallet Balance</div>
                <v-chip link outlined class="px-5 mr-3" color="#9DA73E">
                  <v-avatar left>
                    <v-icon>mdi-wallet-outline</v-icon>
                  </v-avatar>
                  ₹ {{ wallet_balance }}
                </v-chip>
              </div>

              <v-divider class="my-4"></v-divider>

              <div>
                <v-checkbox
                  label="Pay with wallet Balance"
                  class="ma-0 pa-0"
                  v-model="value"
                  value="Wallet"
                  disabled
                ></v-checkbox>
              </div>

              <div class="text-right mt-5 mb-2">
                <div class="mb-2">
                  <v-select
                    :items="paymentOptions"
                    v-model="selectedPaymentOption"
                    label="Select Payment Method"
                    outlined
                  >
                    <template #item="{ item }">
                      <img class="mr-2" width="20" :src="item.logo" alt="" />
                      <span style="font-size: 0.9rem">
                        {{ item.name }}
                      </span>
                    </template>

                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        v-on="on"
                        color="transparent"
                        class="px-0"
                      >
                        <img class="mr-2" width="20" :src="item.logo" alt="" />
                        <span
                          style="font-size: 0.9rem"
                          v-text="item.name"
                        ></span>
                      </v-chip>
                    </template>
                  </v-select>
                </div>
                <v-btn
                  elevation="0"
                  color="#9DA73E"
                  dark
                  large
                  class="px-5 ml-2"
                  style="min-width: 120px"
                  @click="confirmPayment"
                >
                  Pay ₹
                  {{ commitionData && commitionData.gross_commission_amount }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <div class="pyro" v-if="auctionwon">
      <div class="before"></div>
      <div class="after"></div>
    </div>
    <v-dialog v-model="auctionwon" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-trophy-outline </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">Auction Finished</div>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Winners List</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box ">
                  <div class="auction-left-part">
                    <v-avatar
                      class="mr-3 white--text font-weight-bold bounce-in-fwd"
                      color="primary"
                      size="40"
                      >H1</v-avatar
                    >
                    <div class="name-of-company mr-4">No Name</div>

                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">₹ 1200.00</div>
                    </div>
                  </div>
                  <div class="auction-right-part">
                    <v-btn
                      @click="extendTime = true"
                      elevation="0"
                      color="#9DA73E"
                      dark
                      class="px-2 ml-2"
                    >
                      <v-icon class="mr-2"> mdi-check </v-icon>
                      Confirm Order
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-alert dense outlined color="warning" prominent border="left">
                After your order is confirmed, we will display Winner's name and all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="isShowGeneratePO" max-width="450">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mt-3">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-gavel
                  </v-icon>
                  Generate PO
                </div>
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="orderdetails = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="12" class="pt-0 pb-0 pl-12">
              <h3 class="font-weight-medium mb-4">
                Have to generate PO for the auction {{ this.auction_number }}
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8 pl-14">
          <v-btn color="primary" @click="generatePOForAutcion">
            Generate PO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import {
  getDecodedStringBase64,
  getEncodedStringBase64,
  COMMONPAYLOAD,
  FILEDOWNALOADHEADER,
  API_URL,
  PUSHER_KEY,
} from "@/utils/helper";
import moment from "moment";

import { required } from "vee-validate/dist/rules";
import axios from "axios";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import Pusher from "pusher-js";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});
export default {
  components: { dashlayout, ValidationObserver, ValidationProvider },
  name: "AuctionAddEdit",
  inject: ["mySpinner"],
  data: () => ({
    auctionwon: false,
    extendTime: false,
    dialog: false,

    auctionsInfo: null,
    isShowDeleteDialog: false,
    auction_end_date_time: "",
    minList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    extendedMinAdded: "",

    extendedTimeCount: 0,

    lowest_bid: [],
    isAuctionComplted: false,

    isShowExtendedTime: false,
    auction_number: "",
    auctionConfirmDetails: null,
    winner_bidder_id: "",
    isShowCommitionPopUp: false,
    isLosstheAuction: false,
    bidDetails: null,
    commitionData: null,
    paymentOptions: [
      {
        logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZWvmpOm0qexC1rWXOrpIlUBwTE3ZfomJcSkYRlY-anEXzxHzoM3N8s43yAFI3dzGBT8c&usqp=CAU",
        name: "Wallet",
        value: "Wallet",
      },
    ],
    selectedPaymentOption: "Wallet",
    isShowGeneratePO: false,
    paymentdialog: false,
    value: "Wallet",
    wallet_balance: 0,
    auction_status: "",
    status: "",
    activityData: [],
    time_increase_count: 3,
  }),
  created() {
    this.getAutionInfo();
    this.auction_number = getDecodedStringBase64(this.$route.params.id);
    // this.closeTheAuction()
    this.wallet_balance = getDecodedStringBase64(
      localStorage.getItem("wallet_balance")
    );
  },
  computed: {
    getAuctionDiff() {
      if (this.auctionsInfo) {
        let dt1 = new Date(this.auctionsInfo.start_date_time);
        let dt2 = new Date(this.auctionsInfo.end_date_time);

        // calculate the time difference of two dates JavaScript
        var diffTime = dt2.getTime() - dt1.getTime();

        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600);
        return daysDiff.toFixed(2);
      }
    },
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });
      return formattedProps;
    },
    async getAutionInfo() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getAuctionDetails", {
          auction_number: getDecodedStringBase64(this.$route.params.id),
        });
        if (response.http_status === 200) {
          this.auctionsInfo = response.data;
          this.auction_end_date_time = response.data.end_date_time;
          this.lowest_bid = response.data.lowest_bid;
          this.status = response.data.status;
          this.time_increase_count = response.data.time_increase_count;

          if (this.status != "Live" && this.status != "Scheduled") {
            this.getActivityList();
          }
          const user_id = localStorage.getItem("user_id");
          if (response.data?.user_id != user_id) {
            this.$router.push("/dashboard");
          }
          this.mySpinner.val = false;

          Pusher.logToConsole = true;
          var pusher = new Pusher(PUSHER_KEY, {
            cluster: "ap2",
          });
          let that = this;
          var channel = pusher.subscribe(
            `${
              this.auction_number
            }-${this.auctionsInfo.auction_for.toUpperCase()}-AUCTIONEER`
          );
          channel.bind("my-event", function (data) {
            this.message = data;
            that.$emit("incoming_data", data);
          });

          this.$on("incoming_data", function (notificationData) {
            this.auctionDataFromPusher(notificationData);
          });

          this.winner_bidder_id = response.data.winner_user_id;
          this.commitionData = response.data.auction_commission;
          this.auction_status = response.data.auction_flag;
          if (response.data.auction_flag == "auction_ended") {
            this.isAuctionComplted = true;
          } else if (response.data.auction_flag == "auctioneer_confirmation") {
            this.isShowCommitionPopUp = true;
          } else if (
            response.data.auction_flag == "participant_payment_confirmation"
          ) {
            this.isShowGeneratePO = true;
          } else if (
            response.data.auction_flag == "auctioneer_payment_confirmation"
          ) {
            this.isShowCommitionPopUp = false;
            this.paymentdialog = false;
          }
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    calculateTime() {
      const now = new Date();
      const endDate = new Date(this.auction_end_date_time);
      return endDate - now;
    },
    async confirmExtendedMin() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("auctionExtendedMin", {
          auction_number: getDecodedStringBase64(this.$route.params.id),
          minute: this.extendedMinAdded,
        });
        if (response.http_status === 200) {
          this.extendTime = false;
          this.extendedTimeCount = response.data.time_increase_count;
          this.time_increase_count = response.data.time_increase_count;
          this.auction_end_date_time = response.data.end_date_time;
          this.isShowExtendedTime = false;
          this.extendedMinAdded = "";
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    calculateDiff(index) {
      const bidData = this.lowest_bid;
      if (index == bidData.length - 1) {
        return `+${bidData[index].bid_amount}`;
      } else {
        const diff = bidData[index].bid_amount - bidData[index + 1].bid_amount;
        if (diff < 0) {
          return `${diff}`;
        } else {
          return `+${diff}`;
        }
      }
    },
    getUpdatedAuctionTime(date) {
      let arrSD = date.split("/");
      let arrST = date.split(":");
      let SD = new Date(
        arrSD[2].split(" ")[0],
        Number.parseInt(arrSD[1]) - 1,
        arrSD[0],
        arrST[0].split(" ")[1],
        arrST[1],
        arrST[2]
      );

      let now = new Date();
      const diff = now.getTime() - SD.getTime();
      var hoursDiff = Math.floor(diff / (1000 * 3600));
      var minDiff = Math.floor((diff - hoursDiff * 3600 * 1000) / (1000 * 60));
      var secDiff = Math.floor(
        (diff - hoursDiff * 3600 * 1000 - minDiff * 60 * 1000) / 1000
      );

      return `${hoursDiff}h ${minDiff}m ${secDiff}s`;
    },
    checkTimer(time) {
      let timeSeconds = time.hours * 3600 + time.minutes * 60 + time.seconds;
      if (timeSeconds < 60 * 3) {
        this.isShowExtendedTime = true;
      }
      if (timeSeconds == 0) {
        this.closeTheAuction();
        // this.isAuctionComplted = true
      }
    },
    auctionDataFromPusher(data) {
      if (data.hasOwnProperty("message")) {
        // if(data.message.hasOwnProperty('value')){
        this.auction_end_date_time = data.message.hasOwnProperty(
          "end_date_time"
        )
          ? data.message.end_date_time
          : this.auction_end_date_time;
        this.lowest_bid = data.message.hasOwnProperty("lowest_bid")
          ? data.message.lowest_bid
          : this.lowest_bid;
        this.winner_bidder_id = data.message.hasOwnProperty("winner_bidder_id")
          ? data.message.winner_user_id
          : this.winner_bidder_id;
        this.extendedTimeCount = data.message.hasOwnProperty(
          "time_increase_count"
        )
          ? data.message.time_increase_count
          : this.extendedTimeCount;
        this.commitionData = data.message.hasOwnProperty("auction_commission")
          ? data.message.auction_commission
          : this.commitionData;
        // }

        if (data.message.hasOwnProperty("channel_type")) {
          if (data.message.channel_type == "auction_ended") {
            this.isAuctionComplted = true;
          } else if (data.message.channel_type == "auctioneer_confirmation") {
            this.isShowCommitionPopUp = true;
          }
          // else if(data.message.channel_type == 'bidder_payment_confirmation'){
          //   this.isShowGeneratePO = true
          // }
        }
      }
    },
    async closeTheAuction() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("changeStatusAuction", {
          auction_number: this.auction_number,
          status: "Closed",
          organization_id: localStorage.getItem("selectedBranch"),
          user_id: localStorage.getItem("user_id"),
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isAuctionComplted = true;
          this.getAutionInfo();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    confirmOrder(data) {
      this.auctionwon = true;
      this.auctionConfirmDetails = data;
    },
    async confirmAuctionByAuctioneer() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("confirmAuctionOrder", {
          auction_number: this.auction_number,
        });
        if (response.http_status === 200) {
          this.auctionConfirmDetails = null;
          this.auctionwon = false;
          this.getAutionInfo();
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.auctionConfirmDetails = null;
          this.auctionwon = false;
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    generatePOForAutcion() {
      this.$router.push(
        `/invoice/${getEncodedStringBase64(this.auction_number)}`
      );
    },
    async confirmPayment() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("sumbitThePayment", {
          auction_number: this.auction_number,
          payment_type: "Wallet",
          user_type: "auctioneer",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowCommitionPopUp = false;
          this.paymentdialog = false;
          this.getAutionInfo();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getActivityList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getActivityList", {
          auction_number: this.auction_number,
        });
        this.activityData = response.data;
        if (response.http_status === 200) {
          this.mySpinner.val = false;
        } else {
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async downloadGeneratedPO(id) {
      if (id != "") {
        this.mySpinner.val = true;
        axios({
          url: `${API_URL}get-file/po_document/${id}`,
          method: "POST",
          COMMONPAYLOAD,
          headers: { ...FILEDOWNALOADHEADER },
          responseType: "blob",
        })
          .then((res) => {
            if (res.data.type == "application/json") {
              this.$toasted.error("No record found.");
              this.mySpinner.val = false;
            } else {
              var FILE = window.URL.createObjectURL(
                new Blob([res.data], { type: res.headers["content-type"] })
              );
              var docUrl = document.createElement("a");
              docUrl.href = FILE;
              var extension = res.headers["content-type"].split("/");
              docUrl.setAttribute("download", `po_document.${extension[1]}`);
              document.body.appendChild(docUrl);
              docUrl.click();
              this.mySpinner.val = false;
            }
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
          });
      }
    },
    async downloadInvoice() {
      if (this.commitionData && this.commitionData.id) {
        this.mySpinner.val = true;
        axios({
          url: `${API_URL}get-file/auction_document/${this.commitionData.id}/auctioneer`,
          method: "POST",
          COMMONPAYLOAD,
          headers: { ...FILEDOWNALOADHEADER },
          responseType: "blob",
        })
          .then((res) => {
            if (res.data.type == "application/json") {
              this.$toasted.error("No record found.");
              this.mySpinner.val = false;
            } else {
              var FILE = window.URL.createObjectURL(
                new Blob([res.data], { type: res.headers["content-type"] })
              );
              var docUrl = document.createElement("a");
              docUrl.href = FILE;
              var extension = res.headers["content-type"].split("/");
              docUrl.setAttribute(
                "download",
                `invoice_document.${extension[1]}`
              );
              document.body.appendChild(docUrl);
              docUrl.click();
              this.mySpinner.val = false;
            }
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
          });
      }
    },
  },
};
</script>
  
<style></style>