<template>
  <dashlayout>
    <v-container fluid>
        <div class="d-flex" style="justify-content: end">
          <v-btn elevation="0" color="#9DA73E" dark text to="/yourPartner">
            <v-icon class="mr-2">
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
      </div>
      <div class="dash-bg">
        <div class="cover-image">
          <img
            style="width: 100%"
            src="@/assets/images/user-cover.jpg"
            alt=""
          />
        </div>

        <div class="user-profile-and-detail ml-8 mb-10">
          <div class="user-img-of-profile">
            <img
              v-if="dispayProfilePic"
              :src="profilePicUrl"
              width="150px"
              alt=""
            />
            <img v-else :src="getImageName()" width="150px" alt="" />
          </div>
          <div class="user-da">
            <div class="user-name">
              {{ firstName }} {{ lastName }}
              <span class="ml-1 subtitle-1"
                ><v-icon color="#421E06">mdi-check-decagram</v-icon>
                Verified</span
              >
            </div>
            <div class="user-designation">
              {{ userRole }} ||

              <span class="user-designation" v-if="status !== 'Verified'">{{
                status
              }}</span>
            </div>
          </div>
        </div>
        <div class="sub-tabs">
          <v-tabs show-arrows v-model="tab2" color="#421E06">
            <v-tab
              ><v-icon class="mr-2">mdi-account-outline</v-icon> Basic
              Info</v-tab
            >
            <v-tab
              ><v-icon class="mr-2">mdi-domain</v-icon> Organisations</v-tab
            >
          </v-tabs>
        </div>

        <v-tabs-items v-model="tab2">
          <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn mb-0">
                <div class="box-title">Basic Info</div>
                <div class="btns-right"></div>
              </div>
                <form>
                  <v-row>
                    <v-col cols="12" md="3">
                        <label for=""
                          >First Name <span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="firstName"
                          readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Last Name <span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="lastName"
                          readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Email <span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-email-outline"
                          v-model="email"
                          readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Gender <span class="required">*</span></label
                        >
                        <v-select
                          :items="genderItem"
                          v-model="gender"
                          outlined
                          readonly
                          dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Mobile No.<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          readonly
                          prepend-inner-icon="mdi-cellphone"
                          v-model="phoneNumber"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >WhatsApp No.<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          readonly
                          prepend-inner-icon="mdi-whatsapp"
                          v-model="whatsappNumber"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for="">Reference By</label>
                        <v-text-field
                          outlined
                          dense
                          v-model="reference_by"
                          readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Do you have multiple branches? <span class="required">*</span></label
                        >
                        <v-select
                          :items="isBranchesItem"
                          v-model="is_branches"
                          outlined
                          dense
                          readonly
                        ></v-select>
                    </v-col>
                  </v-row>

                  <template v-if="userRole != 'Admin'">
                    <v-divider class="my-8"></v-divider>
                    <div class="box-title-with-btn mb-0">
                      <div class="box-title">Address</div>
                      <div class="btns-right"></div>
                    </div>
                  </template>
                  <v-row>
                    <template v-if="userRole != 'Admin'">
                      <v-col cols="12" md="3">
                          <label for=""
                            >Address line1<span class="required">*</span></label
                          >
                          <v-text-field
                            outlined
                            dense
                            readonly
                            :error-messages="errors"
                            prepend-inner-icon="mdi-map-marker-outline"
                            v-model="address1"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <label for="">Address line2</label>
                        <v-text-field
                          outlined
                          dense
                          readonly
                          prepend-inner-icon="mdi-map-marker-outline"
                          v-model="address2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                          <label for=""
                            >Landmark / Area<span class="required">*</span></label
                          >
                          <v-text-field
                            outlined
                            dense
                            readonly
                            v-model="area"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                          <label for=""
                            >State<span class="required">*</span></label
                          >
                          <v-select
                            :items="stateItem"
                            v-model="state"
                            outlined
                            dense
                            readonly
                            item-value="id"
                            item-text="name"
                            @change="getCityDropdownData"
                          ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                          <label for=""
                            >City<span class="required">*</span></label
                          >
                          <v-autocomplete
                            :items="cityItem"
                            v-model="city"
                            outlined
                            dense
                            readonly
                            item-value="id"
                            item-text="name"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3">
                          <label for=""
                            >Country<span class="required">*</span></label
                          >
                          <v-select
                            :items="countryItem"
                            readonly
                            v-model="country"
                            outlined
                            dense
                            item-value="id"
                            item-text="name"
                          >
                          </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                          <label for=""
                            >pincode<span class="required">*</span></label
                          >
                          <v-text-field
                            outlined
                            dense
                            readonly
                            v-model="pinCode"
                          ></v-text-field>
                      </v-col>
                    </template>
                  </v-row>
                </form>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn mb-0">
                <div class="box-title">Organisations</div>
                <div class="btns-right"></div>
              </div>
                <form>
                  <v-row>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Business Type <span class="required">*</span></label
                        >
                        <v-select
                          :items="businessTypeList"
                          v-model="businessType"
                          outlined
                          dense
                          readonly
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Display Name<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          readonly
                          v-model="displayName"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >GSTIN<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          readonly
                          v-model="gstin"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Registered Business Name<span class="required"
                            >*</span
                          ></label
                        >
                        <v-text-field
                          outlined
                          dense
                          readonly
                          prepend-inner-icon="mdi-domain"
                          v-model="registerBusinessName"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Website URL</label
                        >
                        <v-text-field
                          outlined
                          readonly
                          dense
                          prepend-inner-icon="mdi-web"
                          v-model="websiteuUrl"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <label for="">GST</label>
                      <v-file-input
                        truncate-length="15"
                        hide-details
                        outlined
                        dense
                        prepend-inner-icon="mdi-attachment"
                        prepend-icon=""
                        append-icon="mdi-download"
                        @click:append="downloadGstFile"
                        v-model="gst"
                      ></v-file-input>
                    </v-col>

                    <v-col cols="12" md="3">
                      <label for="">Company Logo</label>
                      <v-file-input
                        truncate-length="15"
                        disabled
                        outlined
                        dense
                        prepend-inner-icon="mdi-attachment"
                        prepend-icon=""
                        v-model="companyLogo"
                        :append-icon="documents[1].doc_path != null ? 'mdi-file-eye-outline' : ''"
                        @click:append="downloadCompanyLogo"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Year Of Incorporation<span class="required"
                            >*</span
                          ></label
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="yearsOfCorporation"
                          readonly
                          type="number"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Material Type <span class="required">*</span></label
                        >
                        <v-select
                          :items="materialTypeList"
                          v-model="materialType"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          chips
                          readonly
                          multiple
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for=""
                          >Consupation / Production
                          <span class="required">*</span></label
                        >
                        <v-select
                          :items="monthlyCapacityList"
                          item-value="id"
                          item-text="name"
                          v-model="monthlyCapacity"
                          outlined
                          dense
                          readonly
                          suffix="/Month"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="3">
                        <label for=""
                          >Installed Capacity<span class="required"
                            >*</span
                          ></label
                        >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              size="18"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span
                            >Please enter monthly installed capacity of
                            production</span
                          >
                        </v-tooltip>
                        <v-text-field
                          outlined
                          readonly
                          dense
                          v-model="installedCapacity"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <label for="">Number of employees</label>
                        <v-text-field
                          outlined
                          dense
                          readonly
                          v-model="noOfEmployee"
                          type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="pb-0"
                      v-if="materialType.includes(materialOtherOptionId)"
                    >
                        <label for=""
                          >New Material Type<span class="required"
                            >*</span
                          ></label
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="newMaterialType"
                          readonly
                        ></v-text-field>
                    </v-col>
                  </v-row>
                </form>
              <template v-if="is_branches == 'Yes'">
                <v-divider class="my-8"></v-divider>
                <div class="box-title-with-btn mb-0">
                  <div class="box-title">Branches</div>
                </div>
                <v-row>
                  <v-col cols="12" class="mt-3">
                    <v-data-table
                      dense
                      :headers="headers"
                      :items="organizationList"
                      :items-per-page="5"
                      class="elevation-1"
                      hide-default-footer
                    >
                      <!-- <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom v-if="item.status == 'New' && isAdmin">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              small
                              elevation="0"
                              color="transparent"
                              @click="approveBranch(item.id)"
                            >
                              <v-icon color="#8f9742"> mdi-check-bold </v-icon>
                            </v-btn>
                          </template>
                          <span>Approve</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="item.status == 'New' && isAdmin">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              small
                              elevation="0"
                              color="transparent"
                              @click="rejectBranch(item.id)"
                            >
                              <v-icon color="red"> mdi-close-thick </v-icon>
                            </v-btn>
                          </template>
                          <span>Reject</span>
                        </v-tooltip>
                      </template> -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </template>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </dashlayout>
</template>
  
<script>
  import dashlayout from '@/components/layout/dashlayout.vue'
  import ConformationBox from "@/components/common/ConformationBox.vue";
//   import AddEditBranch from "./Branch/AddEditBranch.vue";
import { UUID, AUTHENTIACTIONKEY } from "@/utils/helper";
import axios from "axios";
import { API_URL, FILEDOWNALOADHEADER, COMMONPAYLOAD,getDecodedStringBase64, getDecodedString, getDecodedToken,encryptPayload } from "@/utils/helper";

export default {
  components: {
    dashlayout,
    ConformationBox,
  //   AddEditBranch,
  },
  name: "dashboard",
  inject: ["mySpinner"],
  methods: {
    downloadGstFile() {
      this.mySpinner.val = true;
            axios({
                url : `${API_URL}get-file/user_gst_path/${this.organization_id}`,
                method : 'POST',
                COMMONPAYLOAD,
                headers : {...FILEDOWNALOADHEADER},
                responseType: 'blob',
            }).then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                var extension = res.headers['content-type'].split('/');
                docUrl.setAttribute('download', `${this.displayName}_gst.${extension[1]}`);
                document.body.appendChild(docUrl);
                docUrl.click();
                this.mySpinner.val = false;
            })
            .catch((e) => {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            });
    },
    downloadCompanyLogo() {
      this.mySpinner.val = true;
            axios({
                url : `${API_URL}get-file/company_logo_image/${this.organization_id}`,
                method : 'POST',
                COMMONPAYLOAD,
                headers : {...FILEDOWNALOADHEADER},
                responseType: 'blob',
            }).then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                var extension = res.headers['content-type'].split('/');
                docUrl.setAttribute('download', `${this.displayName}_logo.${extension[1]}`);
                document.body.appendChild(docUrl);
                docUrl.click();
                this.mySpinner.val = false;
            })
            .catch((e) => {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            });
    },
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
    async getProfilePic() {
      this.mySpinner.val = true;
      const token = getDecodedToken();
      axios({
        url: `${API_URL}get-file/user_profile_image/${this.user_id}`,
        method: "POST",
        COMMONPAYLOAD,
        headers: { authorization : 'Bearer ' + token},
        responseType: "blob",
      })
        .then((res) => {
          // this.profilePicUrl = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          this.profilePicUrl = window.URL.createObjectURL(blob);
          this.dispayProfilePic = true;
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
    async updateUserBasicInfo() {  
      this.mySpinner.val = true;
      try {
        const payload = {
          organization_id: this.organization_id,
          user_addresses_id: this.user_addresses_id,
          id: this.user_id,
          is_user_profile: true,
          is_address: true,
          is_organisations: false,
          is_kyc: false,
          last_name: this.lastName,
          first_name: this.firstName,
          email: this.email,
          gender: this.gender,
          mobile_number: this.phoneNumber,
          whatsapp_number: this.whatsappNumber,
          address_line_1: this.address1,
          address_line_2: this.address2,
          district: this.area,
          pin_code: this.pinCode,
          city_id: this.city,
          state_id: this.state,
          country_id: this.country,
          is_branches: this.is_branches,
        };
        const response = await this.$store.dispatch(
          "updateAdminUserProfile",
          payload
        );
        if (response.http_status === 200 || response.http_status === 201) {
          this.$toasted.success(response.message);
          this.getUserBasicInfo();
          this.getDropdownData();
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getUserBasicInfo() {
      this.mySpinner.val = true;
      try {
        const payload = {
          id: this.user_id,
          action: "VIEW"
        };
        const response = await this.$store.dispatch("getPartnerUserProfile", payload);
        if (response.http_status === 200) {
          this.user_addresses_id = response.data.user_addresses_id;
          this.organization_id = response.data.organization_id;
          this.state = response.data.state_id;
          this.city = response.data.city_id;
          this.pinCode = response.data.pin_code;
          this.area = response.data.district;
          this.address1 = response.data.address_line_1;
          this.address2 = response.data.address_line_2;
          this.businessType = response.data.business_type;
          this.displayName = response.data.display_name;
          this.gstin = response.data.gst_number;
          this.registerBusinessName = response.data.registered_business_name;
          this.websiteuUrl = response.data.website_url;
          this.yearsOfCorporation = response.data.years_of_incorporation;
          this.materialType = response.data.material_type;
          this.monthlyCapacity = response.data.monthly_capacity;
          this.installedCapacity = response.data.installed_capacity;
          this.noOfEmployee = response.data.number_of_employees;
          this.newMaterialType = response.data.material_type_other;
          this.documents = [
            {
              doc_path: response.data.gst_file_path,
              name: "GST Document",
              path: "user_gst_path",
              id: response.data.user_id,
            },
            {
              doc_path: response.data.company_logo,
              name: "Company Logo",
              path: "company_logo_image",
              id: response.data.user_id,
            },
          ];

          this.whatsappNumber = response.data.whatsapp_number;
          this.phoneNumber = response.data.phone;
          this.gender = response.data.gender;
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.reference_by = response.data.reference_by;
          this.email = response.data.email;
          this.status = response.data.status;
          this.is_branches = response.data.is_branches;
          if (response.data.is_seller) {
            this.userRole = "Seller";
          }
          if (response.data.is_buyer) {
            this.userRole = "Buyer";
          }
          if (response.data.is_admin) {
            this.userRole = "Admin";
          }
          if (response.data.is_trader) {
            this.userRole = "Trader";
          }
          if (response.data.is_branches == "Yes") {
            this.getBranchList();
          }
          
          if(!response.data.is_admin){
            this.getStateDropdownData();
            this.getCityDropdownData();
          }

          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module: "MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST,BUSINESS_TYPE",
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.materialTypeList = response.data.material_type;
          this.monthlyCapacityList = response.data.monthly_capacity;
          this.countryItem = response.data.country_list;
          this.businessTypeList = response.data.business_type;
          this.mySpinner.val = false;
          this.materialTypeList.map((item, index) => {
            if (item.name == "Other" || item.name == "other") {
              this.materialOtherOptionId = item.id;
            }
          });
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getStateDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = { module: "STATE_LIST", country_id: 101 };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.stateItem = response.data.state_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getCityDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module: "CITY_LIST",
          country_id: 101,
          state_id: this.state,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.cityItem = response.data.city_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    getImageName() {
      return `https://ui-avatars.com/api/?name=${this.firstName}+${this.lastName}`;
    },
    // downloadDocument(path, id) {
    //   this.mySpinner.val = true;
    //   axios({
    //     url: `${API_URL}get-image/${path}/${id}`,
    //     method: "GET",
    //     responseType: "blob",
    //   })
    //     .then((res) => {
    //       var FILE = window.URL.createObjectURL(new Blob([res.data]));
    //       var docUrl = document.createElement("a");
    //       docUrl.href = FILE;
    //       var extension = res.headers["content-type"].split("/");
    //       docUrl.setAttribute("download", `${path}.${extension[1]}`);
    //       document.body.appendChild(docUrl);
    //       docUrl.click();
    //       this.mySpinner.val = false;
    //     })
    //     .catch((e) => {
    //       this.$toasted.error(e.message);
    //       this.mySpinner.val = false;
    //     });
    // },
    async getBranchList() {
      this.mySpinner.val = true;
      try {
        const payload = {
          user_id: this.user_id,
          search: "",
          action: "LIST",
          sorting_order: "",
          order_by: "",
          show_records: 1000,
          page: 1,
        };
        const response = await this.$store.dispatch("getBranchList", payload);
        if (response.http_status === 200) {
          this.organizationList = response.data.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },

    // showSendModal(id) {
    //   this.isShowResend = true;
    //   this.isResendId = id;
    // },
    // closeResnedModal() {
    //   this.isShowResend = false;
    //   this.isResendId = "";
    // },
  //   async confirmResned() {
  //     this.mySpinner.val = true;
  //     try {
  //       const response = await this.$store.dispatch("resendLink", {
  //         user_id: this.isResendId,
  //       });
  //       if (response.http_status === 200) {
  //         this.$toasted.success(response.message);
  //         this.mySpinner.val = false;
  //         this.isShowResend = false;
  //         this.isResendId = "";
  //         this.getUserBasicInfo();
  //       } else {
  //         this.$toasted.error(response.message);
  //         this.mySpinner.val = false;
  //         this.isShowResend = false;
  //         this.isResendId = "";
  //       }
  //     } catch (e) {
  //       this.$toasted.error(e.message);
  //       this.mySpinner.val = false;
  //     }
  //   },

  //   showBlockModal(id) {
  //     this.isShowBlock = true;
  //     this.isBlockId = id;
  //   },
  //   closeBlockModal() {
  //     this.isShowBlock = false;
  //     this.isBlockId = "";
  //   },
  //   async confirmBlock() {
  //     this.mySpinner.val = true;
  //     try {
  //       const response = await this.$store.dispatch("adminUserStatus", {
  //         user_id: this.isBlockId,
  //         status: "Locked",
  //       });
  //       if (response.http_status === 200) {
  //         this.$toasted.success(response.message);
  //         this.mySpinner.val = false;
  //         this.isShowBlock = false;
  //         this.isBlockId = "";
  //         this.getUserBasicInfo();
  //       } else {
  //         this.$toasted.error(response.message);
  //         this.mySpinner.val = false;
  //         this.isBlockId = false;
  //         this.isRejectId = "";
  //       }
  //     } catch (e) {
  //       this.$toasted.error(e.message);
  //       this.mySpinner.val = false;
  //     }
  //   },

  //   showDeactiveModal(id) {
  //     this.isShowDeactive = true;
  //     this.isDeactiveId = id;
  //   },
  //   closeDeactiveModal() {
  //     this.isShowDeactive = false;
  //     this.isDeactiveId = "";
  //   },
  //   async confirmDeactive() {
  //     this.mySpinner.val = true;
  //     try {
  //       const response = await this.$store.dispatch("adminUserStatus", {
  //         user_id: this.isDeactiveId,
  //         status: "Suspended",
  //       });
  //       if (response.http_status === 200) {
  //         this.$toasted.success(response.message);
  //         this.mySpinner.val = false;
  //         this.isShowDeactive = false;
  //         this.isDeactiveId = "";
  //         this.getUserBasicInfo();
  //       } else {
  //         this.$toasted.error(response.message);
  //         this.mySpinner.val = false;
  //         this.isShowDeactive = false;
  //         this.isDeactiveId = "";
  //       }
  //     } catch (e) {
  //       this.$toasted.error(e.message);
  //       this.mySpinner.val = false;
  //     }
  //   }
  },
  created() {
    this.user_id = this.$route.params.id ? getDecodedStringBase64(this.$route.params.id) : localStorage.getItem("user_id");
    this.getDropdownData();
    this.getUserBasicInfo();
    this.getProfilePic();
  },
  watch: {
    $route(to, from) {
      this.user_id = this.$route.params.id
        ? getDecodedStringBase64(this.$route.params.id)
        : localStorage.getItem("user_id");
      this.getDropdownData();
      this.getUserBasicInfo();
    },
  },
  data: () => ({
    viewImage: false,
    addBranch: false,
    profilePicUrl: "",
    dispayProfilePic: false,
    isShowApprove: false,
    isShowReject: false,
    isShowResend: false,
    isShowBlock: false,
    isShowDeactive : false,
    isApproveId: "",
    isRejectId: "",
    isResendId : "",
    isBlockId : "",
    isDeactiveId : "",

    isShowApproveBranch: false,
    isShowRejectBranch: false,
    isApproveBranchId: "",
    isRejectBranchId: "",

    currentpassword: "",
    newPassword: "",
    confirmPassword: "",
    isShow3: false,
    isShow2: false,
    isShow: false,
    isShowCurrentPassword : true,

    user_addresses_id: "",
    organization_id: "",
    is_branches: "",
    user_id: "",
    firstName: "",
    lastName: "",
    userRole: "",
    email: "",
    gender: "",
    phoneNumber: "",
    whatsappNumber: "",
    address1: "",
    address2: "",
    area: "",
    city: "",
    state: "",
    country: 101,
    pinCode: "",
    reference_by: "",

    tab2: null,
    genderItem: ["Male", "Female"],
    isBranchesItem: ["Yes", "No"],
    countryItem: [],
    stateItem: [],
    cityItem: [],
    status: "",

    businessType: "",
    displayName: "",
    gstin: "",
    registerBusinessName: "",
    websiteuUrl: "",
    gst: null,
    companyLogo: null,
    yearsOfCorporation: "",
    monthlyCapacityList: [],
    monthlyCapacity: "",
    materialTypeList: [],
    materialType: [],
    newMaterialType: "",
    installedCapacity: "",
    noOfEmployee: "",
    businessTypeList: [],
    addDocument: false,
    uploadProfilePic: false,
    profile_picture: null,
    isAdmin : false,
    headers: [
      {
        text: "Display Name",
        align: "start",
        sortable: false,
        value: "display_name",
      },
      {
        text: "GSTIN",
        align: "start",
        sortable: false,
        value: "gst_number",
      },
      {
        text: "Consupation / Production",
        align: "start",
        sortable: false,
        value: "monthly_capacity",
      },
      {
        text: "Address line 1",
        align: "start",
        sortable: false,
        value: "address_line_1",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status",
      },
      { text: "Action", value: "action", align: "right", width: "180px" },
    ],

    organizationList: null,
    organizationId: "",

    documents: [],
  }),
};
</script>