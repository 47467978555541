import axiosServices from "@/utils/axios";
import axiosServicesFormData from "@/utils/axiosFormData";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD,API_URL,APPKEY,getDecodedToken } from '@/utils/helper'
import axios from "axios";


const state = () => ({
    isLoggedIn: false,
    authToken  : null,
    userInfo : null,
})

const getters  = {

}

const actions = {
    getUserModuleList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('/user-module/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createUserModule({ commit },payload) {
        return axiosServices
            .post('/user-module/create', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    deleteUserModule({ commit },payload) {
        return axiosServices
            .post('/user-module/delete', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewUserModule({ commit },payload) {
        return axiosServices
            .post('/user-module/list', {...payload,...OTPCOMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateUserModule({ commit },payload) {
        return axiosServices
            .post('/user-module/update', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateAdminUserProfile({ commit },payload){
        return axiosServices
            .post('/user/update/profile', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    submitContactUs({ commit }, payload) {
        return axiosServicesFormData
            .post(`/contact-us`, payload)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getUserProfile({ commit },payload){
        return axiosServices
            .post('/user/profile', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createAdminUserProfile({ commit },payload){
        return axiosServices
            .post('/admin/user/create', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateUserOrgationDetails({ commit },payload) {
        return axiosServicesFormData.post(`/user/update/profile/organization`, payload)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    submitProfilePicture({ commit }, payload) {
        const token = getDecodedToken()
        return axiosServicesFormData
            .post(`/user/update/profile_picture`, payload)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createBranches({ commit },payload){
        return axiosServicesFormData.post(`/organization/create`, payload)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getBranchList({ commit },payload){
        return axiosServices
            .post('/organization/list', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewBranch({ commit },payload){
        return axiosServices
            .post('/organization/list', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateBranches({ commit },payload){
        return axiosServicesFormData.post(`/organization/update`, payload)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    updateBranchStatus({ commit },payload) {
        return axiosServices
            .post('/organization/update/status', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getUserListData({ commit }, payload) {
        return axiosServices
            .post('/user/get_users', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getUserBranches({ commit }, payload) {
        return axiosServices
            .post('/user/get_organisations', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    logoutUser() {
        this.$router.push({ path: "/login" });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("is_buyer");
      localStorage.removeItem("is_seller");
      localStorage.removeItem("is_trader");
      localStorage.removeItem("last_password_changed_at");
      localStorage.removeItem("last_name");
      localStorage.removeItem("userid");
      localStorage.removeItem("useremail");
      localStorage.removeItem("userphone");
      localStorage.removeItem("registration_number");
      localStorage.removeItem("otp_key");
      localStorage.removeItem("first_name");
      localStorage.removeItem("branches");
      localStorage.removeItem("selectedBranch");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_type");
      this.$router.push({ path: "/login" });
    }
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}