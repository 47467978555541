<template>
  <weblayout>
    <div class="banner-blog small-inner-banner">
      <v-container>
        <div class="wrapper-of-banner justify-center">
          <div class="partner-img mt-0">
            <img src="@/assets/images/partner-2.png" alt="" />
          </div>
          <div class="title-of-blog">A J CORPORATION</div>
        </div>
      </v-container>
    </div>

    <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12">
            <div class="about-wrap">
              <div class="text-h5 mb-4"><strong>About A J CORPORATION</strong></div>

              <p>
                A J CORPORATION is a sincere pursuit to help industries with
                providing one stop solution for all types of heating equipment's
                sales, installation, commissioning, lesioning, operation and
                maintenance under one roof. A J CORPORATION is a team with more
                than 20 years of experience, who are well versed in providing
                exceptional services in manufacturing of all types of heating
                equipment's non pressure parts as well as manufacture of
                chemical plants machineries, air pollution control and
                monitoring equipment's, Ibr equipment's, supply of man power for
                all type of heating equipment's operation as well as maintenance
                team, A J CORPORATION is IBR approved contractor so we done all
                types of Ibr work, we introduce ourselves to be technological
                and data driven firm which provide unique customer experience
                through its diversified services.
              </p>

              <p>
                We are work in industries last more than 20 + years and we have
                erection & commissioned more than 300 units in Gujarat. We have
                well equipped own workshop & fabrication team. We also undertake
                turnkey Projects like Fuel Conversion, Pipeline Fabrication, Gas
                Line Fabrication, Boiler Erection, and Burner Commissioning.
                (All Burner Any Make).
              </p>

              <v-btn color="#9da73e" dark elevation="0" @click="openpdf">
                <v-icon size="18" class="mr-1">mdi-book-account-outline</v-icon>
                Download Brochure
              </v-btn>
            </div>
          </v-col>

          <v-col>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="12">
            <div class="about-wrap product-wrap">
              <div class="text-h5 mb-4"><strong>Products</strong></div>

              <div>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Boiler</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Burners</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Thermic Fluid</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Hot Water Generator</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Bag Fitter</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">ESP</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Water Tank</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Water Scrubber</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Chimney</v-chip>
                <v-chip color="#FFEEE1" class="mr-2 px-4 mb-2">Ro</v-chip>
              </div>
            </div>
          </v-col>

          <v-col>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" md="12">
            <div class="about-wrap">
              <div class="text-h5 mb-4"><strong>Contact Us</strong></div>

              <div>
                <div class="mb-1">
                  <a
                    class="black--text text-decoration-none"
                    href="tel:+917600616767"
                    >+91 123456789  </a
                  >
                </div>
                <div>
                  <a
                    class="black--text text-decoration-none"
                    href="mailto:support@biofuelbid.com"
                    >Dipen@yahoo.com</a
                  >
                </div>

                <div class="mt-3">
                  <v-btn
                    color="#9da73e"
                    dark
                    elevation="0"
                    @click="dialog = true"
                  >
                    <v-icon size="18" class="mr-1">mdi-phone-outline</v-icon>
                    Enquiry
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-text class="pt-8">
          <div class="m-title">Leave a <strong> Message</strong></div>
          <v-row class="mt-3">
            <v-col cols="12" md="6">
              <label for="">Name <span class="required">*</span></label>
              <v-text-field
                outlined
                dense
                :error-messages="errors"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label for="">Email <span class="required">*</span></label>
              <v-text-field
                outlined
                dense
                prepend-inner-icon="mdi-email-outline"
                v-model="email"
                :error-messages="errors"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <label for="">Subject <span class="required">*</span></label>
              <v-text-field
                outlined
                dense
                :error-messages="errors"
                v-model="subject"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <textarea
                class="ma-0 pa-2"
                placeholder="Type Message Here"
                dense
                :error-messages="errors"
                v-model="message"
              ></textarea>
            </v-col>
            <v-col cols="12" md="6">
              <label for="">Document</label>
              <v-file-input
                truncate-length="15"
                outlined
                dense
                prepend-inner-icon="mdi-attachment"
                prepend-icon=""
                v-model="document"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="12" class="d-flex justify-end">
              <v-btn
                color="grey"
                dark
                text
                class="px-6 mr-2"
                type="submit"
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn color="#9da73e" dark class="px-6" type="submit">
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </weblayout>
</template>
  
  <script>
import weblayout from "../layout/weblayout.vue";

export default {
  components: { weblayout },
  data() {
    return {
      dialog: false,
      tab: null,
      items: [
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
      ],
    };
  },
  methods: {
    openpdf() {
      window.open("boiler.pdf");
    },
  },
};
</script>
  
  <style>
</style>