<template>
  <AuthLayout>
    <div>
      <div class="login-wrapper" style="max-width: 650px">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>

        <div class="title-of-auth mb-4">
          <h2>Registraion Details</h2>
        </div>

        <div class="form-part">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(registerUsers)">
              <v-row>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <label for=""
                      >First Name <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="first_name"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <label for=""
                      >Last Name <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="last_name"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <label for="">Email <span class="required">*</span></label>
                    <v-text-field
                      v-model="email"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone number"
                    rules="required"
                  >
                    <label for="">Phone <span class="required">*</span></label>
                    <v-text-field
                      v-model="phoneNumber"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <label for=""
                      >Company Name <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="companyName"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <!-- <v-col cols="12" md="12" class="pb-0"> -->
                <!-- <validation-provider
                v-slot="{ errors }"
                name="Address"
                rules="required"
            >
            <label for="">Address <span class="required">*</span></label>
            <v-text-field
              v-model="address"
              outlined
              dense
              :error-messages="errors"
            ></v-text-field>
            </validation-provider> -->
                <v-col cols="12" md="12" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address1"
                    rules="required"
                  >
                    <label for=""
                      >Address line1<span class="required">*</span></label
                    >
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      prepend-inner-icon="mdi-map-marker-outline"
                      v-model="address1"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <label for="">Address line2</label>
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-map-marker-outline"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="landmark/area"
                    rules="required"
                  >
                    <label for=""
                      >Landmark / Area<span class="required">*</span></label
                    >
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="area"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col colscols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="state"
                    rules="required"
                  >
                    <label for="">State<span class="required">*</span></label>
                    <v-select
                      :items="stateItem"
                      v-model="state"
                      outlined
                      dense
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                      @change="getCityDropdownData"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="city"
                    rules="required"
                  >
                    <label for="">City<span class="required">*</span></label>
                    <v-autocomplete
                      :items="cityItem"
                      v-model="city"
                      outlined
                      dense
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <label for="">Country<span class="required">*</span></label>
                    <v-select
                      :items="countryItem"
                      readonly
                      v-model="country"
                      outlined
                      dense
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="pincode"
                    rules="required"
                  >
                    <label for="">pincode<span class="required">*</span></label>
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="pinCode"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <!-- </v-col> -->
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <label for=""
                      >User Type <span class="required">*</span></label
                    >
                    <v-select
                      :items="userTypeList"
                      item-value="id"
                      item-text="name"
                      v-model="userType"
                      outlined
                      dense
                      :error-messages="errors"
                      return-object
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Capacity"
                    rules="required"
                  >
                    <label for=""
                      >Consupation / Production
                      <span class="required">*</span></label
                    >
                    <v-select
                      :items="monthlyCapacityList"
                      item-value="id"
                      item-text="name"
                      v-model="monthlyCapacity"
                      outlined
                      dense
                      suffix="/Month"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Material"
                    rules="required"
                  >
                    <label for=""
                      >Material Type <span class="required">*</span></label
                    >
                    <v-select
                      :items="materialTypeList"
                      v-model="materialType"
                      item-value="id"
                      item-text="name"
                      outlined
                      dense
                      chips
                      multiple
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pb-0"
                  v-if="
                    materialOtherOptionId != '' &&
                    materialType.includes(materialOtherOptionId)
                  "
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="New Material Type"
                    rules="required"
                  >
                    <label for=""
                      >New Material Type<span class="required">*</span></label
                    >
                    <v-text-field
                      outlined
                      dense
                      v-model="newMaterialType"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="GST"
                    rules="required"
                  >
                    <label for=""
                      >GST Details <span class="required">*</span></label
                    >
                    <v-file-input
                      truncate-length="15"
                      outlined
                      dense
                      prepend-inner-icon="mdi-attachment"
                      prepend-icon=""
                      v-model="gstDetails"
                      :error-messages="errors"
                    ></v-file-input>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="GST Number"
                    rules="required|min:10"
                  >
                    <label for=""
                      >GST Number<span class="required">*</span></label
                    >
                    <v-text-field
                      outlined
                      dense
                      v-model="gstNumber"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Reference By"
                    rules="max:50"
                  >
                    <label for="">Reference By </label>
                    <v-text-field
                      outlined
                      dense
                      v-model="reference_by"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Terms & conditions"
                    rules="required"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        color="#9da73e"
                        value="accepted"
                        v-model="acceptCodition"
                        class="mt-n1"
                        :error-messages="errors"
                      ></v-checkbox>
                      Agree to the BioFuel Bid
                      <span
                        v-ripple
                        @click="gotoTermCondition"
                        class="font-weight-medium ml-1"
                        style="color: #9da73e; cursor: pointer"
                      >
                        Term and Condition</span
                      >
                    </div>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    rounded
                    dark
                    color="#9DA73E"
                    block
                    large
                    type="submit"
                    class="mt-4"
                  >
                    Register
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "./layout/auth.vue";
import {
  required,
  digits,
  email,
  max,
  min,
  regex,
  length,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { UUID, AUTHENTIACTIONKEY,encryptPayload, getDecodedString } from "@/utils/helper";
setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("length", {
  ...length,
  message: "Please accept the {_field_}",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("min", {
  ...min,
  message: "{_field_} may not be less than {length} characters",
});
export default {
  name: "registerdetails",
  inject: ["mySpinner"],
  data: () => ({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    address: "",
    gstDetails: null,
    panDetails: null,
    materialType: "",
    materialTypeList: [],
    userType: null,
    userTypeList: null,
    monthlyCapacity: null,
    monthlyCapacityList: null,
    acceptCodition: [],
    gstNumber: "",
    newMaterialType: "",
    materialOtherOptionId: "",
    reference_by: "",

    address1: "",
    address2: "",
    area: "",
    city: "",
    state: "",
    country: 101,
    pinCode: "",
    countryItem: [],
    stateItem: [],
    cityItem: [],
  }),
  created() {
    // this.first_name = localStorage.getItem('first_name') != null ? localStorage.getItem('first_name') : ''
    // this.last_name = localStorage.getItem('last_name') != null ? localStorage.getItem('last_name') : ''
    // this.email = localStorage.getItem('useremail') != null ? localStorage.getItem('useremail') : ''
    // this.phoneNumber = localStorage.getItem('userphone') !=null ? localStorage.getItem('userphone') : ''
    // this.id = localStorage.getItem('userid') !=null ? localStorage.getItem('userid') : ''
    this.getDropdownData();
    this.getRegisterUserData();
    if(localStorage.getItem('reference_by')) {
      this.reference_by = getDecodedString(localStorage.getItem('reference_by')).replace(
      /["']/g,
      ""
    );
    }
  },
  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    uploadPan(event) {
      this.panDetails = event.target.files[0];
    },
    uploadGst(event) {
      this.gstDetails = event.target;
    },
    gotoTermCondition() {
      const routeData = this.$router.resolve({ path: "/termcondition" });
      window.open(routeData.href, "_blank");
    },
    async registerUsers() {
      // let formData = new FormData();
      // formData.append('first_name',this.first_name)
      // formData.append('last_name',this.last_name)
      // formData.append('phone',this.phoneNumber)
      // formData.append('email',this.email)
      // formData.append('id',this.id)
      // formData.append('company_name',this.companyName)
      // // formData.append('address',this.address)
      // formData.append('monthly_capacity',this.monthlyCapacity)
      // formData.append('gst_path',this.gstDetails)
      // formData.append('gst_number',this.gstNumber)
      // formData.append('uuid',UUID)
      // formData.append('user_type',this.userType.id)
      // formData.append('material_type',this.materialType)
      // formData.append('material_type_other',this.newMaterialType)
      // formData.append('address_line_1',this.address1)
      // formData.append('address_line_2',this.address2)
      // formData.append('district',this.area)
      // formData.append('city_id',this.city)
      // formData.append('state_id',this.state)
      // formData.append('country_id',this.country)
      // formData.append('pin_code',this.pinCode)
      // formData.append('authentication_key',AUTHENTIACTIONKEY)
      // formData.append('reference_by',this.reference_by)
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        phone: this.phoneNumber,
        email: this.email,
        id: this.id,
        company_name: this.companyName,
        monthly_capacity: this.monthlyCapacity,
        gst_number: this.gstNumber,
        uuid: UUID,
        user_type: this.userType.id,
        material_type: this.materialType,
        material_type_other: this.newMaterialType,
        address_line_1: this.address1,
        address_line_2: this.address2,
        district: this.area,
        city_id: this.city,
        state_id: this.state,
        country_id: this.country,
        pin_code: this.pinCode,
        authentication_key: AUTHENTIACTIONKEY,
        reference_by: this.reference_by,
      };
      const encryptData = encryptPayload(data);
      let formData = new FormData();
      formData.append("bfb_data", encryptData.bfb_data);
      formData.append('gst_path',this.gstDetails)
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "handleRegisterUserDetails",
          formData
        );
        if (response.http_status === 200) {
          // localStorage.setItem('otp_key',response.data.otp_key);
          localStorage.removeItem('reference_by')
          this.$router.push({ path: "/forapproval" });
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module:
            "USER_TYPE,MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST,STATE_LIST",
          country_id: 101,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.monthlyCapacityList = response.data.monthly_capacity;
          this.materialTypeList = response.data.material_type;
          this.userTypeList = response.data.user_type;
          this.countryItem = response.data.country_list;
          this.stateItem = response.data.state_list;
          this.mySpinner.val = false;
          this.materialTypeList.map((item, index) => {
            if (item.name == "Other" || item.name == "other") {
              this.materialOtherOptionId = item.id;
              // console.log(this.materialOtherOptionId)
            }
          });
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getRegisterUserData() {
      let payload = {
        registration_number:
          localStorage.getItem("registration_number") != null
            ? localStorage.getItem("registration_number")
            : null,
      };
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "getUserRegisterData",
          payload
        );
        if (response.http_status === 200) {
          // localStorage.setItem('otp_key',response.data.otp_key);
          this.id = response.data.id;
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          this.email = response.data.email;
          this.phoneNumber = response.data.phone;
          this.companyName = response.data.company_name;
          this.monthlyCapacity = response.data.monthly_capacity;
          this.address = response.data.address;
          // this.$router.push({ path: '/forapproval' })
          // this.$toasted.success(response.message)
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getCityDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module: "CITY_LIST",
          country_id: 101,
          state_id: this.state,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.cityItem = response.data.city_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>

<style>
</style>