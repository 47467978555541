<template>
    <dashlayout>
        <v-container-fluid fluid>
            <div class="dash-bg">
                <div class="cover-image">
                    <img style="width: 100%" src="@/assets/images/user-cover.jpg" alt="" />

                    <!-- <v-btn fab small class="cover-edit">
              <v-icon color="#9DA73E"> mdi-pencil-outline </v-icon>
            </v-btn> -->
                </div>

                <div class="user-profile-and-detail ml-8 mb-10">
                    <div class="user-img-of-profile">
                        <img src="@/assets/images/user.png" width="150px" alt="" />
                    </div>
                    <div class="user-da mt-2">
                        <div class="user-name">Aliah Lane <span class="ml-1 subtitle-1"><v-icon color="#421E06">mdi-check-decagram</v-icon> Verified</span></div>
                        <div class="user-designation">Buyer </div>
                    </div>
                </div>
                <div class="sub-tabs">
                    <v-tabs v-model="tab2" color="#421E06">
                        <v-tab><v-icon class="mr-2">mdi-account-outline</v-icon> Basic
                            Info</v-tab>
                        <v-tab><v-icon class="mr-2">mdi-domain</v-icon> Organisations</v-tab>
                        <!-- <v-tab><v-icon class="mr-2">mdi-account-check-outline</v-icon>
                KYC</v-tab> -->
                        <v-tab><v-icon class="mr-2">mdi-shield-account-outline</v-icon>
                            Security</v-tab>
                    </v-tabs>
                </div>

                <v-tabs-items v-model="tab2">
                    <v-tab-item>
                        <v-card flat>
                            <div class="box-title-with-btn mb-0">
                                <div class="box-title">Basic Info</div>
                                <div class="btns-right"></div>
                            </div>
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateUserBasicInfo)">
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="First name" rules="required">
                                                <label for="">First Name <span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="firstName"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Last name" rules="required">

                                                <label for="">Last Name <span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="lastName"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                                                <label for="">Email <span class="required">*</span></label>
                                                <v-text-field outlined dense prepend-inner-icon="mdi-email-outline"
                                                    v-model="email" :error-messages="errors"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="gender" rules="required">
                                                <label for="">Gender <span class="required">*</span></label>
                                                <v-select :items="ganderItem" v-model="gender" outlined dense
                                                    :error-messages="errors"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Phone Number" :rules="{
                                                required: true,
                                                digits: 10,
                                            }">
                                                <label for="">Mobile No.<span class="required">*</span></label>
                                                <v-text-field outlined dense prepend-inner-icon="mdi-cellphone"
                                                    :error-messages="errors" v-model="phoneNumber"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="whatsapp Number" :rules="{
                                                required: true,
                                                digits: 10,
                                            }">
                                                <label for="">WhatsApp No.<span class="required">*</span></label>
                                                <v-text-field outlined dense prepend-inner-icon="mdi-whatsapp"
                                                    :error-messages="errors" v-model="whatsappNumber"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>

                                    <v-divider class="my-8"></v-divider>
                                    <div class="box-title-with-btn mb-0">
                                        <div class="box-title">Address</div>
                                        <div class="btns-right"></div>
                                    </div>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Address1" rules="required">
                                                <label for="">Address line1<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    prepend-inner-icon="mdi-map-marker-outline"
                                                    v-model="address1"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <label for="">Address line2</label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                prepend-inner-icon="mdi-map-marker-outline"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="landmark/area" rules="required">

                                                <label for="">Landmark / Area<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="area"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="state" rules="required">

                                                <label for="">State<span class="required">*</span></label>
                                                <v-select :items="stateItem" v-model="state" outlined dense
                                                    :error-messages="errors" item-value="id" item-text="name"
                                                    @change="getCityDropdownData"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="city" rules="required">

                                                <label for="">City<span class="required">*</span></label>
                                                <v-autocomplete :items="cityItem" v-model="city" outlined dense
                                                    :error-messages="errors" item-value="id" item-text="name"></v-autocomplete>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="country" rules="required">

                                                <label for="">Country<span class="required">*</span></label>
                                                <v-select :items="countryItem" readonly v-model="country" outlined dense
                                                    :error-messages="errors" item-value="id" item-text="name">
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="pincode" rules="required">

                                                <label for="">pincode<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="pinCode"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            <div class="text-right">
                                                <v-btn elevation="0" color="#9DA73E" dark class="px-8" type="submit">
                                                    Save
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                        </v-card>
                    </v-tab-item>


                    <v-tab-item>
                        <v-card flat>
                            <div class="box-title-with-btn mb-0">
                                <div class="box-title">Organisations</div>
                                <div class="btns-right"></div>
                            </div>
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateUserOrganization)">
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Business Type" rules="required">
                                                <label for="">Business Type<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="businessType"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Display Name" rules="required">
                                                <label for="">Display Name<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="displayName"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="GSTIN" rules="required">
                                                <label for="">GSTIN<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="gstin"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <label for="">GST</label>
                                            <v-file-input truncate-length="15" outlined dense
                                                prepend-inner-icon="mdi-attachment" prepend-icon=""
                                                v-model="gst"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Registered Business Name"
                                                rules="required">
                                                <label for="">Registered Business Name<span
                                                        class="required">*</span></label>
                                                <v-text-field outlined dense prepend-inner-icon="mdi-domain"
                                                    :error-messages="errors" v-model="registerBusinessName"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Website URL" rules="required">
                                                <label for="">Website URL<span class="required">*</span></label>
                                                <v-text-field outlined dense prepend-inner-icon="mdi-web"
                                                    :error-messages="errors" v-model="websiteuUrl"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <!-- <v-col cols="12" md="3">
                    <label for="">Company Type</label>
                    <v-select :items="items" v-model="value" outlined dense ></v-select>
                  </v-col> -->
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Business Pan" rules="required">

                                                <label for="">Business Pan<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="businessPan"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Business Pan name"
                                                rules="required">

                                                <label for="">Business Name Pan<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="businessPanName"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <!-- <v-col cols="12" md="3">
                    <validation-provider v-slot="{ errors }" name="GSTIN" rules="required">
  
                    <label for="">GSTIN</label>
                    <v-text-field outlined dense :error-messages="errors" v-model="gstin"></v-text-field>
                    </validation-provider>
                  </v-col> -->
                                       

                                        <v-col cols="12" md="3">
                                            <label for="">Company Logo</label>
                                            <v-file-input truncate-length="15" outlined dense
                                                prepend-inner-icon="mdi-attachment" prepend-icon=""
                                                v-model="companyLogo"></v-file-input>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Year Of Incorporation"
                                                rules="required">

                                                <label for="">Year Of Incorporation<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="yearsOfCorporation"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Number of employees"
                                                rules="required">

                                                <label for="">Number of employees<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors" prepend-inner-icon="mdi-account-group-outline"
                                                    v-model="noOfEmployee"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Material" rules="required">
                                                <label for="">Material Type <span class="required">*</span></label>
                                                <v-select :items="materialTypeList" v-model="materialType" item-value="id"
                                                    item-text="name" outlined dense chips multiple
                                                    :error-messages="errors"></v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" md="3">

                                            <validation-provider v-slot="{ errors }" name="Capacity" rules="required">
                                                <label for="">Consupation / Production <span
                                                        class="required">*</span></label>
                                                <v-select :items="monthlyCapacityList" item-value="id" item-text="name"
                                                    v-model="monthlyCapacity" outlined dense suffix="/Month"
                                                    :error-messages="errors"></v-select>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" md="3">
                                            <validation-provider v-slot="{ errors }" name="Installed Capacity"
                                                rules="required">

                                                <label for="">Installed Capacity<span class="required">*</span></label>
                                                <v-text-field outlined dense :error-messages="errors"
                                                    v-model="installedCapacity"></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                     

                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <div class="text-right">
                                                <v-btn elevation="0" color="#9DA73E" dark class="px-8" type="submit">
                                                    Save
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                        </v-card>
                    </v-tab-item>
                    <!-- <v-tab-item>
              <v-card flat>
                <div class="box-title-with-btn mb-0">
                  <div class="box-title">KYC</div>
                  <div class="btns-right"></div>
                </div>
                <v-row>
                  <v-col cols="12" md="3">
                    <label for="">Business Type</label>
                    <v-text-field outlined dense  :error-messages="errors"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label for="">Business PAN</label>
                    <v-text-field outlined dense  prepend-inner-icon="mdi-card-account-details-outline"
                      :error-messages="errors"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label for="">Business Name as per PAN</label>
                    <v-text-field outlined dense  prepend-inner-icon="mdi-email-outline"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label for="">GSTIN</label>
                    <div class="d-flex">
                      <v-text-field outlined dense ></v-text-field>
                      <div>
                        <v-btn outlined height="40" elevation="0" color="#9DA73E" dark class="ml-2 px-1">
                          Verify
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <div class="text-right">
                      <v-btn elevation="0" color="#9DA73E" dark class="px-8">
                        Save
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item> -->

                    <v-tab-item>
                        <v-card flat>
                            <div class="box-title-with-btn mb-0">
                                <div class="box-title">Change Password</div>
                                <div class="btns-right"></div>
                            </div>
                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(changeUserPassword)">
                                    <v-row>
                                        <v-col cols="12" sm="3" class="pb-0">
                                            <validation-provider v-slot="{ errors }" name="current password"
                                                rules="required">
                                                <label for="">Current Password <span class="required">*</span></label>
                                                <v-text-field v-model="currentpassword" outlined dense
                                                    :error-messages="errors"
                                                    :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="isShow ? 'text' : 'password'" @click:append="isShow = !isShow">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" class="pb-0">
                                            <validation-provider v-slot="{ errors }" name="password" rules="required|min:8">
                                                <label for="">New Password<span class="required">*</span></label>
                                                <v-text-field v-model="newPassword" outlined dense :error-messages="errors"
                                                    name="password" :append-icon="isShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="isShow2 ? 'text' : 'password'"
                                                    @click:append="isShow2 = !isShow2">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" class="pb-0">
                                            <validation-provider v-slot="{ errors }" name="Confirmatoin"
                                                rules="required|confirmed:password|min:8">
                                                <label for="">Confirm Password <span class="required">*</span></label>
                                                <v-text-field v-model="confirmPassword" outlined dense
                                                    :error-messages="errors"
                                                    :append-icon="isShow3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="isShow3 ? 'text' : 'password'"
                                                    @click:append="isShow3 = !isShow3">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3" md="12">
                                            <div class="text-left mt-4">
                                                <v-btn elevation="0" color="#9DA73E" dark class="px-8" type="submit">
                                                    Change Password
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </form>
                            </validation-observer>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-container-fluid>
    </dashlayout>
</template>
    
<script>
import dashlayout from "../layout/dashlayout.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import { required, digits, email, max, min, regex, confirmed } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { UUID, AUTHENTIACTIONKEY } from '@/utils/helper'

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})
extend('min', {
    ...min,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})
extend('confirmed', {
    ...confirmed,
    message: 'Please make sure your password and confirm password are the same.',
})
export default {
    components: {
        dashlayout,
        ConformationBox,
        ValidationObserver,
        ValidationProvider
    },
    name: "updateProfile",
    inject: ['mySpinner'],
    methods: {
        goToLogin() {
            this.$router.push({ path: "/login" });
        },
        
        async changeUserPassword() {
            try {
                this.mySpinner.val = true;
                const payload = {
                    password: this.newPassword,
                    password_confirmation: this.confirmPassword,
                    old_password: this.currentpassword
                }
                const response = await this.$store.dispatch('updateUserPassword', payload)
                if (response.http_status === 200) {
                    localStorage.setItem('otp_key', response.data.otp_key);
                    this.$router.push({ path: '/login' })
                    localStorage.removeItem('last_password_changed_at')
                    this.$toasted.success(response.message)
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        },
        async updateUserBasicInfo() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    is_user_profile: true,
                    is_address: true,
                    is_organisations: false,
                    is_kyc: false,
                    last_name: this.lastName,
                    first_name: this.firstName,
                    gender: this.gender,
                    mobile_number: this.phoneNumber,
                    whatsapp_number: this.whatsappNumber,
                    address_line_1: this.address1,
                    address_line_2: this.address2,
                    district: this.area,
                    pin_code: this.pinCode,
                    city_id: this.city,
                    state_id: this.state,
                    country_id: this.country,
                    id: this.sellerId
                }
                const response = await this.$store.dispatch('updateAdminUserProfile', payload)
                if (response.http_status === 200) {
                    this.$toasted.success(response.message)
                    this.getUserBasicInfo()
                    this.getDropdownData()
                    this.getStateDropdownData()
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        },
        async getUserBasicInfo() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    id: this.sellerId
                }
                const response = await this.$store.dispatch('getUserProfile', payload)
                if (response.http_status === 200) {
                    if (response.data.addresses.length > 0) {
                        this.state = response.data.addresses[0].state_id
                        this.city = response.data.addresses[0].city_id
                        this.pinCode = response.data.addresses[0].pin_code
                        this.area = response.data.addresses[0].district
                        this.address2 = response.data.addresses[0].address_line_2
                        this.address1 = response.data.addresses[0].address_line_1
                        this.getCityDropdownData()
                    }
                    this.whatsappNumber = response.data.whatsapp_number
                    this.phoneNumber = response.data.phone
                    this.gender = response.data.gender
                    this.firstName = response.data.first_name
                    this.lastName = response.data.last_name
                    this.email = response.data.email
                    this.status = response.data.status
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        },

        async getDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = { module: 'MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST' }
                const response = await this.$store.dispatch('loadDynamicData', payload)
                if (response.http_status === 200) {
                    this.materialTypeList = response.data.material_type;
                    this.monthlyCapacityList = response.data.monthly_capacity;
                    this.countryItem = response.data.country_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        },
        async getStateDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = { module: 'STATE_LIST', country_id: 101 }
                const response = await this.$store.dispatch('loadDynamicData', payload)
                if (response.http_status === 200) {
                    this.stateItem = response.data.state_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        },
        async getCityDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = { module: 'CITY_LIST', country_id: 101, state_id: this.state }
                const response = await this.$store.dispatch('loadDynamicData', payload)
                if (response.http_status === 200) {
                    this.cityItem = response.data.city_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        },
        async updateUserOrganization() {
            let formData = new FormData();
            formData.append('registered_business_name', this.registerBusinessName)
            formData.append('display_name', this.displayName)
            formData.append('website_url', this.websiteuUrl)
            formData.append('business_type', 'MSME')
            formData.append('id', this.sellerId)
            formData.append('business_pan', this.businessPan)
            formData.append('business_name_pan', this.businessPanName)
            formData.append('gst_number', this.gstin)
            formData.append('gst', this.gst)
            formData.append('uuid', UUID)
            formData.append('company_logo', this.companyLogo)
            formData.append('material_type', this.materialType)
            formData.append('years_of_incorporation', this.yearsOfCorporation)
            formData.append('annual_consumption', this.monthlyCapacity)
            formData.append('installed_capacity', this.installedCapacity)
            formData.append('number_of_employees', this.noOfEmployee)
            formData.append('authentication_key', AUTHENTIACTIONKEY)
            this.mySpinner.val = true;
            try {
                const response = await this.$store.dispatch('updateUserOrgationDetails', formData)
                if (response.http_status === 200) {
                    this.$toasted.success(response.message)
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        }

    },
    created() {
        let isAdmin = localStorage.getItem('is_admin')
        if(isAdmin != 1) {
           this.sellerId =  localStorage.getItem('user_id')
        }
        this.getUserBasicInfo()
        this.getDropdownData()
        this.getStateDropdownData()
    },
    data: () => ({
        sellerId : '',
        isShowApprove: false,
        isShowReject: false,
        isApproveId: '',
        isRejectId: '',

        currentpassword: '',
        newPassword: '',
        confirmPassword: '',
        isShow3: false,
        isShow2: false,
        isShow: false,

        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        phoneNumber: '',
        whatsappNumber: '',
        address1: '',
        address2: '',
        area: '',
        city: '',
        state: '',
        country: 101,
        pinCode: '',

        tab2: null,
        ganderItem: ['Male', 'Female'],
        countryItem: [],
        stateItem: [],
        cityItem: [],
        status: '',

        businessType: '',
        displayName: '',
        gstin: '',
        registerBusinessName: '',
        websiteuUrl: '',
        businessPan: '',
        businessPanName: '',
        gst: null,
        companyLogo: null,
        yearsOfCorporation: '',
        monthlyCapacityList: [],
        monthlyCapacity: '',
        materialTypeList: [],
        materialType: '',
        installedCapacity: '',
        noOfEmployee: ''
    }),
};
</script>
    
<style></style>