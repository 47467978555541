<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2"
              >mdi-post-outline</v-icon
            >
            {{ $route.params.id != undefined ? "Edit" : "Add" }} Purchase
            Invoices
          </div>
          <div class="btns-right">
            <span
              class="mr-2"
              style="color: #9da73e; font-size: 14px"
              v-if="$route.params.id != undefined"
              >Payment Done: {{ paidAmount | toCurrency }}</span
            >
            <v-btn
              class="mr-2"
              elevation="0"
              color="#9DA73E"
              dark
              @click="paymentDialog"
              v-if="$route.params.id != undefined"
            >
              Add Payment</v-btn
            >
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              class="mr-2"
              @click="paymentHistory = true"
            >
              View Details
            </v-btn>
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              text
              to="/purchaseInvoicesList"
            >
              <v-icon class="mr-2"> mdi-arrow-left </v-icon> Back</v-btn
            >
          </div>
        </div>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(adminAddEditInvoice)">
            <div
              style="display: flex; align-items: center; justify-content: left"
            >
              <v-card
                class="mx-auto ml-0"
                height="330"
                width="300"
                min-width="20%"
                style="
                  justify-content: center;
                  align-items: center;
                  display: flex;
                "
              >
                <v-card-text>
                  Bill To <br />
                  Sustineri Biofuel Solutions LLP <br />
                  <span>
                    243 SOBO Center, <br />
                    Gala Gymkhana Road,<br />
                    South Bopal,<br />
                    Ahmedabad
                  </span>
                </v-card-text>
              </v-card>
              <v-card
                class="mx-auto ml-n8"
                height="330"
                width="300"
                min-width="20%"
                style="
                  justify-content: center;
                  align-items: center;
                  display: flex;
                "
              >
                <v-col v-if="user == ''" cols="12" md="8" class="mt-n8">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User"
                    rules="required"
                  >
                    <label for="">User <span class="required">*</span></label>
                    <v-select
                      v-model="user"
                      :items="userData"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      :error-messages="errors"
                      @change="getUserBranches"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-card-text v-if="user != ''">
                  Ship To <br />
                  {{ findUser(user) }} <br />
                  <span
                    v-if="shipping_address2 == null || shipping_address2 == ''"
                  >
                    {{ shipping_address1 }}, {{ shipping_area }} <br />
                    {{ shippingCityName(shipping_city) }},
                    {{ shippingStateName(shipping_state) }},
                    {{ shippingCountryName(shipping_country) }} <br />
                    {{ shipping_pinCode }}
                  </span>
                  <span v-else>
                    {{ shipping_address1 }}, {{ shipping_address2 }},
                    {{ shipping_area }} <br />
                    {{ shippingCityName(shipping_city) }},
                    {{ shippingStateName(shipping_state) }},
                    {{ shippingCountryName(shipping_country) }} <br />
                    {{ shipping_pinCode }}
                  </span>
                  <br />
                  <div
                    style="color: #9da73e; font-weight: 500"
                    class="pointer d-inline"
                    v-ripple
                  >
                    <span @click="editShippingAddress">Edit Address</span>
                  </div>
                  <br />
                  <div
                    style="color: #9da73e; font-weight: 500"
                    class="pointer d-inline"
                    v-ripple
                  >
                    <span @click="chooseAnotherUser"
                      >Choose a different User</span
                    >
                  </div>
                </v-card-text>
              </v-card>
              <div style="min-width: 40%">
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Branch"
                      rules="required"
                    >
                      <label for=""
                        >Branch <span class="required">*</span></label
                      >
                      <v-select
                        v-model="branch"
                        :items="branchData"
                        item-value="id"
                        item-text="display_name"
                        dense
                        outlined
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="PO Number"
                      rules="alpha_num"
                    >
                      <label for="">PO No.</label>
                      <v-text-field
                        outlined
                        :error-messages="errors"
                        dense
                        v-model="po_no"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Phone Number"
                      :rules="{
                        digits: 10,
                      }"
                    >
                      <label for="">Mobile </label>
                      <v-text-field
                        v-model="phoneNumber"
                        outlined
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="6" md="6" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Contact Person"
                      rules="required"
                    >
                      <label for=""
                        >Contact Person<span class="required">*</span></label
                      >
                      <v-text-field
                        outlined
                        dense
                        v-model="contact_person"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="pb-0 mt-1">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Invoice Date"
                          rules="required"
                        >
                          <label for=""
                            >Invoice Date <span class="required">*</span></label
                          >
                          <v-text-field
                            v-model="invoice_date"
                            outlined
                            dense
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="invoice_date"
                        no-title
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0 mt-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Vendor Code"
                      rules="alpha_num"
                    >
                      <label for="">Vendor Code</label>
                      <v-text-field
                        outlined
                        dense
                        :error-messages="errors"
                        v-model="vendor_code"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="PO Date"
                          rules="required"
                        >
                          <label for=""
                            >PO Date <span class="required">*</span></label
                          >
                          <v-text-field
                            v-model="po_date"
                            outlined
                            dense
                            hint="MM/DD/YYYY format"
                            persistent-hint
                            prepend-inner-icon="mdi-calendar"
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="po_date"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Payment condition"
                      rules="required"
                    >
                      <label for=""
                        >Payment condition
                        <span class="required">*</span></label
                      >
                      <v-select
                        v-model="payment_condition"
                        :items="paymentConditionData"
                        @input="changeDueDate"
                        dense
                        outlined
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="mt-8">
              <v-row>
                <v-col cols="2" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Packaging"
                    rules="required"
                  >
                    <label for=""
                      >Packaging <span class="required">*</span></label
                    >
                    <v-select
                      v-model="packaging"
                      :items="packagingData"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="2" md="2" v-if="payment_condition == 'Credit'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment terms"
                    rules="required"
                  >
                    <label for=""
                      >Payment terms <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="payment_term_days"
                      outlined
                      type="number"
                      dense
                      placeholder="10"
                      suffix="Days"
                      :min="minMaxPaymentTerms[0]"
                      :max="minMaxPaymentTerms[1]"
                      :error-messages="errors"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="2" md="2">
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    style="left: 100px !important"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Due Date"
                        rules="required"
                      >
                        <label for=""
                          >Due Date <span class="required">*</span></label
                        >
                        <v-text-field
                          v-model="due_date"
                          outlined
                          dense
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="due_date"
                      no-title
                      :min="new Date().toJSON().slice(0, 10)"
                      @input="menu3 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" md="2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Delivery"
                    rules="required"
                  >
                    <label for=""
                      >Delivery <span class="required">*</span></label
                    >
                    <v-select
                      v-model="delivery"
                      :items="deliveryData"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="2" md="2">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Delivery Date"
                        rules="required"
                      >
                        <label for=""
                          >Delivery Date <span class="required">*</span></label
                        >
                        <v-text-field
                          v-model="delivery_date"
                          outlined
                          dense
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="delivery_date"
                      no-title
                      :min="po_date"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" md="2">
                  <!-- <validation-provider
                      v-slot="{ errors }"
                      name="Vehicle No."
                      rules="required"
                    > -->
                  <label for="">Vehicle No.</label>
                  <v-text-field
                    v-model="vehicleno"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-text-field>
                  <!-- </validation-provider> -->
                </v-col>
              </v-row>
            </div>
            <br />
            <v-divider class="my-8"></v-divider>
            <v-simple-table
              dense
              fixed-header
              :class="{
                'mt-4 simple_table table_outline': tableError,
                'mt-4 simple_table': !tableError,
              }"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Amount</th>
                    <th class="text-right">Action</th>
                  </tr>
                </thead>
                <tbody v-if="TableData.length > 0">
                  <tr v-for="(item, index) in TableData" :key="index">
                    <td>{{ item.category_name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.price | toCurrency }}</td>
                    <td class="text-right">
                      {{ item.sub_total | toCurrency }}
                    </td>
                    <td class="text-right">
                      <v-btn
                        fab
                        small
                        elevation="0"
                        color="transparent"
                        @click="gotoEdit(index)"
                      >
                        <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        small
                        elevation="0"
                        color="transparent"
                        @click="confirmDeleteitem(index)"
                      >
                        <v-icon color="red"> mdi-delete-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center" colspan="6">
                      <v-btn block @click="itemAdd" text>
                        <v-icon> mdi-plus </v-icon>
                        Add Item
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">Subtotal</td>
                    <td class="text-right">{{ subtotal | toCurrency }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">IGST(5%)</td>
                    <td class="text-right">{{ gstAmount | toCurrency }}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">Total</td>
                    <td class="text-right">
                      {{ total | toCurrency }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <td colspan="number_of_columns">No data available</td>
                  <tr>
                    <td class="text-center" colspan="6">
                      <v-btn block @click="addItem = true" text>
                        <v-icon> mdi-plus </v-icon>
                        Add Item
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <span class="table_error" v-if="tableError"
              >Table can not be empty</span
            >

            <div>
              <v-row>
                <v-col cols="12" md="6" class="mt-3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Terms & Condition"
                    rules="required"
                  >
                    <label for=""
                      >Term & Condition <span class="required">*</span></label
                    >
                    <v-textarea
                      v-model="terms_conditions"
                      rows="5"
                      :error-messages="errors"
                      outlined
                      readonly
                      placeholder="Write your term & conditions here"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6" class="mt-3">
                  <!-- <validation-provider
                      v-slot="{ errors }"
                      name="Terms & Condition"
                      rules="required"
                    > -->
                  <label for="">Additional Term & Condition</label>
                  <v-textarea
                    v-model="additional_terms_conditions"
                    rows="5"
                    :error-messages="errors"
                    outlined
                    placeholder="Write your term & conditions here"
                  ></v-textarea>
                  <!-- </validation-provider> -->
                </v-col>
                <v-col cols="12" md="12" class="mt-3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Aceept Term & Condition"
                    rules="required"
                  >
                    <v-checkbox
                      class="mt-0 pt-0"
                      v-model="is_terms_accepted"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        <div>
                          I agree that
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                target="_blank"
                                href="/TermCondition"
                                @click.stop
                                v-on="on"
                              >
                                Term & Condition
                              </a>
                            </template>
                            Click here to read details
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="12">
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-4"
                    type="submit"
                  >
                    Submit
                  </v-btn>
                  &nbsp;
                  <v-btn
                    v-if="$route.params.id != undefined"
                    elevation="0"
                    @click="getInvoicePDF"
                    color="#9DA73E"
                    dark
                    class="px-4"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </form>
        </validation-observer>
      </div>
    </v-container-fluid>
    <v-dialog v-model="isDisplayInvoice" max-width="720" persistent>
      <v-card height="1080">
        <v-card-title>
          <div class="box-title-with-btn mb-0">
            <div class="box-title mb-0">Invoice Details</div>
          </div>
        </v-card-title>
        <iframe height="100%" width="100%" :src="`${pdfPath}`"></iframe>
        <v-card-actions class="pb-4" style="background: white">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="isDisplayInvoice = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentHistory" width="450" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          Payment Transaction History
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="paymentHistory = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-timeline clipped dense>
            <template v-for="(data, index) in paymentDetails">
              <template>
                <v-timeline-item
                  small
                  class="mb-4"
                  icon="mdi-check"
                  :key="index"
                >
                  <v-row class="pt-1">
                    <v-col cols="5">
                      <strong>{{ data.payment_date }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ data.payment_amount | toCurrency }}</strong>
                      <div class="text-caption">
                        {{ data.transaction_type }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </template>
            </template>
            <div
              class="mb-8"
              v-if="paymentDetails == ''"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h3>No Payment has been Done</h3>
            </div>
          </v-timeline>
          <span style="color: black"
            >Total Paid Amount:
            {{ paidAmount | toCurrency }}</span
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentMenu" width="450" persistent>
      <v-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(purchaseAddPayment)">
            <v-card-title class="dialog-title justify-space-between">
              Payment
              <v-btn
                fab
                x-small
                elevation="0"
                color="transparent"
                @click="closeDialog"
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Amount"
                    rules="required"
                  >
                    <label for=""
                      >Payment Amount<span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="paymentAmount"
                      type="number"
                      outlined
                      dense
                      @change="PaymentCheck"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <v-menu
                    ref="menuPayment"
                    v-model="menuPayment"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Payment Date"
                        rules="required"
                      >
                        <label for="">Payment Date</label>
                        <v-text-field
                          v-model="paymentDate"
                          outlined
                          dense
                          hint="MM/DD/YYYY format"
                          persistent-hint
                          prepend-inner-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="paymentDate"
                      no-title
                      :min="new Date().toJSON().slice(0, 10)"
                      @input="menuPayment = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Method"
                    rules="required"
                  >
                    <label for=""
                      >Payment Method<span class="required">*</span></label
                    >
                    <v-select
                      v-model="paymentMethod"
                      :items="paymentMethodList"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-4">
              <v-spacer></v-spacer>
              <div class="btns-right">
                <v-btn
                  elevation="0"
                  color="#9DA73E"
                  dark
                  class="mr-2"
                  @click="paymentHistory = true"
                >
                  View Details
                </v-btn>
                <v-btn
                  elevation="0"
                  v-if="payButton"
                  color="#9DA73E"
                  dark
                  type="submit"
                >
                  Pay
                </v-btn>
              </div>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>

    <!---     
                        ADD ITEMS  
  
            --->
    <v-dialog v-model="addItem" width="750" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between"
          >Add Item
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="closeItem"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateInvoiceData)">
              <v-row>
                <v-col cols="12" md="4" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Raw Material"
                    rules="required"
                  >
                    <label for=""
                      >Raw Material <span class="required">*</span></label
                    >
                    <v-select
                      v-model="category_id"
                      :items="categoriesData"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      :error-messages="errors"
                      @change="getItemData"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Item"
                    rules="required"
                  >
                    <label for="">Item <span class="required">*</span></label>
                    <v-select
                      v-model="item"
                      :items="itemData"
                      dense
                      outlined
                      item-value="id"
                      item-text="item_name"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4" class="pb-0">
                  <label for="">Details</label>
                  <v-textarea
                    outlined
                    dense
                    cols="10"
                    rows="1"
                    v-model="description"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="4" class="pb-0"
                  ><validation-provider
                    v-slot="{ errors }"
                    name="Quantity"
                    rules="required||min_value:1"
                  >
                    <label for=""
                      >Quantity <span class="required">*</span>
                      <!-- <span v-if="item">(Maximum {{ itemStock }} Stock)</span> -->
                    </label>
                    <v-text-field
                      v-model="quantity"
                      dense
                      ref="field"
                      outlined
                      :error-messages="errors"
                      type="number"
                      step="0.01"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Price"
                    rules="required|min_value:1"
                  >
                    <label for=""
                      >Price <span class="required">*</span> (Please enter per
                      Ton Price)
                    </label>

                    <v-text-field
                      v-model="expected_price"
                      outlined
                      dense
                      type="number"
                      step="0.01"
                      id="price"
                      suffix=""
                      :error-messages="errors"
                      :min="0"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="4" class="pb-0"
                  ><validation-provider
                    v-slot="{ errors }"
                    name="Amount"
                    rules="required"
                  >
                    <label for="">Amount <span class="required">*</span></label>
                    <v-text-field
                      v-model="amount"
                      dense
                      readonly
                      outlined
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="pb-0"
                  style="display: flex; align-items: end; justify-content: end"
                >
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-4"
                    type="submit"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--     SHIPPING ADDRESS     
      
      -->
    <v-dialog v-model="shippingaddressModel" width="750" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between"
          >User Shipping Address
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="shippingaddressModel = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updateShippingAddress)">
              <v-row>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address line1"
                    rules="required"
                  >
                    <label for=""
                      >Address line1<span class="required">*</span></label
                    >
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      prepend-inner-icon="mdi-map-marker-outline"
                      v-model="shipping_edit_address1"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <label for="">Address line2</label>
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-map-marker-outline"
                    v-model="shipping_edit_address2"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Landmark / Area"
                    rules="required"
                  >
                    <label for=""
                      >Landmark / Area<span class="required">*</span></label
                    >
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="shipping_edit_area"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <label for="">State<span class="required">*</span></label>
                    <v-select
                      :items="shippingStateItem"
                      v-model="shipping_edit_state"
                      outlined
                      dense
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                      @change="getShippingCityDropdownData()"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <label for="">City<span class="required">*</span></label>
                    <v-autocomplete
                      :items="shippingCityEditItem"
                      v-model="shipping_edit_city"
                      outlined
                      dense
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <label for="">Country<span class="required">*</span></label>
                    <v-select
                      :items="shippingCountryItem"
                      readonly
                      v-model="shipping_edit_country"
                      outlined
                      dense
                      :error-messages="errors"
                      item-value="id"
                      item-text="name"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="6" class="pb-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Pincode"
                    rules="required"
                  >
                    <label for="">pincode<span class="required">*</span></label>
                    <v-text-field
                      outlined
                      dense
                      :error-messages="errors"
                      v-model="shipping_edit_pinCode"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pb-0"
                  style="display: flex; align-items: end; justify-content: end"
                >
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-4"
                    type="submit"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConformationBox
      v-if="isShowDeleteDialog"
      :title="'Confirmation'"
      message="Are you sure want to delete ?"
      @confirm="confirmDeleteForm"
      @cancel="closeDeleteFormModal"
    />
  </dashlayout>
</template>
    
  <script>
import dashlayout from "@/components/layout/dashlayout.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import {
  required,
  digits,
  max,
  alpha_num,
  integer,
  min_value,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import {
  API_URL,
  UUID,
  AUTHENTIACTIONKEY,
  getDecodedStringBase64,
  getDecodedToken,
  COMMONPAYLOAD,
  encryptPayload,
} from "@/utils/helper";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import axios from "axios";
// import pdfData from '../../../assets/test.pdf'
setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} needs to be alphabet or number",
});

extend("integer", {
  ...integer,
  message: "{_field_} needs to be number",
});

extend("min_value", {
  ...min_value,
  message: "{_field_} may not be less than {min}",
});

export default {
  name: "blogaddedit",
  inject: ["mySpinner"],
  components: {
    dashlayout,
    ValidationObserver,
    ValidationProvider,
    VuePdfEmbed,
    ConformationBox,
  },
  data: () => ({
    title: "",
    user: "",
    user_id: "",
    userData: [],
    branch: "",
    branchData: [],
    category_id: "",
    categoriesData: [],
    quantity: "",
    payment_condition: "",
    payment_term_days: 0,
    paymentConditionData: ["Advanced", "Against delivery", "Credit"],
    packaging: "",
    packagingData: ["Loose", "Packed"],
    status: "",
    vehicleno: "",
    delivery: "",
    deliveryData: ["Ex-works", "FOR at site"],
    expected_price: "",
    description: "",
    phoneNumber: "",
    shipping_address1: "",
    shipping_address2: "",
    shipping_area: "",
    shipping_state: "",
    shippingStateItem: [],
    shippingCityItem: [],
    shipping_city: "",
    shippingCountryItem: [],
    shipping_country: 101,
    shipping_pinCode: "",
    is_terms_accepted: "",
    terms_delivery: "",
    terms_conditions: "",
    pdfPath: "",
    isDisplayInvoice: false,
    item: "",
    itemData: [],
    po_no: "",
    vendor_code: "",
    contact_person: "",
    po_date: "",
    delivery_date: "",
    menu1: false,
    menu2: false,
    menu3: false,
    hsn: "",
    due_date: "",
    menu4: false,
    menu5: false,
    invoice_date: "",
    additional_terms_conditions: "",
    paymentMenu: false,
    menuPayment: false,
    paymentAmount: "",
    paymentDate: "",
    payButton: false,
    addressModel: false,
    shippingaddressModel: false,
    addItem: false,
    TableData: [],
    shipping_edit_address1: "",
    shipping_edit_address2: "",
    shipping_edit_area: "",
    shipping_edit_state: "",
    shipping_edit_city: "",
    shipping_edit_country: 101,
    shipping_edit_pinCode: "",
    shipping_address_edit: false,
    shippingCityEditItem: [],
    totalAmount: "",
    invoice_number: "",
    isShowDeleteDialog: false,
    deleteItem: "",
    editItemId: "",
    diffBetweenInvoice: "",
    tableError: false,
    changeUserAddress: false,
    allTotal: "",
    paymentMethod: "",
    paymentMethodList: ["Cash", "UPI/Bank Transfer", "Cheque"],
    total_amount: "",
    paymentHistory: false,
    paidAmount: "",
    paymentDetails: [],
    // itemStock: 0,
  }),
  created() {
    this.getUserListData();
    this.getDropdownData();
    this.getCountryStateData();
    if (this.$route.params.id != "" && this.$route.params.id != undefined) {
      // this.viewItemDetails();
      this.viewInvoiceDetails();
      this.purchaseInvoiceGetPayment();
    }
    var today = new Date();
    this.paymentDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    )
      .toJSON()
      .slice(0, 10);
    // this.due_date = new Date(
    //   today.getFullYear(),
    //   today.getMonth(),
    //   today.getDate() + 8
    // )
    //   .toJSON()
    //   .slice(0, 10);
    this.terms_conditions = `1. Delivery : ${this.delivery}
2. Payment : ${this.diffBetweenInvoice} Days from Date of Invoice.
3. Goods once sold w ill never be taken back or exchanged unless there are any manufacturing defects.
4. Interest @ 18% p.a. plus applicable GST will be charged for payment after due date.
5. Prices are subject to revision for reasons beyond our control.
6. Any claim in respect of bill will be made within 7 days after receipt of the goods.
7. Unloading in Buyer's Scop
8. Other Taxes & Charges Extra as per actual, If Any
9. All transactions/matters are strictly Subject to Ahmedabad jurisdiction only.
10. Our responsibility ceases as soon as the goods are delivered from our premises.
  `;
  },
  filters: {
    toCurrency: function (value) {
      var formatter = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      });
      return formatter.format(value);
    },
  },
  computed: {
    minMaxPaymentTerms() {
      if (this.payment_condition == "Credit") {
        return [1, 60];
      } else if (this.payment_condition == "Advanced") {
        return [10, 100];
      } else {
        return [0, 0];
      }
    },
    amount() {
      let totalAmount = this.expected_price * this.quantity;
      this.totalAmount = totalAmount.toFixed(2);
      return totalAmount.toFixed(2);
    },
    subtotal() {
      return this.calculateSubtotal();
    },
    gstAmount() {
      return this.calculateGST();
    },
    total() {
      const total = parseFloat(this.subtotal) + parseFloat(this.gstAmount);
      this.allTotal = total.toFixed(2);
      return total.toFixed(2);
    },
    PaymentCheck() {
      if (this.paymentAmount == "") {
        this.payButton = false;
      } else {
        if (
          Number(this.paymentAmount) <=
            Number(this.allTotal) - Number(this.paidAmount) &&
          Number(this.paymentAmount) > 0
        ) {
          this.payButton = true;
        } else {
          this.payButton = false;
        }
      }
    },
    changeDueDate() {
      if (this.payment_condition === "Advanced") {
        this.due_date = this.invoice_date;
      } else if (this.payment_condition === "Against delivery") {
        let invoice_date = new Date(this.invoice_date);
        invoice_date = new Date(
          invoice_date.getTime() + 7 * 24 * 60 * 60 * 1000
        );
        this.due_date = invoice_date.toISOString().split("T")[0];
      } else if (this.payment_condition === "Credit") {
        let invoice_date = new Date(this.invoice_date);
        invoice_date = new Date(
          invoice_date.getTime() + this.payment_term_days * 24 * 60 * 60 * 1000
        );
        this.due_date = invoice_date.toISOString().split("T")[0];
      }
    },
  },
  watch: {
    due_date: {
      handler(newVal, oldVal) {
        const oneDay = 1000 * 60 * 60 * 24;
        const due_date = new Date(this.due_date).getTime();
        const invoice_date = new Date(this.invoice_date).getTime();
        this.diffBetweenInvoice = Math.floor(
          (due_date - invoice_date) / oneDay
        );
        this.terms_conditions = `1. Delivery : ${this.delivery}
2. Payment : ${this.diffBetweenInvoice} Days from Date of Invoice.
3. Goods once sold w ill never be taken back or exchanged unless there are any manufacturing defects.
4. Interest @ 18% p.a. plus applicable GST will be charged for payment after due date.
5. Prices are subject to revision for reasons beyond our control.
6. Any claim in respect of bill will be made within 7 days after receipt of the goods.
7. Unloading in Buyer's Scop
8. Other Taxes & Charges Extra as per actual, If Any
9. All transactions/matters are strictly Subject to Ahmedabad jurisdiction only.
10. Our responsibility ceases as soon as the goods are delivered from our premises.
  `;
      },
      deep: true,
    },
    delivery: {
      handler(newVal, oldVal) {
        this.terms_conditions = `1. Delivery : ${this.delivery}
2. Payment : ${this.diffBetweenInvoice} Days from Date of Invoice.
3. Goods once sold w ill never be taken back or exchanged unless there are any manufacturing defects.
4. Interest @ 18% p.a. plus applicable GST will be charged for payment after due date.
5. Prices are subject to revision for reasons beyond our control.
6. Any claim in respect of bill will be made within 7 days after receipt of the goods.
7. Unloading in Buyer's Scop
8. Other Taxes & Charges Extra as per actual, If Any
9. All transactions/matters are strictly Subject to Ahmedabad jurisdiction only.
10. Our responsibility ceases as soon as the goods are delivered from our premises.
  `;
      },
    },
  },
  methods: {
    // stockCheck(id) {
    //   const value = this.itemData.find((item) => item.id === id)
    //   this.itemStock = value.stock
    // },
    itemAdd() {
      this.addItem = true;
      this.itemData = [];
    },
    closeItem() {
      this.addItem = false;
      this.editItemId = "";
      this.category_id = "";
      this.item = "";
      this.description = "";
      this.quantity = "";
      this.expected_price = "";
      this.addItem = false;
      this.$refs.observer.reset();
      this.itemData = [];
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    calculateSubtotal() {
      return this.TableData.reduce((accumulator, currentItem) => {
        accumulator = parseFloat(accumulator);
        currentItem = parseFloat(currentItem.sub_total);
        const subtotal = (accumulator + currentItem).toFixed(2);
        return subtotal;
      }, 0);
    },
    calculateGST() {
      const gstPercentage = 5;
      const calculation = ((this.subtotal * gstPercentage) / 100).toFixed(2);
      return calculation;
    },
    editShippingAddress() {
      this.shipping_edit_address1 = this.shipping_address1;
      this.shipping_edit_address2 = this.shipping_address2;
      this.shipping_edit_area = this.shipping_area;
      this.shipping_edit_state = this.shipping_state;
      this.shipping_edit_city = this.shipping_city;
      this.shipping_edit_country = 101;
      this.shipping_edit_pinCode = this.shipping_pinCode;
      this.shippingaddressModel = true;
      this.shipping_address_edit = true;
    },
    updateShippingAddress() {
      this.shipping_address1 = this.shipping_edit_address1;
      this.shipping_address2 = this.shipping_edit_address2;
      this.shipping_area = this.shipping_edit_area;
      this.shipping_city = this.shipping_edit_city;
      this.shipping_state = this.shipping_edit_state;
      this.shipping_country = this.shipping_edit_country;
      this.shipping_pinCode = this.shipping_edit_pinCode;
      this.shippingaddressModel = false;
      this.shipping_address_edit = false;
    },
    chooseAnotherUser() {
      this.user = "";
      this.changeUserAddress = true;
      this.shipping_address2 = "";
      this.shipping_address1 = "";
      this.shipping_area = "";
      this.shipping_city = "";
      this.shipping_state = "";
      this.shipping_country = "";
      this.shipping_pinCode = "";
    },
    confirmDeleteitem(id) {
      this.isShowDeleteDialog = true;
      this.deleteItem = id;
    },
    confirmDeleteForm() {
      if (this.deleteItem !== -1) {
        this.TableData.splice(this.deleteItem, 1);
      }
      this.isShowDeleteDialog = false;
    },
    closeDeleteFormModal() {
      this.deleteItem = "";
      this.isShowDeleteDialog = false;
    },
    gotoEdit(id) {
      this.addItem = true;
      this.editItemId = id;
      this.category_id = this.TableData[id].category_id;
      this.item = this.TableData[id].item_id;
      this.description = this.TableData[id].description;
      this.quantity = this.TableData[id].quantity;
      this.expected_price = this.TableData[id].price;
      this.getItemData();
    },
    updateInvoiceData() {
      const value = this.categoriesData.find(
        (item) => item.id === this.category_id
      );
      if (this.editItemId === "" || this.editItemId === undefined) {
        const data = {
          category_name: value.name,
          category_id: this.category_id,
          item_id: this.item,
          description: this.description,
          quantity: this.quantity,
          price: this.expected_price,
          sub_total: this.totalAmount,
        };

        this.TableData.push(data);
        this.category_id = "";
        this.item = "";
        this.description = "";
        this.quantity = "";
        this.expected_price = "";
        this.addItem = false;
        this.$refs.observer.reset();
        this.itemData = [];
        document.getElementById("price").blur();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      } else {
        this.TableData[this.editItemId].category_name = value.name;
        this.TableData[this.editItemId].category_id = this.category_id;
        this.TableData[this.editItemId].item_id = this.item;
        this.TableData[this.editItemId].description = this.description;
        this.TableData[this.editItemId].quantity = this.quantity;
        this.TableData[this.editItemId].price = this.expected_price;
        this.TableData[this.editItemId].sub_total = this.totalAmount;
        this.editItemId = "";
        this.category_id = "";
        this.item = "";
        this.description = "";
        this.quantity = "";
        this.expected_price = "";
        this.addItem = false;
        this.$refs.observer.reset();
        this.itemData = [];
        document.getElementById("price").blur();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
    shippingCityName(id) {
      const value = this.shippingCityItem.find((item) => item.id === id);
      return value ? value.name : "";
    },
    shippingStateName(id) {
      const value = this.shippingStateItem.find((item) => item.id === id);
      return value ? value.name : "";
    },
    shippingCountryName(id) {
      const value = this.shippingCountryItem.find((item) => item.id === id);
      return value ? value.name : "";
    },
    findUser(id) {
      const value = this.userData.find((item) => item.id === id);
      return value.name;
    },
    async purchaseInvoiceGetPayment() {
      const payload = {
        purchase_invoice_id: getDecodedStringBase64(this.$route.params.id),
      };
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "purchaseInvoiceGetPayment",
          payload
        );
        if (response.http_status === 200) {
          this.paymentDetails = response.data;
          this.mySpinner.val = false;
        } else {
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async purchaseAddPayment() {
      const payload = {
        purchase_invoice_id: getDecodedStringBase64(this.$route.params.id),
        payment_amount: this.paymentAmount,
        transaction_type: this.paymentMethod,
        payment_date: this.paymentDate,
      };
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "purchaseInvoicePayment",
          payload
        );
        if (response.http_status === 200) {
          location.reload();
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    closeDialog() {
      this.paymentMenu = false;
      this.$refs.observer.reset();
    },
    paymentDialog() {
      this.paymentMenu = true;
    },
    async getDropdownData() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getAllCategory", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.categoriesData = response.data;
          var temp = [];
          this.categoriesData.forEach((ele) => {
            temp[ele.id] = ele.name;
          });
          this.displayCatList = temp;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getItemData() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getItemListInvoice", {
          action: "ALL",
          category_id: this.category_id,
        });
        if (response.http_status === 200) {
          this.itemData = response.data;
          var temp = [];
          this.itemData.forEach((ele) => {
            temp[ele.id] = ele.name;
          });
          // this.displayCatList = temp
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getUserListData() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getUserListData", {});
        if (response.http_status === 200) {
          var temp = [];
          response.data.forEach((ele) => {
            temp.push({
              id: ele.id,
              name: ele.first_name + " " + ele.last_name,
            });
          });
          this.userData = temp;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getUserBranches() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getUserBranches", {
          user_id: this.user,
        });
        if (response.http_status === 200) {
          this.branchData = response.data;
          if (this.branchData.length == 1) {
            this.branch = this.branchData[0].id;
          }
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }

      if (
        this.changeUserAddress ||
        this.$route.params.id == undefined ||
        this.$route.params.id == ""
      ) {
        this.userAddress();
      }
    },
    async userAddress() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getUserProfile", {
          id: this.user,
        });
        if (response.http_status === 200) {
          this.shipping_address1 = response.data.address_line_1;
          this.shipping_address2 = response.data.address_line_2;
          this.shipping_area = response.data.district;
          this.shipping_state = response.data.state_id;
          this.getShippingCityDropdownData();
          this.shipping_city = response.data.city_id;
          this.shipping_country = response.data.country_id;
          this.shipping_pinCode = response.data.pin_code;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    changeQuantity() {
      if (Number.parseInt(this.quantity) < 20) {
        this.quantityWarning = true;
        this.quantity = "";
      }
    },
    async getCountryStateData() {
      this.mySpinner.val = true;
      try {
        const payload = { module: "COUNTRY_LIST,STATE_LIST", country_id: 101 };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          // this.billingCountryItem = response.data.country_list;
          this.shippingCountryItem = response.data.country_list;
          // this.billingStateItem = response.data.state_list;
          // this.billingStateEditItem = response.data.state_list;
          this.shippingStateItem = response.data.state_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getShippingCityDropdownData() {
      this.mySpinner.val = true;
      let stateId = "";
      if (this.shipping_address_edit) {
        stateId = this.shipping_edit_state;
      } else {
        stateId = this.shipping_state;
      }
      try {
        const payload = {
          module: "CITY_LIST",
          country_id: 101,
          state_id: stateId,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.shippingCityItem = response.data.city_list;
          this.shippingCityEditItem = response.data.city_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async adminAddEditInvoice() {
      if (this.TableData.length === 0) {
        this.tableError = true;
      } else {
        const data = {
          user_id: this.user,
          organization_id: this.branch,
          payment_condition: this.payment_condition,
          payment_terms: 20,
          packaging: this.packaging,
          is_terms_accepted: this.is_terms_accepted ? 1 : 0,
          delivery: this.delivery,
          phone: this.phoneNumber,
          vehicle_number: this.vehicleno,
          shipping_address_line_1: this.shipping_address1,
          shipping_address_line_2: this.shipping_address2,
          shipping_district: this.shipping_area,
          shipping_pin_code: this.shipping_pinCode,
          shipping_city_id: this.shipping_city,
          shipping_state_id: this.shipping_state,
          shipping_country_id: this.shipping_country,
          terms_conditions: this.terms_conditions,
          po_number: this.po_no,
          po_date: this.po_date,
          vendor_code: this.vendor_code,
          delivery_date: this.delivery_date,
          contact_person: this.contact_person,
          due_date: this.due_date,
          invoice_type: "generate_pdf",
          invoice_date: this.invoice_date,
          additional_terms_conditions: this.additional_terms_conditions,
          invoice_data: this.TableData,
        };
        if (this.status != "Cancelled") {
          data.status = "Invoice Generated";
        } else {
          data.status = "Cancelled";
        }
        if (this.payment_condition == "Credit") {
          data.payment_term_days = this.payment_term_days;
        }
        let url = "createPurchaseInvoices";
        if (this.$route.params.id != undefined) {
          url = "updatePurchaseInvoices";
          data.id = getDecodedStringBase64(this.$route.params.id);
        }
        this.mySpinner.val = true;
        try {
          const response = await this.$store.dispatch(url, data);
          if (response.http_status === 200) {
            this.$toasted.success(response.message);
            this.mySpinner.val = false;
            this.$router.push("/purchaseInvoicesList");
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      }
    },
    async viewInvoiceDetails() {
      const payload = {
        id: getDecodedStringBase64(this.$route.params.id),
        action: "VIEW",
        search: "",
      };
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "viewPurchaseInvoiceOrder",
          payload
        );
        if (response.http_status === 200) {
          this.user = response.data.user_id;
          this.getUserBranches();
          this.branch = response.data.organization_id;
          this.payment_condition = response.data.payment_condition;
          this.payment_term_days = response.data.payment_term_days;
          this.packaging = response.data.packaging;
          this.is_terms_accepted = response.data.is_terms_accepted ? 1 : 0;
          this.delivery = response.data.delivery;
          this.phoneNumber = response.data.phone;
          this.vehicleno = response.data.vehicle_number;
          this.shipping_address1 = response.data.shipping_address_line_1;
          this.shipping_address2 = response.data.shipping_address_line_2;
          this.shipping_area = response.data.shipping_district;
          this.shipping_pinCode = response.data.shipping_pin_code;
          this.shipping_city = response.data.shipping_city_id;
          this.shipping_state = response.data.shipping_state_id;
          this.item = response.data.item_id;
          this.getShippingCityDropdownData();
          this.getItemData();
          this.shipping_state = response.data.shipping_state_id;
          this.shipping_country = response.data.shipping_country_id;
          this.terms_conditions = response.data.terms_conditions;
          this.po_no = response.data.po_number;
          this.po_date = response.data.po_date;
          this.vendor_code = response.data.vendor_code;
          this.delivery_date = response.data.delivery_date;
          this.contact_person = response.data.contact_person;
          this.due_date = response.data.due_date;
          this.additional_terms_conditions =
            response.data.additional_terms_conditions;
          this.invoice_date = response.data.invoice_date;
          this.invoice_number = response.data.invoice_number;
          this.TableData = response.data.invoice_data;
          this.invoice_date = response.data.invoice_date;
          this.status = response.data.status;
          this.getUserBranches();
          this.total_amount = response.data.total_amount;
          this.paidAmount = response.data.paid_amount;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getInvoicePDF() {
      this.mySpinner.val = true;
      const token = getDecodedToken();
      let payload = {
        bfb_data: {
          ...COMMONPAYLOAD,
        },
      };
      payload = encryptPayload(payload);
      axios({
        url: `${API_URL}get-file/direct_purchase_invoice_document/${getDecodedStringBase64(
          this.$route.params.id
        )}`,
        method: "POST",
        data: payload,
        headers: { authorization: "Bearer " + token },
        responseType: "blob",
      })
        .then((res) => {
          // this.profilePicUrl = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          this.pdfPath = window.URL.createObjectURL(blob);
          // this.pdfPath = 'http://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf';
          this.isDisplayInvoice = true;
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
  },
};
</script>