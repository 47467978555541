<template></template>

<script>
export default {
  mounted() {
    this.loadClarityScript();
    this.loadAnalyticsScript();
  },
  methods: {
    loadAnalyticsScript() {
      // Load Google Analytics script
      if (document.location.hostname.search("biofuelbid.com") !== -1) {
        (function (i, s, o, g, r, a, m) {
          i["GoogleAnalyticsObject"] = r;
          (i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
          a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
          a.async = 1;
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(
          window,
          document,
          "script",
          "https://www.google-analytics.com/analytics.js",
          "ga"
        );

        ga("create", "G-S2SR59Y1NX", "auto"); // Replace with your actual GA tracking ID
      }
    },
    loadClarityScript() {
      // Load Clarity script
      if (document.location.hostname.search("biofuelbid.com") !== -1 && !window.clarity) { // Check for existing Clarity script
        (function (c, l, a, r, i, t, y) {
          c[a] = c[a] || function () {
              (c[a].q = c[a].q || []).push(arguments);
            };
          t = l.createElement(r);
          t.async = 1;
          t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "gzjwjlmqut"); // Replace with your Clarity project ID
      }
    },
  },
};
</script>