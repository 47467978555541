import axiosServices from "@/utils/axios";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    
})

const getters  = {

}

const actions = {
    updateDirectPO({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase-orders-updated', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createDirectPO({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/purchase-orders-updated', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewPurchaseOrder({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/direct-purchase-orders-updated', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    deletePurchaseOrder({ commit }, payload) {
        return axiosServices
            .post('admin/accounts/direct-purchase-orders-updated/delete', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getDirectPurchaseOrders({ commit }, inputPage) {
        const payLoad = {
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0],
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            ...COMMONPAYLOAD,
            search: inputPage.search,
            action: inputPage.action,
            start_date: inputPage.start_date,
            end_date: inputPage.end_date,
        }
        return axiosServices
        .post('/admin/accounts/direct-purchase-orders-updated', payLoad)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}