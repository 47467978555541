<template>
  <AuthLayout>
    <div>
      <div class="login-wrapper">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>

        <div class="title-of-auth mb-4">
          <h2>Verify Otp</h2>
        </div>

        <div class="form-part">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(verifyOtp)">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <validation-provider v-slot="{ errors }" name="OTP" rules="required">
                    <label for="">Otp has been sent to registered Email / Mobile Number</label>
                    <v-text-field v-model="userOtp" outlined dense
                      :error-messages="errors"></v-text-field>
                  </validation-provider>
                  <!-- <v-otp-input  length="6"></v-otp-input> -->
                </v-col>

                <v-col cols="12">
                  <v-btn type="submit" rounded dark color="#9DA73E" block large class="cta">
                    Verify
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <div class="text-center mb-0">
                    Didn't received Otp?
                    <div style="color: #9da73e; font-weight: 500" class="pointer d-inline" v-ripple>
                      <br/>
                      <span v-if="!enableSendOTPBtn">
                        Wait for <span style="color: black;">{{ waitingSeconds }}</span> Seconds to </span>
                      <span v-if="enableSendOTPBtn" @click="resendOtp" style="color: black; cursor: pointer;"> Resend </span> 
                      <span v-else style="color: grey; cursor: not-allowed;"> Resend </span> 
                      OTP
                    </div>
                  </div>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from './layout/auth.vue';
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})

export default {
  name: 'registerotp',
  inject : ['mySpinner'],
  data: () => ({
    userOtp: '',
    otpKey: '',
    waitingSeconds: 30,
    enableSendOTPBtn: false,
    timeIntObj: null,
  }),
  created() {
    this.otpKey = localStorage.getItem('otp_key')
    this.startTimer();
  },
  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    async resendOtp() {
      this.mySpinner.val = true;
      try {
        const payload = {
          otp_key:  localStorage.getItem('otp_key'),
        }
        const res = await this.$store.dispatch('handleResendOtp', payload)
        if (res.http_status === 200) {
          this.$toasted.success(res.message)
          localStorage.removeItem('otp_key')
          localStorage.setItem('otp_key', res.data.otp_key);
          this.mySpinner.val = false;
        }else if(res.http_status == 406){
          this.$router.push({ path: '/register' })
          localStorage.removeItem('otp_key')
          this.mySpinner.val = false;
          this.$toasted.error(res.message)
        } 
        else {
          this.$toasted.error(res.message)
          this.mySpinner.val = false;
        }
        this.startTimer();
      } catch (e) {
        this.$toasted.error(e.message)
        this.mySpinner.val = false;
        this.startTimer();
      }
    },
    async verifyOtp() {
      this.mySpinner.val = true;
      try {
        const payload = {
          otp: this.userOtp,
          otp_key: this.otpKey,
          grant_type: 'otp_grant'
        }
        const res = await this.$store.dispatch('handleRegisterOtp', payload)
        if (res.http_status === 200) {
          this.$toasted.success(res.message)
          this.$router.push({ path: '/registerdetails' })
          localStorage.setItem('registration_number', res.data.registration_number)
          localStorage.removeItem('otp_key')
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(res.message)
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message)
        this.mySpinner.val = false;
      }

    },
    startTimer(){
      this.enableSendOTPBtn = true;
      this.timeIntObj = setInterval(() => {
        this.waitingSeconds--;
        if(this.waitingSeconds == 0){
          this.stopTimer()
        }
      },  1000);
    },
    stopTimer(){
      this.enableSendOTPBtn = true;
      this.waitingSeconds = 30;
      clearInterval(this.timeIntObj);
    }
  }
}
</script>

<style>

</style>