import axiosServices from "@/utils/axios";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    
})

const getters  = {

}

const actions = {
    partnerHandleRegisterUser({ commit },payload) {
        return axiosServices
        .post('/partner-register', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    partnerHandleRegisterOtp({ commit },payload) {
        return axiosServices
        .post('/partner-register/verify-otp', { ...payload, ...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    partnerHandleRegisterUserDetails({ commit },payload) {
        return axiosServicesFormData
        .post(`/partner-register/update`, payload)
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getPartnerUserProfile({ commit },payload){
        return axiosServices
            .post('partner/user/show', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    partnerGetUserList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            // search : inputPage.search,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('partner/dashboard', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    partnerYourPartnerList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            search : inputPage.search,
            start_date : inputPage.start_date,
            end_date : inputPage.end_date,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('partner/user/get', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}