<template>
    <v-dialog v-model="show" width="450" persistent>
        <v-card>
            <v-card-title class="text-h6">
                Branch
            </v-card-title>
            <v-divider class="mb-5" />
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(confirmBranch)">
            <v-card-text>
                <validation-provider v-slot="{ errors }" name="Select Branch" rules="required">
                
                <v-select :items="branches" v-model="selectedBranch" label="Your Branch" rounded outlined
                    class="mr-4 font-weight-bold" dense prepend-inner-icon="mdi-domain" item-value="id"
                    item-text="display_name" :error-messages="errors"></v-select>
                    </validation-provider>
             
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="grey darken-1" text type="submit"> Confirm </v-btn>

                <!-- <v-btn color="red darken-1" text @click="cancel" class="font-weight-bold">
                    Cancel
                </v-btn> -->
            </v-card-actions>
        </form>
                    </validation-observer>
        </v-card>
    </v-dialog>
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
export default {
    props: {
        branches: []
    },
    data() {
        return {
            show: true,
            selectedBranch : ''
        }
    },
    components: {
        ValidationObserver,
        ValidationProvider
      },
    methods : {
        cancel() {
                this.$router.push({ path: "/login" });
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("is_admin");
                localStorage.removeItem("is_buyer");
                localStorage.removeItem("is_seller");
                localStorage.removeItem("is_trader");
                localStorage.removeItem("last_password_changed_at");
                localStorage.removeItem("last_name");
                localStorage.removeItem("userid");
                localStorage.removeItem("useremail");
                localStorage.removeItem("userphone");
                localStorage.removeItem("registration_number");
                localStorage.removeItem("otp_key");
                localStorage.removeItem("first_name");
                localStorage.removeItem("branches");
                localStorage.removeItem("selectedBranch");

                this.$router.push({ path: "/login" });
        },
        confirmBranch() {
            localStorage.setItem("selectedBranch",this.selectedBranch)
            this.$emit('branchSelect')
        }
    }
}
</script>

<style></style>