<template>
  <weblayout>
    <div class="banner-blog">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="title-of-blog">
            What is BioFuels and its importance in Indian Context
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
                Darshan Baraiya
                <div class="grey--text body-2">On 06/03/2022 - 16:00PM</div>
              </div>
            </div>
          </div>

          <div class="img-of-blog">
            <!-- <div class="next-prev-btn justify-end">
              <v-btn text dark>
                <v-icon> mdi-chevron-left </v-icon>
                Prev Blog
              </v-btn>
              <v-btn text dark>
                Next Blog
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </div> -->
            <img
              style="max-width: 600px"
              src="@/assets/images/what_is_biofuel.png"
              alt=""
            />
          </div>
        </div>
      </v-container>
    </div>

    <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12">
            <div class="about-wrap">
              <p class="mt-10"><strong>What is Biofuel </strong></p>

              <p>
                Biocoal also known as torrefied biomass, is a solid biofuel produced through a thermal process called torrefaction. Torrefaction is a heat treatment process applied to biomass such as wood, straw, and other agricultural residues, to remove moisture and volatile organic compounds, resulting in a dry and energy-dense material that resembles traditional coal in its physical and chemical properties
              </p>
              <p>
                Biofuel is a form of renewable fuel produced from organic components that have biological origins.
              </p>
              <p>
                It is the fuel produced from biomass, which includes materials like bagasse, cotton stalks, coconut wood, and other plants.
              </p>
              <p>
                Biomass can be extremely important, particularly in rural regions, in light of mounting worries regarding global warming.
              </p>
              <p>
                Over 70% of the Indian population depends on biomass fuel, making it one of the most significant sources of fuel. It accounts for 32% of the primary fuel used in the nation.
              </p>
              <p>
                The present biomass supply is estimated to be between 450 and 500 million tonnes per year, which translates to a potential of roughly 18000 MW.

              </p>

              <p class="mt-10"><strong>Advantages of using Biofuel</strong></p>
              <p>
                <strong>Renewable :</strong>
                  It is a fuel source that has the potential to outlive conventional fossil fuels by a significant margin as long as efforts are made to sustain the resources used for biofuel fuel by committed replanting and replenishment.
              </p>

              <p>
                <strong>Carbon Neutrality :</strong>
                One of the main causes of climate change is the amount of carbon that is emitted into the atmosphere. Biofuel lessens this because, unlike oil and other fossil fuels, it is a natural component of the carbon cycle.
                 
              </p>

              <p>
                <strong>Less Dependency On Fossil Fuels :</strong>
                The more biofuel fuel we use, the less we rely on fossil fuels, which are a key cause of climate change and other environmental problems.
                 
              </p>

              <p>
                <strong>Versatile :</strong>
                In terms of accessible alternatives, biofuel fuel is one of the most versatile. It can be transformed into a variety of fuels, each of which has a range of uses. For instance, biofuel can be used to grow methane gas and a variety of other biofuels, or it can be processed to make biodiesel for automobiles.
              </p>

              <p>
                <strong>Co-firing with coal :</strong>
                Biocoal can be co-fired with coal in existing coal-fired power plants, without the need for significant modifications to the equipment. This can help reduce the cost of transitioning to renewable energy sources while providing a more sustainable and cleaner energy mix.
              </p>

              <p>
                <strong>Improved Air Quality: </strong>
                Burning biocoal releases fewer pollutants, such as sulfur dioxide, nitrogen oxides, and particulate matter, compared to traditional coal, which can improve air quality and reduce the negative health impacts of air pollution.
              </p>

              <p>
                <strong>Reduces Waste: </strong>
                There are many recyclable materials and plant matter in the waste we create here that may be used in other places more effectively.
              </p>

              <p>
                <strong>Domestic Production : </strong>
                Additionally, the use of biofuel fuel can decentralize the management of fuel production from larger corporations. This indicates that customers are no longer obligated to pay the fees charged by power companies.
              </p>



              <p class="mt-10"><strong>Limitations of BioFuels</strong></p>
              <p><strong>Not Completely Clean :</strong></p>
              <p>
                Although a carbon-neutral fuel source, biofuel is not entirely clean. There are other emissions produced by burning wood and other plant material in addition to carbon. Even while the impacts may not be as severe as those from fossil fuels, these might nevertheless contaminate the surrounding ecosystem.
              </p>
              <p><strong>Cost :</strong></p>
              <p>
                Currently, the production of biocoal is more expensive than traditional coal. This is due to the higher cost of the torrefaction process and the need for additional equipment to handle the material. However, as technology improves and production scales up, the cost is expected to decrease. 
              </p>
              <p><strong>Feedstock availability and quality :</strong></p>
              <p>
                The availability and quality of biomass feedstock can vary widely depending on the location, climate, and season. The cost and logistics of sourcing and handling biomass can also be a challenge, particularly in areas where biomass resources are scarce. 
                
              </p>
              <p><strong>Infrastructure :</strong></p>
              <p>The existing infrastructure for producing, transporting, and storing traditional coal is not always compatible with biocoal. Additional equipment and modifications to existing infrastructure may be required, which can increase costs and delay the adoption of biocoal. 
                
              </p>

              <p><strong>Market demand :</strong></p>
              <p>
                The market demand for biocoal is currently limited, and many utilities and power producers are hesitant to switch to a new fuel source. Additionally, the regulatory environment can be uncertain and may not provide the necessary incentives for companies to invest in biocoal.
              </p>

              <p><strong>Environmental impact :</strong></p>
              <p>
                While biocoal has lower carbon emissions than traditional coal, there are still concerns about the potential environmental impacts of large-scale biomass production. For example, the use of fertilizers and other inputs in the production of biomass can contribute to water pollution and soil degradation.
              </p>

              <p class="mt-10"><strong>Role of Bio-fuel in Meeting India’s Fuel Demands</strong></p>
              <p><strong>Fuel Demand :</strong></p>
              <p>Biofuel can help to satiate the nation's rising fuel needs, particularly in rural areas. About 25% of its primary fuel is derived from biomass resources, and about 70% of the people in rural areas rely on biomass to meet their daily fuel demands. More biomass can be used to address fuel needs in rural areas.                
              </p>

              <p><strong>Climate Change Mitigation :</strong></p>
              <p>
                In comparison to fossil fuels, biofuel offers significant advantages, particularly with regard to GHG emissions. By reusing carbon from the air and avoiding the use of fossil fuels, biomass lowers the amount of extra fossil carbon released into the atmosphere from the ground.
              </p>

              <p><strong>Market Growth :</strong></p>
              <p>
                India's rural and urban markets for renewable fuel systems are expected to experience exponential growth. Despite this, biofuel is categorized as "non-commercial" fuel and is generally absent from fuel studies. The identification of species such as Jatropha, Neem, and other wild plants as viable sources for biodiesel manufacturing in India.
              </p>

              <p><strong>Waste to Fuel :</strong></p>
              <p>
                Biofuels can help turn trash into cash. It has a great deal of potential to assist the nation in achieving its 175 GW renewable fuel goal because it is a derivative of renewable biomass resources like plastic, municipal solid waste, forestry residues, agricultural wastes, surplus food grains, etc.                
              </p>

              <p><strong>Income Generation :</strong></p>
              <p>
                Using biofuels as a substitute fuel source can increase farmers' income dramatically and provide jobs, among other benefits.
              </p>

              <p><strong>Reduce Imports :</strong></p>
              <p>
                About 46.13 % of India's total primary fuel consumption comes from imports. By lowering these imports, biofuel can increase India's fuel security and independence.
              </p>

              <p>
                Moreover, biocoal is considered a renewable and sustainable energy source, as it is made from agricultural and forestry residues that would otherwise be discarded or burned. It can contribute to the reduction of greenhouse gas emissions and help to address climate change                
              </p>            
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-divider></v-divider>
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
               Darshan Baraiya
                <div class="grey--text body-2">07/03/2022</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </weblayout>
</template>
  
  <script>
import weblayout from "../layout/weblayout.vue";

export default {
  components: { weblayout },
  data() {
    return {
      tab: null,
      items: [
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
      ],
    };
  },
};
</script>
  
  <style>
</style>