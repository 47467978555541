<template>
  <dashlayout>
    <v-container-fluid fluid>
      <v-row>
        <v-col cols="12" md="9">
          <div class="dash-bg">
            <div class="box-title-with-btn mb-0">
              <div class="box-title">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Buyer Live Auction [{{auctionsInfo && auctionsInfo.auction_number }}]
              </div>
              <div class="btns-right" v-if="auction_status == 'auction_started'">
                <v-chip class="ma-2 blink-2" color="red lighten-1" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-access-point-check</v-icon>
                  </v-avatar>
                  Live
                </v-chip>
                <v-chip outlined class="mr-2" color="#9da73e" text-color="#9da73e"
                  style="font-size: 1rem !important; font-weight: bold">
                  <v-icon left> mdi-clock-outline </v-icon>
                  <countdown :time="calculateTime()" :transform="transformSlotProps" @progress="checkTimer">
                    <template slot-scope="props">
                      {{ props.hours }} :
                      {{ props.minutes }} :
                      {{ props.seconds }}
                    </template>
                  </countdown> &nbsp;left
                </v-chip>
              </div>
            </div>

            <v-divider></v-divider>
            <div class="auction-list-wrap mt-4">
              <!-- <div class="auction-box placeholder">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold" color="primary" size="40">H2</v-avatar>
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1200.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div> -->

              <div :class="{
                  'auction-box' : true, 
                  'slide-in-bottom' : bidder_rank == 1 ? false : true,
                  'slide-in-top' : bidder_rank == 1 ? false : true,
                  'placeholder' : bidder_rank == 1 ? false : true
                }">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{ bidder_rank != '' ? `${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'} ${bidder_rank}` : '-'}}</v-avatar>
                  <div class="name-of-company mr-4" v-if=" bidder_rank == 1">{{ first_name }} {{ last_name }}</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{ last_bid_amount }}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" :text-color="updated_value_from_last_bid >=0 ? '#9DA73E' : 'red'" label>
                      <v-icon class="mr-2"> {{updated_value_from_last_bid >=0 ? 'mdi-trending-up' : 'mdi-trending-down'}} </v-icon>
                      {{ updated_value_from_last_bid }}
                    </v-chip>
                  </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(placeAuctionBid)">
                <div class="auction-right-part">
                  <v-btn @click="getBidHistoryInfo" elevation="0" flat small fab class="mr-2">
                    <v-icon color="#9DA73E"> mdi-history </v-icon>
                  </v-btn>
                  <validation-provider v-slot="{ errors }" name="Bid" rules="required">
                  <v-text-field 
                    v-model="bid" 
                    name="name" 
                    label="Enter Bid Amount" 
                    id="id" 
                    hide-details="" 
                    outlined dense
                    suffix="₹" 
                    :error-messages="errors"
                    :min="1"
                    @change="checkMultiPlyOf"
                  ></v-text-field>
                    </validation-provider>
                  <v-btn type="submit" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                    <v-icon class="mr-2"> mdi-gavel </v-icon>
                    Bid
                  </v-btn>
                </div>
                </form>
                </validation-observer>
              </div>

              <div :class="{
                  'auction-box' : true, 
                  'slide-in-bottom' : bidder_rank == 2 ? false : true ,
                  'slide-in-top' : bidder_rank == 2 ? false : true,
                  'placeholder' : bidder_rank == 2 ? false : true
                }">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{ bidder_rank != '' ? `${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}${bidder_rank}` : '-'}}</v-avatar>
                  <div class="name-of-company mr-4" v-if=" bidder_rank == 2">{{ first_name }} {{ last_name }}</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{ last_bid_amount }}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      {{ updated_value_from_last_bid }}
                    </v-chip>
                  </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(placeAuctionBid)">
                <div class="auction-right-part">
                  <v-btn @click="getBidHistoryInfo" elevation="0" flat small fab class="mr-2">
                    <v-icon color="#9DA73E"> mdi-history </v-icon>
                  </v-btn>
                  <validation-provider v-slot="{ errors }" name="Bid" rules="required">
                  <v-text-field 
                    v-model="bid" 
                    name="name" 
                    label="Enter Bid Amount" 
                    id="id" 
                    hide-details="" 
                    outlined dense
                    suffix="₹" 
                    :min="1"
                    :error-messages="errors"
                    @change="checkMultiPlyOf"
                  ></v-text-field>
                    </validation-provider>
                  <v-btn type="submit" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                    <v-icon class="mr-2"> mdi-gavel </v-icon>
                    Bid
                  </v-btn>
                </div>
                </form>
                </validation-observer>
              </div>

              <div :class="{
                  'auction-box' : true, 
                  'slide-in-bottom' : bidder_rank > 2 ? false : bidder_rank == 0 ? false : true ,
                  'slide-in-top' : bidder_rank > 2 ? false :  bidder_rank == 0 ? false : true,
                  'placeholder' : bidder_rank > 2 ? false :  bidder_rank == 0 ? false : true
                }">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{ bidder_rank != '' ? `${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}${bidder_rank}` : '-'}}</v-avatar>
                  <div class="name-of-company mr-4" v-if=" bidder_rank > 2 || bidder_rank == 0">{{ first_name }} {{ last_name }}</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{ last_bid_amount }}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      {{ updated_value_from_last_bid }}
                    </v-chip>
                  </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(placeAuctionBid)">
                <div class="auction-right-part">
                  <v-btn @click="getBidHistoryInfo" elevation="0" flat small fab class="mr-2">
                    <v-icon color="#9DA73E"> mdi-history </v-icon>
                  </v-btn>
                  <validation-provider v-slot="{ errors }" name="Bid" rules="required">
                  <v-text-field 
                    v-model="bid" 
                    name="name" 
                    label="Enter Bid Amount" 
                    id="id" 
                    hide-details="" 
                    outlined dense
                    suffix="₹" 
                    :min="1"
                    :error-messages="errors"
                    @change="checkMultiPlyOf"
                    :disabled="isAuctionCompleted"
                  ></v-text-field>
                    </validation-provider>
                  <v-btn :disabled="isAuctionCompleted" type="submit" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                    <v-icon class="mr-2"> mdi-gavel </v-icon>
                    Bid
                  </v-btn>
                </div>
                </form>
                </validation-observer>
              </div>

              <!-- <div class="auction-box placeholder">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold" color="primary" size="40">H3</v-avatar>
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1200.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div> -->
            </div>

            <v-divider></v-divider>
            <div v-if="status != 'Live' && status != 'Scheduled'">
              <div class="auction-list-wrap mt-4">
                <v-timeline dense clipped>
                  <template v-for="(data, index) in activityData">
                    <template v-if="data.details">
                      <v-timeline-item
                        class="mb-4"
                        small
                        icon="mdi-check"
                        :key="index"
                      >
                        <v-row justify="space-between">
                          <v-col cols="12">
                          <!-- {{ status }}- {{ data.details.type }} -  -->{{ data.title }}
                            <v-chip
                              class="white--text ml-3"
                              color="brown"
                              label
                              small
                              outlined
                              v-if="data.details.type == 'auction_ended' || data.details.type == 'auction_result'"
                            >
                              {{ data.details.bid_amount }} Rs
                            </v-chip>
                            <v-chip
                              class="white--text ml-3"
                              color="brown"
                              label
                              small
                              outlined
                              v-if="data.details.type == 'bidder_payment_confirmation'"
                            >
                              {{ data.details.amount }} Rs
                            </v-chip>
                            <div class="time-and-date">{{ data.created_at }}</div>
                            <v-btn  color="primary" class="mt-2" v-if="data.details.type == 'auctioneer_confirmed'" :disabled="status == 'Confirmed' && data.details.open_popup == 'open_popup' ? false : true">
                              Confirm Order
                            </v-btn>
                            <v-btn  color="primary" class="mt-2" v-if="data.details.type == 'bidder_payment_confirmation'" @click="downloadInvoice">
                              Download Invoice
                            </v-btn>
                            <v-btn  color="primary" class="mt-2" v-if="data.details.type == 'purchase_order_generated'" @click="downloadGeneratedPO(data.details.po_id)">
                              Download Purchase Order
                            </v-btn>
                              <!-- <v-btn outlined color="primary" class="mt-2 ml-3">
                                View invoice
                              </v-btn> -->
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </template>
                    <template v-else>
                      <v-timeline-item
                        class="mb-4"
                        small
                        icon="mdi-check"
                        :key="index"
                      >
                        <v-row justify="space-between">
                          <v-col cols="7">
                            {{ data.title }}
                            <div class="time-and-date">{{ data.created_at }}</div>
                          </v-col>
                        </v-row>
                      </v-timeline-item>  
                    </template>
                  </template>
                </v-timeline>
              </div>
            </div>

          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="dash-bg">
            <div class="box-title-with-btn">
              <div class="box-title">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Auction Details
              </div>
            </div>

            <div class="auction-details">
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Name </span>
                <div class="auction-name">{{ auctionsInfo && auctionsInfo.auction_number }}</div> 
                <div>
                  <span class="auction-name">{{ auctionsInfo && auctionsInfo.category_name }}</span>
                  <!-- <span style="color: #9da73e">(Cotton)</span> -->
                  <span class="auction-name">&nbsp;{{ auctionsInfo && auctionsInfo.quantity }} Tonne</span>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Description </span>
                <div>
                  <div class="d-details-auction">
                    {{ auctionsInfo && auctionsInfo.auction_description }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Base Price </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.expected_price }}</div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Quantity </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.quantity }} Tonne</div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Auction for </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.auction_for }}</div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> EMD Amount </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.range_of_bid_amount }}</div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Auction Time </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && getAuctionDiff }}</div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Start Time </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.start_date_time }}</div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> End Time </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.end_date_time }}</div>
                </div>
              </div>
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Delivery </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.auction_delivery }}</div>
                </div>
              </div>
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Packaging </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.packaging }}</div>
                </div>
              </div>
              <div class="d-wrap-auction" v-if="auctionsInfo && auctionsInfo.auction_for == 'buy'">
                <span class="title-of-detail"> Delivery Pincode </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.delivery_pincode }}</div>
                </div>
              </div>
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Estimated Distance </span>
                <div>
                  <div class="d-details-auction">{{ auctionsInfo && auctionsInfo.distance }}</div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container-fluid>
     <v-dialog v-model="historybox" width="450">
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          History
          <v-btn fab x-small elevation="0" color="transparent" @click="historybox = false">
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12" v-if="bidHistory.length > 0">
              <v-timeline align-top dense>
                <v-timeline-item color="pink" small v-for="(data,index) in bidHistory" :key="index">
                  <v-row class="pt-1">
                    <v-col cols="8">
                      <strong>{{data.updated_at}}</strong>
                    </v-col>
                    <v-col>
                      <strong>₹ {{data.bid_amount}} </strong>
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <!-- <v-timeline-item color="pink" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>12:25 AM</strong>
                    </v-col>
                    <v-col>
                      <strong>₹ 1250 </strong>
                      <v-chip small color="#F1F4D5" text-color="#9DA73E" class="px-2 ml-2" label>
                        +50.00
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item color="teal lighten-3" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>12:20 AM</strong>
                    </v-col>
                    <v-col>
                      <strong>₹ 1200 </strong>
                      <v-chip small color="#F1F4D5" text-color="#9DA73E" class="px-2 ml-2" label>
                        +100.00
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-timeline-item> -->
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

     <div class="pyro" v-if="auctionwon">
      <div class="before"></div>
      <div class="after"></div>
    </div> 
    <v-dialog v-model="auctionwon" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-trophy-outline </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">Congratulations, <br>You Won Auction</div>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Your Bid</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                      size="40">{{auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}}1</v-avatar>
                    <div class="name-of-company mr-4">{{ first_name }} {{ last_name }}</div>

                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">₹ {{ userBidData && userBidData.bid_amount }}</div>
                    </div>
                  </div>
                  <!-- <div class="auction-right-part">
                    <v-btn @click="historybox = true" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                      <v-icon class="mr-2"> mdi-check </v-icon>
                      Place Order
                    </v-btn>
                  </div> -->
                </div>
              </div>

              <v-alert dense outlined color="warning" prominent border="left">
                After your order is confirmed, we will display Seller's name and
                all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> 


    <v-dialog v-model="auctionlose" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-emoticon-happy-outline </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">Better Luck Next time, <br>You Lose Auction</div>
          </div>
          <!-- <v-row>
            <v-col cols="12" md="12">
              <label for="">Your Bid</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                      size="40">H1</v-avatar>
                    <div class="name-of-company mr-4">No Name</div>

                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">₹ 1200.00</div>
                    </div>
                  </div>
                  <div class="auction-right-part">
                  </div>
                </div>
              </div>

             
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog> 

    <v-dialog v-model="isShowOrderAccept" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-gavel </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">There has been a successful auction</div>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <label for="">{{auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'Lowest Bid' : 'Highest Bid'}}</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar
                      class="mr-3 white--text font-weight-bold bounce-in-fwd"
                      color="primary"
                      size="40"
                      >{{auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}}1</v-avatar
                    >
                    <div class="name-of-company mr-4">{{first_name}} {{last_name}}</div>
                    <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-2">Bid Amount</div>
                        <div class="last-bid-box">₹ {{ lowest_bid && lowest_bid.length > 0 && lowest_bid[0].bid_amount }}</div>
                      </div>
                    <!-- <div class="last-bid-section mr-4" v-if="auctionConfirmDetails">
                      <div class="last-bid-label mr-2">Bid</div>
                      <v-chip color="#F1F4D5" :text-color="auctionConfirmDetails.diff_in_bid >= 0 ? '#9DA73E' : 'red'" label>
                      <v-icon class="mr-2"> {{ auctionConfirmDetails.diff_in_bid >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }} </v-icon>
                      {{ auctionConfirmDetails.diff_in_bid }}
                    </v-chip>
                    </div> -->
                  </div>
                  <div class="auction-right-part">
                    <v-btn
                      @click="confirmOrderAccept"
                      elevation="0"
                      color="#9DA73E"
                      dark
                      class="px-2 ml-2"
                    >
                      <v-icon class="mr-2"> mdi-check </v-icon>
                      Accept Order
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-alert dense outlined color="warning" prominent border="left">
                After your order is confirmed, we will display Seller's name and
                all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isShowCommitionPopUp" persistent width="1000">
        <v-card>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <div class="box-title-with-btn mb-0">
                  <div class="box-title mt-3">
                    <v-icon size="24" color="#9DA73E" class="mr-2">
                      mdi-gavel
                    </v-icon>
                    Auction Details
                  </div>
                  <v-btn
                    fab
                    x-small
                    elevation="0"
                    color="transparent"
                    @click="orderdetails = false"
                  >
                    <v-icon color="black"> mdi-close </v-icon>
                  </v-btn>
                </div>
              </v-col>
  
              <v-col cols="12" md="12" class="pt-0 pb-0">
                <label for="">{{auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'Lowest Bid' : 'Highest Bid'}}</label>
  
                <div class="auction-list-wrap mt-2">
                  <div class="auction-box">
                    <div class="auction-left-part">
                      <v-avatar
                        class="mr-3 white--text font-weight-bold bounce-in-fwd"
                        color="primary"
                        size="40"
                        >{{auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}}1</v-avatar
                      >
  
                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-2">Order Quantity</div>
                        <div class="last-bid-box">{{ auctionsInfo && auctionsInfo.quantity}}&nbsp;Tonne</div>
                      </div>
                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-2">Bid Amount</div>
                        <div class="last-bid-box">₹ {{ lowest_bid && lowest_bid.length > 0 && lowest_bid[0].bid_amount }}</div>
                      </div>
                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-2">Total</div>
                        <div class="last-bid-box">₹ {{ commitionData && commitionData.order_amount }}</div>
                      </div>
                    </div>
                    <div class="auction-right-part">
                    </div>
                  </div>
                </div>
              </v-col>
  
              <v-col cols="12" md="12" v-if="commitionData != null">
                <table class="hm-p table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th style="width: 50px">#</th>
                      <th><h4>Item Name</h4></th>
                      <th style="width: 100px">
                        <h4>
                          <!-- SSN<br />
                          Code -->
                          SSC
                        </h4>
                      </th>
                      <th style="width: 80px">
                        <h4>
                          Taxable <br />
                          Amount
                        </h4>
                      </th>
                    <template v-if="commitionData.igst == null || commitionData.igst == 0">
                      <th style="width: 80px">
                        <h4>
                          CGST
                          <hr />
                          Amount
                        </h4>
                      </th>
                      <th style="width: 80px" >
                        <h4>
                           SGST<br />
                          <hr />
                          Amount
                        </h4>
                      </th>
                    </template>
                    <template v-else>
                      <th style="width: 80px">
                        <h4>
                          IGST<br />
                          <hr />
                          Amount
                        </h4>
                      </th>
                    </template>
                      <th style="width: 120px"><h4>TOTAL</h4></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>Commission({{ auctionsInfo && auctionsInfo.category_name }})</td>
                      <td>{{ commitionData && commitionData.ssn_code }}</td>
                      <td>{{ commitionData && commitionData.taxable_amount }}</td>
                      <template v-if="commitionData.igst == null || commitionData.igst == 0">
                        <td>{{ commitionData && commitionData.cgst }}</td>
                        <td>{{ commitionData && commitionData.sgst }}</td>
                      </template>
                      <template v-else>
                        <td>{{ commitionData && commitionData.igst}}</td>
                      </template>
                      <td>{{ commitionData && commitionData.total_commission_amount }}</td>
                    </tr>
  
                    <tr style="background-color: #f7f7f7">
                      <td></td>
                      <td><strong>Total</strong></td>
                      <td>&nbsp;</td>
                      <td><strong>{{ commitionData && commitionData.taxable_amount }} </strong></td>
                      <template v-if="commitionData.igst == null || commitionData.igst == 0">
                        <td><strong>{{ commitionData && commitionData.cgst }}</strong></td>
                        <td><strong>{{ commitionData && commitionData.sgst }}</strong></td>
                      </template>
                      <template v-else>
                        <td><strong>{{ commitionData && commitionData.igst}}</strong></td>
                      </template>
                      <td>{{ commitionData && commitionData.total_commission_amount }}</td>
                    </tr>
                  </tbody>
                </table>
  
                <table
                  class="hm-p table-bordered ml-auto"
                  style="width: fit-content; margin-top: 15px"
                >
                  <tbody>
                    <tr style="background-color: #f7f7f7">
                      <td style="text-align: right">
                        <strong>Biofuel Commission %</strong>
                      </td>
                      <td style="width: 80px"><strong>{{commitionData && commitionData.service_charge_percentage}}</strong></td>
                      <td style="font-size: 18px; width: 120px; color: #9da648">
                        <strong>₹ {{commitionData && commitionData.gross_commission_amount}}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
  
                <div class="text-right mt-4 mb-2">
                  <v-btn
                    @click="paymentdialog=true"
                    elevation="0"
                    color="#9DA73E"
                    dark
                    large
                    class="px-5 ml-2"
                    style="min-width: 120px"
                  >
                    Pay ₹ {{commitionData && commitionData.total_commission_amount}}
                  </v-btn>
                </div>
  
                <v-alert
                  dense
                  outlined
                  color="warning"
                  prominent
                  class="mb-0 mt-3"
                >
                  After your order is confirmed, we will display Seller's name and
                  all relevant details.
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="paymentdialog" persistent width="400">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mb-0">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-account-credit-card-outline
                  </v-icon>
                  Payment
                </div>
                <!-- <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="paymentdialog = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn> -->
              </div>
            </v-col>

            <v-col cols="12" md="12">
              <div class="d-flex justify-space-between align-center">
                <div for="" style="font-size: 1rem">Wallet Balance</div>
                <v-chip
                  link
                  outlined
                  class="px-5 mr-3"
                  color="#9DA73E"
                >
                  <v-avatar left>
                    <v-icon>mdi-wallet-outline</v-icon>
                  </v-avatar>
                  ₹ {{wallet_balance}}
                </v-chip>
              </div>

              <v-divider class="my-4"></v-divider>

              <div>
                <v-checkbox
                  label="Pay with wallet Balance"
                  class="ma-0 pa-0"
                  v-model="value"
                  value="Wallet"
                  disabled
                ></v-checkbox>
              </div>

              <div class="text-right mt-5 mb-2">
                <div class="mb-2">
                  <v-select
                    :items="paymentOptions"
                    v-model="selectedPaymentOption"
                    label="Select Payment Method"
                    outlined
                  >
                    <template #item="{ item }">
                      <img class="mr-2" width="20" :src="item.logo" alt="" />
                      <span style="font-size: 0.9rem">
                        {{ item.name }}
                      </span>
                    </template>

                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        v-on="on"
                        color="transparent"
                        class="px-0"
                      >
                        <img class="mr-2" width="20" :src="item.logo" alt="" />
                        <span
                          style="font-size: 0.9rem"
                          v-text="item.name"
                        ></span>
                      </v-chip>
                    </template>
                  </v-select>
                </div>
                <v-btn
                  elevation="0"
                  color="#9DA73E"
                  dark
                  large
                  class="px-5 ml-2"
                  style="min-width: 120px"
                  @click="confirmPayment"
                >
                  Pay ₹ {{commitionData && commitionData.gross_commission_amount}}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import { getDecodedStringBase64, COMMONPAYLOAD, FILEDOWNALOADHEADER, API_URL, PUSHER_KEY } from '@/utils/helper'
import {
  required,
} from "vee-validate/dist/rules";
import axios from 'axios'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import Pusher from 'pusher-js'

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: { 
    dashlayout,
    ValidationObserver,
    ValidationProvider
  },
  name: "liveauctionbuyer",
  inject: ["mySpinner"],
  data: () => ({
    auctionlose: false,
    auctionwon: false,
    historybox: false,
    dialog: false,

    auctionsInfo: null,
    isShowDeleteDialog: false,
    auction_number: "",
    auction_end_date_time: "",

    first_name : "",
    last_name : "",

    last_bid_amount : 0,
    updated_value_from_last_bid : 0,
    bid : "",
    EMDAmount : 1,

    bidHistory : [],

    bidder_rank : '',
    user_id : '',
    isAuctionCompleted : false,

    isShowOrderAccept : false,
    winner_bidder_id : '',
    isShowCommitionPopUp : false,
    isLosstheAuction : false,
    bidDetails : null,
    commitionData : null,
      paymentOptions: [
      {
        logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZWvmpOm0qexC1rWXOrpIlUBwTE3ZfomJcSkYRlY-anEXzxHzoM3N8s43yAFI3dzGBT8c&usqp=CAU",
        name: "Wallet",
        value : 'Wallet'
      },
    ],
    selectedPaymentOption : 'Wallet',
    value : "Wallet",
    isShowGeneratePO : false,
    paymentdialog : false,
    lowest_bid : [],
    wallet_balance: 0,
    auction_status : '',
    userBidData : null,
    status: '',
    activityData: []
  }),
  created() {
    this.getAuctionInfo();
    this.auction_number = getDecodedStringBase64(this.$route.params.id)
    this.first_name = localStorage.getItem("first_name");
    this.last_name = localStorage.getItem("last_name");
    this.user_id = localStorage.getItem("user_id")
    this.wallet_balance = getDecodedStringBase64(localStorage.getItem("wallet_balance"))
    
  },
  computed: {
    getAuctionDiff() {
      if (this.auctionsInfo) {
        let dt1 = new Date(this.auctionsInfo.start_date_time)
        let dt2 = new Date(this.auctionsInfo.end_date_time)

        // calculate the time difference of two dates JavaScript
        var diffTime = (dt2.getTime() - dt1.getTime());

        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600);

        return daysDiff.toFixed(2);
      }
    }
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });
      return formattedProps;
    },
    async getAuctionInfo() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getAuctionDetails", {
          auction_number: getDecodedStringBase64(this.$route.params.id)
        });
        if (response.http_status === 200) {
          this.auctionsInfo = response.data;
          this.auction_end_date_time = response.data.end_date_time
          this.EMDAmount = response.data.range_of_bid_amount
          this.last_bid_amount = response.data.last_bid
          this.updated_value_from_last_bid = response.data.diff_in_bid
          this.bidder_rank = response.data.your_positions
          this.lowest_bid = response.data.lowest_bid
          this.mySpinner.val = false;
          this.commitionData = response.data.auction_commission
          this.auction_status = response.data.auction_flag
          this.status = response.data.status

          if(this.status != 'Live' && this.status != 'Scheduled'){
            this.getActivityList()
          }
          
          Pusher.logToConsole = true;
          var pusher = new Pusher(PUSHER_KEY, {
            cluster: 'ap2',
          });
          let that = this;
          console.log('Pusher channel', `${this.auction_number}-${this.auctionsInfo.auction_for.toUpperCase()}-PARTICIPANT-${this.user_id}`)
          var channel = pusher.subscribe(`${this.auction_number}-${this.auctionsInfo.auction_for.toUpperCase()}-PARTICIPANT-${this.user_id}`);
          channel.bind('my-event', function (data) {
            this.message = data;
            that.$emit('incoming_data', data)
          });

          this.$on('incoming_data', function (notificationData) {
            this.auctionDataFromPusher(notificationData)
          })

          if(response.data.auction_flag == 'auctioneer_payment_confirmation'){
            this.isShowOrderAccept = true
          }else if(response.data.auction_flag == 'participant_confirmation'){
            this.isShowCommitionPopUp = true
          }else if(response.data.auction_flag == 'participant_payment_confirmation'){
            this.isShowOrderAccept = false
          }

        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    calculateTime() {
      const now = new Date();
      const endDate = new Date(this.auction_end_date_time)
      return endDate - now
    },
    async placeAuctionBid() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("auctionBid", {
          auction_number: getDecodedStringBase64(this.$route.params.id),
          bid_amount : this.bid,
          organization_id : localStorage.getItem("selectedBranch")
        });
        if (response.http_status === 200) {
          this.last_bid_amount = response.data.last_bid
          this.updated_value_from_last_bid = response.data.diff_in_bid
          this.bidder_rank = response.data.your_positions
          this.bid = ''
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    checkMultiPlyOf(){
      if (this.bid % this.EMDAmount !== 0) {
        this.bid = "";
        this.$toasted.error(`You can only add in range of ${this.EMDAmount}`);
      }
    },
    async getBidHistoryInfo() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getAuctionBidHistory", {
          auction_number: getDecodedStringBase64(this.$route.params.id)
        });
        if (response.http_status === 200) {
          this.historybox = true
          this.bidHistory = response.data.all_bids;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.bidHistory = []
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },

    auctionDataFromPusher(data){
      if(data.hasOwnProperty('message')){
      if(data.message.hasOwnProperty('value')){
        this.auction_end_date_time = data.message.value.hasOwnProperty('end_date_time') ? data.message.value.end_date_time : this.auction_end_date_time
        this.last_bid_amount = data.message.value.hasOwnProperty('bid_amount') ? data.message.value.bid_amount : this.last_bid_amount
        this.updated_value_from_last_bid = data.message.value.hasOwnProperty('diff_in_bid') ? data.message.value.diff_in_bid : this.updated_value_from_last_bid
        this.bidder_rank = data.message.value.hasOwnProperty('your_positions') ? data.message.value.your_positions : this.bidder_rank
        this.lowest_bid = data.message.value.hasOwnProperty('lowest_bid') ? data.message.value.lowest_bid : this.lowest_bid
        }
        if(data.message.hasOwnProperty('channel_type')){
          // console.log(data.message.channel_type)
          if(data.message.channel_type == 'auctioneer_payment_confirmation'){
            this.isShowOrderAccept = true
          }else if(data.message.channel_type == 'participant_confirmation'){
            this.isShowCommitionPopUp = true
          }else if(data.message.channel_type == 'auction_ended'){
            console.log('channel got',data.message.selected);
            if(data.message.hasOwnProperty('selected')){
              console.log('select got',data.message.selected);
             if(data.message.selected == 'Yes' || data.message.selected == "Yes") {
                this.auctionwon = true
             }else{
                this.auctionlose = true
             }
            }
            if(data.message.hasOwnProperty('bid')){
                this.userBidData = data.message.bid
            } 
          }
        }
      }
    },
    checkTimer(time) {
      let timeSeconds = (time.hours * 3600) + (time.minutes * 60) + (time.seconds)
        if(timeSeconds <= 0){
          // this.completeLiveAuction()
        }
    },
    async completeLiveAuction() {
        try {
            this.mySpinner.val = true;
            const response = await this.$store.dispatch("changeStatusAuction", { 
              auction_number: this.auction_number,
              status: 'Closed',
            });
            if (response.http_status === 200) {
                this.$toasted.success(response.message);
                this.mySpinner.val = false;
                this.isAuctionCompleted = true
            } else {
                this.$toasted.error(response.message);
                this.mySpinner.val = false;
                this.isAuctionCompleted = false

            }
        } catch (e) {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
        }
      },

      async confirmOrderAccept() {
        try {
            this.mySpinner.val = true;
            const response = await this.$store.dispatch("acceptBidderOrder", { 
              auction_number: this.auction_number,
            });
            if (response.http_status === 200) {
                this.$toasted.success(response.message);
                this.mySpinner.val = false;
                this.getAuctionInfo()
            } else {
                this.$toasted.error(response.message);
                this.mySpinner.val = false;
            }
        } catch (e) {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
        }
      },
      async confirmPayment() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("sumbitThePayment", {
          auction_number: this.auction_number,
          payment_type : "Wallet",
          user_type : "bidder"
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowOrderAccept = false
          this.isShowCommitionPopUp = false
          this.paymentdialog = false
          this.getAuctionInfo()
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getActivityList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getActivityList", {
          auction_number: this.auction_number
        });
        this.activityData = response.data
        if (response.http_status === 200) {
          this.mySpinner.val = false;
        } else {
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async downloadGeneratedPO(id) {
      if(id != '') {
        this.mySpinner.val = true;
      axios({
          url : `${API_URL}get-file/po_document/${id}`,
          method : 'POST',
          COMMONPAYLOAD,
          headers : {...FILEDOWNALOADHEADER},
          responseType: 'blob',
      }).then((res) => {
        if (res.data.type == "application/json") {
          this.$toasted.error('No record found.')
          this.mySpinner.val = false;
        }else{
          var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          var extension = res.headers['content-type'].split('/');
          docUrl.setAttribute('download', `po_document.${extension[1]}`);
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        }
          
      })
      .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
      });
      }
    },
    async downloadInvoice() {
      if(this.commitionData && this.commitionData.id) {
            this.mySpinner.val = true;
          axios({
              url : `${API_URL}get-file/auction_document/${this.commitionData.id}/participant`,
              method : 'POST',
              COMMONPAYLOAD,
              headers : {...FILEDOWNALOADHEADER},
              responseType: 'blob',
          }).then((res) => {
            if (res.data.type == "application/json") {
              this.$toasted.error('No record found.')
              this.mySpinner.val = false;
            }else{
              var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
              var docUrl = document.createElement('a');
              docUrl.href = FILE;
              var extension = res.headers['content-type'].split('/');
              docUrl.setAttribute('download', `invoice_document.${extension[1]}`);
              document.body.appendChild(docUrl);
              docUrl.click();
              this.mySpinner.val = false;
            }
              
          })
          .catch((e) => {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          });
      }
      
    }

  },
};
</script>
  
<style></style>