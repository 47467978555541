<template>
  <AuthLayout>
    <div>
      <div class="login-wrapper">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="title-of-auth mb-4">
          <h2>Password Reset</h2>
        </div>
        <div class="form-part">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(resetPasswordEmail)">
              <v-row>
                <v-col cols="12" class="pb-0">
                  <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                    <label for="">Email / Mobile </label>
                    <v-text-field v-model="email" outlined dense id="email"
                      :error-messages="errors"></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <v-btn rounded dark color="#9DA73E" block large class="cta" type="submit">
                    Submit
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <div class="text-center mb-0">
                    Back to <div style="color: #9DA73E; font-weight: 500;" class="pointer d-inline"
                      v-ripple>
                      <span @click="goToLogin">Login</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from './layout/auth.vue';
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
export default {
  name: 'login',
  inject : ['mySpinner'],
  data: () => ({
    email: ''
  }),
  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    goToLogin() {
      this.$router.push({ path: '/login' })
    },
    async resetPasswordEmail() {
      try {
        this.mySpinner.val = true;
        const payload = {
          email: this.email
        }
        const response = await this.$store.dispatch('resetPasswordEmail', payload)
        if (response.http_status === 200) {
          this.email = ''
          this.$refs.observer.reset();
          document.getElementById("email").blur();
          this.$toasted.success(response.message)
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message)
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message)
        this.mySpinner.val = false;
      }
    }
  }
}
</script>