<template>
  <DashLayout>
    <div>
      <v-container-fluid fluid>
        <div class="dash-bg">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-wallet-outline
            </v-icon>
            Wallet Transactions
          </div>

          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12">
                <v-list subheader two-line>
                  <v-list-item link
                  >
                    <v-list-item-avatar>
                      <v-icon
                        class="pink"
                        dark
                      >mdi-account-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Darshan Baraiya</v-list-item-title>

                      <v-list-item-subtitle
                        >Food - 29 Jan 2023</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-chip class="ma-2" color="red" outlined>
                        <v-icon left> mdi-arrow-top-right-thin </v-icon>
                        2200.20
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item link
                  >
                    <v-list-item-avatar>
                      <v-icon
                        class="blue lighten-4"
                        
                      >mdi-account-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Darshan Baraiya</v-list-item-title>

                      <v-list-item-subtitle
                        >Food - 29 Jan 2023</v-list-item-subtitle
                      >
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-chip class="ma-2" color="success" outlined>
                        <v-icon left> mdi-arrow-bottom-right-thin </v-icon>
                       52200.20
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container-fluid>
    </div>
  </DashLayout>
</template>
  
  <script>
import DashLayout from "@/components/layout/dashlayout.vue";

export default {
  name: "dashboard",

  data() {
    return {
      files: [
        {
          color: "blue",
          icon: "mdi-clipboard-text",
          subtitle: "Jan 20, 2014",
          title: "Vacation itinerary",
        },
        {
          color: "amber",
          icon: "mdi-gesture-tap-button",
          subtitle: "Jan 10, 2014",
          title: "Kitchen remodel",
        },
      ],
      folders: [
        {
          subtitle: "Jan 9, 2014",
          title: "Photos",
        },
        {
          subtitle: "Jan 17, 2014",
          title: "Recipes",
        },
        {
          subtitle: "Jan 28, 2014",
          title: "Work",
        },
      ],
    };
  },

  components: {
    DashLayout,
  },
};
</script>
  
  <style>
</style>