<template>
  <dashlayout>
    <v-container-fluid fluid>
      <v-row>
        <v-col cols="12" md="9">
          <div class="dash-bg">
            <div class="box-title-with-btn mb-0">
              <div class="box-title">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Seller Live Auction
              </div>
              <div class="btns-right">
                <!-- <v-chip class="ma-2 blink-2" color="red lighten-1" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-access-point-check</v-icon>
                  </v-avatar>
                  Live
                </v-chip> -->
                <v-chip class="ma-2 blink-2" color="primary" text-color="white">
                  <v-avatar left>
                    <v-icon>mdi-check</v-icon>
                  </v-avatar>
                  Completed
                </v-chip>
              </div>
            </div>

            <v-divider></v-divider>
            <div class="auction-list-wrap mt-4">

              <v-timeline
                  dense
                  clipped
                >
                  <v-timeline-item
                    class="mb-4"
                    small
                    icon="mdi-check"
                  >
                    <v-row justify="space-between">
                      <v-col cols="7">
                       Auction has been completed succesfully.

                        <div class="time-and-date">27/05/2023 01:22 AM</div>
                      </v-col>
                      
                    </v-row>
                  </v-timeline-item>
                 
                 
                  <v-timeline-item
                    class="mb-4"
                    small
                    icon="mdi-check"
                  >
                    <v-row justify="space-between">
                      <v-col cols="7">
                       Commission paid to Biofuel. 
                       <v-chip
                      class="white--text ml-3"
                      color="brown"
                      label
                      small
                      outlined
                    >
                      1700 Rs
                    </v-chip>

                    

                        <div class="time-and-date">27/05/2023 01:22 AM</div>
                        <v-btn  color="primary" class="mt-2">
                            Download invoice
                          </v-btn>
                        <v-btn outlined color="primary" class="mt-2 ml-3">
                            View invoice
                          </v-btn>
                      </v-col>
                      
                    </v-row>
                  </v-timeline-item>

                 

                  <v-timeline-item
                    class="mb-4"
                    color="grey"
                    small
                  >
                    <v-row justify="space-between">
                      <v-col cols="7">
                        Order confirmation email was sent to John Leider (john@vuetifyjs.com).

                        <div class="time-and-date">27/05/2023 01:22 AM</div>
                        <div class="mt-2">
                          <v-btn
                          color="primary" 
                            class="mx-0"
                            disabled
                          >
                            Send PO
                          </v-btn>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                class="ml-4"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>The button will be enabled as soon as <br> the winner confirms your order. </span>
                          </v-tooltip>

                        </div>
                      </v-col>

                      
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    class="mb-4"
                    small
                    color="grey"

                  >
                    <v-row justify="space-between">
                      <v-col cols="7">
                        This order was archived.
                       

                        <div class="time-and-date">27/05/2023 01:22 AM</div>
                      </v-col>
                      
                    </v-row>
                  </v-timeline-item>


                  <v-timeline-item
                    class="mb-4"
                    small
                    color="grey"
                  >
                    <v-row justify="space-between">
                      <v-col cols="7">
                        This order was archived.
                       

                        <div class="time-and-date">27/05/2023 01:22 AM</div>
                      </v-col>
                      
                    </v-row>
                  </v-timeline-item>

              

                  
                </v-timeline>

              <!-- <div class="auction-box">
                <div class="auction-left-part">
                  <v-avatar
                    class="mr-3 white--text font-weight-bold"
                    color="primary"
                    size="40"
                    >H1</v-avatar
                  >
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1800.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div>
              <div class="auction-box">
                <div class="auction-left-part">
                  <v-avatar
                    class="mr-3 white--text font-weight-bold"
                    color="primary"
                    size="40"
                    >H2</v-avatar
                  >
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1200.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div>
              <div class="auction-box">
                <div class="auction-left-part">
                  <v-avatar
                    class="mr-3 white--text font-weight-bold"
                    color="primary"
                    size="40"
                    >H3</v-avatar
                  >
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1100.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div> -->

              <!-- <div :class="{
                  'auction-box' : true, 
                  'slide-in-bottom' : bidder_rank == 1 ? false : true,
                  'slide-in-top' : bidder_rank == 1 ? false : true,
                  'placeholder' : bidder_rank == 1 ? false : true
                }">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{ bidder_rank != '' ? `${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'} ${bidder_rank}` : '-'}}</v-avatar>
                  <div class="name-of-company mr-4" v-if=" bidder_rank == 1">{{ first_name }} {{ last_name }}</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{ last_bid_amount }}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" :text-color="updated_value_from_last_bid >=0 ? '#9DA73E' : 'red'" label>
                      <v-icon class="mr-2"> {{updated_value_from_last_bid >=0 ? 'mdi-trending-up' : 'mdi-trending-down'}} </v-icon>
                      {{ updated_value_from_last_bid }}
                    </v-chip>
                  </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(placeAuctionBid)">
                <div class="auction-right-part">
                  <v-btn @click="getBidHistoryInfo" elevation="0" flat small fab class="mr-2">
                    <v-icon color="#9DA73E"> mdi-history </v-icon>
                  </v-btn>
                  <validation-provider v-slot="{ errors }" name="Bid" rules="required">
                  <v-text-field 
                    v-model="bid" 
                    name="name" 
                    label="Enter Bid Amount" 
                    id="id" 
                    hide-details="" 
                    outlined dense
                    suffix="₹" 
                    :error-messages="errors"
                    @change="checkMultiPlyOf"
                  ></v-text-field>
                    </validation-provider>
                  <v-btn type="submit" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                    <v-icon class="mr-2"> mdi-gavel </v-icon>
                    Bid
                  </v-btn>
                </div>
                </form>
                </validation-observer>
              </div> -->

              <!-- <div :class="{
                  'auction-box' : true, 
                  'slide-in-bottom' : bidder_rank == 2 ? false : true ,
                  'slide-in-top' : bidder_rank == 2 ? false : true,
                  'placeholder' : bidder_rank == 2 ? false : true
                }">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{ bidder_rank != '' ? `${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}${bidder_rank}` : '-'}}</v-avatar>
                  <div class="name-of-company mr-4" v-if=" bidder_rank == 2">{{ first_name }} {{ last_name }}</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{ last_bid_amount }}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      {{ updated_value_from_last_bid }}
                    </v-chip>
                  </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(placeAuctionBid)">
                <div class="auction-right-part">
                  <v-btn @click="getBidHistoryInfo" elevation="0" flat small fab class="mr-2">
                    <v-icon color="#9DA73E"> mdi-history </v-icon>
                  </v-btn>
                  <validation-provider v-slot="{ errors }" name="Bid" rules="required">
                  <v-text-field 
                    v-model="bid" 
                    name="name" 
                    label="Enter Bid Amount" 
                    id="id" 
                    hide-details="" 
                    outlined dense
                    suffix="₹" 
                    :error-messages="errors"
                    @change="checkMultiPlyOf"
                  ></v-text-field>
                    </validation-provider>
                  <v-btn type="submit" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                    <v-icon class="mr-2"> mdi-gavel </v-icon>
                    Bid
                  </v-btn>
                </div>
                </form>
                </validation-observer>
              </div> -->

              <!-- <div :class="{
                  'auction-box' : true, 
                  'slide-in-bottom' : bidder_rank > 2 ? false : bidder_rank == 0 ? false : true ,
                  'slide-in-top' : bidder_rank > 2 ? false :  bidder_rank == 0 ? false : true,
                  'placeholder' : bidder_rank > 2 ? false :  bidder_rank == 0 ? false : true
                }">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                    size="40">{{ bidder_rank != '' ? `${auctionsInfo && auctionsInfo.auction_for == 'buy' ? 'L' : 'H'}${bidder_rank}` : '-'}}</v-avatar>
                  <div class="name-of-company mr-4" v-if=" bidder_rank > 2 || bidder_rank == 0">{{ first_name }} {{ last_name }}</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ {{ last_bid_amount }}</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      {{ updated_value_from_last_bid }}
                    </v-chip>
                  </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(placeAuctionBid)">
                <div class="auction-right-part">
                  <v-btn @click="getBidHistoryInfo" elevation="0" flat small fab class="mr-2">
                    <v-icon color="#9DA73E"> mdi-history </v-icon>
                  </v-btn>
                  <validation-provider v-slot="{ errors }" name="Bid" rules="required">
                  <v-text-field 
                    v-model="bid" 
                    name="name" 
                    label="Enter Bid Amount" 
                    id="id" 
                    hide-details="" 
                    outlined dense
                    suffix="₹" 
                    :error-messages="errors"
                    @change="checkMultiPlyOf"
                    :disabled="isAuctionCompleted"
                  ></v-text-field>
                    </validation-provider>
                  <v-btn :disabled="isAuctionCompleted" type="submit" elevation="0" color="#9DA73E" dark class="px-2 ml-2">
                    <v-icon class="mr-2"> mdi-gavel </v-icon>
                    Bid
                  </v-btn>
                </div>
                </form>
                </validation-observer>
              </div> -->

              <!-- <div class="auction-box placeholder">
                <div class="auction-left-part">
                  <v-avatar class="mr-3 white--text font-weight-bold" color="primary" size="40">H3</v-avatar>
                  <div class="name-of-company mr-4">No Name</div>

                  <div class="last-bid-section mr-4">
                    <div class="last-bid-label mr-2">Last Bid</div>
                    <div class="last-bid-box">₹ 1200.00</div>
                  </div>

                  <div class="price-up-down">
                    <v-chip color="#F1F4D5" text-color="#9DA73E" label>
                      <v-icon class="mr-2"> mdi-trending-up </v-icon>
                      +100.00
                    </v-chip>
                  </div>
                </div>
                <div class="auction-right-part">
                  <div class="bid-update-tine">20s ago</div>
                </div>
              </div> -->
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="dash-bg">
            <div class="box-title-with-btn">
              <div class="box-title">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Auction Details
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container-fluid>
    <v-dialog v-model="historybox" width="450">
      <v-card>
        <v-card-title class="dialog-title justify-space-between">
          History
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="historybox = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12" v-if="bidHistory.length > 0">
              <v-timeline align-top dense>
                <v-timeline-item
                  color="pink"
                  small
                  v-for="(data, index) in bidHistory"
                  :key="index"
                >
                  <v-row class="pt-1">
                    <v-col cols="8">
                      <strong>{{ data.updated_at }}</strong>
                    </v-col>
                    <v-col>
                      <strong>₹ {{ data.bid_amount }} </strong>
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <!-- <v-timeline-item color="pink" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>12:25 AM</strong>
                    </v-col>
                    <v-col>
                      <strong>₹ 1250 </strong>
                      <v-chip small color="#F1F4D5" text-color="#9DA73E" class="px-2 ml-2" label>
                        +50.00
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item color="teal lighten-3" small>
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong>12:20 AM</strong>
                    </v-col>
                    <v-col>
                      <strong>₹ 1200 </strong>
                      <v-chip small color="#F1F4D5" text-color="#9DA73E" class="px-2 ml-2" label>
                        +100.00
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-timeline-item> -->
              </v-timeline>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="pyro" v-if="auctionwon">
      <div class="before"></div>
      <div class="after"></div>
    </div>
    <v-dialog v-model="auctionwon" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-gavel </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">There has been a successful auction</div>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Highest Bid</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar
                      class="mr-3 white--text font-weight-bold bounce-in-fwd"
                      color="primary"
                      size="40"
                      >H1</v-avatar
                    >
                    <div class="name-of-company mr-4">No Name</div>

                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">₹ 1200.00</div>
                    </div>
                  </div>
                  <div class="auction-right-part">
                    <v-btn
                      @click="
                        auctionwon = false;
                        orderdetails = true;
                      "
                      elevation="0"
                      color="#9DA73E"
                      dark
                      class="px-2 ml-2"
                    >
                      <v-icon class="mr-2"> mdi-check </v-icon>
                      Confirm Order
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-alert dense outlined color="warning" prominent border="left">
                After Payment PO will be sent to Seller, and we will display Seller's name and all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderdetails" persistent width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mt-3">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-gavel
                  </v-icon>
                  Auction Details
                </div>
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="orderdetails = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="12" class="pt-0 pb-0">
              <label for="">Highest Bid</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar
                      class="mr-3 white--text font-weight-bold bounce-in-fwd"
                      color="primary"
                      size="40"
                      >H1</v-avatar
                    >
                    <div class="name-of-company mr-4">No Name</div>

                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">₹ 1200.00</div>
                    </div>
                  </div>
                  <div class="auction-right-part">
                    <!-- <v-btn
                      @click="deleteModule"
                      elevation="0"
                      color="#9DA73E"
                      dark
                      class="px-2 ml-2"
                    >
                      <v-icon class="mr-2"> mdi-email-fast-outline </v-icon>
                      Send PO
                    </v-btn> -->
                  </div>
                </div>
              </div>
            </v-col>

            <v-col>
              <label for="">Purchase Order Term & Condition</label>
              <v-textarea rows="2" outlined placeholder="Write your term & conditions here"></v-textarea>
            </v-col>

            <v-col cols="12" md="12">
              <table class="hm-p table-bordered" style="width: 100%">
                <thead>
                  <tr>
                    <th style="width: 50px">#</th>
                    <th><h4>Item Name</h4></th>
                    <th style="width: 100px">
                      <h4>
                        HSN<br />
                        Code
                      </h4>
                    </th>
                    <th style="width: 80px"><h4>QTY</h4></th>
                    <th style="width: 80px">
                      <h4>
                        Unit<br />
                        Price
                      </h4>
                    </th>

                    <th style="width: 80px">
                      <h4>
                        Taxable <br />
                        Amount
                      </h4>
                    </th>
                    <th style="width: 80px">
                      <h4>
                        CGST
                        <hr />
                        Amount
                      </h4>
                    </th>
                    <th style="width: 80px">
                      <h4>
                        SGST<br />
                        <hr />
                        Amount
                      </h4>
                    </th>
                    <th style="width: 120px"><h4>TOTAL</h4></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>01</td>
                    <td>Milkfood Ghee Tp 980Ml</td>
                    <td>40590200</td>
                    <td>100</td>
                    <td>1</td>
                    <td>550.00</td>
                    <td>409.82</td>
                    <td>409.82</td>
                    <td>12.00</td>
                  </tr>

                  <tr style="background-color: #f7f7f7">
                    <td></td>
                    <td><strong>Total</strong></td>
                    <td>&nbsp;</td>
                    <td><strong>100</strong></td>
                    <td>&nbsp;</td>
                    <td><strong>409.82 </strong></td>
                    <td><strong>550.0</strong></td>
                    <td><strong>550.0</strong></td>
                    <td><strong>₹ 550.0</strong></td>
                  </tr>
                </tbody>
              </table>

              <table
                class="hm-p table-bordered ml-auto"
                style="width: fit-content; margin-top: 15px"
              >
                <tbody>
                  <tr style="background-color: #f7f7f7">
                    <td style="text-align: right">
                      <strong>Biofuel Commission</strong>
                    </td>
                    <td style="width: 80px"><strong>2.5 %</strong></td>
                    <td style="font-size: 18px; width: 120px; color: #9da648">
                      <strong>₹ 50.00</strong>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="text-right mt-4 mb-2">
                <v-btn
                  @click="paymentdialog=true"
                  elevation="0"
                  color="#9DA73E"
                  dark
                  large
                  class="px-5 ml-2"
                  style="min-width: 120px"
                >
                  Pay ₹ 50
                </v-btn>
              </div>

              <v-alert
                dense
                outlined
                color="warning"
                prominent
                border="left"
                class="mb-0 mt-3"
              >
                After Payment PO will be sent to Seller, and we will display Seller's name and all relevant details.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentdialog" persistent width="400">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mb-0">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-account-credit-card-outline
                  </v-icon>
                  Payment
                </div>
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="paymentdialog = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="12">
              <div class="d-flex justify-space-between align-center">
                <div for="" style="font-size: 1rem">Wallet Balance</div>
                <v-chip
                  link
                  outlined
                  @click="goToWallet"
                  class="px-5 mr-3"
                  color="#9DA73E"
                >
                  <v-avatar left>
                    <v-icon>mdi-wallet-outline</v-icon>
                  </v-avatar>
                  ₹ 115.25
                </v-chip>
              </div>

              <v-divider class="my-4"></v-divider>

              <div>
                <v-checkbox
                  label="Pay with wallet Balance"
                  class="ma-0 pa-0"
                  v-model="value"
                  value=""
                ></v-checkbox>
              </div>

              <div class="text-right mt-5 mb-2">
                <div class="mb-2">
                  <v-select
                    :items="items"
                    v-model="value"
                    label="Select Payment Method"
                    outlined
                  >
                    <template #item="{ item }">
                      <img class="mr-2" width="20" :src="item.logo" alt="" />
                      <span style="font-size: 0.9rem">
                        {{ item.name }}
                      </span>
                    </template>

                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip
                        v-bind="attr"
                        :input-value="selected"
                        v-on="on"
                        color="transparent"
                        class="px-0"
                      >
                        <img class="mr-2" width="20" :src="item.logo" alt="" />
                        <span
                          style="font-size: 0.9rem"
                          v-text="item.name"
                        ></span>
                      </v-chip>
                    </template>
                  </v-select>
                </div>
                <v-btn
                  elevation="0"
                  color="#9DA73E"
                  dark
                  large
                  class="px-5 ml-2"
                  style="min-width: 120px"
                >
                  Pay ₹ 50
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConformationBox
      v-if="isShowConfirmDialog"
      message="Are you sure want to Send PO ?"
      confirmText="Yes Send"
      @confirm="closeDeleteFormModal"
      @cancel="closeDeleteFormModal"
    />

    <!-- <v-dialog v-model="auctionlose" width="800">
      <v-card>
        <v-card-text class="pt-4">
          <div class="text-center my-8">
            <v-avatar size="80" color="#E8BE2C" class="flip-vertical-right">
              <v-icon size="50" color="white"> mdi-emoticon-happy-outline </v-icon>
            </v-avatar>
            <div class="text-h6 mt-3">Better Luck Next time, <br>You Lose Auction</div>
          </div>
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Your Bid</label>

              <div class="auction-list-wrap mt-2">
                <div class="auction-box">
                  <div class="auction-left-part">
                    <v-avatar class="mr-3 white--text font-weight-bold bounce-in-fwd" color="primary"
                      size="40">H1</v-avatar>
                    <div class="name-of-company mr-4">No Name</div>

                    <div class="last-bid-section mr-4">
                      <div class="last-bid-label mr-2">Bid</div>
                      <div class="last-bid-box">₹ 1200.00</div>
                    </div>
                  </div>
                  <div class="auction-right-part">
                  </div>
                </div>
              </div>

             
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import { getDecodedStringBase64 } from "@/utils/helper";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import Pusher from "pusher-js";
import ConformationBox from "@/components/common/ConformationBox.vue";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    dashlayout,
    ValidationObserver,
    ValidationProvider,
    ConformationBox,
  },
  name: "LiveAuctionSeller",
  inject: ["mySpinner"],
  data: () => ({
    items: [
      {
        logo: "https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo-thumbnail.png",
        name: "Gpay",
      },
    ],
    paymentdialog: false,
    isShowConfirmDialog: false,
    orderdetails: false,
    auctionlose: false,
    auctionwon: true,
    historybox: false,
    dialog: false,

    auctionsInfo: null,
    auction_number: "",
    auction_end_date_time: "",

    first_name: "",
    last_name: "",

    last_bid_amount: 0,
    updated_value_from_last_bid: 0,
    bid: "",
    EMDAmount: 1,

    bidHistory: [],

    bidder_rank: "",
    user_id: "",
    isAuctionCompleted: false,
  }),
  created() {
    this.getAuctionInfo();
    this.auction_number = getDecodedStringBase64(this.$route.params.id);
    this.first_name = localStorage.getItem("first_name");
    this.last_name = localStorage.getItem("last_name");
    this.user_id = localStorage.getItem("user_id");
  },
  computed: {
    getAuctionDiff() {
      if (this.auctionsInfo) {
        let dt1 = new Date(this.auctionsInfo.start_date_time);
        let dt2 = new Date(this.auctionsInfo.end_date_time);

        // calculate the time difference of two dates JavaScript
        var diffTime = dt2.getTime() - dt1.getTime();

        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600);

        return daysDiff;
      }
    },
  },
  methods: {
    deleteModule() {
      this.isShowConfirmDialog = true;
    },
    closeDeleteFormModal() {
      this.isShowConfirmDialog = false;
      this.orderdetails = false;
    },
  },
};
</script>
  
<style></style>