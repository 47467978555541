import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/modules/login'
import user from '@/store/modules/user'
import userRole from '@/store/modules/userRole'
import blog from '@/store/modules/blog'
import tag from '@/store/modules/tag'
import category from '@/store/modules/category'
import contactus from '@/store/modules/contactus'
import auction from '@/store/modules/auction'
import enquiry from '@/store/modules/enquiry'
import notification from '@/store/modules/notification'
import item from '@/store/modules/item'
import partner from '@/store/modules/partner'
import invoice from '@/store/modules/invoice'
import purchaseOrder from '@/store/modules/purchaseOrder'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading : false,
  },
  getters: {
  },
  mutations: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  },
  actions: {
  },
  modules: {
    login : login,
    user : user,
    userRole : userRole,
    blog : blog,
    tag : tag,
    category : category,
    contactus : contactus,
    auction: auction,
    enquiry : enquiry,
    notification : notification,
    item: item,
    partner: partner,
    invoice: invoice,
    purchaseOrder: purchaseOrder,
  }
})
