<template>
  <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6">
            {{message}}
          </v-card-title>
  
          <!-- <v-card-text> Lorem ipsum dolor sit amet consectetur. </v-card-text> -->
  
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
  
            <v-btn color="grey darken-1" text @click="cancel"> No </v-btn>
  
            <v-btn
              color="red darken-1"
              text
              @click="confirm"
              class="font-weight-bold"
            >
              {{confirmText != undefined ? confirmText : 'Yes, Delete'}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    props :['title','message','confirmText'],
    data () {
        return {
            dialog: true,
        }
    },
    methods :{

        // confirm delete
        confirm() {
            this.$emit('confirm')
            this.show = false
        },

        // cancel delete
        cancel() {
            this.$emit('cancel')
            this.show = false
        }
    }
}
</script>

<style>

</style>