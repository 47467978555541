<template>
  <v-dialog v-model="viewAuctionDetails" width="500" persistent>
    <v-card>

      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" md="12">
            <div class="box-title-with-btn">
              <div class="box-title mt-3">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Auction Details
              </div>
              <v-btn
                fab
                x-small
                elevation="0"
                color="transparent"
                @click="$emit('close')"
              >
                <v-icon color="black"> mdi-close </v-icon>
              </v-btn>
            </div>

            <div class="auction-details">
              <div class="d-wrap-auction">
                <span class="title-of-detail"> Name </span>
                <div>
                  <span class="auction-name">{{
                    data && data.category_name
                  }}</span>
                  <!-- <span style="color: #9da73e">(Cotton)</span> -->
                  <span class="auction-name"
                    >&nbsp;{{ data && data.quantity }} Tonne</span
                  >
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Description </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && data.auction_description }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Base Price </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && data.expected_price }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Quantity </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && data.quantity }} Tonne
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> EMD Amount </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && data.range_of_bid_amount }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Auction Time </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && getAuctionDiff }}
                  </div>
                </div>
              </div>

              <div class="d-wrap-auction">
                <span class="title-of-detail"> Start Time </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && data.start_date_time }}
                  </div>
                </div>
              </div>
              <div class="d-wrap-auction">
                <span class="title-of-detail"> End Time </span>
                <div>
                  <div class="d-details-auction">
                    {{ data && data.end_date_time }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <div class="btns-right">
          <v-btn
            elevation="0"
            color="#9DA73E"
            dark
            class="px-4"
            type="submit"
            @click="$emit('close')"
          >
            Okay
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      viewAuctionDetails: true,
    };
  },
  computed: {
    getAuctionDiff() {
      if (this.data) {
        let dt1 = new Date(this.data.start_date_time);
        let dt2 = new Date(this.data.end_date_time);

        // calculate the time difference of two dates JavaScript
        var diffTime = dt2.getTime() - dt1.getTime();

        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600);

        return daysDiff.toFixed(2);
      }
    },
  },
};
</script>

<style></style>