import axiosServices from "@/utils/axios";
import { COMMONPAYLOAD, OTPCOMMONPAYLOAD, FORMDATAHEADER, APPKEY, API_URL,getDecodedToken } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    
})

const getters  = {

}

const actions = {
    getAdminCategoryList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc != undefined && inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('admin/category/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getAllCategory({ commit }, inputPage) {
        const payLoad = {
            action: inputPage.action,
            // sorting_order: inputPage.sortDesc != undefined && inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            // order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            // show_records: inputPage.itemsPerPage,
            // page: inputPage.page,            
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('admin/category/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getSliderCategoryList({ commit }, inputPage) {
        const payLoad = {
            action: inputPage.action,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('admin/category/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createAdminCategory({ commit },payload) {
        return axiosServicesFormData
            .post(`/admin/category/create`, payload)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    deleteAdminCategory({ commit },payload) {
        return axiosServices
            .post('/admin/category/delete', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewAdminCategory({ commit },payload) {
        return axiosServices
            .post('/admin/category/list', {...payload,...OTPCOMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateAdminCategory({ commit },payload) {
        const token = getDecodedToken();
        return axiosServicesFormData
            .post(`/admin/category/update`, payload)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}