<template>
    <weblayout>
      <div class="banner-blog">
        <v-container>
          <div class="wrapper-of-banner">
            <div class="title-of-blog">
              {{blogData && blogData.title}}
              <div class="auther mt-5">
                <v-avatar>
                  <!-- <img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                    alt="John"
                  /> -->
                </v-avatar>
                <div class="auther-name">
                  <!-- {{  blogData && blogData.updated_by_name }} -->
                  BioFuelBid
                  <div class="grey--text body-2">On {{ moment(blogData.created_at).format('DD/MM/YYYY - h:mm a') }}</div>
                </div>
              </div>
            </div>
  
            <div class="img-of-blog" v-if="blogData">
              <!-- <div class="next-prev-btn justify-end">
                <v-btn text dark>
                  <v-icon> mdi-chevron-left </v-icon>
                  Prev Blog
                </v-btn>
                <v-btn text dark>
                  Next Blog
                  <v-icon> mdi-chevron-right </v-icon>
                </v-btn>
              </div> -->
              <img
                style="max-width: 600px"
                :src="blogData.header_image_full_path"
                alt=""
              />
            </div>
          </div>
        </v-container>
      </div>
  
      <v-container class="my-16">
        <div class="about-video-section">
          <v-row>
            <v-col cols="12" md="12">
              <div class="about-wrap" v-if="blogData">
                
                <p v-html="blogData.content"></p>
                
              </div>
            </v-col>
  
            <v-col cols="12" md="12">
              <v-divider></v-divider>
              <div class="auther mt-5">
                <v-avatar>
                  <!-- <img
                    src="https://cdn.vuetifyjs.com/images/john.jpg"
                    alt="John"
                  /> -->
                </v-avatar>
                <div class="auther-name">
                  BioFuelBid
                    <!-- {{  blogData && blogData.updated_by_name }} -->
                  <div class="grey--text body-2">{{ moment(blogData.updated_at).format('DD/MM/YYYY') }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </weblayout>
  </template>
    
    <script>
  import weblayout from "../../../components/layout/weblayout.vue";
  import moment from 'moment'

  export default {
    components: { weblayout },
    inject : ['mySpinner'],
    data() {
      return {
        tab: null,
        blogData : null,
        id : ''
      };
    },
    created() {
        this.id = this.$route.params.id
        if(this.id != '' ) {
            this.getBlogData();
        }
    },
    methods : {
        moment: function () {
    return moment();
  },
        async getBlogData() {
            
            try {
            this.mySpinner.val = true;
            const response = await this.$store.dispatch("getDashboardBlogList", {action : 'VIEW',id : this.id});
            if (response.http_status === 200) {

            this.blogData = response.data;
            this.mySpinner.val = false;
            } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
            }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        }
    }
  };
  </script>
    
    <style>
  </style>