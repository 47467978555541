<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-account-check-outline
            </v-icon>
            Category
          </div>
          <div class="btns-right">
            
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              class="px-2"
              @click="addEditRole = true"
            >
              <v-icon> mdi-plus </v-icon>
              Add Category
            </v-btn>
          </div>
        </div>

        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="categoryData"
              :items-per-page="5"
              :options.sync="options"
              :server-items-length="total"
              class="elevation-0"
              :footer-props="footerList"
              dense
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  fab
                  small
                  elevation="0"
                  color="transparent"
                  @click="editCategory(item.id)"
                >
                  <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  elevation="0"
                  color="transparent"
                  @click="deleteRole(item.id)"
                >
                  <v-icon color="red"> mdi-delete-outline </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container-fluid>
    <ConformationBox
      v-if="isShowDeleteDialog"
      :title="'Confirmation'"
      message="Are you sure want to delete ?"
      @confirm="confirmDeleteForm"
      @cancel="closeDeleteFormModal"
    />
    <AddEditCategory
      v-if="addEditRole"
      @cancel="closeDialog"
      :id="editUserRoleId"
    />
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import { FOOTERPAGELIST, PAGINATIONOPTIONS } from "@/utils/helper";
import ConformationBox from "@/components/common/ConformationBox.vue";
import AddEditCategory from "@/components/dashboard/AddEditCategory.vue";
export default {
  components: { dashlayout, ConformationBox, AddEditCategory },
  name: "CategoryList",
  inject: ["mySpinner"],
  methods: {
    async fetchCategoryList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getAdminCategoryList", {
          ...this.options,
          action: "LIST",
        });
        if (response.http_status === 200) {
          this.categoryData = response.data.data;
          this.total = response.data.total;
          // this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    closeDialog() {
      this.addEditRole = false;
      this.fetchCategoryList();
      this.editUserRoleId = "";
    },
    deleteRole(id) {
      this.isShowDeleteDialog = true;
      this.deleteRoleId = id;
    },
    closeDeleteFormModal() {
      this.isShowDeleteDialog = false;
      this.deleteRoleId = "";
    },
    async confirmDeleteForm() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("deleteAdminCategory", {
          id: this.deleteRoleId,
        });
        if (response.http_status === 200) {
          this.isShowDeleteDialog = false;
          this.deleteRoleId = "";
          this.fetchCategoryList();
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.isShowDeleteDialog = false;
          this.deleteRoleId = "";
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    editCategory(id) {
      this.editUserRoleId = id;
      this.addEditRole = true;
    },
  },
  created() {
    this.fetchCategoryList();
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchCategoryList();
        }
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,
    addEditModule: false,

    categoryData: [],
    total: 0,

    options: PAGINATIONOPTIONS,
    footerList: {
      "items-per-page-options": [10, 20, 50, 100],
    },

    addEditRole: false,
    editUserRoleId: "",

    isShowDeleteDialog: false,
    deleteRoleId: "",

    headers: [
      { text: "Name", value: "name" },
      { text: "Updated At", value: "updated_at" },
      { text: "Updated By", value: "updated_by_name", sortable: false },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
  }),
};
</script>
  
<style></style>