<template>
  <weblayout>
    <div class="banner-blog small-inner-banner">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="text-center my-0 mx-auto">
            <div class="text-h3 font-weight-medium white--text">About Us</div>
            <v-breadcrumbs large :items="items">
              <template v-slot:divider>
                <v-icon color="white">mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </div>
        </div>
      </v-container>
    </div>
    <v-container class="my-16">
      <v-row>
        <v-col cols="12" md="5" class="justify-center d-flex">
          <img
            style="max-width: 440px; width: 100%;"
            src="@/assets/images/about-img.73554a5d.png"
            alt=""
          />
        </v-col>
        <v-col cols="12" md="7" class="d-flex align-center">
          <div class="about-wrap">
            <div class="m-title">About <strong>Us</strong></div>

            <div>
              <p>
                Biofuelbid is platform to connect buyer and seller dealing in bio coals. Bio coal is seen as future of fossil coal consumption leading to green practices across all industries.
              </p>
              <p>
                With the growing demand of bio coals in India manufactured find it difficult to sustain and provide regular supply of bio coal to industries and at the same time supplier are restricted to their nearby vendors only when it comes to fulfilling their coal demand where they are loosing advantage of price compatibility available in market.
              </p>
              <p>
                We aim to bring transparency in bio coal trading in terms of pricing making sure thar buyer and seller can have better reach to price and quality competitive products in real time.
              </p>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="12" class="d-flex align-center mt-10">
          <div class="about-wrap">
            <div class="m-title ">Why  <strong>Us</strong></div>

            <div>
              <p>
                Biofuel bid is one platform where you will find maximum number of manufacturers trading their inventories and providing better rate and terms of trade. Its solution to this emerging industry trying to bring all the stakeholders together and easily facilitate trade between them at transparent terms.
              </p>
              <p>
                We also facilitate documentation support to buyer to ensure 100% Transparency in the deal and also heling them creating documentation trail helping them in audit and also help them in documentation of ESG Compliances and enabling them for claim of carbon credit. For seller its opportunity to utilise their ideal capacity and explore new customer base ensuring maximum profitability and creating eco system around biofuel supplies and management
              </p>
              
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </weblayout>
</template>

<script>
import Weblayout from "../layout/weblayout.vue";
import {generateTheMetaInfo} from '@/utils/helper'
export default {
  components: { Weblayout },
  data() {
    return {
      items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "About",
        disabled: true,
        href: "/blogs",
      },
    ],
    }
  },
  metaInfo () {
    return generateTheMetaInfo('AboutUs')
  },
};
</script>

<style>
</style>