import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '@/components/LoginPage.vue'
import RegistraionPage from '@/components/RegistraionPage.vue'
import VerifyOtp from '@/components/VerifyOtp.vue'
import RegisterDetails from '@/components/RegisterDetails.vue'
import RegisterOtp from '@/components/RegisterOtp.vue'
import ForApproval from '@/components/ForApproval.vue'
import VerifyEmail from '@/components/VerifyEmail.vue'
import ResetPasswordEmail from '@/components/ResetPasswordEmail.vue'
import ResetPasswordVerify from '@/components/ResetPasswordVerify.vue'
import ResetPasswordConfirm from '@/components/ResetPasswordConfirm.vue'
import Dashboard from '@/components/dashboard/Dashboard.vue'
import Userlist from '@/components/dashboard/userList.vue'
import UserAddEdit from '@/components/dashboard/userAddEdit.vue'
import BlogList from '@/components/dashboard/blogList.vue'
import BlogAddEdit from '@/components/dashboard/blogAddEdit.vue'
import UserModuleList from '@/components/dashboard/UserModuleList.vue'
import RoleAccess from '@/components/dashboard/RoleAccess.vue'
import RoleAccessEdit from '@/components/dashboard/RoleAccessEdit.vue'
import Wallet from '@/components/dashboard/Wallet.vue'

import PartnerRegistration from '@/components/dashboard/partner/PartnerRegistration.vue'
import PartnerRegisterOtp from '@/components/dashboard/partner/PartnerRegisterOtp.vue'
import PartnerRegisterDetails from '@/components/dashboard/partner/PartnerRegisterDetails.vue'
import PartnerDashboard from '@/components/dashboard/partner/PartnerDashboard.vue'

import Maintance from '@/components/common/Maintance.vue'
import ChangePassword from '@/components/ChangePassword.vue'
import TermCondition from '@/components/TermCondition.vue'

import HomePage from '@/components/website/HomePage.vue'
import AboutUs from '@/components/website/AboutUs.vue'
import BlogView from '@/components/website/BlogView.vue'
import AllBLog from '@/components/website/AllBlog.vue'
import Partner from '@/components/website/Partner.vue'
import ViewPartner from '@/components/website/ViewPartner.vue'

// blogs static
import Blog2 from '@/components/website/Blog2.vue'
import Blog3 from '@/components/website/Blog3.vue'
import Blog4 from '@/components/website/Blog4.vue'


import Buyer from '@/components/website/Buyer.vue'
import Seller from '@/components/website/Seller.vue'


import ContactUs from '@/components/website/ContactUs.vue'
import Inquiry from '@/components/website/Inquiry.vue'


import UpdateProfile from '@/components/seller/UpdateProfile.vue'
import CategoryList from '@/components/dashboard/CategoryList.vue'
import ContactUsList from '@/components/dashboard/ContactUsList.vue'
import TagsList from '@/components/dashboard/TagsList.vue'
import ItemsList from '@/components/dashboard/ItemsList.vue'
import EnquiryList from '@/components/dashboard/EnquiryList.vue'


import BlogDetails from '@/components/dashboard/blogs/BlogDetails.vue'

// Auction
import AuctionList from '@/components/dashboard/AuctionList.vue'
import MyAuctionList from '@/components/dashboard/MyAuctionList.vue'
import AuctionAddEdit from '@/components/dashboard/AuctionAddEdit.vue'
import LiveAuction from '@/components/dashboard/LiveAuction.vue'
import LiveAuctionBuyer from '@/components/dashboard/LiveAuctionBuyer.vue'
import AdminAuctionList from '@/components/dashboard/AdminAuctionList.vue'
import AdminAuctionDetails from '@/components/dashboard/AdminAuctionDetails.vue'
import PartnerUserList from '@/components/dashboard/partner/PartnerUserList.vue'
import PartnerUserView from '@/components/dashboard/partner/PartnerUserView.vue'

import LiveAuctionSeller from '@/components/dashboard/LiveAuctionSeller.vue'

import UpcomingAuctionList from '@/components/dashboard/UpcomingAuctionList.vue'

// PDF
import Invoice from '@/components/dashboard/pdf/Invoice.vue'

// Purchse Order
import PurchaseOrdersList from '@/components/dashboard//PurchaseOrdersList.vue'

import directPurchaseOrdersList from '@/components/dashboard/DirectPurchaseOrders/List.vue'
import directPurchaseOrdersAddEdit from '@/components/dashboard/DirectPurchaseOrders/AddEdit.vue'

import directSalesInvoice from '@/components/dashboard/DirectSalesInvoice/List.vue'
import directSalesInvoiceAddEdit from '@/components/dashboard/DirectSalesInvoice/AddEdit.vue'

import directPurchaseInvoice from '@/components/dashboard/DirectPurchaseInvoice/List.vue'
import directPurchaseInvoiceAddEdit from '@/components/dashboard/DirectPurchaseInvoice/AddEdit.vue'

import auth from '@/middleware/auth'
import middlewarePipeline from '@/middleware/middlewarePipeline'
import isLoggedIn from '@/middleware/isLoggedIn'
import isPartner from '@/middleware/isPartner'
import isAdmin from '@/middleware/isAdmin'
import isUser from '@/middleware/isUser'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)

Vue.use(VueMeta)

const appRoutes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    /* meta : {
      middleware : [auth]
    } */
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginPage,
    meta: {
      middleware: [isLoggedIn]
    }
  },
  {
    path: '/register',
    name: 'Registration',
    component: RegistraionPage,
    meta: {
      middleware: [isLoggedIn]
    }
  },
  {
    path: '/register/:id',
    name: 'Registration with ID',
    component: RegistraionPage,
    meta: {
      middleware: [isLoggedIn]
    }
  },
  {
    path: '/partner-register',
    name: 'Partner Registration',
    component: PartnerRegistration,
    meta: {
      middleware: [isLoggedIn]
    }
  },
  {
    path: '/verifyOtp',
    name: 'Verify Otp',
    component: VerifyOtp,
    meta: {
      middleware: [auth, isLoggedIn]
    }
  },
  {
    path: '/registerotp',
    name: 'Register otp',
    component: RegisterOtp,
    meta: {
      middleware: [auth, isLoggedIn]
    }
  },
  {
    path: '/partner-registerotp',
    name: 'Partner Register otp',
    component: PartnerRegisterOtp,
    meta: {
      middleware: [auth, isLoggedIn]
    }
  },
  {
    path: '/registerdetails',
    name: 'Register Details',
    component: RegisterDetails,
    meta: {
      middleware: [auth, isLoggedIn]
    }
  },
  {
    path: '/partner-registerdetails',
    name: 'Partner Register Details',
    component: PartnerRegisterDetails,
    meta: {
      middleware: [auth, isLoggedIn]
    }
  },
  // {
  //   path: '/userview',
  //   name: 'User View',
  //   component: PartnerUserView
  // },
  {
    path: '/userview/:id',
    name: 'User View',
    component: PartnerUserView,
    meta: {
      middleware: [auth, isAdmin, isUser]
    }
  },
  {
    path: '/yourPartner',
    name: 'Your Partner',
    component: PartnerUserList,
    meta: {
        middleware: [auth, isAdmin, isUser]
      }
  },
  {
    path: '/partner-dashboard',
    name: 'Partner Dashboard',
    component: PartnerDashboard,
    meta: {
      middleware: [
        auth, isUser, isAdmin
      ],
    }
  },
  {
    path: '/forapproval',
    name: 'Approval',
    component: ForApproval,
    meta: {
      middleware: [auth, isLoggedIn]
    }
  },
  {
    path: '/resetPasswordEmail',
    name: 'Reset Password Email',
    component: ResetPasswordEmail
  },
  {
    path: '/passwordReset',
    name: 'Reset Password Verify',
    component: ResetPasswordVerify
  },
  {
    path: '/resetPasswordConfirm',
    name: 'Reset Password Confirm',
    component: ResetPasswordConfirm
  },
  {
    path: '/verifyEmail',
    name: 'Verify Email',
    component: VerifyEmail
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth, isPartner]
    }
  },
  {
    path: '/userProfile',
    name: 'Profile',
    component: UserAddEdit,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/userlist',
    name: 'Users',
    component: Userlist,
    meta: {
      middleware: [auth, isUser]
    }
  },
  {
    path: '/useraddedit/:id',
    name: 'User Add/Update',
    component: UserAddEdit,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/updateProfile',
    name: 'Update Profile',
    component: UpdateProfile,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/bloglist',
    name: 'Blogs List',
    component: BlogList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/blogaddedit',
    name: 'Blog Add',
    component: BlogAddEdit,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/blogaddedit/:id',
    name: 'Blog Edit',
    component: BlogAddEdit,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/usermodulelist',
    name: 'Module List',
    component: UserModuleList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/roleaccess',
    name: 'Role Access',
    component: RoleAccess,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/roleaccessedit/:id',
    name: 'Role Access Edit',
    component: RoleAccessEdit,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/upcomingAuctionList',
    name: 'Upcoming Auction List',
    component: UpcomingAuctionList,
    meta: {
      middleware: [auth, isPartner]
    }
  },
  {
    path: '/auctionList',
    name: 'Auction List',
    component: AuctionList,
    meta: {
      middleware: [auth, isPartner]
    }
  },
  {
    path: '/adminAuctionList',
    name: 'Admin Auction List',
    component: AdminAuctionList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/myAuction',
    name: 'My Auction List',
    component: MyAuctionList,
    meta: {
      middleware: [auth, isPartner, isAdmin]
    }
  },
  {
    path: '/auction/auctioneer/:id',
    name: 'Live Auction',
    component: LiveAuction,
    meta: {
      middleware: [auth, isPartner, isAdmin]
    }
  },
  {
    path: '/auction/admindetails/:id',
    name: 'Auction Details',
    component: AdminAuctionDetails,
    meta: {
      middleware: [auth, isPartner, isUser]
    }
  },
  {
    path: '/auction/participant/:id',
    name: 'Live Auction Buyer',
    component: LiveAuctionBuyer,
    meta: {
      middleware: [auth, isPartner]
    }
  },
  {
    path: '/liveauctionseller',
    name: 'Live Auction Seller',
    component: LiveAuctionSeller,
    meta: {
      middleware: [auth, isPartner]
    }
  },
  {
    path: '/auctionAdd',
    name: 'Auction Add',
    component: AuctionAddEdit,
    meta: {
      middleware: [auth, isPartner, isAdmin]
    }
  },
  {
    path: '/auctionEdit/:id',
    name: 'Auction Edit',
    component: AuctionAddEdit,
    meta: {
      middleware: [auth, isPartner]
    }
  },
  {
    path: '/buyerAuction',
    name: 'Auction',
    component: Maintance,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/categoryList',
    name: 'Category List',
    component: CategoryList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/contactUsList',
    name: 'Contact Us List',
    component: ContactUsList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/tagsList',
    name: 'Tags List',
    component: TagsList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/itemsList',
    name: 'Item List',
    component: ItemsList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/enquirylist',
    name: 'Enquiry List',
    component: EnquiryList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/changePassword',
    name: 'Change Password',
    component: ChangePassword,
    meta: {
      middleware : [auth]
    }
  },
  {
    path: '/blogs/:slug/:id',
    name: 'Blog Details',
    component: BlogDetails,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/termcondition',
    name: 'termcondition',
    component: TermCondition,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/nation-bioenergy-mission-2018',
    name: 'Blog View',
    component: BlogView,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/biofiels-and-government-policies-around-it',
    name: 'blog2',
    component: Blog2,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/what-is-biofuel',
    name: 'blog3',
    component: Blog3,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/carbon-credit-a-tool-to-incentive-use-of-biofuel',
    name: 'blog4',
    component: Blog4,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/blogs',
    name: 'Blogs',
    component: AllBLog,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/partner',
    name: 'Partner',
    component: Partner,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/viewpartner',
    name: 'View Partner',
    component: ViewPartner,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: AboutUs,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/buyer',
    name: 'Buyer',
    component: Buyer,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/seller',
    name: 'Seller',
    component: Seller,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/contactus',
    name: 'Contact Us',
    component: ContactUs,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/inquiry',
    name: 'Enquiry',
    component: Inquiry,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/biofuels-government-policies-around-it',
    name: 'Blog2',
    component: Blog2,
    // meta : {
    // middleware : []
    // }
  },
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: Invoice,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/purchaseorder',
    name: 'Purchase Orders',
    component: PurchaseOrdersList,
    meta : {
      middleware : [auth, isAdmin, isPartner]
    }
  },

  {
    path: '/directPurchaseOrdersList',
    name: 'Direct Purchase Orders List',
    component: directPurchaseOrdersList,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/directPurchaseOrdersAdd',
    name: 'Direct Purchase Orders Add',
    component: directPurchaseOrdersAddEdit,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/directPurchaseOrdersEdit/:id',
    name: 'Direct Purchase Orders Edit',
    component: directPurchaseOrdersAddEdit,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/salesInvoicesList',
    name: 'Sales Invoice List',
    component: directSalesInvoice,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/salesInvoiceAdd',
    name: 'Sales Invoice Add',
    component: directSalesInvoiceAddEdit,
    meta: {
      middleware: [auth, isPartner, isUser]
    }
  },
  {
    path: '/salesInvoicesEdit/:id',
    name: 'Sales Invoices Edit',
    component: directSalesInvoiceAddEdit,
    meta: {
      middleware: [isUser, isPartner]
    }
  },
  {
    path: '/purchaseInvoicesList',
    name: 'Purchase Invoice List',
    component: directPurchaseInvoice,
    meta: {
      middleware: [auth, isUser, isPartner]
    }
  },
  {
    path: '/purchaseInvoicesAdd',
    name: 'Purchase Invoice Add',
    component: directPurchaseInvoiceAddEdit,
    meta: {
      middleware: [auth, isPartner, isUser]
    }
  },
  {
    path: '/purchaseInvoicesEdit/:id',
    name: 'Purchase Invoices Edit',
    component: directPurchaseInvoiceAddEdit,
    meta: {
      middleware: [isUser, isPartner]
    }
  },
]

const routes = [...appRoutes]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    //   store  | You can also pass store as an argument
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach((to, from) => {
  ga('send', 'pageview', to.fullPath);
});

export default router
