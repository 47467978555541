<template>
    <dashlayout>
      <v-container-fluid fluid>
        <div class="dash-bg">
          <div class="box-title-with-btn">
            <div class="box-title">
              <v-icon size="24" color="#9DA73E" class="mr-2">
                mdi-book-edit-outline
              </v-icon>
              Auction
            </div>
            <div>
              <DatePicker 
                v-model="dateFilter" 
                range 
                @change="getAuctionList2" 
                format="YYYY-MM-DD" 
                valueType="YYYY-MM-DD"
                clearable
              />
            </div>
            <div>
                <v-select
                    v-model="auction_for"
                    :items="actionForData"
                    item-value="key"
                    item-text="value"
                    dense
                    outlined
                    placeholder="Auction For"
                    clearable
                    @change="getAuctionList2"
                ></v-select>
            </div>
            <div>
              <v-select 
                v-model="userId" 
                @change="getAuctionList2"
                :items="userData"
                item-value="id"
                item-text="name"
                dense
                outlined
                clearable
                placeholder="User"
              />
            </div>
            <div>
              <v-select 
                v-model="participatedId" 
                @change="getAuctionList2"
                :items="userData"
                item-value="id"
                item-text="name"
                dense
                outlined
                clearable
                placeholder="Participated User"
              />
            </div>
            <div class="btns-right">
              <v-select
                :items="auctionStatusList"
                v-model="auctionStatus2"
                item-value="id"
                item-text="text"
                label="Filter"
                outlined
                dense
                chips
                multiple
                :error-messages="errors"
                @change="getAuctionList2"
                class="d-inline-block mr-2 mb-0"
              ></v-select>
            </div>
          </div>
  
          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="auctionsData2"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:[`item.start_date_time`]="{ item }">
                    {{ item.start_date_time }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn fab small elevation="0" color="transparent" @click="gotoEdit(item.id)">
                      <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                    </v-btn>
                    <v-btn fab small elevation="0" color="transparent" @click="showAuctionDetails(item)" v-if="item.status !== 'Scheduled'">
                      <v-icon color="primary"> mdi-eye-outline </v-icon>
                    </v-btn>
                    <v-btn fab small elevation="0" color="transparent" @click="confirmDeleteAuction(item.id)">
                      <v-icon color="red"> mdi-delete-outline </v-icon>
                    </v-btn>
                     <!-- <v-btn fab small elevation="0" color="transparent" v-if="item.status != 'Scheduled'" @click="viewYourOngingAution(item)">
                        <v-icon color="primary">mdi-gavel</v-icon>
                      </v-btn> -->
                      <v-btn fab small elevation="0" color="transparent" v-if="item.status == 'Scheduled'" @click="confirmLiveAuction(item.auction_number)">
                        <v-icon color="primary">mdi-play</v-icon>
                      </v-btn>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <div class="d-flex">
                      <div v-if="item.status == 'Live'">
                        <v-chip class="ma-2 blink-2" color="red lighten-1" text-color="white" small>
                          <v-avatar left>
                            <v-icon>mdi-access-point-check</v-icon>
                          </v-avatar>
                          Live
                        </v-chip>
                      </div>
                      <div v-else-if="item.status == 'Closed'">
                        <v-icon color="primary" class="mr-1">mdi-check-bold</v-icon>
                        <span style="font-size: 1rem">Completed</span>
                      </div>
                      <div v-else-if="item.status == 'Scheduled'">
                        <v-icon color="primary" class="mr-1">mdi-tag-check-outline</v-icon>
                        <span style="font-size: 1rem">Upcoming</span>
                      </div>
                      <div v-else>
                        <!-- <v-icon color="primary" class="mr-1">mdi-tag-check-outline</v-icon> -->
                        <span style="font-size: 1rem">{{ item.status }}</span>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
  
        </div>
        <ViewAuctionDetailsModel 
        v-if="viewAuctionDetails"
        :data="singleAuctionData"
        @close="hideAuctionDetails"
      />
      </v-container-fluid>
      <ConformationBox
        v-if="isShowDeleteDialog"
        :title="'Confirmation'"
        message="Are you sure want to delete auction ?"
        @confirm="confirmDeleteForm"
        @cancel="closeDeleteFormModal"
      />
        <v-dialog v-model="isShowLiveDialog" max-width="400">
          <v-card>
            <v-card-title class="text-h6">
             Are you sure want to Live Auction ?
            </v-card-title>  
            <v-card-text>
            </v-card-text>
            <v-card-actions class="pb-4">
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirmLiveForm" class="font-weight-bold">
                Yes
              </v-btn>
              <v-btn color="grey darken-1" text @click="closeLiveFormModal">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </dashlayout>
  </template>
    
    <script>
  import dashlayout from "../layout/dashlayout.vue";
  import ConformationBox from "@/components/common/ConformationBox.vue";
  import ViewAuctionDetailsModel from "./ViewAuctionDetailsModel.vue";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  
  import {
    FOOTERPAGELIST,
    PAGINATIONOPTIONS,
    getEncodedStringBase64,
  } from "@/utils/helper";
  export default {
    components: { dashlayout, ConformationBox, ViewAuctionDetailsModel,DatePicker },
    name: "AuctionAddEdit",
    inject: ["mySpinner"],
    data: () => ({
      dialog: false,
      viewAuctionDetails: false,
      headers: [
        { text: "Auction No.", value: "auction_number" },
        { text: "Quantity", value: "quantity" },
        { text: "Raw Material", value: "category_name" },
        { text: "Expected/Base price", value: "expected_price" },
        { text: "Delivery", value: "delivery" },
        { text: "Start Date", value: "start_date_time" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", align: "right" },
      ],
      auctionsData2: [],
      total2: 0,
      options: PAGINATIONOPTIONS,
      footerList: {
        "items-per-page-options": [10, 20, 50, 100],
      },
      isShowDeleteDialog: false,
      deleteRoleId: "",
      auctionStatusList: [
        {
          text: "Scheduled",
          id: "Scheduled",
        },
        {
          text: "Live",
          id: "Live",
        },
        {
          text: "Completed",
          id: "Closed",
        },
        {
          text: "Confirmed",
          id: "Confirmed",
        },
        {
          text: "Accepted",
          id: "Accepted",
        },
        {
          text: "Purchase Order Generated",
          id: "Purchase Order Generated",
        },
      ],
      auctionStatus2: ["Scheduled", "Live", "Closed"],
      isShowLiveDialog: false,
      auction_number: '',
      dateFilter : [],
      userData : [],
      userId : '',
      participatedId : '',
      actionForData: [
      {
        key: "buy",
        value: "Buy",
      },
      {
        key: "sell",
        value: "Sell",
      },
    ],
    auction_for: "",
    }),
    created() {
      this.getAuctionList2();
      this.getUserDropdownData();
    },
    methods: {
      gotoAdd(id) {
        this.$router.push({ path: `/auctionAdd` });
      },
      gotoEdit(id) {
        this.$router.push({ path: `/auctionEdit/${id}` });
      },
      async getAuctionList2() {
        this.mySpinner.val = true;
        try {
          const response = await this.$store.dispatch("getAdminAuctionList", {
            ...this.options,
            status: this.auctionStatus2,
            action: "LIST",
            list_type: "participated",
            start_date : this.dateFilter.length > 0 ? this.dateFilter[0] != null ? this.dateFilter[0] : "" : "",
            end_date : this.dateFilter.length > 0 ? this.dateFilter[1] != null ? this.dateFilter[1] : "" : "",
            user_id : this.userId,
            participated_by : this.participatedId,
            auction_for : this.auction_for
          });
          if (response.http_status === 200) {
            this.auctionsData2 = response.data.data;
            this.total2 = response.data.total;
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      },
      confirmDeleteAuction(id) {
        this.isShowDeleteDialog = true;
        this.deleteRoleId = id;
      },
      closeDeleteFormModal() {
        this.isShowDeleteDialog = false;
        this.deleteRoleId = "";
      },
      async confirmDeleteForm() {
        try {
          this.mySpinner.val = true;
          const response = await this.$store.dispatch("deleteAuction", {
            id: this.deleteRoleId,
          });
          if (response.http_status === 200) {
            this.isShowDeleteDialog = false;
            this.deleteRoleId = "";
            this.getAuctionList2();
            this.$toasted.success(response.message);
            this.mySpinner.val = false;
          } else {
            this.isShowDeleteDialog = false;
            this.deleteRoleId = "";
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      },
      viewYourOngingAution(data) {
        this.$router.push({
          path: `/auction/participant/${getEncodedStringBase64(data.auction_number)}`,
        });
      },
      showAuctionDetails(data) {
        this.$router.push({
          path: `/auction/admindetails/${getEncodedStringBase64(data.auction_number)}`,
        });
      },
      hideAuctionDetails() {
        this.viewAuctionDetails = false
        this.singleAuctionData = null
      },
      confirmLiveAuction(auction_number) {
          this.isShowLiveDialog = true
          this.auction_number = auction_number
      },
      closeLiveFormModal() {
          this.isShowLiveDialog = false
          this.auction_number = ''
      },
      async confirmLiveForm() {
          try {
              this.mySpinner.val = true;
              const response = await this.$store.dispatch("changeStatusAuction", { 
                auction_number: this.auction_number,
                status: 'Live',
                organization_id: localStorage.getItem("selectedBranch"),
                user_id: localStorage.getItem("user_id"),
              });
              if (response.http_status === 200) {
                  this.isShowLiveDialog = false
                  this.auction_number = ''
                  this.getAuctionList2()
                  this.$toasted.success(response.message);
                  this.mySpinner.val = false;
              } else {
                  this.isShowLiveDialog = false
                  this.auction_number = ''
                  this.$toasted.error(response.message);
                  this.mySpinner.val = false;
              }
          } catch (e) {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          }
        },

        async getUserDropdownData() {
            this.mySpinner.val = true;
            try {
                const response = await this.$store.dispatch("getUserListData", {});
                if (response.http_status === 200) {
                    var temp = [];
                    response.data.forEach(ele => {
                        temp.push({
                        id: ele.id,
                        name: ele.first_name+' '+ele.last_name
                        });
                    });
                    this.userData = temp
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        }
    },
  };
  </script>
    
    <style>
  </style>