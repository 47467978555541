<template>
    <v-dialog v-model="addEditModule" width="500" persistent>
      <v-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(addEditUserModule)">
            <v-card-title class="dialog-title justify-space-between">
              {{ id !== "" ? "Edit" : "Add" }} Category
              <v-btn
                fab
                x-small
                elevation="0"
                color="transparent"
                @click="closeDialog"
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
  
            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="6" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Category name"
                    rules="required"
                  >
                    <label for=""
                      >Category Name<span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="categoryName"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Price"
                    rules="required"
                  >
                    <label for="">Price<span class="required">*</span></label>
                    <v-text-field
                      v-model="price"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="CV Value"
                    rules="required"
                  >
                    <label for="">CV Value<span class="required">*</span></label>
                    <v-text-field
                      v-model="cvValue"
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Category Image"
                    :rules="id == '' ? 'required' : ''"
                  >
                    <label for=""
                      >Category Image<span v-if="id == 'Add'" class="required">*</span></label
                    >
                    <v-file-input
                      outlined
                      dense
                      prepend-icon=""
                      prepend-inner-icon="mdi-paperclip"
                      :error-messages="errors"
                      truncate-length="15"
                      v-model="categoryImage"
                    ></v-file-input>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-4 px-4">
              <v-spacer></v-spacer>
              <div class="btns-right">
                <v-btn elevation="0" color="#9DA73E" dark type="submit">
                  {{ id !== "" ? "Edit" : "Add" }} Category
                </v-btn>
              </div>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </template>
   
  <script>
  import { required, digits, email, max, regex } from "vee-validate/dist/rules";
  import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
  } from "vee-validate";
  import { encryptPayload } from "@/utils/helper";
  setInteractionMode("eager");
  
  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits. ({_value_})",
  });
  
  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });
  
  extend("max", {
    ...max,
    message: "{_field_} may not be greater than {length} characters",
  });
  
  extend("regex", {
    ...regex,
    message: "{_field_} {_value_} does not match {regex}",
  });
  
  extend("email", {
    ...email,
    message: "Email must be valid",
  });
  export default {
    inject: ["mySpinner"],
    props: {
      id: "",
    },
    data: () => ({
      categoryName: "",
      userCategory: "",
      price: "",
      cvValue: "",
      categoryImage: null,
      addEditModule: true,
      productType: ["Seller", "Buyer", "Trader", "Admin"],
      parentModuleList: [],
    }),
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    async created() {
      if (this.id != "") {
        this.getUserRoleDetails();
      }
    },
    methods: {
      async addEditUserModule() {
        this.mySpinner.val = true;
        try {
          if (this.id != "") {
            const data = {
              id: this.id,
              name: this.categoryName,
              price: this.price,
              cv_value: this.cvValue,
            };
            const encryptData = encryptPayload(data);
            let formData = new FormData();
            formData.append("bfb_data", encryptData.bfb_data);
            if (this.categoryImage != null) {
              formData.append("category_image", this.categoryImage);
            }
            const response = await this.$store.dispatch(
              "updateAdminCategory",
              formData
            );
            if (response.http_status === 200 || response.http_status === 201) {
              this.$toasted.success(response.message);
              setTimeout(() => {
                this.$emit("cancel");
              }, 2000);
              this.mySpinner.val = false;
            } else {
              this.$toasted.error(response.message);
              this.mySpinner.val = false;
            }
          } else {
            const data = {
              name: this.categoryName,
              price: this.price,
              cv_value: this.cvValue,
            };
            const encryptData = encryptPayload(data);
            let formData = new FormData();
            formData.append("bfb_data", encryptData.bfb_data);
            if (this.categoryImage != null) {
              formData.append("category_image", this.categoryImage);
            }
            const response = await this.$store.dispatch(
              "createAdminCategory",
              formData
            );
            if (response.http_status === 200 || response.http_status === 201) {
              this.$toasted.success(response.message);
              setTimeout(() => {
                this.$emit("cancel");
              }, 2000);
              this.mySpinner.val = false;
            } else {
              this.$toasted.error(response.message);
              this.mySpinner.val = false;
            }
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      },
      // close dialog
      closeDialog() {
        this.$emit("cancel");
      },
      async getUserRoleDetails() {
        this.mySpinner.val = true;
        try {
          const payload = {
            id: this.id,
            action: "VIEW",
          };
          const response = await this.$store.dispatch(
            "viewAdminCategory",
            payload
          );
          if (response.http_status === 200 || response.http_status === 201) {
            this.categoryName = response.data.name;
            this.price = response.data.price;
            this.cvValue = response.data.cv_value;
            this.$toasted.success(response.data.message);
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } catch (e) {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        }
      },
    },
  };
  </script>
   
  <style></style>