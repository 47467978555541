<template>
  <v-dialog v-model="openDialg" width="1000" persistent>
      <v-card flat>
          <!-- <div class="box-title-with-btn mb-0">
              <div class="box-title">Organisations</div>
              <div class="btns-right"></div>
          </div> -->
          <v-card-title class="dialog-title justify-space-between"
        >{{ organizationId !=='' ? 'Edit' : 'Add' }} Branch
        <v-btn
          fab
          x-small
          elevation="0"
          color="transparent"
          @click="$emit('closeAddeddit')"
        >
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(updateUserOrganization)">
                  <v-row>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Business Type " rules="required">
                              <label for="">Business Type <span class="required">*</span></label>
                              <v-select :items="businessTypeList" v-model="businessType" outlined dense
                                  :error-messages="errors"></v-select>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Display Name" rules="required">
                              <label for="">Display Name<span class="required">*</span></label>
                              <v-text-field outlined dense :error-messages="errors" v-model="displayName"></v-text-field>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="GSTIN" rules="required">
                              <label for="">GSTIN<span class="required">*</span></label>
                              <v-text-field outlined dense :error-messages="errors" v-model="gstin"></v-text-field>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Registered Business Name" rules="required">
                              <label for="">Registered Business Name<span class="required">*</span></label>
                              <v-text-field outlined dense prepend-inner-icon="mdi-domain" :error-messages="errors"
                                  v-model="registerBusinessName"></v-text-field>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                              <label for="">Website URL</label>
                              <v-text-field outlined dense prepend-inner-icon="mdi-web" 
                                  v-model="websiteuUrl"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider v-slot="{ errors }" name="GST" :rules="organizationId == '' ? 'required' : ''">
                          <label for="">GST<span class="required">*</span></label>
                          
                          <v-file-input truncate-length="15" outlined dense prepend-inner-icon="mdi-attachment"
                              prepend-icon="" v-model="gst" :error-messages="errors" :append-icon="organizationId != '' ? 'mdi-download' : ''"  @click:append="downloadGstFile"></v-file-input>
                            </validation-provider>
                      </v-col>

                      <!-- <v-col cols="12" md="3">
                          <label for="">Company Logo</label>
                          <v-file-input truncate-length="15" outlined dense prepend-inner-icon="mdi-attachment"
                              prepend-icon="" v-model="companyLogo" append-icon="mdi-file-eye-outline"></v-file-input>
                      </v-col> -->
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Year Of Incorporation" rules="required">
                              <label for="">Year Of Incorporation<span class="required">*</span></label>
                              <v-text-field outlined dense :error-messages="errors"
                                  v-model="yearsOfCorporation" type="number"></v-text-field>
                          </validation-provider>
                      </v-col>
                  </v-row>
                  <v-row>
                      <!-- <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Material" rules="required">
                              <label for="">Material Type <span class="required">*</span></label>
                              <v-select :items="materialTypeList" v-model="materialType" item-value="id" item-text="name"
                                  outlined dense chips multiple :error-messages="errors"></v-select>
                          </validation-provider>
                      </v-col> -->
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Capacity" rules="required">
                              <label for="">Consupation / Production
                                  <span class="required">*</span></label>
                              <v-select :items="monthlyCapacityList" item-value="id" item-text="name"
                                  v-model="monthlyCapacity" outlined dense suffix="/Month"
                                  :error-messages="errors"></v-select>
                          </validation-provider>
                      </v-col>

                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Installed Capacity" rules="required">
                              <label for="">Installed Capacity<span class="required">*</span></label>
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon color="primary" dark v-bind="attrs" v-on="on" size="18">
                                          mdi-information-outline
                                      </v-icon>
                                  </template>
                                  <span>Please enter monthly installed capacity of
                                      production</span>
                              </v-tooltip>
                              <v-text-field outlined dense :error-messages="errors"
                                  v-model="installedCapacity"></v-text-field>
                          </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                          <validation-provider v-slot="{ errors }" name="Number of employees" rules="required">
                              <label for="">Number of employees<span class="required">*</span></label>
                              <v-text-field outlined dense :error-messages="errors" v-model="noOfEmployee" type="number"></v-text-field>
                          </validation-provider>
                      </v-col>
                      <!-- <v-col cols="12" md="6" class="pb-0" v-if="materialType.includes(materialOtherOptionId)">
                          <validation-provider v-slot="{ errors }" name="New Material Type" rules="required">
                              <label for="">New Material Type<span class="required">*</span></label>
                              <v-text-field outlined dense v-model="newMaterialType"
                                  :error-messages="errors"></v-text-field>
                          </validation-provider>
                      </v-col> -->
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                <div class="box-title-with-btn mb-0">
                  <div class="box-title">Address</div>
                  <div class="btns-right"></div>
                </div>
                <v-row>
                  <v-col cols="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address1"
                      rules="required"
                    >
                      <label for=""
                        >Address line1<span class="required">*</span></label
                      >
                      <v-text-field
                        outlined
                        dense
                        :error-messages="errors"
                        prepend-inner-icon="mdi-map-marker-outline"
                        v-model="address1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="3">
                    <label for="">Address line2</label>
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-map-marker-outline"
                      v-model="address2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Landmark/Area"
                      rules="required"
                    >
                      <label for=""
                        >Landmark / Area<span class="required">*</span></label
                      >
                      <v-text-field
                        outlined
                        dense
                        :error-messages="errors"
                        v-model="area"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <label for=""
                        >State<span class="required">*</span></label
                      >
                      <v-select
                        :items="stateItem"
                        v-model="state"
                        outlined
                        dense
                        :error-messages="errors"
                        item-value="id"
                        item-text="name"
                        @change="getCityDropdownData"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <label for=""
                        >City<span class="required">*</span></label
                      >
                      <v-autocomplete
                        :items="cityItem"
                        v-model="city"
                        outlined
                        dense
                        :error-messages="errors"
                        item-value="id"
                        item-text="name"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <label for=""
                        >Country<span class="required">*</span></label
                      >
                      <v-select
                        :items="countryItem"
                        readonly
                        v-model="country"
                        outlined
                        dense
                        :error-messages="errors"
                        item-value="id"
                        item-text="name"
                      >
                      </v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="3">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Pincode"
                      rules="required"
                    >
                      <label for=""
                        >pincode<span class="required">*</span></label
                      >
                      <v-text-field
                        outlined
                        dense
                        :error-messages="errors"
                        v-model="pinCode"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12" md="12">
                          <div class="text-right">
                              <v-btn elevation="0" color="#9DA73E" dark class="px-8" type="submit">
                                  Save
                              </v-btn>
                          </div>
                      </v-col>
                  </v-row>
                  
              </form>
          </validation-observer>
      </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import {
required,
digits,
email,
max,
min,
regex,
confirmed,
} from "vee-validate/dist/rules";
import {
extend,
ValidationObserver,
ValidationProvider,
setInteractionMode,
} from "vee-validate";
import axios from "axios";
import { UUID, AUTHENTIACTIONKEY,FILEDOWNALOADHEADER,API_URL ,COMMONPAYLOAD, encryptPayload } from "@/utils/helper";
setInteractionMode("eager");

extend("digits", {
...digits,
message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
...required,
message: "{_field_} can not be empty",
});

extend("max", {
...max,
message: "{_field_} may not be greater than {length} characters",
});
extend("min", {
...min,
message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
...regex,
message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
...email,
message: "Email must be valid",
});
extend("confirmed", {
...confirmed,
message: "Please make sure your password and confirm password are the same.",
});
export default {
  props : {
      organizationId : '',
      businessTypeList : [],
      stateItem : [],
      countryItem : [],
      monthlyCapacityList:[],
      materialTypeList:[],
      userId : ''
  },
  data() {
      return {
          openDialg : true,
          businessType: "",
          displayName: "",
          gstin: "",
          registerBusinessName: "",
          websiteuUrl: "",
          gst: null,
          companyLogo: null,
          yearsOfCorporation: "",
          monthlyCapacity: "",
          materialTypeList: [],
          materialType: [],
          newMaterialType: "",
          installedCapacity: "",
          noOfEmployee: "",

          address1: "",
          address2: "",
          area: "",
          city: "",
          state: "",
          country: 101,
          pinCode: "",
          reference_by: '',
          cityItem : [],
          user_addresses_id : ''
      }
  },
  inject: ["mySpinner"],
  components : {
      ValidationObserver,
      ValidationProvider,
  },
  created() {
    if(this.organizationId != '') {
      this.getOrganization()
    }
  },
  methods : {
    downloadGstFile() {
    this.mySpinner.val = true;
          axios({
              url : `${API_URL}get-file/user_gst_path/${this.organizationId}`,
              method : 'POST',
              COMMONPAYLOAD,
              headers : {...FILEDOWNALOADHEADER},
              responseType: 'blob',
          }).then((res) => {
              var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
              var docUrl = document.createElement('a');
              docUrl.href = FILE;
              var extension = res.headers['content-type'].split('/');
              docUrl.setAttribute('download', `${this.displayName}_gst.${extension[1]}`);
              document.body.appendChild(docUrl);
              docUrl.click();
              this.mySpinner.val = false;
          })
          .catch((e) => {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          });
  },
      async getCityDropdownData() {
          this.mySpinner.val = true;
          try {
              const payload = {
                  module: "CITY_LIST",
                  country_id: 101,
                  state_id: this.state,
              };
              const response = await this.$store.dispatch("loadDynamicData", payload);
              if (response.http_status === 200) {
                  this.cityItem = response.data.city_list;
                  this.mySpinner.val = false;
              } else {
                  this.$toasted.error(response.message);
                  this.mySpinner.val = false;
              }
          } catch (e) {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          }
      },
      async updateUserOrganization() {
        if(this.organizationId != '') {
          const data = {
            user_id : this.userId,
            uuid: UUID,
            authentication_key : AUTHENTIACTIONKEY,
            business_type : this.businessType,
            display_name : this.displayName,
            gst_number : this.gstin,
            registered_business_name : this.registerBusinessName,
            website_url : this.websiteuUrl,
            years_of_incorporation : this.yearsOfCorporation,
            monthly_capacity : this.monthlyCapacity,
            installed_capacity : this.installedCapacity,
            number_of_employees : this.noOfEmployee,
            address_line_1 : this.address1,
            address_line_2 : this.address2,
            district : this.area,
            city_id : this.city,
            state_id : this.state,
            country_id : this.country,
            pin_code : this.pinCode,
            organization_id : this.organizationId,
            user_address_id : this.user_addresses_id
          }
          let formData = new FormData();
          if (this.gst != null) {
              formData.append("gst_path", this.gst);
          }
          if (this.companyLogo != null) {
              formData.append("company_logo", this.companyLogo);
          }
          const encryptData = encryptPayload(data)
          formData.append('bfb_data',encryptData.bfb_data)
          this.mySpinner.val = true;
          try {
              const response = await this.$store.dispatch(
                  "updateBranches",
                  formData
              );
              if (response.http_status === 200) {
                  this.$toasted.success(response.message);
                  this.mySpinner.val = false;
                  this.$emit('successAddEdit')
              } else {
                  this.$toasted.error(response.message);
                  this.mySpinner.val = false;
              }
          } catch (e) {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          }
        }else{
          let formData = new FormData();
          const data = {
            user_id : this.userId,
            uuid: UUID,
            authentication_key : AUTHENTIACTIONKEY,
            business_type : this.businessType,
            display_name : this.displayName,
            gst_number : this.gstin,
            registered_business_name : this.registerBusinessName,
            website_url : this.websiteuUrl,
            years_of_incorporation : this.yearsOfCorporation,
            monthly_capacity : this.monthlyCapacity,
            installed_capacity : this.installedCapacity,
            number_of_employees : this.noOfEmployee,
            address_line_1 : this.address1,
            address_line_2 : this.address2,
            district : this.area,
            city_id : this.city,
            state_id : this.state,
            country_id : this.country,
            pin_code : this.pinCode,
          }
          if (this.gst != null) {
              formData.append("gst_path", this.gst);
          }
          if (this.companyLogo != null) {
              formData.append("company_logo", this.companyLogo);
          }
          const encryptData = encryptPayload(data)
          formData.append('bfb_data',encryptData.bfb_data)
          this.mySpinner.val = true;
          try {
              const response = await this.$store.dispatch(
                  "createBranches",
                  formData
              );
              if (response.http_status === 200) {
                  this.$toasted.success(response.message);
                  this.mySpinner.val = false;
                  this.$emit('successAddEdit')
              } else {
                  this.$toasted.error(response.message);
                  this.mySpinner.val = false;
              }
          } catch (e) {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          }
        }
          
      },
      async getOrganization() {
        this.mySpinner.val = true;
          try {
              const payload = {
                  action: "VIEW",
                  id: this.organizationId,
              };
              const response = await this.$store.dispatch("viewBranch", payload);
              if (response.http_status === 200) {
                this.businessType = response.data.business_type
                this.displayName = response.data.display_name
                this.gstin = response.data.gst_number
                this.registerBusinessName = response.data.registered_business_name
                this.websiteuUrl = response.data.website_url
                this.gst = response.data.gst_path
                this.companyLogo = response.data.company_logo
                this.yearsOfCorporation = response.data.years_of_incorporation
                this.materialType = response.data.material_type
                this.monthlyCapacity = response.data.monthly_capacity
                this.installedCapacity = response.data.installed_capacity
                this.noOfEmployee = response.data.number_of_employees
                this.address1 = response.data.address_line_1
                this.address2 = response.data.address_line_2
                this.area = response.data.district
                this.state = response.data.state_id
                this.country = response.data.country_id
                this.city = response.data.city_id
                this.pinCode = response.data.pin_code
                this.user_addresses_id = response.data.user_addresses_id
                this.getCityDropdownData()
                this.mySpinner.val = false;
              } else {
                  this.$toasted.error(response.message);
                  this.mySpinner.val = false;
              }
          } catch (e) {
              this.$toasted.error(e.message);
              this.mySpinner.val = false;
          }
      }
  }
}
</script>

<style></style>