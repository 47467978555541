<template>
  <v-dialog v-model="addusermodel" width="1080" persistent>
    <v-card>
      <v-card-title class="dialog-title justify-space-between"
        >Add User
        <v-btn
          fab
          x-small
          elevation="0"
          color="transparent"
          @click="closeDialog"
        >
          <v-icon color="white"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <div class="box-title-with-btn mb-0">
          <div class="box-title">Basic Info</div>
          <div class="btns-right"></div>
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateUserBasicInfo)">
            <v-row>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="First name"
                  rules="required"
                >
                  <label for=""
                    >First Name <span class="required">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    v-model="firstName"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Last name"
                  rules="required"
                >
                  <label for=""
                    >Last Name <span class="required">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    v-model="lastName"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <label for="">Email <span class="required">*</span></label>
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-email-outline"
                    v-model="email"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <!-- <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" name="gender" rules="required">
                  <label for="">Gender</label>
                  <v-select :items="ganderItem" v-model="gender" outlined dense  :error-messages="errors"></v-select>
                  </validation-provider>
                </v-col> -->
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone Number"
                  :rules="{
                    required: true,
                    digits: 10,
                  }"
                >
                  <label for=""
                    >Mobile No.<span class="required">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    prepend-inner-icon="mdi-cellphone"
                    :error-messages="errors"
                    v-model="phoneNumber"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Company Name"
                  rules="required"
                >
                  <label for=""
                    >Company Name <span class="required">*</span></label
                  >
                  <v-text-field
                    v-model="companyName"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <label for=""
                    >User Type <span class="required">*</span></label
                  >
                  <v-select
                    :items="userTypeList"
                    item-value="id"
                    item-text="name"
                    v-model="userType"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Capacity"
                  rules="required"
                >
                  <label for=""
                    >Consupation / Production
                    <span class="required">*</span></label
                  >
                  <v-select
                    :items="monthlyCapacityList"
                    item-value="id"
                    item-text="name"
                    v-model="monthlyCapacity"
                    outlined
                    dense
                    suffix="/Month"
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  name="Material"
                  rules="required"
                >
                  <label for=""
                    >Material Type <span class="required">*</span></label
                  >
                  <v-select
                    :items="materialTypeList"
                    v-model="materialType"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    chips
                    multiple
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="pb-0"
                v-if="materialType.includes(materialOtherOptionId)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="New Material Type"
                  rules="required"
                >
                  <label for=""
                    >New Material Type<span class="required">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="newMaterialType"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>

            <v-divider class="my-8"></v-divider>
            <div class="box-title-with-btn mb-0">
              <div class="box-title">Address</div>
              <div class="btns-right"></div>
            </div>
            <v-row>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Address1"
                  rules="required"
                >
                  <label for=""
                    >Address line1<span class="required">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    prepend-inner-icon="mdi-map-marker-outline"
                    v-model="address1"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <label for="">Address line2</label>
                <v-text-field
                  outlined
                  dense
                  prepend-inner-icon="mdi-map-marker-outline"
                  v-model="address2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Landmark/Area"
                  rules="required"
                >
                  <label for=""
                    >Landmark / Area<span class="required">*</span></label
                  >
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    v-model="area"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="State"
                  rules="required"
                >
                  <label for="">State<span class="required">*</span></label>
                  <v-select
                    :items="stateItem"
                    v-model="state"
                    outlined
                    dense
                    :error-messages="errors"
                    item-value="id"
                    item-text="name"
                    @change="getCityDropdownData"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="City"
                  rules="required"
                >
                  <label for="">City<span class="required">*</span></label>
                  <v-autocomplete
                    :items="cityItem"
                    v-model="city"
                    outlined
                    dense
                    :error-messages="errors"
                    item-value="id"
                    item-text="name"
                  ></v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <label for="">Country<span class="required">*</span></label>
                  <v-select
                    :items="countryItem"
                    readonly
                    v-model="country"
                    outlined
                    dense
                    :error-messages="errors"
                    item-value="id"
                    item-text="name"
                  >
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="Pincode"
                  rules="required"
                >
                  <label for="">pincode<span class="required">*</span></label>
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    v-model="pinCode"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="12">
                <div class="text-right">
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-8"
                    type="submit"
                  >
                    Save
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
 
 <script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  props: {
    id: "",
  },
  inject: ["mySpinner"],
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    phoneNumber: "",
    whatsappNumber: "",
    address1: "",
    address2: "",
    area: "",
    city: "",
    state: "",
    country: 101,
    pinCode: "",

    materialType: "",
    materialTypeList: [],
    userType: null,
    userTypeList: null,
    monthlyCapacity: null,
    monthlyCapacityList: null,
    companyName: "",
    newMaterialType: "",

    tab2: null,
    ganderItem: ["Male", "Female"],
    countryItem: [],
    stateItem: [],
    cityItem: [],

    addusermodel: true,
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  async created() {
    this.getDropdownData();
    // this.getStateDropdownData()
  },
  methods: {
    async updateUserBasicInfo() {
      this.mySpinner.val = true;
      try {
        const payload = {
          last_name: this.lastName,
          first_name: this.firstName,
          phone: this.phoneNumber,
          email: this.email,
          monthly_capacity: this.monthlyCapacity,
          company_name: this.companyName,
          user_type: this.userType,
          material_type: this.materialType,
          material_type_other: this.newMaterialType,
          address_line_1: this.address1,
          address_line_2: this.address2,
          district: this.area,
          pin_code: this.pinCode,
          city_id: this.city,
          state_id: this.state,
          country_id: this.country,
        };
        const response = await this.$store.dispatch(
          "createAdminUserProfile",
          payload
        );
        if (response.http_status === 200 || response.http_status === 201) {
          this.$toasted.success(response.message);
          setTimeout(() => {
            this.$emit("cancel");
          }, 2000);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    // close dialog
    closeDialog() {
      this.$emit("cancel");
    },
    async getDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module:
            "USER_TYPE,MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST,STATE_LIST",
          country_id: 101,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.monthlyCapacityList = response.data.monthly_capacity;
          this.materialTypeList = response.data.material_type;
          this.userTypeList = response.data.user_type;
          this.countryItem = response.data.country_list;
          this.stateItem = response.data.state_list;
          this.mySpinner.val = false;
          this.materialTypeList.map((item, index) => {
            if (item.name == "Other" || item.name == "other") {
              this.materialOtherOptionId = item.id;
            }
          });
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getStateDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = { module: "STATE_LIST", country_id: 101 };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.stateItem = response.data.state_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getCityDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module: "CITY_LIST",
          country_id: 101,
          state_id: this.state,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.cityItem = response.data.city_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>
 
 <style>
</style>