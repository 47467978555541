<template>
  <weblayout>
    <div class="banner-blog small-inner-banner">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="text-center my-0 mx-auto">
            <div class="text-h3 font-weight-medium white--text">Partners</div>
            <v-breadcrumbs large :items="items">
              <template v-slot:divider>
                <v-icon color="white">mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </div>
        </div>
      </v-container>
    </div>
    <v-container class="my-16 mt-6">
      <div class="blog-wrap partner-wrapper ">
        <v-row> 
          <v-col cols="12" md="12">
            <div class="m-title text-h6 mb-2"><strong>Boiler Partner</strong></div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="3">
            <v-card class="mx-auto blog-box">
              <div class="partner-img">
                <img src="@/assets/images/partner-2.png" alt="" />
              </div>
              <v-card-title>A J CORPORATION</v-card-title>

              <v-card-text class="text--primary">
                <div>
                  A J CORPORATION is a sincere pursuit to help industries with providing one stop solution for all types of heating equipment's sales, installation, commissioning, lesioning, operation and maintenance under one roof. A J CORPORATION is a team with more than 20 years of experience,
                </div>
              </v-card-text>

              <v-card-actions class="mb-3 ml-2">
                
                <v-btn color="#9da73e" outlined to="/ViewPartner"> Read More </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
         
      </div>
    </v-container>
  </weblayout>
</template>

<script>
import BlogCard from "../dashboard/blogs/BlogCard.vue";
import weblayout from "../layout/weblayout.vue";

export default {
  components: { weblayout, BlogCard },
  inject: ["mySpinner"],
  data() {
    return {
      tab: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Partners",
          disabled: true,
          href: "/partners",
        },
      ],
      blogData: [],
    };
  },
  created() {
    this.fetchBlogList();
  },
  methods: {
    gohome() {
      this.$router.push("/");
    },
    blog1() {
      this.$router.push("/nation-bioenergy-mission-2018");
    },
    blog2() {
      this.$router.push("/biofiels-and-government-policies-around-it");
    },
    blog3() {
      this.$router.push("/what-is-biofuel");
    },
    blog4() {
      this.$router.push("/carbon-credit-a-tool-to-incentive-use-of-biofuel");
    },

    async fetchBlogList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getAdminBlogList", {
          ...this.options,
          action: "LIST",
        });
        if (response.http_status === 200) {
          this.blogData = response.data.data;
          this.total = response.data.total;
          // this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>

<style>
</style>