<template>
  <v-app>
    <v-main class="website-wrapper">
      <v-toolbar class="elevation-0" style="border-bottom: 1px solid #e7e7e7">
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

        <div @click="$router.push('/')" v-ripple class="pointer">
          <img width="150px" src="@/assets/images/logo.png" alt="" />
        </div>

        <v-spacer></v-spacer>

        <v-tabs
          class="web-header mr-4 d-none d-md-block"
          color="#9DA73E"
        >
          <v-tab to="/">Home</v-tab>
          <v-tab to="/aboutus">About Us</v-tab>
          <v-tab to="/buyer">Buyer</v-tab>
          <v-tab to="/seller">Seller</v-tab>
          <v-tab to="/blogs">Blog</v-tab>
          <!-- <v-tab to="/partner">Partner</v-tab> -->
          <v-tab to="/contactus">Contact Us</v-tab>
          <v-tab to="/inquiry">Enquiry</v-tab>
        </v-tabs>
        <v-btn elevation="0" color="#E3EFCE" to="/login" v-if="isloggedin()">
          <v-icon class="mr-1">mdi-view-dashboard-outline</v-icon> Dashboard
        </v-btn>
        <v-btn elevation="0" color="#E3EFCE" to="/login" v-else>
          <v-icon class="mr-1">mdi-account-outline</v-icon> Login
        </v-btn>

        <v-btn class="d-block d-md-none" icon @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-toolbar>

      <v-navigation-drawer
        v-model="drawer"
        :mini-variant="miniVariant"
        :clipped="clipped"
        fixed
        app
        color="#231105"
        dark
      >
        <v-list>
          <v-list-item class="px-2 justify-center mb-4">
            <img src="@/assets/images/logo.png" alt="" style="width: 70%" />
          </v-list-item>
          <v-list-item link to="/">
            <v-list-item-action>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/aboutus">
            <v-list-item-action>
              <v-icon>mdi-text-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/buyer">
            <v-list-item-action>
              <v-icon>mdi-cart-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Buyer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/seller">
            <v-list-item-action>
              <v-icon>mdi-shopping-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Seller</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/blogs">
            <v-list-item-action>
              <v-icon>mdi-post-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/partner">
            <v-list-item-action>
              <v-icon>mdi-handshake-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Partner</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/contactus">
            <v-list-item-action>
              <v-icon>mdi-phone-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Contact Us</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/inquiry">
            <v-list-item-action>
              <v-icon>mdi-phone-outline</v-icon>
              </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Enquiry</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <slot></slot>

      <v-footer dark padless color="#3F2D1F" class="pa-16 pb-8">
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <div>
                <h3>Registered Address</h3>
                <div>
                  C243, Second Floor, SoBo Center, <br />
                  Gala Gymkhana Road, South Bopal, <br />
                  Ahmedabad, 380058.
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div>
                <h3>Email</h3>
                <div>
                  <a
                    class="white--text text-decoration-none"
                    href="mailto:support@biofuelbid.com"
                    >support@biofuelbid.com</a
                  >
                </div>
              </div>

              <div class="mt-8">
                <h3>Mobile</h3>
                <div>
                  <a
                    class="white--text text-decoration-none"
                    href="tel:+917600616767"
                    >+91 76006 16767</a
                  >
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="3">
              <div>
                <h3>Information</h3>
                <v-list-item-group class="ml-n4 mt-2">
                  <v-list-item link to="/aboutus">
                    <v-list-item-content>
                      <v-list-item-title>About us</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link to="/blogs">
                    <v-list-item-content>
                      <v-list-item-title>Blog</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link to="/contactus">
                    <v-list-item-content>
                      <v-list-item-title>Contact</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item link to="/inquiry">
                    <v-list-item-content>
                      <v-list-item-title>Enquiry</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </div>
            </v-col>

            <v-col cols="12" md="3">
              <div>
                <h3>Social Media</h3>

                <div class="mt-2">
                  <v-btn icon href="https://www.linkedin.com/company/sustineri-biofuel-solutions-llp/">
                    <v-icon> mdi-linkedin </v-icon>
                  </v-btn>
                  <v-btn icon :href="openMail">
                    <v-icon> mdi-gmail </v-icon>
                  </v-btn>
                </div>

                <div class="mt-8">
                  <h4 for="" class="ml-3 mb-1">Subscribe Us</h4>
                  <v-text-field
                    name="name"
                    id="id"
                    rounded
                    solo
                    light
                    append-icon="mdi-arrow-right-thin"
                  ></v-text-field>
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
              <div class="text-center grey--text mt-8">Powered by Sustineri Biofuel Solutions LLP © {{ this.year }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
  </v-app>
</template>
    
<script>
  export default {
    data() {
      return {
        drawer: false,
        tab: null,
        phone: "7600616767",
        mail: "support@biofuelbid.com",
        year: "",
        items: [
          {
            text: "Home",
            disabled: false,
            href: "/",
          },
          {
            text: "Blogs",
            disabled: true,
            href: "/blogs",
          },
        ],
      };
    },
    created() {
      this.year = new Date().getFullYear();
    },
    computed: {
      openMail() {
        return `mailto:${this.mail}`
      },
    },
    methods: {
      isloggedin(){
        if(localStorage.getItem('access_token')){
          return true
        }else{
          return false
        }
      }
    },
  };
</script>