<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2"> mdi-phone </v-icon>
            Contact Us
          </div>
          <div class="btns-right">
            <v-btn elevation="0" color="#9DA73E" dark text @click="gotoEdit">
              <v-icon class="mr-2"> mdi-arrow-left </v-icon>
              Back
            </v-btn>
          </div>
        </div>

        <v-row>
          <v-col cols="12" md="12">
            <v-data-table
              :headers="headers"
              :items="categoryData"
              :items-per-page="5"
              :options.sync="options"
              :server-items-length="total"
              class="elevation-0"
              :footer-props="footerList"
              dense
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  fab
                  small
                  elevation="0"
                  color="transparent"
                  @click="viewMessage(item.message)"
                >
                  <v-icon color="blue"> mdi-message-outline </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.document != null"
                  fab
                  small
                  elevation="0"
                  color="transparent"
                  @click="downloadContactUsFile(item.name, item.id)"
                >
                  <v-icon color="#8f9742"> mdi-download </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </v-container-fluid>
    <ViewContactUsMessage
      v-if="isShowMessage"
      :contactUsMessage="messageContent"
      @cancel="closeDialog"
    />
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import { FOOTERPAGELIST, PAGINATIONOPTIONS } from "@/utils/helper";
import ConformationBox from "@/components/common/ConformationBox.vue";
import AddEditCategory from "@/components/dashboard/AddEditCategory.vue";
import ViewContactUsMessage from "@/components/dashboard/ViewContactUsMessage.vue";
import axios from "axios";
import { API_URL, FILEDOWNALOADHEADER, COMMONPAYLOAD } from "@/utils/helper";
export default {
  components: {
    dashlayout,
    ConformationBox,
    AddEditCategory,
    ViewContactUsMessage,
  },
  name: "CategoryList",
  inject: ["mySpinner"],
  methods: {
    async fetchContactUsList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getAdminContactUsList", {
          ...this.options,
          action: "LIST",
        });
        if (response.http_status === 200) {
          this.categoryData = response.data.data;
          this.total = response.data.total;
          // this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    closeDialog() {
      this.isShowMessage = false;
      // this.fetchContactUsList()
      this.messageContent = "";
    },

    viewMessage(data) {
      this.messageContent = data;
      this.isShowMessage = true;
    },

    async downloadContactUsFile(name, id) {
      const payload = {
        id: id,
        path: "contact_us_path",
      };
      this.mySpinner.val = true;
      axios({
        url: `${API_URL}get-file/contact_us_path/${id}`,
        method: "POST",
        COMMONPAYLOAD,
        headers: { ...FILEDOWNALOADHEADER },
        responseType: "blob",
      })
        .then((res) => {
          var FILE = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          // console.log(FILE)
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          var extension = res.headers["content-type"].split("/");
          docUrl.setAttribute("download", `${name}.${extension[1]}`);
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
      // try {
      //     this.mySpinner.val = true;
      //     const res = await this.$store.dispatch("downloadFile", payload);
      //     // console.log(res)
      //     // console.log(res.type)

      //         if(res.headers['content-type'] == 'application/json'){
      //             this.$toasted.error('File not found');
      //         }
      //     else{
      //             var extension = res.headers['content-type'].split('/');
      // 			var fileName = 'contactUs'+'.'+ extension[1];
      // 			const url = window.URL.createObjectURL(new Blob([res],{ type:res.headers['content-type']  }));
      // 			const link = document.createElement('a');
      // 			link.href = url;
      // 			link.setAttribute('download', fileName);
      // 			link.click();
      //     this.mySpinner.val = false;

      //     }

      // } catch (e) {
      //     this.$toasted.error(e.message);
      //     this.mySpinner.val = false;
      // }
    },
  },
  created() {
    this.fetchContactUsList();
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchContactUsList();
        }
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,

    isShowMessage: false,

    categoryData: [],
    total: 0,

    options: PAGINATIONOPTIONS,
    footerList: {
      "items-per-page-options": [10, 20, 50, 100],
    },

    addEditRole: false,
    editUserRoleId: "",

    isShowDeleteDialog: false,
    deleteRoleId: "",

    headers: [
      { text: "Name", value: "name" },
      { text: "email", value: "email" },
      { text: "subject", value: "subject" },
      { text: "Created At", value: "created_at" },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
  }),
};
</script>
  
<style></style>