<template>
    <div ref="loader" class="loading-page" id="cover-spin">
    </div>
</template>

<script>
export default {
    // props : {
    //     start
    // },
    data () {
        return {
            loading: false,
        }
    }
}
</script>

<style scoped>
/*---- COVER-SPIN - START -----*/
#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    /*background-color: rgba(9,30,66,0.4);*/
    background-color: rgba(255,255,255,0.3);
    z-index:99999;
}

@-webkit-keyframes spin {
    from {-webkit-transform:rotate(0deg);}
    to {-webkit-transform:rotate(360deg);}
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}
/*---- COVER-SPIN - END -----*/
</style>
