<template>
  <weblayout>
    <div class="banner-blog small-inner-banner">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="text-center my-0 mx-auto">
            <div class="text-h3 font-weight-medium white--text">Buyer</div>
            <v-breadcrumbs large :items="items">
              <template v-slot:divider>
                <v-icon color="white">mdi-forward</v-icon>
              </template>
            </v-breadcrumbs>
          </div>
        </div>
      </v-container>
    </div>
    <v-container class="my-16">
      <div class="blog-wrap">
        <v-row>
          <v-col cols="12">
            <p>
              Biofuel bid welcomes all buyers to place their bid on the platform. Buyers can be bio coal consumers and traders looking to procure bio coal on regular basis or on occasional basis. Our platform delivers following benefits to buyers 
            </p>

            <div>
              <ul class="bullet mt-10">
                <li>Competitive pricing alternatives</li>
                <li>Broader Seller profiles availability</li>
                <li>Documentation support for entire transaction</li>
                <li>Additional support for the associates like transportation, insurance, testing and other service providers</li>
                <li>Eligibility for ESG Compliance and Carbon Credit documentation</li>
                <li>Real time update on the market pricing and available different nature of bio coals</li>
                <li>Upfront availability for fulfilling urgent need to bio coal</li>
              </ul>
            </div>
          </v-col>

        
          <v-container class="my-16">
            <v-row>
              <v-col cols="12">
                <div class="m-title text-center">
                  Biofuel <strong>Products</strong>
                </div>
              </v-col>
            </v-row>
            <VueSlickCarousel v-bind="settings">
              <div>
                <v-card class="mx-auto blog-box">
                  <v-img class="white--text align-end" height="200px" src="@/assets/images/saw-dust.png">
                  </v-img>

                  <div class="titile-and-price-section main-title">
                    <div class="left-t">
                      <div class="card-title">Saw Dust</div>
                    </div>
                    <div class="right-pr text-h5 font-weight-bold">
                      ₹ 8000-9000 / MT
                      <v-chip color="#d7dbb6" class="d-block ml-auto" small
                        style="width: fit-content;">March-2023</v-chip>
                    </div>
                  </div>

                  <div class="titile-and-price-section">
                    <div class="left-t">
                      <span>CV Value</span>
                    </div>
                    <div class="right-pr font-weight-bold">
                      <span>4000</span>
                    </div>
                  </div>

                  <v-divider></v-divider>

                  <v-card-text class="text--primary">
                    <div class="text-right">
                      <span>Price can be vary </span>
                      <a href="#">Term & Condition</a>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div>
                <v-card class="mx-auto blog-box">
                  <v-img class="white--text align-end" height="200px" src="@/assets/images/peanut-shell.png">
                  </v-img>

                  <div class="titile-and-price-section main-title">
                    <div class="left-t">
                      <div class="card-title">Ground Nut Shell</div>
                    </div>
                    <div class="right-pr text-h5 font-weight-bold">₹ 8500-9500 / MT
                      <v-chip color="#d7dbb6" class="d-block ml-auto" small
                        style="width: fit-content;">March-2023</v-chip>
                    </div>
                  </div>
                  <div class="titile-and-price-section">
                    <div class="left-t">
                      <span>CV Value</span>
                    </div>
                    <div class="right-pr font-weight-bold">
                      <span>4200</span>
                    </div>
                  </div>

                  <v-divider></v-divider>

                  <v-card-text class="text--primary">
                    <div class="text-right">
                      <span>Price can be vary </span>
                      <a href="#">Term & Condition</a>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div>
                <v-card class="mx-auto blog-box">
                  <v-img class="white--text align-end" height="200px" src="@/assets/images/agri-waste.png">
                  </v-img>

                  <div class="titile-and-price-section main-title">
                    <div class="left-t">
                      <div class="card-title">Agri Waste</div>
                    </div>
                    <div class="right-pr text-h5 font-weight-bold">₹ 5000-6000 / MT
                      <v-chip color="#d7dbb6" class="d-block ml-auto" small
                        style="width: fit-content;">March-2023</v-chip>
                    </div>
                  </div>

                  <div class="titile-and-price-section">
                    <div class="left-t">
                      <span>CV Value</span>
                    </div>
                    <div class="right-pr font-weight-bold">
                      <span>3300</span>
                    </div>
                  </div>

                  <v-divider></v-divider>

                  <v-card-text class="text--primary">
                    <div class="text-right">
                      <span>Price can be vary </span>
                      <a href="#">Term & Condition</a>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div>
                <v-card class="mx-auto blog-box">
                  <v-img class="white--text align-end" height="200px" src="@/assets/images/cotton.png">
                  </v-img>

                  <div class="titile-and-price-section main-title">
                    <div class="left-t">
                      <div class="card-title">Cotton Husk</div>
                    </div>
                    <div class="right-pr text-h5 font-weight-bold">₹ 5000-6000 / MT
                      <v-chip color="#d7dbb6" class="d-block ml-auto" small
                        style="width: fit-content;">March-2023</v-chip>
                    </div>
                  </div>

                  <div class="titile-and-price-section">
                    <div class="left-t">
                      <span>CV Value</span>
                    </div>
                    <div class="right-pr font-weight-bold">
                      <span>3500</span>
                    </div>
                  </div>

                  <v-divider></v-divider>

                  <v-card-text class="text--primary">
                    <div class="text-right">
                      <span>Price can be vary </span>
                      <a href="#">Term & Condition</a>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div>
                <v-card class="mx-auto blog-box">
                  <v-img class="white--text align-end" height="200px" src="@/assets/images/jeera.webp">
                  </v-img>

                  <div class="titile-and-price-section main-title">
                    <div class="left-t">
                      <div class="card-title">Jeera</div>
                    </div>
                    <div class="right-pr text-h5 font-weight-bold">₹ 5000-6000 / MT
                      <v-chip color="#d7dbb6" class="d-block ml-auto" small
                        style="width: fit-content;">March-2023</v-chip>
                    </div>
                  </div>

                  <div class="titile-and-price-section">
                    <div class="left-t">
                      <span>CV Value</span>
                    </div>
                    <div class="right-pr font-weight-bold">
                      <span>3500</span>
                    </div>
                  </div>

                  <v-divider></v-divider>

                  <v-card-text class="text--primary">
                    <div class="text-right">
                      <span>Price can be vary </span>
                      <a href="#">Term & Condition</a>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </VueSlickCarousel>

          </v-container>
        </v-row>
      </div>
    </v-container>
  </weblayout>
</template>

<script>
import weblayout from "../layout/weblayout.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"
import {generateTheMetaInfo} from '@/utils/helper'
export default {
  components: { weblayout, VueSlickCarousel },
  data() {
    return {
      tab: null,
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Buyer",
          disabled: true,
          href: "/buyer",
        },
      ],
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  metaInfo () {
    return generateTheMetaInfo('Buyer')
  },
  methods: {
    gohome() {
      this.$router.push("/");
    },
    viewblog() {
      this.$router.push("/blogview");
    },
  },
};
</script>

<style>
</style>