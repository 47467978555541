<template>
    <dashlayout>
      <v-container-fluid fluid>
        <div class="dash-bg">
          <div class="box-title-with-btn">
            <div class="box-title">
              
            </div>
            <div class="btns-right">
              
            </div>
          </div>
  
            <v-row>
              <v-col cols="12" md="12">
                <h2>Work In Progress</h2>
              </v-col>
            </v-row>
        </div>
      </v-container-fluid>
      <!-- <v-dialog v-model="dialog" max-width="400">
        <v-card>
          <v-card-title class="text-h6">
            Are you sure want to delete ?
          </v-card-title>
  
          <v-card-text> Lorem ipsum dolor sit amet consectetur. </v-card-text>
  
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
  
            <v-btn color="grey darken-1" text @click="dialog = false"> No </v-btn>
  
            <v-btn
              color="red darken-1"
              text
              @click="dialog = false"
              class="font-weight-bold"
            >
              Yes, Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addEditModule" width="450" persistent>
        <v-card>
          <v-card-title class="dialog-title justify-space-between"> Add User Role
          <v-btn fab x-small elevation="0" color="transparent" @click="addEditModule=false">
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>  
          </v-card-title>
  
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <label for="">Role Name</label>
                <v-text-field
                  v-model="userpassword"
                  outlined
                  dense
                  hide-details=""
                  :error-messages="errors"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <label for="">User Category</label>
                <v-text-field
                  v-model="userpassword"
                  outlined
                  dense
                  hide-details=""
                  type="password"
                  :error-messages="errors"
                ></v-text-field>
              </v-col>
   
              
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <div class="btns-right">
              <v-btn
                elevation="0"
                color="#9DA73E"
                dark
                large
                @click="addEditModule=false"
              >
                Add 
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      
    </dashlayout>
  </template>
  
  <script>
  import dashlayout from "../layout/dashlayout.vue";
  export default {
    components: { dashlayout },
    name: "BlogList",
    
  };
  </script>
  
  <style>
  </style>