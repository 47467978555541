<template>
  <weblayout>
    <div class="banner-blog">
      <v-container>
        <div class="wrapper-of-banner">
          <div class="title-of-blog">
            Carbon Credit : A Tool to incentive use of Biofuel
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
                Darshan Baraiya
                <div class="grey--text body-2">On 07/03/2022 - 18:00PM</div>
              </div>
            </div>
          </div>

          <div class="img-of-blog">
            <!-- <div class="next-prev-btn justify-end">
              <v-btn text dark>
                <v-icon> mdi-chevron-left </v-icon>
                Prev Blog
              </v-btn>
              <v-btn text dark>
                Next Blog
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </div> -->
            <img
              style="max-width: 600px"
              src="@/assets/images/carbon_credit.png"
              alt=""
            />
          </div>
        </div>
      </v-container>
    </div>

    <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12">
            <div class="about-wrap">
              <p class="mt-10"><strong>What is Biofuel </strong></p>

              <p>
                Carbon credits and carbon markets are a component of national and international attempts to mitigate the growth in concentrations of greenhouse gases in the atmosphere. A carbon credit (often called a carbon offset) is a credit for greenhouse emissions reduced or removed from the atmosphere by an emission reduction project, which can be used by governments, industry, or private individuals to compensate for the emissions they generate elsewhere. Since GHG mitigation projects generate credits, this approach can be used to finance carbon reduction schemes between trading partners around the world.
              </p>

              <p>
                One carbon credit is equal to one metric ton of carbon dioxide, or in some markets, carbon dioxide equivalent gases (CO2-eq), and are bought and sold through international brokers, online retailers, and trading platforms. Businesses that find it difficult to comply with the carbon emission requirements can purchase carbon credits to offset their emissions by making finance readily available to renewable energy projects, forest protection, and reforestation projects around the world. Projects which sell carbon credits include wind, solar, geothermal, and biomass which replace fossil-fuel-powered plants. 
              </p>

              <p>
                Offsetting one metric ton of carbon means that there will be one less Mt of carbon dioxide in the atmosphere than there would otherwise have been. The Kyoto Protocol provides for three mechanisms that enable countries, or operators in developed countries, to acquire greenhouse gas reduction credits:
              </p>

              <p>
                Carbon credit is a mechanism that allows companies and organizations to offset their greenhouse gas emissions by financing or investing in projects that reduce emissions elsewhere. One carbon credit is typically equal to one metric ton of CO2 emissions that have been avoided, reduced, or removed from the atmosphere. 
              </p>

              <p>Here are some advantages of carbon credits to business:</p>

              <p><strong>Meeting sustainability goals :</strong></p>
              <p>
                Carbon credits help companies to meet their sustainability goals and reduce their carbon footprint by offsetting their emissions. This is particularly important for companies that are under pressure from consumers, investors, and regulators to reduce their environmental impact. 
              </p>

              <p><strong>Cost-effective :</strong></p>
              <p>
                Carbon credits can be a cost-effective way for companies to reduce their emissions, as they can often be obtained at a lower cost than implementing emission reduction measures in-house. 
              </p>

              <p><strong>Improved reputation :</strong></p>
              <p>
                Companies that use carbon credits to offset their emissions can improve their reputation and enhance their brand value as they are seen as taking action to reduce their environmental impact. 
              </p>

              <p><strong>Compliance :</strong></p>
              <p>
                Carbon credits can also help companies to comply with emissions regulations in countries or regions where they operate, and reduce the risk of penalties or other legal action. 
              </p>

              <p><strong>Access to new markets :</strong></p>
              <p>
                Some carbon credit programs allow companies to sell their credits on carbon markets, providing them with access to new revenue streams and market opportunities. 
              </p>

              <p><strong>Supporting sustainable development :</strong></p>
              <p>
                Many carbon credit projects support sustainable development by promoting renewable energy, energy efficiency, and other measures that benefit local communities
              </p>

              <p><strong>New economic resources for industries are created :</strong></p>
              <p>
                Companies lower their emissions because there is a low cost for implementation. Companies with emissions credits can sell them for extra profit. This creates economic resources, because more is spent to lower emissions and the credits are a new product to be purchased for additional profits
              </p>
              <p>
                However carbon credit have some inherent limitations like Some credits may be artificially high in price. Many environmental agencies have discovered that they can purchase these credits and choose not to use them and hold them indefinitely, creating artificially high credit prices. Emissions credits are almost always cheaper than converting. For industries that use fossil fuels, the cost of converting to renewable resources can be very high.            
              </p>
              <p>
                The emissions credits, offsets, and even penalties and fines for exceeding a cap limit, often are all cheaper than going through a conversion to a new source of energy. This means there is no real incentive for those industries to change their practices. It is relatively easy to cheat the system. Most industries do not have monitoring devices installed to determine how much output is really occurring. This makes it very easy for the average business to cheat on its emissions reports. 
              </p>
              <p>
                Different nations may have different standards for a maximum cap. Some nations create more emissions than others. This means a maximum cap will be defined differently in every society. Some countries may be very lenient about their emissions caps and credits. Others may be very strict.
              </p>
              <p>
                Overall, carbon credits can provide significant advantages to corporates by helping them to reduce their carbon footprint, meet sustainability goals, improve their reputation, comply with regulations, access new markets, and support sustainable development
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-divider></v-divider>
            <div class="auther mt-5">
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
              <div class="auther-name">
               Darshan Baraiya
                <div class="grey--text body-2">07/03/2022</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </weblayout>
</template>
  
  <script>
import weblayout from "../layout/weblayout.vue";

export default {
  components: { weblayout },
  data() {
    return {
      tab: null,
      items: [
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
        {
          src: require("@/assets/images/slider-1.jpg"),
        },
      ],
    };
  },
};
</script>
  
  <style>
</style>