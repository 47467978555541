<template>
  <DashLayout>
    <div>
      <v-container-fluid fluid>
        <div class="dash-bg mb-4">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2"> mdi-gavel </v-icon>
            Live Auction
          </div>

          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12" class="pb-1">
                <div
                  class="auction-list-wrap"
                  v-if="liveAuctionList.length > 0"
                >
                  <div
                    class="auction-box"
                    v-for="(data, index) in liveAuctionList"
                    :key="index"
                  >
                    <div class="auction-left-part">
                      <v-avatar
                        class="mr-3 white--text font-weight-bold blink-2"
                        color="red"
                        size="40"
                      >
                        <v-icon color="white">mdi-access-point-check</v-icon>
                      </v-avatar>
                      <div class="name-of-company mr-4">
                        {{ data.auction_number }} - {{ data.category_name }}
                      </div>

                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-1">Quantity</div>
                        <div class="last-bid-box font-weight-bold">
                          {{ data.quantity }} Tonne
                        </div>
                      </div>

                      <v-chip
                        outlined
                        class="mr-2"
                        color="#9da73e"
                        text-color="#9da73e"
                        style="font-size: 1rem !important; font-weight: bold"
                      >
                        <v-icon left> mdi-clock-outline </v-icon>
                        <countdown
                          :time="calculateTime(data.end_date_time)"
                          :transform="transformSlotProps"
                        >
                          <template slot-scope="props">
                            {{ props.hours }} : {{ props.minutes }} :
                            {{ props.seconds }}
                          </template>
                        </countdown>
                        &nbsp;left
                      </v-chip>
                    </div>
                    <div class="auction-right-part">
                      <v-btn
                        v-if="countTimerArray[index]"
                        @click="participateForAuction(data)"
                        elevation="0"
                        color="#9DA73E"
                        dark
                        class="px-2 ml-2"
                      >
                        <v-icon class="mr-2"> mdi-check </v-icon>
                        Participate
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="auction-list-wrap" v-else>No Data</div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="dash-bg mb-4">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2"> mdi-gavel </v-icon>
            Upcoming Auction
          </div>

          <div class="four-box-of-status">
            <v-row>
              <v-col cols="12" md="12" class="pb-1">
                <div
                  class="auction-list-wrap"
                  v-if="upcomingAutcionList.length > 0"
                >
                  <div
                    class="auction-box"
                    v-for="(data, index) in upcomingAutcionList"
                    :key="index"
                  >
                    <div class="auction-left-part">
                      <v-avatar
                        class="mr-3 white--text font-weight-bold"
                        color="primary"
                        size="40"
                      >
                        <v-icon color="white">mdi-tag-check-outline</v-icon>
                      </v-avatar>
                      <div class="name-of-company mr-4">
                        {{ data.auction_number }} - {{ data.category_name }}
                      </div>

                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-1">Quantity</div>
                        <div class="last-bid-box font-weight-bold">
                          {{ data.quantity }} Tonne
                        </div>
                      </div>

                      <div class="last-bid-section mr-4">
                        <div class="last-bid-label mr-1">Start Date</div>
                        <div class="last-bid-box font-weight-bold">
                          <v-icon> mdi-calendar-blank-outline </v-icon>
                          {{ data.start_date_time }}
                        </div>
                      </div>
                    </div>
                    <div class="auction-right-part">
                      <v-btn
                        @click="showAuctionDetails(data)"
                        elevation="0"
                        color="#9DA73E"
                        dark
                        text
                        class="px-2 ml-2"
                      >
                        View Details
                      </v-btn>
                      <v-btn
                        v-if="!data.auction_participant_id != ''"
                        @click="notifyForAuction(data)"
                        elevation="0"
                        color="#9DA73E"
                        dark
                        class="px-2 ml-2"
                      >
                        <v-icon class="mr-2"> mdi-bell-check-outline </v-icon>
                        Notify me
                      </v-btn>
                      <v-btn
                        v-else
                        elevation="0"
                        color="#9DA73E"
                        dark
                        class="px-2 ml-2"
                      >
                        <v-icon class="mr-2"> mdi-bell-check-outline </v-icon>
                        {{ data.participants_count }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="auction-list-wrap" v-else>No Data</div>
                <div class="text-center">
                  <v-btn
                    small
                    outlined
                    color="primary"
                    @click="$router.push('upcomingAuctionList')"
                    >View All</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="dash-bg mb-4">
          <div class="blog-wrap">
            <v-row>
              <v-col cols="12">
                <div class="box-title text-left mb-0">
                  <v-icon size="30" color="#9DA73E" class="mr-2">
                    mdi-chart-box-outline
                  </v-icon>
                  Products
                </div>
              </v-col>
              <v-col cols="12">
                <VueSlickCarousel
                  v-bind="settings"
                  v-if="categoryData.length > 0"
                >
                  <div v-for="item in categoryData" :key="item.id">
                    <v-card class="mx-auto blog-box">
                      <v-img
                        class="white--text align-end"
                        height="200px"
                        :src="item.image_path"
                      >
                      </v-img>

                      <div class="titile-and-price-section main-title">
                        <div class="left-t">
                          <div class="card-title">{{ item.name }}</div>
                        </div>
                        <div class="right-pr text-h5 font-weight-bold">
                          ₹ {{ item.price }} / MT
                          <v-chip
                            color="#d7dbb6"
                            class="d-block ml-auto"
                            small
                            style="width: fit-content"
                            >{{ currentMonthYear }}</v-chip
                          >
                        </div>
                      </div>

                      <div class="titile-and-price-section">
                        <div class="left-t">
                          <span>CV Value</span>
                        </div>
                        <div class="right-pr font-weight-bold">
                          <span>{{ item.cv_value }}</span>
                        </div>
                      </div>

                      <v-divider></v-divider>

                      <v-card-text class="text--primary">
                        <div class="text-right">
                          <span>Price can be vary </span>
                          <a href="/termcondition">Term & Condition</a>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </VueSlickCarousel>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="dash-bg mb-4">
          <div class="blog-wrap">
            <v-row>
              <v-col cols="12">
                <div class="box-title text-left mb-0">
                  <v-icon size="30" color="#9DA73E" class="mr-2">
                    mdi-post-outline
                  </v-icon>
                  Blogs
                </div>
              </v-col>

              <v-col cols="12">
                <VueSlickCarousel v-bind="settings" v-if="blogData.length > 0">
                  <template v-if="blogData.length > 0">
                    <div v-for="data in blogData" :key="data.slug">
                      <BlogCard :blogInfo="data" :key="data.id" />
                    </div>
                  </template>
                </VueSlickCarousel>
              </v-col>
              <template v-if="blogData.length == 0">
                <v-row>
                  <v-col cols="12" class="pb-10">
                    <h3 class="text-center">No Blog Found</h3>
                  </v-col>
                </v-row>
              </template>
              <v-col cols="12" v-if="blogData.length > 0">
                <div class="text-center">
                  <v-btn to="/blogs" color="#9da73e" dark> View All </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container-fluid>
    </div>
    <ViewAuctionDetailsModel
      v-if="viewAuctionDetails"
      :data="singleAuctionData"
      @close="hideAuctionDetails"
    />
  </DashLayout>
</template>
  
<script>
import DashLayout from "@/components/layout/dashlayout.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import BlogCard from "./blogs/BlogCard.vue";
import ViewAuctionDetailsModel from "./ViewAuctionDetailsModel.vue";
import { getEncodedStringBase64 } from "@/utils/helper";

export default {
  name: "dashboard",
  inject: ["mySpinner"],
  data() {
    return {
      settings: {
        vertical: false,
        dots: true,
        // dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      blogData: [],
      categoryData: [],
      currentMonthYear: "",
      viewAuctionDetails: false,

      liveAuctionList: [],
      upcomingAutcionList: [],

      singleAuctionData: null,
      countTimerArray: [],
    };
  },

  components: {
    DashLayout,
    VueSlickCarousel,
    BlogCard,
    ViewAuctionDetailsModel,
  },
  created() {
    this.getBlogList();
    this.getCategoryList();

    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date = new Date();

    this.currentMonthYear = months[date.getMonth()] + " " + date.getFullYear();
    this.getAuctionListList();
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });
      return formattedProps;
    },
    async getBlogList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getDashboardBlogList", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.blogData = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getCategoryList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getSliderCategoryList", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.categoryData = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getAuctionListList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getDashboardAuctionList", {
          search: "",
          action: "LIST",
        });
        if (response.http_status === 200) {
          this.liveAuctionList = response.data.live;
          this.upcomingAutcionList = response.data.upcoming.data;
          for (let i = 0; i < this.liveAuctionList.length; i++) {
            this.countTimerArray[i] = true;
          }
          this.mySpinner.val = false;
          this.checkTimer();
          setInterval(() => {
            this.checkTimer();
          }, 10000);
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    checkTimer() {
      for (let i in this.liveAuctionList) {
        const now = new Date();
        const endDate = new Date(this.liveAuctionList[i].end_date_time);
        const diff = endDate.getTime() - now.getTime();
        var seconds = diff / 1000;
        if (seconds < 60 * 1) {
          this.countTimerArray[i] = false;
        }
      }
    },
    calculateTime(data) {
      const now = new Date();
      const endDate = new Date(data);
      return endDate - now;
    },
    showAuctionDetails(data) {
      this.viewAuctionDetails = true;
      this.singleAuctionData = data;
    },
    hideAuctionDetails() {
      this.viewAuctionDetails = false;
      this.singleAuctionData = null;
    },
    async notifyForAuction(data) {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("auctionAddParticipants", {
          auction_number: data.auction_number,
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.getAuctionListList();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    participateForAuction(data) {
      // if(data.auction_for == 'buy'){
      //   this.$router.push({path:`/liveauction/${getEncodedStringBase64(data.auction_number)}`})
      // }
      // if(data.auction_for == 'sell'){
      this.$router.push({
        path: `/auction/participant/${getEncodedStringBase64(
          data.auction_number
        )}`,
      });
      // }
    },
  },
};
</script>
  
<style></style>