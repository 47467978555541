<template>
	<dashlayout>
		<v-container-fluid fluid>
			<div class="dash-bg">
				<div class="box-title-with-btn">
					<div class="box-title">
						<v-icon size="24" color="#9DA73E" class="mr-2">
						mdi-book-edit-outline
						</v-icon>
						Direct Purchase Order
					</div>
					<div class="btns-right">
						<DatePicker 
							v-model="dateFilter" 
							range 
							@change="getDirectPurchaseOrders" 
							format="YYYY-MM-DD" 
							valueType="YYYY-MM-DD"
							clearable
							class="mr-3"
						/>
						<v-text-field
							v-model="search"
							outlined
							type="text"
							dense
							placeholder="Search..."
							@keypress.enter="getDirectPurchaseOrders"
							class="d-inline-block mr-2 mb-0"
						></v-text-field>
						<v-btn elevation="0" color="#9DA73E" dark class="px-2" @click="gotoAdd">
							<v-icon> mdi-plus </v-icon> Add Purchase Order
						</v-btn>
					</div>
				</div>

				<div class="four-box-of-status">
					<v-row>
						<v-col cols="12" md="12">
							<v-data-table :headers="headers" :items="listData" class="elevation-0" dense>
								<template v-slot:[`item.total_amount`]="{ item }">
                {{ item.total_amount | toCurrency }}
              </template>
                <template v-slot:[`item.start_date_time`]="{ item }">
									{{ item.start_date_time }}
								</template>
								<template v-slot:[`item.action`]="{ item }">
									<v-btn fab small elevation="0" color="transparent" @click="gotoEdit(item.id)">
										<v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
									</v-btn>
									<v-btn fab small elevation="0" color="transparent" @click="downloadPO(item.id)">
										<v-icon color="#8f9742"> mdi-download </v-icon>
									</v-btn>
									<v-btn fab small elevation="0" color="transparent" @click="confirmDeleteAuction(item.id)">
										<v-icon color="red"> mdi-delete-outline </v-icon>
									</v-btn>
								</template>
								<template v-slot:[`item.status`]="{ item }">
									<div class="d-flex">
										<div v-if="item.status == 'Live'">
											<v-chip class="ma-2 blink-2" color="red lighten-1" text-color="white" small>
												<v-avatar left><v-icon>mdi-access-point-check</v-icon></v-avatar> Live
											</v-chip>
										</div>
										<div v-else-if="item.status == 'Closed'">
											<v-icon color="primary" class="mr-1">mdi-check-bold</v-icon>
											<span style="font-size: 1rem">Completed</span>
										</div>
										<div v-else-if="item.status == 'Scheduled'">
											<v-icon color="primary" class="mr-1">mdi-tag-check-outline</v-icon>
											<span style="font-size: 1rem">Upcoming</span>
										</div>
										<div v-else>
											<!-- <v-icon color="primary" class="mr-1">mdi-tag-check-outline</v-icon> -->
											<span style="font-size: 1rem">{{ item.status }}</span>
										</div>
									</div>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-container-fluid>
		<ConformationBox
			v-if="isShowDeleteDialog"
			:title="'Confirmation'"
			message="Are you sure want to delete ?"
			@confirm="confirmDeleteForm"
			@cancel="closeDeleteFormModal"
		/>
	</dashlayout>
</template>
  
  <script>
import dashlayout from "@/components/layout/dashlayout.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";

import {
  FOOTERPAGELIST,
  PAGINATIONOPTIONS,
  getEncodedStringBase64,
  API_URL,
  getDecodedToken,
  COMMONPAYLOAD,
  encryptPayload
} from "@/utils/helper";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axios from 'axios'

export default {
  components: { dashlayout, ConformationBox,DatePicker },
  name: "AuctionAddEdit",
  inject: ["mySpinner"],
  data: () => ({
	search: '',
    dialog: false,
    viewAuctionDetails: false,
    headers: [
      { text: "PO No.", value: "direct_po_number" },
      { text: "Quantity", value: "total_quantity" },
      { text: "Price", value: "total_amount" },
      { text: "Delivery", value: "delivery" },
      { text: "Update Date", value: "updated_at" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
    listData: [],
    total2: 0,
    options: PAGINATIONOPTIONS,
    footerList: {
      "items-per-page-options": [10, 20, 50, 100],
    },
    isShowDeleteDialog: false,
    deleteRoleId: "",
    auction_number: "",
	  dateFilter : [],
  }),
  created() {
    this.getDirectPurchaseOrders();
  },
  filters: {
    toCurrency: function (value) {
      var formatter = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR'
      });
    return formatter.format(value);
    }
  },
  methods: {
    gotoAdd(id) {
      this.$router.push({ path: `/directPurchaseOrdersAdd`});
    },
    gotoEdit(id) {
      this.$router.push({ path: `/directPurchaseOrdersEdit/${getEncodedStringBase64(id)}` });
    },
    async getDirectPurchaseOrders() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getDirectPurchaseOrders", {
          ...this.options,
          action: "LIST",
		      search: this.search,
		      start_date : this.dateFilter.length > 0 ? this.dateFilter[0] != null ? this.dateFilter[0] : "" : "",
          end_date : this.dateFilter.length > 0 ? this.dateFilter[1] != null ? this.dateFilter[1] : "" : "",
        });
        if (response.http_status === 200) {
          this.listData = response.data.data;
          this.total2 = response.data.total;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    confirmDeleteAuction(id) {
      this.isShowDeleteDialog = true;
      this.deleteRoleId = id;
    },
    closeDeleteFormModal() {
      this.isShowDeleteDialog = false;
      this.deleteRoleId = "";
    },
    async confirmDeleteForm() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("deletePurchaseOrder", {
          id: this.deleteRoleId,
        });
        if (response.http_status === 200) {
          this.isShowDeleteDialog = false;
          this.deleteRoleId = "";
          this.getDirectPurchaseOrders();
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.isShowDeleteDialog = false;
          this.deleteRoleId = "";
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async downloadPO(id) {
      this.mySpinner.val = true;
      const token = getDecodedToken();
      let payload = {
        bfb_data: {
          ...COMMONPAYLOAD
        }
      }
      payload = encryptPayload(payload)
      axios({
        url: `${API_URL}get-file/direct_po_document/${id}`,
        method: "POST",
        data: payload,
        headers: { authorization : 'Bearer ' + token},
        responseType: "blob",
      })
        .then((res) => {
			var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            var extension = res.headers['content-type'].split('/');
            docUrl.setAttribute('download', `PO_document.${extension[1]}`);
            document.body.appendChild(docUrl);
            docUrl.click();
            this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
  },
};
</script>