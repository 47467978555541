import { render, staticRenderFns } from "./gtag.vue?vue&type=template&id=8f7a9c34"
import script from "./gtag.vue?vue&type=script&lang=js"
export * from "./gtag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports