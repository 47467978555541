<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn mb-1">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-account-check-outline
            </v-icon>
            Role Access Name
          </div>
          <div class="btns-right">
            <v-btn elevation="0" color="#9DA73E" dark text to="/roleaccess">
              <v-icon class="mr-2">
                mdi-arrow-left
              </v-icon>
              Back
            </v-btn>
          </div>
        </div>

        <v-row>
          <v-col cols="12" md="2" class="pb-0">
            <label for="">Role Name</label>
            <v-text-field v-model="roleName" outlined dense disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="pb-0">
            <label for="">User Category</label>
            <v-select :items="items" v-model="userCategory" outlined dense readonly></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <v-simple-table dense class="data-table-with-switch usermodule-table">
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-left">Module Name</th>
                    <th class="text-center">View</th>
                    <th class="text-center">Add</th>
                    <th class="text-center">Edit</th>
                    <th class="text-center">Delete</th>
                  </tr>
                </thead>
                <tbody v-for="item in user_access" :key="item.module_slug">
                  <tr>
                    <td>{{ item.module_name }}</td>
                    <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"
                        v-model="item.is_view" :false-value=0 :true-value=1></v-switch></td>
                    <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"
                        v-model="item.is_add" :false-value=0 :true-value=1></v-switch></td>
                    <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"
                        v-model="item.is_edit" :false-value=0 :true-value=1></v-switch></td>
                    <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"
                        v-model="item.is_delete" :false-value=0 :true-value=1></v-switch></td>
                  </tr>
                  <tr v-for="data in item.child_modules" :key="data.name">

                    <td class="text-left table-title-child">{{ data.module_name }}</td>
                    <td class="text-center">
                      <v-switch v-model="data.is_view" dense color="#9da73e" inset hide-details="" class="mt-0"
                        :false-value=0 :true-value=1></v-switch>
                    </td>
                    <td class="text-center">
                      <v-switch v-model="data.is_add" dense color="#9da73e" inset hide-details="" class="mt-0"
                        :false-value=0 :true-value=1></v-switch>
                    </td>
                    <td class="text-center">
                      <v-switch v-model="data.is_edit" dense color="#9da73e" inset hide-details="" class="mt-0"
                        :false-value=0 :true-value=1></v-switch>
                    </td>
                    <td class="text-center">
                      <v-switch v-model="data.is_delete" dense color="#9da73e" inset hide-details="" class="mt-0"
                        :false-value=0 :true-value=1></v-switch>
                    </td>

                  </tr>
                  <!-- <tr>
                        <td>Auction Sell Module</td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                      </tr>
                      <tr>
                        <td>Auction Sell Module</td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                      </tr>
                      <tr>
                        <td>Auction Sell Module</td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                        <td class="text-center"><v-switch dense color="#9da73e" inset hide-details="" class="mt-0"></v-switch></td>
                      </tr> -->

                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="12">
            <div class="text-right">
              <v-btn elevation="0" color="#9DA73E" dark large class="px-8" @click="updateRoleAccess">
                Save
              </v-btn>
            </div>

          </v-col>
        </v-row>
      </div>
    </v-container-fluid>
  </dashlayout>
</template>

<script>
import dashlayout from "../layout/dashlayout.vue";
import {getDecodedStringBase64} from '@/utils/helper'

export default {
  components: { dashlayout },
  name: "roleaccessedit",
  inject: ['mySpinner'],
  created() {
    this.getUserRoleDetails()
  },
  methods: {
    gotoEdit() {
      this.$router.push({ path: "/roleaccess" });
    },
    async getUserRoleDetails() {
      this.mySpinner.val = true;
      try {
        const payload = {
          id: getDecodedStringBase64(this.$route.params.id),
          action: 'VIEW',
        }
        const response = await this.$store.dispatch("viewUserRole", payload);
        if (response.http_status === 200 || response.http_status === 201) {
          this.roleName = response.data.role_name
          this.userCategory = response.data.product_type
          this.user_access = response.data.user_access
          this.$toasted.success(response.data.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async updateRoleAccess() {
      this.mySpinner.val = true;
      try {
        const payload = {
          role_name: this.roleName,
          id: getDecodedStringBase64(this.$route.params.id),
          product_type: this.userCategory,
          user_access: this.user_access
        }
        const response = await this.$store.dispatch("updateUserRole", payload);
        if (response.http_status === 200 || response.http_status === 201) {
          this.$toasted.success(response.data.message);
          this.mySpinner.val = false;
          this.getUserRoleDetails()
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    }
  },
  data: () => ({
    dialog: false,
    addEditModule: false,
    items: ['Seller', 'Buyer', 'Trader', 'Admin'],

    roleName: '',
    userCategory: '',
    user_access: null,

    headers: [
      { text: "Role Name", value: "role" },
      { text: "User Category", value: "category" },
      { text: "Updated At", value: "updatedAt" },
      { text: "Updated By", value: "updatedBy" },
      { text: "Action", value: "action", align: "right" },
    ],
    details: [
      {
        role: "Basic Access",
        category: "Auction",
        updatedAt: "12/12/2022",
        updatedBy: "Biofuel Bid Admin",
        action: "",
      },
      {
        role: "Basic Access",
        category: "Auction",
        updatedAt: "12/12/2022",
        updatedBy: "Biofuel Bid Admin",
        action: "",
      },
      {
        role: "Basic Access",
        category: "Auction",
        updatedAt: "12/12/2022",
        updatedBy: "Biofuel Bid Admin",
        action: "",
      },
      {
        role: "Basic Access",
        category: "Auction",
        updatedAt: "12/12/2022",
        updatedBy: "Biofuel Bid Admin",
        action: "",
      },
      {
        role: "Basic Access",
        category: "Auction",
        updatedAt: "12/12/2022",
        updatedBy: "Biofuel Bid Admin",
        action: "",
      },
    ],
  }),
};
</script>

<style></style>