<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      fixed
      app
      color="#231105"
      dark
    >
      <v-list>
        <v-list-item link to="/" class="px-2 justify-center mb-4">
          <img
            v-if="!miniVariant"
            src="@/assets/images/logo.png"
            alt=""
            style="width: 70%"
          />
          <img
            v-else
            src="@/assets/images/small-logo.png"
            alt=""
            style="width: 25px"
          />
        </v-list-item>
        <template v-for="(item, i) in menuList">
          <v-list-item
            v-if="!item.child_modules"
            :key="i"
            :to="item.to"
            router
            exact
          >
            <v-list-item-action>
              <v-tooltip bottom v-if="miniVariant">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="item.child_modules && item.child_modules"
            no-action
            :key="i"
            class="router-link-active"
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon color="#B7B7B7">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(item2, j) in item.child_modules"
              :key="j"
              :to="item2.to"
              class="pl-10"
            >
              <v-list-item-action>
                <v-icon color="#B7B7B7">
                  {{ item2.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item2.title" :key="j" />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      fixed
      app
      color="#FFFFFF"
      elevation="0"
      style="border-bottom: 1px solid #e6e6e6"
    >
      <v-btn
        class="d-none d-md-block"
        icon
        @click.stop="miniVariant = !miniVariant"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn class="d-block d-md-none" icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <h3 class="main-dash-title">{{ currentRouteName }}</h3>
      <v-spacer></v-spacer>

      <!-- <v-chip link @click="goToWallet" class="px-5 mr-3" color="#9DA73E" text-color="white">
        <v-avatar left>
          <v-icon>mdi-wallet-outline</v-icon>
        </v-avatar>
        ₹ 115.25
      </v-chip> -->

      <!-- <v-menu offset-y nudge-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-badge bordered color="error" content="3" class="mr-5" overlap>
            <v-btn
              class="white--text"
              color="primary"
              fab
              x-small
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-gavel </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                <v-icon color="primary" class="mr-2"> mdi-gavel </v-icon>
                Your Upcoming Auctions</v-list-item-title
              >
              <v-list-item-subtitle class="pl-9"
                >Founder of Vuetify</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item link>
            <v-list-item-title
              class="d-flex justify-space-between align-center"
            >
              <strong>Mustard Husk - BFB-A2394472</strong>
              <div>
                <v-icon color="primary"> mdi-calendar </v-icon>
                2023-04-21 01:00
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              class="d-flex justify-space-between align-center"
            >
              <strong>Mustard Husk - BFB-A2394472</strong>
              <div>
                <v-icon color="primary"> mdi-calendar </v-icon>
                2023-04-21 01:00
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-title
              class="d-flex justify-space-between align-center"
            >
              <strong>Mustard Husk - BFB-A2394472</strong>
              <div>
                <v-icon color="primary"> mdi-calendar </v-icon>
                2023-04-21 01:00
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <div v-if="user_type == 'Buyer' || user_type == 'Seller'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="white--text mr-4"
              color="#f2f5d8"
              fab
              small
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-wallet-outline </v-icon>
            </v-btn>
          </template>
          <span>{{ wallet_balance }}</span>
        </v-tooltip>
      </div>

      <v-menu offset-y nudge-width="250" max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            bordered
            color="error"
            :content="notificationCount.toString()"
            class="mr-5"
            overlap
          >
            <v-btn
              class="white--text"
              color="#f2f5d8"
              fab
              small
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-bell-outline </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="font-weight-bold"
                >{{ notificationCount }} Unread
                Notifications</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-btn rounded text color="primary" @click="readAllNotification()">
              Read All
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list style="max-height: 70vh; overflow-y: scroll">
          <template v-for="(data, index) in notificationList">
            <v-list-item link class="py-2" :key="data.id">
              <v-list-item-title>
                <div class="d-flex justify-space-between align-center">
                  <div class="primary--text" style="white-space: break-spaces">
                    {{ data.title }}
                  </div>
                  <div>
                    <v-btn x-small icon color="primary">
                      <v-icon
                        v-if="data.read_at === null"
                        @click="readSingleNotification(data.id)"
                      >
                        mdi-check-circle
                      </v-icon>
                      <v-icon
                        v-if="data.read_at !== null"
                        @click="unReadSingleNotification(data.id)"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <!-- <div
                class="grey--text mt-1 notification-text"
                style="white-space: break-spaces"
              >Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </div> -->

                <div class="mt-1 text-right">
                  <v-btn
                    small
                    color="primary"
                    outlined
                    class="mr-2"
                    @click="viewAuction(data)"
                  >
                    View
                  </v-btn>
                  <!-- <template v-if="data.details != ''">
                  <v-btn 
                    small 
                    color="primary" 
                    v-if="data.details?.type == 'auctioneer_payment_confirmation'"
                    @click="downloadInvoice(data)"
                  >
                    Download
                  </v-btn>
                  <v-btn 
                    small 
                    color="primary"  
                    v-if="data.details?.type == 'purchase_order_generated'"
                    @click="downloadPurchaseOrder(data)"
                  >
                    Download
                  </v-btn>
                </template> -->
                </div>
              </v-list-item-title>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-menu>

      <v-select
        v-if="!isAdmin && !isPartner"
        :items="branches"
        v-model="branchId"
        label="Your Branch"
        style="max-width: 200px !important"
        rounded
        outlined
        class="mr-4 font-weight-bold"
        dense
        prepend-inner-icon="mdi-domain"
        item-value="id"
        item-text="display_name"
        @change="changeBranchConfirm"
      ></v-select>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="32" v-bind="attrs" v-on="on" v-ripple>
            <img :src="getImageName()" alt="John" />
          </v-avatar>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="getImageName()" alt="John" />
            </v-list-item-avatar>

            <v-list-item-content>
              <router-link :to="{ name: 'Profile' }">
                <v-list-item-title
                  >{{ first_name }} {{ last_name }}</v-list-item-title
                >
                <v-list-item-subtitle>{{ user_type }}</v-list-item-subtitle>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item link v-if="!isAdmin" to="/updateProfile">
            <v-list-item-title>
              <v-icon class="mr-1">mdi-account-outline</v-icon> Profile
            </v-list-item-title>
          </v-list-item> -->
          <v-divider />
          <v-list-item link @click="logout">
            <v-list-item-title class="red--text">
              <v-icon class="mr-1" color="red">mdi-logout</v-icon> Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <div class="dash-borad-wrap">
        <slot></slot>
      </div>
    </v-main>
    <v-navigation-drawer v-model="rightDrawer" :right="right" temporary fixed>
      <v-list>
        <v-list-item @click.native="right = !right">
          <v-list-item-action>
            <v-icon light> mdi-repeat </v-icon>
          </v-list-item-action>
          <v-list-item-title>Switch drawer (click me)</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="changeBranch" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-h6">
          Are you sure want to change branch ?
        </v-card-title>

        <div class="text-center">
          <!-- <v-breadcrumbs :items="items" class="justify-center">
                <template v-slot:divider>
                  <v-icon>mdi-forward</v-icon>
                </template>
              </v-breadcrumbs> -->
          {{ current_branch_name }}
          <v-icon>mdi-forward</v-icon>
          {{ new_branch_name }}
        </div>
        <!-- <v-card-text> Lorem ipsum dolor sit amet consectetur. </v-card-text> -->

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>

          <v-btn color="grey darken-1" text @click="changeBranch = false">
            No
          </v-btn>

          <v-btn
            color="#9da73e"
            text
            @click="changeBranchAction"
            class="font-weight-bold"
          >
            Yes, Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SelectBranchModel
      v-if="isShowBranchModal"
      :branches="branches"
      @branchSelect="branchSelectSuccess"
    />
    <!-- <CommisionPopUp 
      v-if="isWontheAuction"
      :bidDetails="bidDetails"
      :commission_invoice_details="commission_invoice_details"
    /> -->
    <v-dialog v-model="isWontheAuction" persistent width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mt-3">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-gavel
                  </v-icon>
                  Important Auction Update :
                </div>
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="orderdetails = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="12" class="pt-0 pb-0">
              <h3>
                The auctioneer has confirmed your bid for auction number
                {{ this.auction_number }}. To proceed further, please click on
                the button below to view the details and confirm your bid. Thank
                you for your participation!
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="text-right mt-4 mb-2">
          <v-btn
            @click="goToAuctionDetails"
            elevation="0"
            color="#9DA73E"
            dark
            large
            class="px-5 ml-2"
            style="min-width: 120px"
          >
            View Auction Details and Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isGeneratePONotification" persistent width="650">
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <div class="box-title-with-btn mb-0">
                <div class="box-title mt-3">
                  <v-icon size="24" color="#9DA73E" class="mr-2">
                    mdi-gavel
                  </v-icon>
                  Order Confirmation:{{ this.auction_number }}
                </div>
                <v-btn
                  fab
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="orderdetails = false"
                >
                  <v-icon color="black"> mdi-close </v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="12" class="pt-0 pb-0 pl-12">
              <h2 class="mb-3">
                Great news!
                <br />
              </h2>
              <h3>
                The participant has accepted the order. To generate the purchase
                order, please click on the button below.
              </h3>
              <br />
              <h4>
                Once the purchase order is created, the auctioneer will proceed
                to send it. Thank you for your cooperation!
              </h4>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="text-right mt-4 pb-10 pl-12">
          <v-btn
            @click="goToAuctioneer"
            elevation="0"
            color="#9DA73E"
            dark
            large
            class="px-5 ml-2"
            style="min-width: 120px"
          >
            Generate Purchase Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {
  ADMINMENU,
  BUYERMENU,
  PARTNERMENU,
  getDecodedString,
  getDecodedStringBase64,
  getEncodedStringBase64,
  PUSHER_KEY,
  COMMONPAYLOAD,
  FILEDOWNALOADHEADER,
  API_URL,
} from "@/utils/helper";

import SelectBranchModel from "../dashboard/Branch/SelectBranchModel.vue";
import CommisionPopUp from "../dashboard/CommisionPopUp.vue";
import Pusher from "pusher-js";
import axios from "axios";

export default {
  name: "dashboard",
  data: () => ({
    current_branch_name: "",
    new_branch_name: "",
    changeBranch: false,
    clipped: false,
    drawer: true,
    fixed: false,
    menuList: [],
    miniVariant: false,
    right: true,
    rightDrawer: false,
    isAdmin: false,
    isPartner: false,
    first_name: "",
    last_name: "",
    user_type: "",
    isShowBranchModal: false,
    branchId: "",
    branches: [],
    isWontheAuction: false,
    isGeneratePONotification: false,
    auction_number: "",
    notificationList: [],
    notificationCount: 0,
    wallet_balance: 0,
    // isLosstheAuction : false,
    // bidDetails : null,
    //   commitionData : {
    //     sgst : '',
    //     igst : null,
    //     item_name : '',
    //     ssn_code : '',
    //     taxable_amount : '',
    //     cgst : '',
    //     total_commission_amount : '',
    //     gross_commission_amount : '',
    //     service_charge_percentage : '',
    //     auction_number : '',
    //     auction_for : ''
    //   },
    //   auction_number : '',
    //   paymentOptions: [
    //   {
    //     logo: "https://w7.pngwing.com/pngs/667/120/png-transparent-google-pay-2020-hd-logo-thumbnail.png",
    //     name: "Gpay",
    //   },
    // ],
    // selectedPaymentOption : '',
    // paymentdialog : false
  }),
  created() {
    this.first_name = localStorage.getItem("first_name");
    this.last_name = localStorage.getItem("last_name");

    this.user_type = getDecodedString(
      localStorage.getItem("user_type")
    ).replaceAll('"', "");

    let last_password_changed_at =
      localStorage.getItem("last_password_changed_at") != null
        ? localStorage.getItem("last_password_changed_at")
        : null;
    let isAdmin = getDecodedStringBase64(localStorage.getItem("is_admin"));
    this.branches =
      localStorage.getItem("branches") != null
        ? JSON.parse(localStorage.getItem("branches"))
        : [];
    let selectBranchId =
      localStorage.getItem("selectedBranch") != ""
        ? localStorage.getItem("selectedBranch")
        : null;
    if (last_password_changed_at == "null") {
      if (this.$route.path !== "/changePassword") {
        this.$router.push("/changePassword");
      }
    }
    let isPartner = getDecodedStringBase64(localStorage.getItem("is_partner"));

    if (isAdmin == 1) {
      this.isAdmin = true;
      this.isPartner = false;
      this.menuList = ADMINMENU;
    } else if (isPartner == 1) {
      this.isAdmin = false;
      this.isPartner = true;
      this.menuList = PARTNERMENU;
    } else {
      this.isPartner = false;
      this.isAdmin = false;
      this.menuList = BUYERMENU;
      if (
        (this.branches.length > 1) & (selectBranchId == null) &&
        selectBranchId != ""
      ) {
        this.isShowBranchModal = true;
      } else if (this.branches.length == 1) {
        localStorage.setItem("selectedBranch", this.branches[0].id);
      }
      this.branchId = Number.parseInt(localStorage.getItem("selectedBranch"));
      // this.branchId = JSON.parse(selectBranchId)
    }

    this.wallet_balance = getDecodedStringBase64(
      localStorage.getItem("wallet_balance")
    );

    Pusher.logToConsole = true;
    var pusher = new Pusher(PUSHER_KEY, {
      cluster: "ap2",
    });
    let that = this;
    var channel = pusher.subscribe(
      `USER-CHANNEL-${localStorage.getItem("user_id")}`
    );
    channel.bind("my-event", function (data) {
      // this.message = data;
      that.$emit("incoming_data", data);
    });

    this.$on("incoming_data", function (notificationData) {
      this.userNotifireData(notificationData);
    });
    this.getNotificationList();
  },
  components: {
    SelectBranchModel,
    CommisionPopUp,
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  methods: {
    logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("is_admin");
      localStorage.removeItem("is_buyer");
      localStorage.removeItem("is_seller");
      localStorage.removeItem("is_trader");
      localStorage.removeItem("last_password_changed_at");
      localStorage.removeItem("last_name");
      localStorage.removeItem("userid");
      localStorage.removeItem("useremail");
      localStorage.removeItem("userphone");
      localStorage.removeItem("registration_number");
      localStorage.removeItem("otp_key");
      localStorage.removeItem("first_name");
      localStorage.removeItem("branches");
      localStorage.removeItem("selectedBranch");
      localStorage.removeItem("is_partner");
      this.$router.push({ path: "/login" });
    },
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
    goToWallet() {
      this.$router.push({ path: "/wallet" });
    },
    async getUserProfile() {
      let payload = {
        id: localStorage.getItem("user_id"),
      };
      try {
        const response = await this.$store.dispatch("getUserProfile", payload);
        if (response.http_status === 200) {
          this.first_name = response.data.first_name;
          this.last_name = response.data.last_name;
          if (response.data.is_seller) {
            this.user_role = "Seller";
          }
          if (response.data.is_buyer) {
            this.user_role = "Buyer";
          }
          if (response.data.is_admin) {
            this.user_role = "Admin";
          }
          if (response.data.is_trader) {
            this.user_role = "Trader";
          }
        } else {
          this.$toasted.error(response.message);
        }
      } catch (e) {
        this.$toasted.error(e.message);
      }
    },
    getImageName() {
      return `https://ui-avatars.com/api/?name=${this.first_name}+${this.last_name}`;
    },
    branchSelectSuccess() {
      this.isShowBranchModal = false;
      if (!this.isAdmin) {
        let selectBranchId =
          localStorage.getItem("selectedBranch") != ""
            ? localStorage.getItem("selectedBranch")
            : null;
        this.branchId = JSON.parse(selectBranchId);
      }
    },
    changeBranchConfirm() {
      let tmp_cur_branch_id = Number.parseInt(
        localStorage.getItem("selectedBranch")
      );
      for (let i in this.branches) {
        if (this.branches[i].id == this.branchId) {
          this.new_branch_name = this.branches[i].display_name;
        }
        if (this.branches[i].id == tmp_cur_branch_id) {
          this.current_branch_name = this.branches[i].display_name;
        }
      }
      this.changeBranch = true;
    },
    changeBranchAction() {
      localStorage.setItem("selectedBranch", this.branchId);
      this.changeBranch = false;
    },

    userNotifireData(data) {
      if (data.hasOwnProperty("message")) {
        if (
          data.message.hasOwnProperty("channel_type") &&
          data.message.channel_type == "auctioneer_payment_confirmation"
        ) {
          this.isWontheAuction = data.message.hasOwnProperty("selected")
            ? data.message.selected == "Yes"
              ? true
              : false
            : false;
        }
        if (
          data.message.hasOwnProperty("channel_type") &&
          data.message.channel_type == "bidder_payment_confirmation"
        ) {
          this.isGeneratePONotification = data.message.hasOwnProperty(
            "generate_po"
          )
            ? data.message.generate_po == "Yes"
              ? true
              : false
            : false;
        }
        this.bidDetails = data.message.hasOwnProperty("bid")
          ? data.message.bid
          : null;
        this.auction_number = data.message.hasOwnProperty("auction_number")
          ? data.message.auction_number
          : "";
      }
    },
    goToAuctionDetails() {
      this.isWontheAuction = false;
      this.$router.push({
        path: `/auction/participant/${getEncodedStringBase64(
          this.auction_number
        )}`,
      });
      if (
        this.$route.fullPath ==
        `/auction/participant/${getEncodedStringBase64(this.auction_number)}`
      ) {
        this.$router.go(0);
      }
    },
    goToAuctioneer() {
      this.isGeneratePONotification = false;
      this.$router.push({
        path: `/auction/auctioneer/${getEncodedStringBase64(
          this.auction_number
        )}`,
      });
      if (
        this.$route.fullPath ==
        `/auction/auctioneer/${getEncodedStringBase64(this.auction_number)}`
      ) {
        this.$router.go(0);
      }
    },
    async getNotificationList() {
      let payload = {
        action: "LIST",
      };
      try {
        const response = await this.$store.dispatch(
          "getNotificationList",
          payload
        );
        if (response.http_status === 200) {
          this.notificationList = response.data.notifications.data;
          this.notificationCount = response.data.unread_notification_count;
        } else {
          this.$toasted.error(response.message);
        }
      } catch (e) {
        this.$toasted.error(e.message);
      }
    },
    async readAllNotification() {
      let payload = {
        action: "LIST",
      };
      try {
        const response = await this.$store.dispatch(
          "readAllNotification",
          payload
        );
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.getNotificationList();
        }
      } catch (e) {
        this.$toasted.error(e.message);
      }
    },
    async readSingleNotification(id) {
      try {
        const response = await this.$store.dispatch(
          "readSingleNotification",
          id
        );
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.getNotificationList();
        }
      } catch (e) {
        this.$toasted.error(e.message);
      }
    },
    async unReadSingleNotification(id) {
      try {
        const response = await this.$store.dispatch(
          "unReadSingleNotification",
          id
        );
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.getNotificationList();
        }
      } catch (e) {
        this.$toasted.error(e.message);
      }
    },
    viewAuction(data) {
      if (data.type === "auctioneer") {
        this.$router.push({
          path: `/auction/auctioneer/${getEncodedStringBase64(
            data.auction_number
          )}`,
        });
        if (
          this.$route.fullPath ==
          `/auction/auctioneer/${getEncodedStringBase64(data.auction_number)}`
        ) {
          this.$router.go(0);
        }
      } else {
        this.$router.push({
          path: `/auction/participant/${getEncodedStringBase64(
            data.auction_number
          )}`,
        });
        if (
          this.$route.fullPath ==
          `/auction/participant/${getEncodedStringBase64(data.auction_number)}`
        ) {
          this.$router.go(0);
        }
      }
    },
    async downloadPurchaseOrder(data) {
      if (data.auction_id != "") {
        this.mySpinner.val = true;
        axios({
          url: `${API_URL}get-file/po_document/${data.auction_id}`,
          method: "POST",
          COMMONPAYLOAD,
          headers: { ...FILEDOWNALOADHEADER },
          responseType: "blob",
        })
          .then((res) => {
            if (res.data.type == "application/json") {
              this.$toasted.error("No record found.");
              this.mySpinner.val = false;
            } else {
              var FILE = window.URL.createObjectURL(
                new Blob([res.data], { type: res.headers["content-type"] })
              );
              var docUrl = document.createElement("a");
              docUrl.href = FILE;
              var extension = res.headers["content-type"].split("/");
              docUrl.setAttribute("download", `po_document.${extension[1]}`);
              document.body.appendChild(docUrl);
              docUrl.click();
              this.mySpinner.val = false;
            }
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
          });
      }
    },
    async downloadInvoice(data) {
      if (data && data.auction_id) {
        this.mySpinner.val = true;
        axios({
          url: `${API_URL}get-file/auction_document/${data.auction_id}/auctioneer`,
          method: "POST",
          COMMONPAYLOAD,
          headers: { ...FILEDOWNALOADHEADER },
          responseType: "blob",
        })
          .then((res) => {
            if (res.data.type == "application/json") {
              this.$toasted.error("No record found.");
              this.mySpinner.val = false;
            } else {
              var FILE = window.URL.createObjectURL(
                new Blob([res.data], { type: res.headers["content-type"] })
              );
              var docUrl = document.createElement("a");
              docUrl.href = FILE;
              var extension = res.headers["content-type"].split("/");
              docUrl.setAttribute(
                "download",
                `invoice_document.${extension[1]}`
              );
              document.body.appendChild(docUrl);
              docUrl.click();
              this.mySpinner.val = false;
            }
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
          });
      }
    },
  },
};
</script>