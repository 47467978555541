<template>
    <AuthLayout>
    <div>
      <div class="login-wrapper" style="min-width: 400px">
        <div class="logo-bio-fuel">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
  
        <div class="title-of-auth mb-4">
          <h2>Verify Reset Password</h2>
        </div>
  
        <div class="form-part">
          <v-row>
            <v-col cols="12" class="pb-0">
              <div class="text-center mt-10">
                <div>
                  <v-icon size="60" color="#959e45" v-show="isTrue && isTrue != null">
                    mdi-check-circle-outline
                  </v-icon>
                  <v-icon size="60" color="red" v-show="!isTrue && isTrue != null">
                    mdi-close-circle-outline
                  </v-icon>
                </div>
                <div class="mb-10">
                  <div>{{ message }}</div>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
            <div class="text-center mb-0">
                <div style="color: #9DA73E; font-weight: 500;" class="pointer d-inline" v-ripple>
                <span @click="goToLogin">Go To Login</span>
                </div>
            </div>
          </v-col>
          </v-row>
        </div>
      </div>
    </div>
    </AuthLayout>
  </template>
  
  <script>
import AuthLayout from './layout/auth.vue';

  export default {
    name :'forapproval',
    inject : ['mySpinner'],
    data: () => ({
       message : '',
       isTrue : null
    }),
    components : {
        AuthLayout
    },
    async created(){
      this.mySpinner.val = true;
      try {
        const payload = {token : this.$route.query.token}
        const response = await this.$store.dispatch('resetPasswordVerify', payload)
        if(response.http_status === 200) {
          this.message = response.message;
          this.isTrue = true;
          localStorage.setItem('password_reset_token', this.$route.query.token)
          this.mySpinner.val = false;
          setTimeout(() => {
            this.$router.push({path : '/resetPasswordConfirm'})  
          }, 1000);
        }else {
          this.message = response.message 
          this.isTrue = false;
          this.mySpinner.val = false;
        }
      }catch(e) {
        this.$toasted.error(e.message)
        this.mySpinner.val = false;
      }
    },
    methods : {
        goToLogin() {
            this.$router.push({path : '/login'})
        }
    }
  };
  </script>
  
  <style>
  </style>