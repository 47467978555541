import axiosServices from "@/utils/axios";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD,API_URL,APPKEY } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    isLoggedIn: false,
    authToken  : null,
    userInfo : null,
})

const getters  = {

}

const actions = {
    getAuctionList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            status : inputPage.status,
            ...COMMONPAYLOAD,
            list_type: inputPage.list_type ? inputPage.list_type : '',
            organization_id: inputPage.organization_id,
        }
        return axiosServices
            .post('/auction/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getAdminAuctionList({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            status : inputPage.status,
            ...COMMONPAYLOAD,
            auction_for : inputPage.auction_for,
            user_id : inputPage.user_id,
            participated_by: inputPage.participated_by,
            start_date : inputPage.start_date,
            end_date : inputPage.end_date,
        }
        return axiosServices
            .post('/admin/auctions', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createAuction({ commit },payload) {
        return axiosServices
            .post('/auction/create', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateAuction({ commit },payload) {
        return axiosServices
            .post('/auction/update', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateAdminAuction({ commit },payload) {
        return axiosServices
            .post('/admin/auction/update', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewAuction({ commit },payload) {
        return axiosServices
            .post('/auction/list', {...payload,...OTPCOMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    deleteAuction({ commit },payload) {
        return axiosServices
            .post('/auction/delete', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    changeStatusAuction({ commit }, payload) {
        return axiosServices
            .post('/auction/change_status', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },

    getDashboardAuctionList({ commit },inputPage){
        const payLoad = {
            action: inputPage.action,
            sorting_order: 'DESC' ,
            order_by:  ""  ,
            show_records: 200,
            page:0,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('/auction/upcoming-auctions/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    auctionAddParticipants({ commit },payload) {
        return axiosServices
        .post('/auction/add_participants', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getAuctionDetails({ commit },payload){
        return axiosServices
        .post('auction/detail', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getAdminAuctionDetails({ commit },payload){
        return axiosServices
        .post('admin/auction-detail', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    auctionBid({ commit },payload) {
        return axiosServices
        .post('/auction/bid', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getAuctionBidHistory({ commit },payload){
        return axiosServices
        .post('auction/bid_history', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    auctionExtendedMin({ commit },payload){
        return axiosServices
        .post('auction/increase_time', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },

    confirmAuctionOrder({ commit },payload){
        return axiosServices
        .post('auction/confirm', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    sumbitThePayment({ commit },payload){
        return axiosServices
        .post('auction/pay-commission', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    acceptBidderOrder({ commit },payload){
        return axiosServices
        .post('auction/accept', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    generatePurchaseOrder({ commit },payload) {
        return axiosServices
        .post('auction/generate-purchase-order', {...payload,...COMMONPAYLOAD})
        .then((response) => {
            if (response && response.data) {
                return response.data
            }
        })
        .catch((error) => {
            if (error && error.response && error.response.data)
                throw error.response.data
        })
    },
    getPurchaseOrderListData({ commit },inputPage) {
        const payLoad = {
            action: inputPage.action,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            search : inputPage.search,
            ...COMMONPAYLOAD,
            list_type: inputPage.list_type ? inputPage.list_type : '',
            organization_id: inputPage.organization_id,
        }
        return axiosServices
            .post('/auction/purchase-orders/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    
    },
    getActivityList({ commit }, payload) {
        return axiosServices
            .post('auction/activities/list', { ...payload, ...COMMONPAYLOAD })
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
}

const mutations = {
}

export default {
    actions,
    mutations,
    state,
    getters
}