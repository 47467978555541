import axiosServices from "@/utils/axios";
import {COMMONPAYLOAD,OTPCOMMONPAYLOAD } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    
})

const getters  = {

}

const actions = {
    getItemList({ commit }, inputPage) {
        const payLoad = {
            action: inputPage.action,
            category_id: inputPage.category_id,
            sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            show_records: inputPage.itemsPerPage,
            page: inputPage.page,
            search : inputPage.search,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('admin/items/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    getItemListInvoice({ commit }, inputPage) {
        const payLoad = {
            action: inputPage.action,
            category_id: inputPage.category_id,
            // sorting_order: inputPage.sortDesc.length == 0 ? "" : inputPage.sortDesc[0] ? 'DESC' : 'ASC',
            // order_by: inputPage.sortBy.length == 0 ? "" : inputPage.sortBy[0] ,
            // show_records: inputPage.itemsPerPage,
            // page: inputPage.page,
            // search : inputPage.search,
            ...COMMONPAYLOAD
        }
        return axiosServices
            .post('admin/items/list', payLoad)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    createItem({ commit }, payload) {
        return axiosServices
            .post('admin/items/create', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    deleteItem({ commit },payload) {
        return axiosServices
            .post('admin/items/delete', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    viewItem({ commit },payload) {
        return axiosServices
            .post('admin/items/list', {...payload,...OTPCOMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    updateItem({ commit },payload) {
        return axiosServices
            .post('admin/items/update', {...payload,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}