<template>
    <weblayout>
        <div class="banner-blog small-inner-banner">
            <v-container>
                <div class="wrapper-of-banner">
                    <div class="text-center my-0 mx-auto">
                        <div class="text-h3 font-weight-medium white--text">Enquiry</div>
                        <v-breadcrumbs large :items="items">
                            <template v-slot:divider>
                                <v-icon color="white">mdi-forward</v-icon>
                            </template>
                        </v-breadcrumbs>
                    </div>
                </div>
            </v-container>
        </div>
        <v-container class="my-16">
            <v-row>
                <v-col cols="12" md="3">
                    <div class="about-wrap">
                        <div class="m-title">Why Biofuel Bid</div>

                        <div>
                            <p>
                                Biofuel bid is one platform where you will find maximum number
                                of manufacturers trading their inventories and providing better
                                rate and terms of trade. Its solution to this emerging industry
                                trying to bring all the stakeholders together and easily
                                facilitate trade between them at transparent terms.
                            </p>
                        </div>

                        <v-row>
                            <v-col>
                                <div>
                                    <h3 class="mb-2">Registered Address</h3>
                                    <div>
                                        C243, Second Floor, SoBo Center, <br />
                                        Gala Gymkhana Road, South Bopal, <br />
                                        Ahmedabad, 380058.
                                    </div>
                                </div>
                            </v-col>
                            <v-col>
                                <div>
                                    <h3 class="mb-2">Contact Information</h3>
                                    <div>
                                        <div class="mb-1">
                                            <a class="black--text text-decoration-none" href="tel:+917600616767">+91 76006
                                                16767</a>
                                        </div>
                                        <div>
                                            <a class="black--text text-decoration-none"
                                                href="mailto:support@biofuelbid.com">support@biofuelbid.com</a>
                                        </div>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12">
                                <h3 class="mb-2">Social Media</h3>
                                <div class="mt-2">
                                    <v-btn icon>
                                        <v-icon color="#421E06"> mdi-facebook </v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon color="#421E06"> mdi-instagram </v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon color="#421E06"> mdi-whatsapp </v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon color="#421E06"> mdi-gmail </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" md="9">
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(submitContactUs)">
                            <div class="contact-form">
                                <div class="m-title">Leave a <strong> Enquiry</strong></div>
                                <v-row class="mt-3">
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="First name" rules="required">
                                            <label for="">First Name <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                v-model="firstName"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Last name" rules="required">
                                            <label for="">Last Name <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                v-model="lastName"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Email" rules="required|email">
                                            <label for="">Email <span class="required">*</span></label>
                                            <v-text-field outlined dense prepend-inner-icon="mdi-email-outline"
                                                v-model="email" :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Phone Number" :rules="{
                                            required: true,
                                            digits: 10,
                                        }">
                                            <label for="">Mobile <span class="required">*</span></label>
                                            <v-text-field v-model="phoneNumber" outlined dense
                                                :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Company Name" rules="required">
                                            <label for="">Company Name <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                v-model="companyName"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Address line1" rules="required">
                                            <label for="">Address line1 <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                v-model="address1"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <label for="">Address line2</label>
                                        <v-text-field outlined dense v-model="address2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <validation-provider v-slot="{ errors }" name="Landmark / Area" rules="required">
                                            <label for="">Landmark / Area <span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                v-model="landmark"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="State" rules="required">
                                            <label for="">State<span class="required">*</span></label>
                                            <v-select :items="stateItem" v-model="state" outlined dense
                                                :error-messages="errors" item-value="id" item-text="name"
                                                @change="getCityDropdownData"></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="City" rules="required">
                                            <label for="">City<span class="required">*</span></label>
                                            <v-autocomplete :items="cityItem" v-model="city" outlined dense
                                                :error-messages="errors" item-value="id" item-text="name"></v-autocomplete>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Country" rules="required">
                                            <label for="">Country<span class="required">*</span></label>
                                            <v-select :items="countryItem" readonly v-model="country" outlined dense
                                                :error-messages="errors" item-value="id" item-text="name">
                                            </v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Pincode" rules="required">
                                            <label for="">pincode<span class="required">*</span></label>
                                            <v-text-field outlined dense :error-messages="errors"
                                                v-model="pinCode"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Material" rules="required">
                                            <label for="">Material Type <span class="required">*</span></label>
                                            <v-select :items="materialTypeList" v-model="materialType" item-value="id"
                                                item-text="name" outlined dense :error-messages="errors"></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <validation-provider v-slot="{ errors }" name="Quantity" rules="required">
                                            <label for="">Quantity <span class="required">*</span></label>
                                            <v-text-field v-model="quantity" outlined type="number" dense suffix="Tonne"
                                                :min="1" :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <validation-provider v-slot="{ errors }" name="Expected/Base Price"
                                            rules="required">
                                            <label for="">Expected/Base Price<span class="required">*</span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" size="18">
                                                            mdi-information-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Please enter per ton price</span>
                                                </v-tooltip>
                                            </label>

                                            <v-text-field v-model="expected_price" outlined type="number" dense suffix=""
                                                :error-messages="errors" :min="0"></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <validation-provider v-slot="{ errors }" name="Inquiry For" rules="required">
                                            <label for="">Inquiry For<span class="required">*</span></label>
                                            <v-select :items="inquiryForList" item-value="key" item-text="value" v-model="buyOrSell"
                                                 outlined dense :error-messages="errors"></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <label for="">Description</label>
                                        <textarea class="ma-0 pa-2" cols="2" placeholder="Type Message Here" dense
                                            v-model="message"></textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <vue-recaptcha ref="recaptcha" :sitekey="recaptchaSiteKey" @verify="onVerify"
                                            @expired="onExpired" @error="onError" :loadRecaptchaScript="true">
                                        </vue-recaptcha>
                                        <p v-if="recpatchaError != ''" class="required">
                                            {{ recpatchaError }}
                                        </p>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-btn rounded color="#9da73e" dark class="px-6" type="submit">
                                            Submit
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </form>
                    </validation-observer>
                </v-col>
            </v-row>
        </v-container>
    </weblayout>
</template>
  
<script>
import Weblayout from "../layout/weblayout.vue";
import VueRecaptcha from "vue-recaptcha";
import { required, email, max, min } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";
import { generateTheMetaInfo} from "@/utils/helper";
setInteractionMode("eager");
extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});
extend("max", {
    ...max,
    message: "{_field_} may not be greater than {length} characters",
});
extend("min", {
    ...min,
    message: "{_field_} may not be greater than {length} characters",
});
extend("email", {
    ...email,
    message: "Email must be valid",
});
export default {
    components: {
        Weblayout,
        ValidationObserver,
        ValidationProvider,
        VueRecaptcha,
    },
    name: "contactus",
    inject: ["mySpinner"],
    created() {
        this.getDropdownData();
        this.getUserBasicInfo();
    },
    methods: {
        async submitContactUs() {
            if (this.isRecatchaVerified) {
                this.mySpinner.val = true;
                try {
                    const payload = {
                        first_name: this.firstName,
                        last_name: this.lastName,
                        email: this.email,
                        phone: this.phoneNumber,
                        company_name: this.companyName,
                        address_line_1: this.address1,
                        address_line_2: this.address2,
                        district: this.landmark,
                        pin_code: this.pinCode,
                        city_id: this.city,
                        state_id: this.state,
                        country_id: this.country,
                        category_id: this.materialType,
                        quantity: this.quantity,
                        expected_price: this.expected_price,
                        description: this.message,
                        captcha: this.recaptchaToken,
                        enquiry_for : this.buyOrSell
                    }
                    const response = await this.$store.dispatch(
                        "createEnquiry",
                        payload
                    );
                    if (response.http_status === 200 || response.http_status === 201) {
                            this.firstName = '',
                            this.lastName = '',
                            this.email = '',
                            this.phoneNumber = '',
                            this.companyName = '',
                            this.address2 = '',
                            this.address1 = '',
                            this.landmark = '',
                            this.pinCode = '',
                            this.city = '',
                            this.state = '',
                            this.materialType = '',
                            this.quantity = '',
                            this.expected_price = '',
                            this.message = ''
                            this.buyOrSell = ''
                        this.$refs.observer.reset();
                        this.$toasted.success(response.message);
                        this.$refs.recaptcha.reset();
                        this.isRecatchaVerified = false;
                        this.mySpinner.val = false;
                        this.recpatchaError = "";
                    } else {
                        this.$toasted.error(response.message);
                        this.mySpinner.val = false;
                        this.$refs.recaptcha.reset();
                        this.isRecatchaVerified = false;
                        this.recpatchaError = "";
                    }
                } catch (e) {
                    this.$toasted.error(e.message);
                    this.mySpinner.val = false;
                    this.$refs.recaptcha.reset();
                    this.isRecatchaVerified = false;
                    this.recpatchaError = "";
                }
            } else {
                this.recpatchaError = "Please complete the reCAPTCHA to proceed.";
            }
        },
        async getDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    module:
                        "USER_TYPE,MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST,STATE_LIST",
                    country_id: 101,
                };
                const response = await this.$store.dispatch("loadDynamicData", payload);
                if (response.http_status === 200) {
                    this.monthlyCapacityList = response.data.monthly_capacity;
                    this.materialTypeList = response.data.material_type;
                    this.userTypeList = response.data.user_type;
                    this.countryItem = response.data.country_list;
                    this.stateItem = response.data.state_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
        async getCityDropdownData() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    module: "CITY_LIST",
                    country_id: 101,
                    state_id: this.state,
                };
                const response = await this.$store.dispatch("loadDynamicData", payload);
                if (response.http_status === 200) {
                    this.cityItem = response.data.city_list;
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
        async getUserBasicInfo() {
            let user_id = localStorage.getItem('user_id')
            let tmp_check = localStorage.getItem('tmp_check_redirection')
            if(user_id && tmp_check){
                this.mySpinner.val = true;
                try {
                    const payload = {
                        id: user_id,
                    };
                    const response = await this.$store.dispatch("getUserProfile", payload);
                    if (response.http_status === 200) {
                        
                        this.firstName= response.data.first_name;
                        this.lastName= response.data.last_name;
                        this.email= response.data.email;
                        this.phoneNumber= response.data.phone;
                        this.address1 = response.data.address_line_1;
                        this.address2 = response.data.address_line_2;
                        this.landmark = response.data.district;
                        this.pinCode = response.data.pin_code;
                        this.state = response.data.state_id;
                        this.city = response.data.city_id;
                        this.materialType = response.data.material_type[0];
                            
                        if(this.state != ''){
                            this.getCityDropdownData()    
                        }

                        this.mySpinner.val = false;
                    } else {
                        this.$toasted.error(response.message);
                        this.mySpinner.val = false;
                    }
                    localStorage.removeItem('tmp_check_redirection')
                } catch (e) {
                    this.$toasted.error(e.message);
                    this.mySpinner.val = false;
                }
            }
        },
        onExpired() {
            this.recaptchaToken = "";
            this.isRecatchaVerified = false;
            this.$refs.recaptcha.reset();
        },
        onVerify(response) {
            this.recaptchaToken = response;
            if (this.recaptchaToken != "") {
                this.isRecatchaVerified = true;
                this.recpatchaError = "";
            } else {
                this.isRecatchaVerified = false;
            }
        },
        onError() {
            this.recaptchaToken = "";
            this.$refs.recaptcha.reset();
            this.isRecatchaVerified = false;
        },
    },
    metaInfo () {
        return generateTheMetaInfo('Inquery')
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            companyName: "",
            addressline1: "",
            addressline2: "",
            address2: '',
            address1: '',
            landmark: "",
            stateItem: [],
            state: "",
            cityItem: [],
            city: "",
            countryItem: [],
            country: 101,
            pinCode: "",
            materialTypeList: [],
            materialType: "",
            quantity: "",
            expected_price: "",
            message: "",
            document: null,
            buyOrSell : '',
            items: [
                {
                    text: "Home",
                    disabled: false,
                    href: "/",
                },
                {
                    text: "Enquiry",
                    disabled: true,
                    href: "/inquiry",
                },
            ],
            inquiryForList: [
                {
                    key: "buy",
                    value: "Buy"
                },
                {
                    key: "sell",
                    value: "Sell"
                }
            ],
            recaptchaSiteKey: "6LcX8WUlAAAAAKjmy52YmpKJ34D_sEtC3S7NJgXT",
            recaptchaToken: "",
            isRecatchaVerified: false,
            recpatchaError: "",
        };
    },
};
</script>
  
<style></style>