<template>
  <dashlayout>
    <v-container fluid>
      <div class="dash-bg">
        <div class="cover-image">
          <img
            style="width: 100%"
            src="@/assets/images/user-cover.jpg"
            alt=""
          />

          <!-- <v-btn fab small class="cover-edit">
            <v-icon color="#9DA73E"> mdi-pencil-outline </v-icon>
          </v-btn> -->
        </div>

        <div class="user-profile-and-detail ml-8 mb-10">
          <div class="user-img-of-profile">
            <img
              v-if="dispayProfilePic"
              :src="profilePicUrl"
              width="150px"
              alt=""
            />
            <img v-else :src="getImageName()" width="150px" alt="" />
            <!-- <v-avatar color="primary" size="150">SD</v-avatar> -->
            <v-btn
              fab
              small
              class="profile-edit"
              @click="uploadProfilePic = true"
            >
              <v-icon color="#9DA73E"> mdi-pencil-outline </v-icon>
            </v-btn>
          </div>
          <div class="user-da">
            <div class="user-name">
              {{ firstName }} {{ lastName }}
              <span class="ml-1 subtitle-1"
                ><v-icon color="#421E06">mdi-check-decagram</v-icon>
                Verified</span
              >
            </div>
            <div class="user-designation">
              {{ userRole }} ||

              <span class="user-designation" v-if="status !== 'Verified'">{{
                status
              }}</span>
            </div>
          </div>
        </div>

        <div class="approve-reject-btn">
          <template v-if="status == 'Verified'">
            <v-btn
              outlined
              color="red"
              class="white--text elevation-0 mr-2"
              @click="showRejectModal(user_id)"
            >
              Reject
            </v-btn>
            <v-btn
              color="#9da73e"
              class="white--text elevation-0"
              @click="showApproveModal(user_id)"
            >
              <v-icon class="mr-1">mdi-account-check-outline</v-icon>
              Approve </v-btn
            >&nbsp;
          </template>
          <template
            v-if="
              isAdmin &&
              $route.params.id != undefined &&
              $route.params.id != user_id
            "
          >
            <v-btn
              color="#9da73e"
              class="white--text elevation-0"
              @click="showSendModal(user_id)"
            >
              <v-icon class="mr-1">mdi-send-outline</v-icon>
              Resend </v-btn
            >&nbsp;
            <v-btn
              color="red"
              class="white--text elevation-0"
              @click="showBlockModal(user_id)"
            >
              <v-icon class="mr-1">mdi-account-off</v-icon>
              Block </v-btn
            >&nbsp;
            <v-btn
              color="red"
              class="white--text elevation-0"
              @click="showDeactiveModal(user_id)"
            >
              <v-icon class="mr-1">mdi-account-minus</v-icon>
              Deactivate
            </v-btn>
          </template>
        </div>
        <div class="sub-tabs">
          <v-tabs show-arrows v-model="tab2" color="#421E06">
            <v-tab
              ><v-icon class="mr-2">mdi-account-outline</v-icon> Basic
              Info</v-tab
            >
            <v-tab
              ><v-icon class="mr-2">mdi-domain</v-icon> Organisations</v-tab
            >
            <!-- <v-tab><v-icon class="mr-2">mdi-account-check-outline</v-icon>KYC</v-tab> -->
            <v-tab
              ><v-icon class="mr-2">mdi-shield-account-outline</v-icon>
              Security</v-tab
            >
            <!-- <v-tab
              ><v-icon class="mr-2">mdi-file-document-multiple-outline</v-icon>
              Documents</v-tab
            > -->
          </v-tabs>
        </div>

        <v-tabs-items v-model="tab2">
          <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn mb-0">
                <div class="box-title">Basic Info</div>
                <div class="btns-right"></div>
              </div>
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(updateUserBasicInfo)">
                  <v-row>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="First name"
                        rules="required"
                      >
                        <label for=""
                          >First Name <span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="firstName"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Last name"
                        rules="required"
                      >
                        <label for=""
                          >Last Name <span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="lastName"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <label for=""
                          >Email <span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-email-outline"
                          v-model="email"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Gender"
                        rules="required"
                      >
                        <label for=""
                          >Gender <span class="required">*</span></label
                        >
                        <v-select
                          :items="genderItem"
                          v-model="gender"
                          outlined
                          dense
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Phone Number"
                        :rules="{
                          required: true,
                          digits: 10,
                        }"
                      >
                        <label for=""
                          >Mobile No.<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-cellphone"
                          :error-messages="errors"
                          v-model="phoneNumber"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Whatsapp Number"
                        :rules="{
                          required: true,
                          digits: 10,
                        }"
                      >
                        <label for=""
                          >WhatsApp No.<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-whatsapp"
                          :error-messages="errors"
                          v-model="whatsappNumber"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Reference By"
                      >
                        <label for="">Reference By</label>
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="reference_by"
                          readonly
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Is branches"
                        rules="required"
                      >
                        <label for=""
                          >Do you have multiple branches?
                          <span class="required">*</span></label
                        >
                        <v-select
                          :items="isBranchesItem"
                          v-model="is_branches"
                          outlined
                          dense
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <template v-if="userRole != 'Admin'">
                    <v-divider class="my-8"></v-divider>
                    <div class="box-title-with-btn mb-0">
                      <div class="box-title">Address</div>
                      <div class="btns-right"></div>
                    </div>
                  </template>
                  <v-row>
                    <template v-if="userRole != 'Admin'">
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Address1"
                          :rules="userRole != 'Admin' ? 'required' : ''"
                        >
                          <label for=""
                            >Address line1<span class="required">*</span></label
                          >
                          <v-text-field
                            outlined
                            dense
                            :error-messages="errors"
                            prepend-inner-icon="mdi-map-marker-outline"
                            v-model="address1"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <label for="">Address line2</label>
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-map-marker-outline"
                          v-model="address2"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Landmark/Area"
                          :rules="userRole != 'Admin' ? 'required' : ''"
                        >
                          <label for=""
                            >Landmark / Area<span class="required"
                              >*</span
                            ></label
                          >
                          <v-text-field
                            outlined
                            dense
                            :error-messages="errors"
                            v-model="area"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="State"
                          :rules="userRole != 'Admin' ? 'required' : ''"
                        >
                          <label for=""
                            >State<span class="required">*</span></label
                          >
                          <v-select
                            :items="stateItem"
                            v-model="state"
                            outlined
                            dense
                            :error-messages="errors"
                            item-value="id"
                            item-text="name"
                            @change="getCityDropdownData"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="City"
                          :rules="userRole != 'Admin' ? 'required' : ''"
                        >
                          <label for=""
                            >City<span class="required">*</span></label
                          >
                          <v-autocomplete
                            :items="cityItem"
                            v-model="city"
                            outlined
                            dense
                            :error-messages="errors"
                            item-value="id"
                            item-text="name"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Country"
                          :rules="userRole != 'Admin' ? 'required' : ''"
                        >
                          <label for=""
                            >Country<span class="required">*</span></label
                          >
                          <v-select
                            :items="countryItem"
                            readonly
                            v-model="country"
                            outlined
                            dense
                            :error-messages="errors"
                            item-value="id"
                            item-text="name"
                          >
                          </v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Pincode"
                          :rules="userRole != 'Admin' ? 'required' : ''"
                        >
                          <label for=""
                            >pincode<span class="required">*</span></label
                          >
                          <v-text-field
                            outlined
                            dense
                            :error-messages="errors"
                            v-model="pinCode"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </template>
                    <v-col cols="12" md="12">
                      <div class="text-right">
                        <v-btn
                          elevation="0"
                          color="#9DA73E"
                          dark
                          class="px-8"
                          type="submit"
                        >
                          Save
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn mb-0">
                <div class="box-title">Organisations</div>
                <div class="btns-right"></div>
              </div>
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(updateUserOrganization)">
                  <v-row>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Business Type"
                        rules="required"
                      >
                        <label for=""
                          >Business Type <span class="required">*</span></label
                        >
                        <v-select
                          :items="businessTypeList"
                          v-model="businessType"
                          outlined
                          dense
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Display Name"
                        rules="required"
                      >
                        <label for=""
                          >Display Name<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="displayName"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="GSTIN"
                        rules="required"
                      >
                        <label for=""
                          >GSTIN<span class="required">*</span></label
                        >
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="gstin"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Registered Business Name"
                        rules="required"
                      >
                        <label for=""
                          >Registered Business Name<span class="required"
                            >*</span
                          ></label
                        >
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-domain"
                          :error-messages="errors"
                          v-model="registerBusinessName"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <label for="">Website URL</label>
                      <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-web"
                        v-model="websiteuUrl"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <label for="">GST</label>
                      <v-file-input
                        truncate-length="15"
                        outlined
                        dense
                        prepend-inner-icon="mdi-attachment"
                        prepend-icon=""
                        append-icon="mdi-download"
                        @click:append="downloadGstFile"
                        v-model="gst"
                      ></v-file-input>
                    </v-col>

                    <v-col cols="12" md="3">
                      <label for="">Company Logo</label>
                      <v-file-input
                        truncate-length="15"
                        outlined
                        dense
                        prepend-inner-icon="mdi-attachment"
                        prepend-icon=""
                        v-model="companyLogo"
                        :append-icon="
                          documents[1].doc_path != null
                            ? 'mdi-file-eye-outline'
                            : ''
                        "
                        @click:append="downloadCompanyLogo"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Year Of Incorporation"
                        rules="required"
                      >
                        <label for=""
                          >Year Of Incorporation<span class="required"
                            >*</span
                          ></label
                        >
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="yearsOfCorporation"
                          type="number"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Material"
                        rules="required"
                      >
                        <label for=""
                          >Material Type <span class="required">*</span></label
                        >
                        <v-select
                          :items="materialTypeList"
                          v-model="materialType"
                          item-value="id"
                          item-text="name"
                          outlined
                          dense
                          chips
                          multiple
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Capacity"
                        rules="required"
                      >
                        <label for=""
                          >Consupation / Production
                          <span class="required">*</span></label
                        >
                        <v-select
                          :items="monthlyCapacityList"
                          item-value="id"
                          item-text="name"
                          v-model="monthlyCapacity"
                          outlined
                          dense
                          suffix="/Month"
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Installed Capacity"
                        rules="required"
                      >
                        <label for=""
                          >Installed Capacity<span class="required"
                            >*</span
                          ></label
                        >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              size="18"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span
                            >Please enter monthly installed capacity of
                            production</span
                          >
                        </v-tooltip>
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="installedCapacity"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Number of employees"
                      >
                        <label for="">Number of employees</label>
                        <v-text-field
                          outlined
                          dense
                          :error-messages="errors"
                          v-model="noOfEmployee"
                          type="number"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="pb-0"
                      v-if="materialType.includes(materialOtherOptionId)"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="New Material Type"
                        rules="required"
                      >
                        <label for=""
                          >New Material Type<span class="required"
                            >*</span
                          ></label
                        >
                        <v-text-field
                          outlined
                          dense
                          v-model="newMaterialType"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="text-right">
                        <v-btn
                          elevation="0"
                          color="#9DA73E"
                          dark
                          class="px-8"
                          type="submit"
                        >
                          Save
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
              <template v-if="is_branches == 'Yes'">
                <v-divider class="my-8"></v-divider>
                <div class="box-title-with-btn mb-0">
                  <div class="box-title">Branches</div>
                  <div class="btns-right">
                    <v-btn
                      elevation="0"
                      color="#9DA73E"
                      dark
                      type="submit"
                      @click="addBranch = true"
                    >
                      <v-icon> mdi-plus </v-icon>
                      Add
                    </v-btn>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12" class="mt-3">
                    <v-data-table
                      dense
                      :headers="headers"
                      :items="organizationList"
                      :items-per-page="5"
                      class="elevation-1"
                      hide-default-footer
                    >
                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip
                          bottom
                          v-if="item.status == 'New' && isAdmin"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              small
                              elevation="0"
                              color="transparent"
                              @click="approveBranch(item.id)"
                            >
                              <v-icon color="#8f9742"> mdi-check-bold </v-icon>
                            </v-btn>
                          </template>
                          <span>Approve</span>
                        </v-tooltip>

                        <v-tooltip
                          bottom
                          v-if="item.status == 'New' && isAdmin"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              fab
                              small
                              elevation="0"
                              color="transparent"
                              @click="rejectBranch(item.id)"
                            >
                              <v-icon color="red"> mdi-close-thick </v-icon>
                            </v-btn>
                          </template>
                          <span>Reject</span>
                        </v-tooltip>

                        <v-btn
                          fab
                          small
                          elevation="0"
                          color="transparent"
                          @click="editBranchPopUp(item.id)"
                        >
                          <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                        </v-btn>
                        <!-- <v-btn fab small elevation="0" color="transparent">
                          <v-icon color="red"> mdi-delete-outline </v-icon>
                        </v-btn> -->
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </template>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn mb-0">
                <div class="box-title">KYC</div>
                <div class="btns-right"></div>
              </div>
              <v-row>
                <v-col cols="12" md="3">
                  <label for="">Business Type</label>
                  <v-text-field outlined dense  :error-messages="errors"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label for="">Business PAN</label>
                  <v-text-field outlined dense  prepend-inner-icon="mdi-card-account-details-outline"
                    :error-messages="errors"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label for="">Business Name as per PAN</label>
                  <v-text-field outlined dense  prepend-inner-icon="mdi-email-outline"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label for="">GSTIN</label>
                  <div class="d-flex">
                    <v-text-field outlined dense ></v-text-field>
                    <div>
                      <v-btn outlined height="40" elevation="0" color="#9DA73E" dark class="ml-2 px-1">
                        Verify
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <div class="text-right">
                    <v-btn elevation="0" color="#9DA73E" dark class="px-8">
                      Save
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item> -->

          <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn mb-0">
                <div class="box-title">Change Password</div>
                <div class="btns-right"></div>
              </div>
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(changeUserPassword)">
                  <v-row>
                    <v-col
                      md="3"
                      cols="12"
                      class="pb-0"
                      v-if="isShowCurrentPassword"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Current password"
                        :rules="isShowCurrentPassword ? 'required' : ''"
                      >
                        <label for=""
                          >Current Password
                          <span class="required">*</span></label
                        >
                        <v-text-field
                          v-model="currentpassword"
                          outlined
                          dense
                          :error-messages="errors"
                          :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="isShow ? 'text' : 'password'"
                          @click:append="isShow = !isShow"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3" cols="12" class="pb-0">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Password"
                        rules="required|min:8"
                        vid="password"
                      >
                        <label for=""
                          >New Password<span class="required">*</span></label
                        >
                        <v-text-field
                          v-model="newPassword"
                          outlined
                          dense
                          :error-messages="errors"
                          :append-icon="isShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="isShow2 ? 'text' : 'password'"
                          @click:append="isShow2 = !isShow2"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="3" cols="12" class="pb-0">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Confirmation Password"
                        rules="required|confirmed:password|min:8"
                      >
                        <label for=""
                          >Confirm Password
                          <span class="required">*</span></label
                        >
                        <v-text-field
                          v-model="confirmPassword"
                          outlined
                          dense
                          :error-messages="errors"
                          :append-icon="isShow3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="isShow3 ? 'text' : 'password'"
                          @click:append="isShow3 = !isShow3"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="text-left mt-4">
                        <v-btn
                          elevation="0"
                          color="#9DA73E"
                          dark
                          class="px-8"
                          type="submit"
                        >
                          Change Password
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-card>
          </v-tab-item>

          <!-- <v-tab-item>
            <v-card flat>
              <div class="box-title-with-btn">
                <div class="box-title">Documents</div>
                <div class="btns-right">
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-8"
                    type="submit"
                    @click="addDocument= true"
                  >
                    Upload
                  </v-btn>
                </div>
              </div>

              <v-data-table
                dense
                :headers="headers"
                :items="documents"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn v-if="item.doc_path != null" fab small elevation="0" color="transparent" @click="downloadDocument(item.path,item.id)">
                    <v-icon color="#8f9742"> mdi-tray-arrow-down </v-icon>
                  </v-btn>
                  <v-btn fab small elevation="0" color="transparent">
                    <v-icon color="red"> mdi-delete-outline </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item> -->
        </v-tabs-items>
      </div>
      <ConformationBox
        v-if="isShowApprove"
        :title="'Confirmation'"
        message="Are you sure want to approve ?"
        confirmText="Approve"
        @confirm="confirmApprove"
        @cancel="closeApprovModal"
      />
      <ConformationBox
        v-if="isShowReject"
        :title="'Confirmation'"
        message="Are you sure want to reject ?"
        confirmText="Reject"
        @confirm="confirmReject"
        @cancel="closeRejectModal"
      />
      <ConformationBox
        v-if="isShowResend"
        :title="'Confirmation'"
        message="Are you sure want to resend the password link ?"
        confirmText="Resend"
        @confirm="confirmResned"
        @cancel="closeResnedModal"
      />
      <ConformationBox
        v-if="isShowBlock"
        :title="'Confirmation'"
        message="Are you sure want to block the user ?"
        confirmText="Block"
        @confirm="confirmBlock"
        @cancel="closeBlockModal"
      />
      <ConformationBox
        v-if="isShowDeactive"
        :title="'Confirmation'"
        message="Are you sure want to deactive the user ?"
        confirmText="Deactive"
        @confirm="confirmDeactive"
        @cancel="closeDeactiveModal"
      />
      <AddEditBranch
        v-if="addBranch"
        :businessTypeList="businessTypeList"
        :cityItem="cityItem"
        :countryItem="countryItem"
        :stateItem="stateItem"
        :monthlyCapacityList="monthlyCapacityList"
        :userId="user_id"
        :materialTypeList="materialTypeList"
        :organizationId="organizationId"
        @closeAddeddit="closeAddeditModal"
        @successAddEdit="successAddeditOrg"
      />
      <ConformationBox
        v-if="isShowApproveBranch"
        :title="'Confirmation'"
        message="Are you sure want to approve branch ?"
        confirmText="Approve"
        @confirm="confirmApproveBranch"
        @cancel="closeApprovBranchModal"
      />
      <ConformationBox
        v-if="isShowRejectBranch"
        :title="'Confirmation'"
        message="Are you sure want to reject ?"
        confirmText="Reject"
        @confirm="confirmRejectBranch"
        @cancel="closeRejectModalbranch"
      />
    </v-container>

    <v-dialog v-model="addDocument" width="500" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between"
          >Upload Document
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="addDocument = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <label for="">Document<span class="required">*</span></label>
              <v-file-input
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                truncate-length="15"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadProfilePic" width="500" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between"
          >Upload Profile Picture
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="uploadProfilePic = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="12">
              <label for=""
                >Profile Picture<span class="required">*</span></label
              >
              <v-file-input
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                truncate-length="15"
                v-model="profile_picture"
                required
              ></v-file-input>
            </v-col>

            <v-col cols="12" class="text-right pt-0">
              <v-btn
                elevation="0"
                color="#9DA73E"
                dark
                class="px-8"
                :disabled="profile_picture != null ? false : true"
                @click="submitProfilePicture()"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewImage" width="800">
      <v-card>
        <v-card-title class="dialog-title justify-space-between"
          >View Image
          <v-btn
            fab
            x-small
            elevation="0"
            color="transparent"
            @click="viewImage = false"
          >
            <v-icon color="white"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <img
                style="width: 100%"
                src="@/assets/images/agri-waste.png"
                alt=""
              />
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn
                elevation="0"
                color="#9DA73E"
                dark
                class="px-8"
                @click="viewImage = false"
              >
                Okay
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import AddEditBranch from "./Branch/AddEditBranch.vue";
import { required, min, confirmed } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { UUID, AUTHENTIACTIONKEY } from "@/utils/helper";
import axios from "axios";
import {
  API_URL,
  FILEDOWNALOADHEADER,
  COMMONPAYLOAD,
  getDecodedStringBase64,
  getDecodedString,
  getDecodedToken,
  encryptPayload,
} from "@/utils/helper";
setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("min", {
  ...min,
  message: "{_field_} may not be less than {length} characters",
});
extend("confirmed", {
  ...confirmed,
  message: "Please make sure your password and confirm password are the same.",
});
export default {
  components: {
    dashlayout,
    ConformationBox,
    ValidationObserver,
    ValidationProvider,
    AddEditBranch,
  },
  name: "dashboard",
  inject: ["mySpinner"],
  methods: {
    downloadGstFile() {
      this.mySpinner.val = true;
      axios({
        url: `${API_URL}get-file/user_gst_path/${this.organization_id}`,
        method: "POST",
        data: COMMONPAYLOAD,
        headers: { ...FILEDOWNALOADHEADER },
        responseType: "blob",
      })
        .then((res) => {
          var FILE = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          var extension = res.headers["content-type"].split("/");
          docUrl.setAttribute(
            "download",
            `${this.displayName}_gst.${extension[1]}`
          );
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
    downloadCompanyLogo() {
      this.mySpinner.val = true;
      axios({
        url: `${API_URL}get-file/company_logo_image/${this.organization_id}`,
        method: "POST",
        COMMONPAYLOAD,
        headers: { ...FILEDOWNALOADHEADER },
        responseType: "blob",
      })
        .then((res) => {
          var FILE = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          var extension = res.headers["content-type"].split("/");
          docUrl.setAttribute(
            "download",
            `${this.displayName}_logo.${extension[1]}`
          );
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
    approveBranch(id) {
      this.isApproveBranchId = id;
      this.isShowApproveBranch = true;
    },
    rejectBranch(id) {
      this.isRejectBranchId = id;
      this.isShowRejectBranch = true;
    },
    closeRejectModalbranch() {
      this.isRejectBranchId = "";
      this.isShowRejectBranch = false;
    },
    async confirmRejectBranch() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("updateBranchStatus", {
          organization_id: this.isRejectBranchId,
          status: "Rejected",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowRejectBranch = false;
          this.getUserBasicInfo();
          this.isRejectBranchId = "";
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isShowRejectBranch = false;
          this.isRejectBranchId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    closeApprovBranchModal() {
      this.isApproveBranchId = "";
      this.isShowApproveBranch = false;
    },
    async confirmApproveBranch() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("updateBranchStatus", {
          organization_id: this.isApproveBranchId,
          status: "Approved",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowApproveBranch = false;
          this.getUserBasicInfo();
          this.isApproveBranchId = "";
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isShowApproveBranch = false;
          this.isApproveBranchId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    closeAddeditModal() {
      this.addBranch = false;
      this.organizationId = "";
    },
    editBranchPopUp(id) {
      this.organizationId = id;
      this.addBranch = true;
    },
    successAddeditOrg() {
      this.organizationId = "";
      this.addBranch = false;
      this.user_id = this.$route.params.id
        ? getDecodedStringBase64(this.$route.params.id)
        : localStorage.getItem("user_id");
      this.getDropdownData();
      this.getUserBasicInfo();
      this.getStateDropdownData();
      this.getProfilePic();
    },
    goToLogin() {
      this.$router.push({ path: "/login" });
    },
    showApproveModal(id) {
      this.isShowApprove = true;
      this.isApproveId = id;
    },
    showRejectModal(id) {
      this.isShowReject = true;
      this.isRejectId = id;
    },
    async getProfilePic() {
      this.mySpinner.val = true;
      const token = getDecodedToken();
      axios({
        url: `${API_URL}get-file/user_profile_image/${this.user_id}`,
        method: "POST",
        COMMONPAYLOAD,
        headers: { authorization: "Bearer " + token },
        responseType: "blob",
      })
        .then((res) => {
          // this.profilePicUrl = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          this.profilePicUrl = window.URL.createObjectURL(blob);
          this.dispayProfilePic = true;
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
    async confirmApprove() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("updateUserStatus", {
          user_id: this.isApproveId,
          status: "Approved",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowApprove = false;
          this.getUserBasicInfo();
          this.isApproveId = "";
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isShowApprove = false;
          this.isApproveId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    closeApprovModal() {
      this.isShowApprove = false;
      this.isApproveId = "";
    },
    closeRejectModal() {
      this.isShowReject = false;
      this.isRejectId = "";
    },
    async confirmReject() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("updateUserStatus", {
          user_id: this.isRejectId,
          status: "Rejected",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowReject = false;
          this.isRejectId = "";
          this.getUserBasicInfo();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isShowReject = false;
          this.isRejectId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async changeUserPassword() {
      try {
        this.mySpinner.val = true;
        let payload = null;
        let temp_url = "";
        if (!this.isShowCurrentPassword) {
          payload = {
            password: this.newPassword,
            password_confirmation: this.confirmPassword,
            user_id: this.user_id,
          };
          temp_url = "updateUserPasswordFromAdmin";
        } else {
          payload = {
            password: this.newPassword,
            password_confirmation: this.confirmPassword,
            old_password: this.currentpassword,
          };
          temp_url = "updateUserPassword";
        }

        const response = await this.$store.dispatch(temp_url, payload);
        if (response.http_status === 200) {
          localStorage.setItem("otp_key", response.data.otp_key);
          if (this.isAdmin && this.$route.params.id != undefined) {
            this.$router.push({ path: "/userlist" });
          } else {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("is_admin");
            localStorage.removeItem("is_buyer");
            localStorage.removeItem("is_seller");
            localStorage.removeItem("is_trader");
            localStorage.removeItem("last_password_changed_at");
            localStorage.removeItem("last_name");
            localStorage.removeItem("userid");
            localStorage.removeItem("useremail");
            localStorage.removeItem("userphone");
            localStorage.removeItem("registration_number");
            localStorage.removeItem("otp_key");
            localStorage.removeItem("first_name");
            localStorage.removeItem("branches");
            localStorage.removeItem("selectedBranch");
            localStorage.removeItem("is_partner");
            this.$router.push({ path: "/login" });
          }
          localStorage.removeItem("last_password_changed_at");
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async updateUserBasicInfo() {
      this.mySpinner.val = true;
      try {
        const payload = {
          organization_id: this.organization_id,
          user_addresses_id: this.user_addresses_id,
          id: this.user_id,
          is_user_profile: true,
          is_address: true,
          is_organisations: false,
          is_kyc: false,
          last_name: this.lastName,
          first_name: this.firstName,
          email: this.email,
          gender: this.gender,
          mobile_number: this.phoneNumber,
          whatsapp_number: this.whatsappNumber,
          address_line_1: this.address1,
          address_line_2: this.address2,
          district: this.area,
          pin_code: this.pinCode,
          city_id: this.city,
          state_id: this.state,
          country_id: this.country,
          is_branches: this.is_branches,
        };
        const response = await this.$store.dispatch(
          "updateAdminUserProfile",
          payload
        );
        if (response.http_status === 200 || response.http_status === 201) {
          this.$toasted.success(response.message);
          this.getUserBasicInfo();
          this.getDropdownData();
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getUserBasicInfo() {
      this.mySpinner.val = true;
      try {
        const payload = {
          id: this.user_id,
        };
        const response = await this.$store.dispatch("getUserProfile", payload);
        if (response.http_status === 200) {
          this.user_addresses_id = response.data.user_addresses_id;
          this.organization_id = response.data.organization_id;
          this.state = response.data.state_id;
          this.city = response.data.city_id;
          this.pinCode = response.data.pin_code;
          this.area = response.data.district;
          this.address1 = response.data.address_line_1;
          this.address2 = response.data.address_line_2;
          this.businessType = response.data.business_type;
          this.displayName = response.data.display_name;
          this.gstin = response.data.gst_number;
          this.registerBusinessName = response.data.registered_business_name;
          this.websiteuUrl = response.data.website_url;
          this.yearsOfCorporation = response.data.years_of_incorporation;
          this.materialType = response.data.material_type;
          this.monthlyCapacity = response.data.monthly_capacity;
          this.installedCapacity = response.data.installed_capacity;
          this.noOfEmployee = response.data.number_of_employees;
          this.newMaterialType = response.data.material_type_other;
          this.documents = [
            {
              doc_path: response.data.gst_file_path,
              name: "GST Document",
              path: "user_gst_path",
              id: response.data.user_id,
            },
            {
              doc_path: response.data.company_logo,
              name: "Company Logo",
              path: "company_logo_image",
              id: response.data.user_id,
            },
          ];

          this.whatsappNumber = response.data.whatsapp_number;
          this.phoneNumber = response.data.phone;
          this.gender = response.data.gender;
          this.firstName = response.data.first_name;
          this.lastName = response.data.last_name;
          this.reference_by = response.data.reference_by;
          this.email = response.data.email;
          this.status = response.data.status;
          this.is_branches = response.data.is_branches;
          if (response.data.is_seller) {
            this.userRole = "Seller";
          }
          if (response.data.is_buyer) {
            this.userRole = "Buyer";
          }
          if (response.data.is_admin) {
            this.userRole = "Admin";
          }
          if (response.data.is_trader) {
            this.userRole = "Trader";
          }
          if (response.data.is_branches == "Yes") {
            this.getBranchList();
          }
          if (response.data.is_partner) {
        this.userRole = "Partner";
      }

          if (!response.data.is_admin) {
            this.getStateDropdownData();
            this.getCityDropdownData();
          }

          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module: "MATERIAL_TYPE,MONTHLY_CAPACITY,COUNTRY_LIST,BUSINESS_TYPE",
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.materialTypeList = response.data.material_type;
          this.monthlyCapacityList = response.data.monthly_capacity;
          this.countryItem = response.data.country_list;
          this.businessTypeList = response.data.business_type;
          this.mySpinner.val = false;
          this.materialTypeList.map((item, index) => {
            if (item.name == "Other" || item.name == "other") {
              this.materialOtherOptionId = item.id;
            }
          });
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getStateDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = { module: "STATE_LIST", country_id: 101 };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.stateItem = response.data.state_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getCityDropdownData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          module: "CITY_LIST",
          country_id: 101,
          state_id: this.state,
        };
        const response = await this.$store.dispatch("loadDynamicData", payload);
        if (response.http_status === 200) {
          this.cityItem = response.data.city_list;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async updateUserOrganization() {
      const data = {
        organization_id: this.organization_id,
        user_id: this.user_id,
        uuid: UUID,
        authentication_key: AUTHENTIACTIONKEY,
        business_type: this.businessType,
        display_name: this.displayName,
        gst_number: this.gstin,
        registered_business_name: this.registerBusinessName,
        website_url: this.websiteuUrl,
        years_of_incorporation: this.yearsOfCorporation,
        material_type: this.materialType,
        monthly_capacity: this.monthlyCapacity,
        installed_capacity: this.installedCapacity,
        number_of_employees: this.noOfEmployee,
        material_type_other: this.newMaterialType,
      };
      const encryptData = encryptPayload(data);
      let formData = new FormData();
      if (this.gst != null) {
        formData.append("gst", this.gst);
      }
      if (this.companyLogo != null) {
        formData.append("company_logo", this.companyLogo);
      }
      formData.append("bfb_data", encryptData.bfb_data);
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "updateUserOrgationDetails",
          formData
        );
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async submitProfilePicture() {
      const data = {
        id: this.user_id,
      };
      const encryptData = encryptPayload(data);
      let formData = new FormData();
      formData.append("bfb_data", encryptData.bfb_data);
      if (this.profile_picture != null) {
        formData.append("profile_picture", this.profile_picture);
      }
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch(
          "submitProfilePicture",
          formData
        );
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.getProfilePic();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
        this.profile_picture = null;
        this.uploadProfilePic = false;
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
        this.profile_picture = null;
        this.uploadProfilePic = false;
      }
    },
    getImageName() {
      return `https://ui-avatars.com/api/?name=${this.firstName}+${this.lastName}`;
    },
    downloadDocument(path, id) {
      this.mySpinner.val = true;
      axios({
        url: `${API_URL}get-image/${path}/${id}`,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          var extension = res.headers["content-type"].split("/");
          docUrl.setAttribute("download", `${path}.${extension[1]}`);
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
    async getBranchList() {
      this.mySpinner.val = true;
      try {
        const payload = {
          user_id: this.user_id,
          search: "",
          action: "LIST",
          sorting_order: "",
          order_by: "",
          show_records: 1000,
          page: 1,
        };
        const response = await this.$store.dispatch("getBranchList", payload);
        if (response.http_status === 200) {
          this.organizationList = response.data.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },

    showSendModal(id) {
      this.isShowResend = true;
      this.isResendId = id;
    },
    closeResnedModal() {
      this.isShowResend = false;
      this.isResendId = "";
    },
    async confirmResned() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("resendLink", {
          user_id: this.isResendId,
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowResend = false;
          this.isResendId = "";
          this.getUserBasicInfo();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isShowResend = false;
          this.isResendId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },

    showBlockModal(id) {
      this.isShowBlock = true;
      this.isBlockId = id;
    },
    closeBlockModal() {
      this.isShowBlock = false;
      this.isBlockId = "";
    },
    async confirmBlock() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("adminUserStatus", {
          user_id: this.isBlockId,
          status: "Locked",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowBlock = false;
          this.isBlockId = "";
          this.getUserBasicInfo();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isBlockId = false;
          this.isRejectId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },

    showDeactiveModal(id) {
      this.isShowDeactive = true;
      this.isDeactiveId = id;
    },
    closeDeactiveModal() {
      this.isShowDeactive = false;
      this.isDeactiveId = "";
    },
    async confirmDeactive() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("adminUserStatus", {
          user_id: this.isDeactiveId,
          status: "Suspended",
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          this.isShowDeactive = false;
          this.isDeactiveId = "";
          this.getUserBasicInfo();
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
          this.isShowDeactive = false;
          this.isDeactiveId = "";
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
  created() {
    this.user_id = this.$route.params.id
      ? getDecodedStringBase64(this.$route.params.id)
      : localStorage.getItem("user_id");
    this.getDropdownData();
    this.getUserBasicInfo();
    this.getProfilePic();
    let isAdmin = getDecodedString(localStorage.getItem("user_type"));
    if (isAdmin == '"Admin"') {
      this.isAdmin = true;
    }
    // if(isAdmin == '"Partner"') {
    //   console.warn('Partner')
    // }
    if (this.isAdmin) {
      if (
        this.$route.params.id != undefined &&
        this.user_id != this.$route.params.id
      ) {
        this.isShowCurrentPassword = false;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.user_id = this.$route.params.id
        ? getDecodedStringBase64(this.$route.params.id)
        : localStorage.getItem("user_id");
      this.getDropdownData();
      this.getUserBasicInfo();
    },
  },
  data: () => ({
    viewImage: false,
    addBranch: false,
    profilePicUrl: "",
    dispayProfilePic: false,
    isShowApprove: false,
    isShowReject: false,
    isShowResend: false,
    isShowBlock: false,
    isShowDeactive: false,
    isApproveId: "",
    isRejectId: "",
    isResendId: "",
    isBlockId: "",
    isDeactiveId: "",

    isShowApproveBranch: false,
    isShowRejectBranch: false,
    isApproveBranchId: "",
    isRejectBranchId: "",

    currentpassword: "",
    newPassword: "",
    confirmPassword: "",
    isShow3: false,
    isShow2: false,
    isShow: false,
    isShowCurrentPassword: true,

    user_addresses_id: "",
    organization_id: "",
    is_branches: "",
    user_id: "",
    firstName: "",
    lastName: "",
    userRole: "",
    email: "",
    gender: "",
    phoneNumber: "",
    whatsappNumber: "",
    address1: "",
    address2: "",
    area: "",
    city: "",
    state: "",
    country: 101,
    pinCode: "",
    reference_by: "",

    tab2: null,
    genderItem: ["Male", "Female"],
    isBranchesItem: ["Yes", "No"],
    countryItem: [],
    stateItem: [],
    cityItem: [],
    status: "",

    businessType: "",
    displayName: "",
    gstin: "",
    registerBusinessName: "",
    websiteuUrl: "",
    gst: null,
    companyLogo: null,
    yearsOfCorporation: "",
    monthlyCapacityList: [],
    monthlyCapacity: "",
    materialTypeList: [],
    materialType: [],
    newMaterialType: "",
    installedCapacity: "",
    noOfEmployee: "",
    businessTypeList: [],
    addDocument: false,
    uploadProfilePic: false,
    profile_picture: null,
    isAdmin: false,
    headers: [
      {
        text: "Display Name",
        align: "start",
        sortable: false,
        value: "display_name",
      },
      {
        text: "GSTIN",
        align: "start",
        sortable: false,
        value: "gst_number",
      },
      {
        text: "Consupation / Production",
        align: "start",
        sortable: false,
        value: "monthly_capacity",
      },
      {
        text: "Address line 1",
        align: "start",
        sortable: false,
        value: "address_line_1",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "status",
      },
      { text: "Action", value: "action", align: "right", width: "180px" },
    ],

    organizationList: null,
    organizationId: "",

    documents: [],
  }),
};
</script>