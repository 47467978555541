<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-chat-question-outline
            </v-icon>
            Enquiry
          </div>
          <!-- <div class="btns-right">
            <v-btn elevation="0" color="#9DA73E" dark text @click="gotoEdit">
              <v-icon class="mr-2"> mdi-arrow-left </v-icon>
              Back
            </v-btn>
          </div> -->
        </div>

        <div class="four-box-of-status">
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="userModuleData"
                :options.sync="options"
                :server-items-length="total"
                class="elevation-0"
                :footer-props="footerList"
                dense
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="editUserModule(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <!-- <v-btn
                      fab
                      small
                      elevation="0"
                      color="transparent"
                      @click="deleteModule(item.id)"
                    >
                      <v-icon color="red"> mdi-delete-outline </v-icon>
                    </v-btn> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container-fluid>
    <EditEnquiry
      v-if="addEditModule"
      @cancel="closeDialog"
      @success="addUpdateSuccessFull"
      :id="editUserModuleId"
    />
    <ConformationBox
      v-if="isShowDeleteDialog"
      :title="'Confirmation'"
      message="Are you sure want to delete ?"
      @confirm="confirmDeleteForm"
      @cancel="closeDeleteFormModal"
    />
  </dashlayout>
</template>
  
  <script>
import dashlayout from "../layout/dashlayout.vue";
import EditEnquiry from "@/components/dashboard/EditEnquiryList.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import { FOOTERPAGELIST, PAGINATIONOPTIONS } from "@/utils/helper";
import EditEnquiryListVue from "./EditEnquiryList.vue";
export default {
  components: { dashlayout, ConformationBox, EditEnquiry },
  name: "BlogList",
  inject: ["mySpinner"],

  created() {
    this.fetchUserModuleList();
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchUserModuleList();
        }
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,
    addEditModule: false,

    options: PAGINATIONOPTIONS,
    total: 0,
    userModuleData: [],
    footerList: {
      "items-per-page-options": [10, 20, 50, 100],
    },

    name: "",
    slug: "",
    icon: "",
    order: "",
    parentModule: "",
    visiableNav: false,
    isShowDeleteDialog: false,

    deleteUserModuleId: "",
    editUserModuleId: "",

    items: ["user 1", "user 2", "user 3", "user 4"],
    headers: [
      { text: "Name", value: "first_name" },
      { text: "Email", value: "email" },
      { text: "Phone Number", value: "phone" },
      { text: "Company Name", value: "company_name" },
      { text: "Quantity ", value: "quantity" },
      { text: "Category ", value: "material_type", sortable: false },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
    details: [
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
      {
        name: "Auction Bid",
        slug: "auction_bid",
        icon: "mdi-book-outline",
        action: "",
      },
    ],
  }),
  methods: {
    async fetchUserModuleList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getAdminEnquiryList", {
          ...this.options,
          action: "LIST",
        });
        if (response.http_status === 200) {
          this.userModuleData = response.data.data;
          this.total = response.data.total;
          // this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.mySpinner.val = false;
        this.$toasted.error(e.message);
      }
    },
    addUpdateSuccessFull() {
      this.addEditModule = false;
      this.fetchUserModuleList();
      this.editUserModuleId = "";
    },
    closeDialog() {
      this.addEditModule = false;
      // this.fetchUserModuleList();
      this.editUserModuleId = "";
    },
    deleteModule(id) {
      this.isShowDeleteDialog = true;
      this.deleteUserModuleId = id;
    },
    closeDeleteFormModal() {
      this.isShowDeleteDialog = false;
      this.deleteUserModuleId = "";
    },
    async confirmDeleteForm() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("deleteAdminTag", {
          id: this.deleteUserModuleId,
        });
        if (response.http_status === 200) {
          this.isShowDeleteDialog = false;
          this.deleteUserModuleId = "";
          this.fetchUserModuleList();
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.isShowDeleteDialog = false;
          this.deleteUserModuleId = "";
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    editUserModule(id) {
      this.editUserModuleId = id;
      this.addEditModule = true;
    },
  },
};
</script>