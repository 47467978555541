<template>
  <section class="main-pd-wrapper" style="width: 1000px; margin: auto" v-if="purchaseOrderData">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(savePurchaseOrder)">
    <div style="display: table-header-group">
      <h4
        style="
          text-align: center;
          margin: 0px;
          background: rgb(247 247 247);
          padding: 1rem;
        "
      >
        <b>Purchase Order</b>
      </h4>

      <table style="width: 100%; table-layout: fixed; margin-top: 10px">
        <tr>
          <td style="border-right: 1px solid #ddd">
            <div
              style="
                text-align: left;
                margin: auto;
                line-height: 1.5;
                font-size: 14px;
                color: #4a4a4a;
              "
            >
              <img style="
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 1000px;
" v-if="logoUrl != ''" :src="logoUrl" alt="" />
              <p >NAME   :- {{ purchaseOrderData && purchaseOrderData.auctioneer_organization_name }}</p>
                        <p>ADDRESS:- {{purchaseOrderData && purchaseOrderData.auctioneer_address_line_1}}<br/>
                          {{purchaseOrderData && purchaseOrderData.auctioneer_address_line_2}}<br v-if=" purchaseOrderData&& purchaseOrderData.auctioneer_address_line_2 != null"/>
                          {{purchaseOrderData && purchaseOrderData&& purchaseOrderData.auctioneer_district}}<br v-if=" purchaseOrderData&& purchaseOrderData.auctioneer_district != null"/>
                          {{purchaseOrderData && purchaseOrderData&& purchaseOrderData.auctioneer_city_name}}-{{purchaseOrderData && purchaseOrderData.auctioneer_state_name}}<br/>
                          {{purchaseOrderData && purchaseOrderData&& purchaseOrderData.auctioneer_country_name}}-{{purchaseOrderData && purchaseOrderData.auctioneer_pin_code}}<br/>
                        </p>
              <p style="font-weight: bold; margin-top: 15px">
                GSTIN : {{ purchaseOrderData && purchaseOrderData.auctioneer_gst_number }}
              </p>
              <p style="font-weight: bold">
                Mobile No. :
                <a href="tel:7894561232" style="color: #868e3e">{{ purchaseOrderData && purchaseOrderData.auctioneer_mobile_number }}</a>
              </p>
            </div>
          </td>
          <td
            align="right"
            style="
              text-align: right;
              padding-left: 50px;
              line-height: 1.5;
              color: #323232;
            "
          >
            <div>
              <div>
                <p>PO No:- <strong> {{  purchaseOrderData && purchaseOrderData.purchase_order_number}} </strong></p>
            <p style="margin: 5px 0">
              PO Date Time:- <strong> {{ purchaseOrderData && purchaseOrderData.po_generate_date_time }} </strong>
            </p>
            <!-- <p style="margin-bottom: 5px">
              GST: <strong>{{ purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.bidder_gst_number }}</strong>
            </p> -->
              </div>
             
            </div>
          </td>
        </tr>
      </table>
    </div>
    <table
      class="table table-bordered h4-14"
      style="width: 100%; -fs-table-paginate: paginate; margin-top: 15px"
    >
      <thead style="display: table-header-group">
        <tr
          style="
            margin: 0px;
            background: rgb(247, 247, 247);
            padding: 15px 15px 15px 20px;
            -webkit-print-color-adjust: exact;
          "
        >
          <td colspan="3">
            <h3 style="margin-top: 5px; margin-bottom: 5px">
                Bill to
              </h3>
              <p>NAME   :- {{ purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.bidder_organization_name }}</p>
                        <p>ADDRESS:- {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.address_line_1}}<br/>
                          {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.address_line_2}}<br v-if=" purchaseOrderData&& purchaseOrderData.bidder_organization &&purchaseOrderData.bidder_organization.address_line_2 != null"/>
                          {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.district}}<br v-if=" purchaseOrderData&& purchaseOrderData.bidder_organization &&purchaseOrderData.bidder_organization.district != null"/>
                          {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.city_name}}-{{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.state_name}}<br/>
                          {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.country_name}}-{{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.pin_code}}<br/>
                        </p>
                        <p>MOBILE :- {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.bidder_mobile_number}}</p>
                        <p>GST   :- {{purchaseOrderData && purchaseOrderData.bidder_organization && purchaseOrderData.bidder_organization.bidder_gst_number}}</p> 
              <p style="font-size: 14px">
                <!-- Aakriti Rathore,B-268, west vinod nagar, street no.2,near press
                apartment bus stand, Patparganj, IP
                Extension,Delhi,07,110092,Delhi(07)<br />
                Tel:
                <a href="tel:01241234568" style="color: #97a045"
                  >0124-1234568</a
                >-->
                <!-- {{ purchaseOrderData && purchaseOrderData.companyAddress }} -->
              </p> 
          </td>
          <td colspan="3" v-if="purchaseOrderData && purchaseOrderData.auction_for == 'sell'">
              <h3 style="margin-top: 5px; margin-bottom: 5px">
                Ship to
              </h3>
              <validation-provider v-slot="{ errors }" name="Shipping address" :rules="purchaseOrderData.auction_for == 'sell' ? 'required' : ''">
                <v-textarea
                  v-model="shipping_address"
                  :error-messages="errors"
                ></v-textarea>
              </validation-provider>
          </td>
          <td colspan="3" v-else>
              <!-- <h3 style="margin-top: 5px; margin-bottom: 5px">
                Ship to
              </h3>
              <p >{{ purchaseOrderData && purchaseOrderData.shipping_address }}</p> -->
              <h3 style="margin-top: 5px; margin-bottom: 5px">
                Ship to
              </h3>
              <validation-provider v-slot="{ errors }" name="Shipping address" :rules="purchaseOrderData.auction_for == 'buy' ? 'required' : ''">
                <v-textarea
                  v-model="shipping_address"
                  :error-messages="errors"
                ></v-textarea>
              </validation-provider>
          </td>
         
        </tr>
      </thead>

      <tfoot></tfoot>
    </table>

    <table class="hm-p table-bordered" style="width: 100%; margin-top: 10px">
      <thead>
        <tr>
          <th style="width: 50px">#</th>
          <th><h4>Item Name</h4></th>
          <th style="width: 100px">
            <h4>
              HSN<br />
              Code
            </h4>
          </th>
          <th style="width: 80px"><h4>QTY</h4></th>
          <th style="width: 80px">
            <h4>
              Unit<br />
              Price
            </h4>
          </th>

          <th style="width: 80px">
            <h4>
              Taxable <br />
              Amount
            </h4>
          </th>
          <template v-if="purchaseOrderData.igst == null">
            <th style="width: 80px">
            <h4>
              CGST({{purchaseOrderData && purchaseOrderData.cgst_percentage}})<br />
              <hr />
              Amount
            </h4>
          </th>
          <th style="width: 80px">
            <h4>
              SGST({{purchaseOrderData && purchaseOrderData.sgst_percentage}})<br />
              <hr />
              Amount
            </h4>
          </th>
          </template>
          <th style="width: 80px" v-else>
            <h4>
              IGST({{purchaseOrderData && purchaseOrderData.igst_percentage}})<br />
              <hr />
              Amount
            </h4>
          </th>
          <th style="width: 120px"><h4>TOTAL</h4></th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>01</td>
          <td>{{ purchaseOrderData && purchaseOrderData.item_name }}</td>
          <td>{{ purchaseOrderData && purchaseOrderData.hsn }}</td>
          <td>{{ purchaseOrderData && purchaseOrderData.quantity }}</td>
          <td>{{ purchaseOrderData && purchaseOrderData.price }}</td>
          <td>{{ purchaseOrderData && purchaseOrderData.order_amount }}</td>
          <template v-if="purchaseOrderData.igst == null || purchaseOrderData.igst == 0">
            <td>{{ purchaseOrderData && purchaseOrderData.cgst }}</td>
            <td>{{purchaseOrderData && purchaseOrderData.sgst }}</td>
          </template>
          <template v-else>
            <td>{{purchaseOrderData && purchaseOrderData.igst}}</td>
          </template>
          <td>{{ purchaseOrderData && purchaseOrderData.total }}</td>
        </tr>
       

        <tr style="background-color: #f7f7f7">
          <td></td>
          <td><strong>Total</strong></td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td><strong>{{ purchaseOrderData && purchaseOrderData.order_amount }}</strong></td>
          <template v-if="purchaseOrderData.igst == null || purchaseOrderData.igst == 0">
            <td><strong>{{ purchaseOrderData && purchaseOrderData.cgst }} </strong></td>
            <td><strong>{{ purchaseOrderData && purchaseOrderData.sgst }}</strong></td>
          </template>
          <template v-else>
            <td><strong>{{ purchaseOrderData && purchaseOrderData.igst }} </strong></td>
          </template>
          <td><strong>{{ purchaseOrderData && purchaseOrderData.total }} </strong></td>
        </tr>
      </tbody>
    </table>


    <table class="hm-p table-bordered" style="width: 100%; margin-top: 30px">

      <tr style="background-color: rgb(247, 247, 247)">
        <th>Total Order Value</th>
        <td colspan="2" style="width: 70px; text-align: right; border-right: none">
          <b>₹ {{ purchaseOrderData && purchaseOrderData.total }}</b>
        </td>
        <td colspan="5" style="border-left: none"></td>
      </tr>
    </table>

    <table style="width: 100%" cellspacing="0" cellspadding="0" border="0">
      <tr>
        <td>
          <h5 style="margin: 8px 0">Terms and conditions:</h5>
          <!-- <p style="font-size: 12px">
            2. All transactions/matters are strictly Subject to Ahmedabad
            jurisdiction only.
          </p>
          <p style="font-size: 12px">
            3. If any disputes arise between the parties it shall be resolved
            through Arbitration under the Arbitration& Conciliation Act,1996.The
            arbitration proceeding shall be held at Ahmedabad.
          </p>
          <p style="font-size: 12px">
            4.Goods once sold will never be taken. back or exchanged unless
            there are any manufacturing defects.
          </p>
          <p style="font-size: 12px">
            5. Interest @ 18% p.a. plus applicable GST will be charged for
            payment after due date.
          </p>
          <p style="font-size: 12px">
            6. Prices are subject to revision for reasons beyond our control.
          </p>
          <p style="font-size: 12px">
            8. Payment Terms : Within 7 Days of the Date of Invoice.
          </p> -->
          <validation-provider v-slot="{ errors }" name="Terms & conditions" rules="required">
          <v-textarea
            v-model="terms_conditions"
            :error-messages="errors"
            autofocus
          ></v-textarea>
        </validation-provider>
        </td>
        <td style="width: 300px; vertical-align: top; text-align: right">
          <h5 style="margin: 8px 0">For, : {{purchaseOrderData && purchaseOrderData.auctioneer_organization_name }}</h5>

          <div>
            <h2 style="color:#6363e9">{{purchaseOrderData && purchaseOrderData.auctioneer_organization_name }}</h2>
            <div style="font-size: 12px">Authorized Signatory</div>
          </div>
        </td>
      </tr>
    </table>
    <table style="width: 100%" cellspacing="0" cellspadding="0" border="0">
      <tr>
        <td>
        
        </td>
        <td style="width: 300px; vertical-align: top; text-align: right">
          <v-btn rounded dark color="#9DA73E" block class="cta" type="submit">Submit</v-btn>
        </td>
      </tr>
    </table>
    </form>
    </validation-observer>
  </section>
</template>

<script>
import { getDecodedStringBase64,getDecodedToken,API_URL,COMMONPAYLOAD  } from '@/utils/helper'
import axios from "axios";
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
export default {
  data() {
    return {
      auction_number : '',
      purchaseOrderData : null,
      terms_conditions : '',
      shipping_address : '',
      logoUrl : '',
    }
  },
  inject: ["mySpinner"],
  created() {
    this.auction_number = getDecodedStringBase64(this.$route.params.id)
    this.getPurschaseOrder()
  },
  components : {
    ValidationObserver,
    ValidationProvider,
  },
  methods : {
    async getPurschaseOrder() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("generatePurchaseOrder", {
          auction_number: this.auction_number,
          po_type: "get",
        });
        if (response.http_status === 200) {
          this.purchaseOrderData = response.data
          this.shipping_address = response.data.shipping_address
          this.mySpinner.val = false;
          if(response.data != '') {

            this.getAuctineerPic(response.data.auctioneer_organization_id)
          }
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async savePurchaseOrder() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("generatePurchaseOrder", {
          auction_number: this.auction_number,
          po_type: "save",
          shipping_address : this.shipping_address,
          terms_conditions : this.terms_conditions
        });
        if (response.http_status === 200) {
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
          setTimeout(() => {
            this.$router.push({
              path: `/auction/auctioneer/${getEncodedStringBase64(
                this.auction_number
              )}`,
            });
          },2000)
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getAuctineerPic(id) {
      this.mySpinner.val = true;
      const token = getDecodedToken();
      axios({
        url: `${API_URL}get-file/company_logo_image/${id}`,
        method: "POST",
        COMMONPAYLOAD,
        headers: { authorization : 'Bearer ' + token},
        responseType: "blob",
      })
        .then((res) => {
          // this.profilePicUrl = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          this.logoUrl = window.URL.createObjectURL(blob);
          // this.dispayProfilePic = true;
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
  }
};
</script>

<style>
* {
  box-sizing: border-box;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #ddd;
  padding: 10px;
  word-break: break-all;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.h4-14 h4 {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 5px;
}
.img {
  margin-left: "auto";
  margin-top: "auto";
  height: 30px;
}
pre,
p {
  /* width: 99%; */
  /* overflow: auto; */
  /* bpicklist: 1px solid #aaa; */
  padding: 0;
  margin: 0;
}
table {
  font-family: arial, sans-serif;
  width: 100%;
  border-collapse: collapse;
  padding: 1px;
}
.hm-p p {
  text-align: left;
  padding: 1px;
  padding: 5px 4px;
}
td,
th {
  text-align: left;
  padding: 8px 6px;
}
.table-b td,
.table-b th {
  border: 1px solid #ddd;
}
th {
  /* background-color: #ddd; */
}
.hm-p td,
.hm-p th {
  padding: 3px 0px;
}
.cropped {
  float: right;
  margin-bottom: 20px;
  height: 100px; /* height of container */
  overflow: hidden;
}
.cropped img {
  width: 400px;
  margin: 8px 0px 0px 80px;
}
.main-pd-wrapper {
  box-shadow: 0 0 10px #ddd;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
}
</style>