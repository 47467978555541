<template>
    <dashlayout>
        <v-container-fluid fluid>
            <div class="dash-bg">
                <div class="box-title-with-btn">
                    <div class="box-title">
                        <v-icon size="24" color="#9DA73E" class="mr-2">
                            mdi-account-check-outline
                        </v-icon>
                        Change Password
                    </div>
                    <div class="btns-right">

                    </div>
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(changeUserPassword)">
                        <v-row>
                            <v-col cols="6" class="pb-0">
                                <validation-provider v-slot="{ errors }" name="Current Password" rules="required">
                                    <label for="">Current Password <span class="required">*</span></label>
                                    <v-text-field 
                                        v-model="currentpassword" 
                                        outlined 
                                        dense 
                                        :error-messages="errors" 
                                        :append-icon="isShow ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="isShow ? 'text' : 'password'"
                                        @click:append="isShow = !isShow"
                                    >
                                    </v-text-field>
                                </validation-provider>
                            </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" name="Password" rules="required|min:8" vid="password">
                                    <label for="">New Password<span class="required">*</span></label>
                                    <v-text-field 
                                        v-model="newPassword" 
                                        outlined 
                                        dense 
                                        :error-messages="errors" 
                                        name="password"
                                        :append-icon="isShow2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="isShow2 ? 'text' : 'password'"
                                        @click:append="isShow2 = !isShow2"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" name="Confirmatoin" rules="required|confirmed:password|min:8">
                                    <label for="">Confirm Password<span class="required">*</span></label>
                                    <v-text-field 
                                        v-model="confirmPassword" 
                                        outlined 
                                        dense 
                                        :error-messages="errors"
                                        :append-icon="isShow3 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="isShow3 ? 'text' : 'password'"
                                        @click:append="isShow3 = !isShow3"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                            <v-row>
                            <v-col cols="6">
                                <v-btn rounded dark color="#9DA73E" block large class="cta" type="submit">
                                    Change Password
                                </v-btn>
                            </v-col>
                        </v-row>
                    </form>
                </validation-observer>
            </div>
        </v-container-fluid>
    </dashlayout>
</template>

<script>
import dashlayout from "@/components/layout/dashlayout.vue";
import { getDecodedStringBase64 } from '@/utils/helper'
import { required, digits, email, max,min, regex,confirmed } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})
extend('min', {
    ...min,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})
extend('confirmed', {
    ...confirmed,
    message: 'Please make sure your password and confirm password are the same.',
})

export default {
    components: {
        dashlayout, ValidationObserver,
        ValidationProvider
    },
    name: "changepassword",
    inject: ['mySpinner'],
    data: () => ({
        currentpassword: '',
        newPassword: '',
        confirmPassword: '',
        isShow3 : false,
        isShow2 : false,
        isShow : false,
    }),
    methods: {
        async changeUserPassword() {
            try {
                this.mySpinner.val = true;
                const payload = {
                    password: this.newPassword,
                    password_confirmation: this.confirmPassword,
                    old_password: this.currentpassword
                }
                const response = await this.$store.dispatch('updateUserPassword', payload)
                if (response.http_status === 200) {
                    localStorage.setItem('otp_key', response.data.otp_key);
                    if(getDecodedStringBase64(localStorage.getItem("is_partner")) == 1) {
                        this.$router.push({ path: '/partner-dashboard'}) 
                    } else {
                        this.$router.push({ path: '/dashboard' })
                    }
                    localStorage.removeItem('last_password_changed_at')
                    this.$toasted.success(response.message)
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message)
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message)
                this.mySpinner.val = false;
            }
        }
    }

};
</script>

<style>

</style>