<template>
  <div>
  <!-- <v-app> -->
      <!-- <v-main> -->
    <clartity />
    <router-view/>
    <Loading v-if="spin.val" />
  </div>
      <!-- </v-main> -->
  <!-- </v-app> -->
</template>

<script>
  import Loading from '@/components/common/Loading.vue'
  import clartity from './components/clarity/gtag.vue';
  export default {
    name: 'App',

    data: () => ({
      spin : {
        val : false,
      }
      
    }),
    components : {
      Loading,
      clartity
    },
    provide() {
      return {
        mySpinner : this.spin
      }
    }
  };
</script>
