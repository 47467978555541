<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-book-edit-outline
            </v-icon>
            Schedule Auction
          </div>
          <div class="btns-right">
            <v-btn elevation="0" color="#9DA73E" dark text :to="user_type == 'Admin' ? '/adminAuctionList':'/auctionList'">
              <v-icon class="mr-2"> mdi-arrow-left </v-icon>
              Back
            </v-btn>
          </div>
        </div>
        <div class="four-box-of-status">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(confirmAuctionSubmit)">
              <v-row v-if="user_type == 'Admin'">
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="User"
                    rules="required"
                  >
                    <label for=""
                      >User <span class="required">*</span></label
                    >
                    <v-select
                      v-model="user"
                      :items="userData"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      :error-messages="errors"
                      @change="getUserBranches"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Branch"
                    rules="required"
                  >
                    <label for=""
                      >Branch <span class="required">*</span></label
                    >
                    <v-select
                      v-model="branch"
                      :items="branchData"
                      item-value="id"
                      item-text="display_name"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Auction For"
                    rules="required"
                  >
                    <label for=""
                      >Auction For <span class="required">*</span></label
                    >
                    <v-select
                      v-model="auction_for"
                      :items="actionForData"
                      item-value="key"
                      item-text="value"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Raw Material"
                    rules="required"
                  >
                    <label for=""
                      >Raw Material <span class="required">*</span></label
                    >
                    <v-select
                      v-model="category_id"
                      :items="categoriesData"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Quantity"
                    rules="required"
                  >
                    <label for=""
                      >Quantity <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="quantity"
                      outlined
                      type="number"
                      dense
                      suffix="Tonne"
                      :min="1"
                      :error-messages="errors"
                      @change="changeQuantity"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expected/Base Price"
                    rules="required"
                  >
                    <label for=""
                      >Expected/Base Price <span class="required">*</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            size="18"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>Please enter per ton price</span>
                      </v-tooltip>
                    </label>

                    <v-text-field
                      v-model="expected_price"
                      outlined
                      type="number"
                      dense
                      suffix=""
                      :error-messages="errors"
                      :min="0"
                      @change="changeExpactedBasePrice"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Range to Increase/decrease"
                    :rules="{ required: true, max: maxRangeOfBidAmount }"
                  >
                    <label for=""
                      >Range to Increase/decrease
                      <span class="required">*</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            size="18"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>Any added value must be a multiple of 5 and <br> must not exceed 2% of the base price.</span>
                      </v-tooltip>
                    </label>
                    <v-text-field
                      v-model="range_of_bid_amount"
                      outlined
                      type="number"
                      dense
                      placeholder="10"
                      :error-messages="errors"
                      :min="0"
                      :max="maxRangeOfBidAmount"
                      :step="5"
                      @change="checkMultiPlyOf5"
                      :disabled="expected_price == ''"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment Condition"
                    rules="required"
                  >
                    <label for=""
                      >Payment condition <span class="required">*</span></label
                    >
                    <v-select
                      v-model="payment_condition"
                      :items="paymentConditionData"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3" v-if="payment_condition == 'Credit'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Payment terms"
                    rules="required"
                  >
                    <label for=""
                      >Payment terms <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="payment_term_days"
                      outlined
                      type="number"
                      dense
                      placeholder="10"
                      :suffix="
                        payment_condition == 'Advanced'
                          ? '%'
                          : payment_condition == 'Credit'
                          ? 'Days'
                          : ''
                      "
                      :min="minMaxPaymentTerms[0]"
                      :max="minMaxPaymentTerms[1]"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Expected delivery days"
                    rules="required"
                  >
                    <label for=""
                      >Expected delivery days
                      <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="expected_delivery_days"
                      outlined
                      type="number"
                      dense
                      suffix="Days"
                      :min="0"
                      :max="100"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Packaging"
                    rules="required"
                  >
                    <label for=""
                      >Packaging <span class="required">*</span></label
                    >
                    <v-select
                      v-model="packaging"
                      :items="packagingData"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Distance"
                    rules="required"
                  >
                    <label for=""
                      >Distance <span class="required">*</span></label
                    >
                    <v-select
                      v-model="distance"
                      :items="distanceData"
                      dense
                      outlined
                      suffix="KM"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Delivery"
                    rules="required"
                  >
                    <label for=""
                      >Delivery <span class="required">*</span></label
                    >
                    <v-select
                      v-model="delivery"
                      :items="deliveryData"
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Scheduled"
                    rules="required"
                  >
                    <label for=""
                      >Scheduled for <span class="required">*</span></label
                    >
                    <v-select
                      v-model="scheduled"
                      :items="scheduledData"
                      dense
                      outlined
                      :error-messages="errors"
                      item-value="key"
                      item-text="value"
                      @change="changeScheduled"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3" v-if="auction_for == 'buy'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Shipping Address"
                    rules="required"
                  >
                    <label for=""
                      >Shipping Address
                      <span class="required">*</span></label
                    >
                    <v-textarea
                      v-model="shipping_address"
                      outlined
                      auto-grow
                      dense
                      rows="1"
                      row-height="25"
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3" v-if="auction_for == 'buy'">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Delivery Pincode"
                    rules="required"
                  >
                    <label for="">Delivery Pincode
                      <span class="required">*</span></label
                    >
                    <v-text-field
                      v-model="delivery_pincode"
                      outlined
                      type="text"
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" lg="3">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Start Date"
                            rules="required"
                          >
                            <label for=""
                              >Start Date <span class="required">*</span></label
                            >
                            <v-text-field
                              v-model="start_date"
                              outlined
                              dense
                              hint="MM/DD/YYYY format"
                              persistent-hint
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                              :disabled="isTimingDisable ? true : false"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="start_date"
                          no-title
                          :min="getMinStartDate()"
                          :max="getMaxStartDate()"
                          @input="menu1 = false"
                          @change="selectStartDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Start Time"
                        rules="required"
                      >
                        <label for=""
                          >Start Time <span class="required">*</span></label
                        >
                        <v-select
                          v-model="start_time"
                          :items="startTimeData"
                          dense
                          outlined
                          :error-messages="errors"
                          @change="selectEndDate"
                          :disabled="isTimingDisable ? true : false"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <!-- <v-col cols="11" sm="3">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Start Time"
                        rules="required"
                      >
                        <label for="">Start Time </label>
                        <v-text-field
                          v-model="start_time"
                          dense
                          outlined
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="start_time"
                      full-width
                      @click:minute="$refs.menu2.save(start_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col> -->
                    <v-col cols="12" lg="3">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="End Date"
                            rules="required"
                          >
                            <label for=""
                              >End Date <span class="required">*</span></label
                            >
                            <v-text-field
                              v-model="end_date"
                              outlined
                              dense
                              hint="MM/DD/YYYY format"
                              persistent-hint
                              prepend-inner-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :error-messages="errors"
                              :disabled="
                                isTimingDisable
                                  ? true
                                  : start_date != ''
                                  ? false
                                  : true
                              "
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="end_date"
                          no-title
                          :max="endEndDate"
                          :min="startEndDate"
                          @input="menu3 = false"
                          @change="selectEndDate"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="End Time"
                        rules="required"
                      >
                        <label for=""
                          >End Time <span class="required">*</span></label
                        >
                        <v-select
                          v-model="end_time"
                          :items="endTimeData"
                          dense
                          outlined
                          :error-messages="errors"
                          :disabled="isTimingDisable ? true : false"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <!-- <v-col cols="11" sm="3">
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="End Time"
                        rules="required"
                      >
                        <label for="">End Time </label>
                        <v-text-field
                          v-model="end_time"
                          dense
                          outlined
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker
                      v-if="menu4"
                      v-model="end_time"
                      full-width
                      @click:minute="$refs.menu4.save(end_time)"
                    ></v-time-picker>
                  </v-menu>
                </v-col> -->
                    <!-- <v-col cols="12" md="12" class="mt-3">
                  <label for="">Auction Desc</label>
                  <textarea name="" id="" cols="20" rows="5" v-model="auction_description"></textarea>
                </v-col> -->
                    <v-col cols="12" md="12" class="mt-3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Aceept Term & Condition"
                        rules="required"
                      >
                        <v-checkbox
                          class="mt-0 pt-0"
                          v-model="is_terms_accepted"
                          :error-messages="errors"
                        >
                          <template v-slot:label>
                            <div>
                              I agree that
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <a
                                    target="_blank"
                                    href="/TermCondition"
                                    @click.stop
                                    v-on="on"
                                  >
                                    Term & Condition
                                  </a>
                                </template>
                                Click here to read details
                              </v-tooltip>
                            </div>
                          </template>
                        </v-checkbox>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="text-right pt-0">
                  <v-btn
                    elevation="0"
                    color="#9DA73E"
                    dark
                    class="px-8"
                    type="submit"
                  >
                    Schedule
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </div>
      </div>
    </v-container-fluid>

    <v-dialog v-model="quantityWarning" max-width="500">
      <v-card>
        <v-card-title class="text-h6"> Min. Quantity Alert </v-card-title>

        <v-card-text style="font-size: 1rem; font-weight: 500">
          <v-alert dense outlined color="warning"  border="left">
            <v-icon color="warning" >
              mdi-information-outline
            </v-icon>
            The minimum quantity order is 20.
          </v-alert>
          <p>
            If you attempt to enter a quantity below 20, please contact us for
            further information.
          </p>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>

          <v-btn color="grey darken-1" text @click="quantityWarning = false"> Okay </v-btn>

          <v-btn color="primary" @click="gotoInquiryNOw" class="px-4"> Enquiry Now </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="auctionDetail" persistent max-width="1100">
      <v-card>
       
        <v-card-title >
          <div class="box-title-with-btn mb-0">
              <div class="box-title mb-0">
                <v-icon size="24" color="#9DA73E" class="mr-2">
                  mdi-gavel
                </v-icon>
                Auction Details
              </div>
            </div>
        </v-card-title>
        <v-container class="bg-surface-variant pt-0">
          <v-row class="row-of-auction-details ma-0">
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Auction For</label>
                <div class="details-text">
                  {{ auction_for }}
                </div>
              </v-sheet>
            </v-col>
          
          
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Raw Material</label>
                <div class="details-text">
                  {{ displayCatList[category_id] }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Quantity</label>
                <div class="details-text">
                  {{ quantity }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Expected/Base Price</label>
                <div class="details-text">
                  {{ expected_price }}
                </div>
              </v-sheet>
            </v-col>
          
          
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Range to Increase/decrease</label>
                <div class="details-text">
                  {{ range_of_bid_amount }}
                </div>
              </v-sheet>
            </v-col>
            
            
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Payment condition</label>
                <div class="details-text">
                  {{ payment_condition }}
                </div>
              </v-sheet>
            </v-col>
            
            
            <v-col cols="12" md="3" v-if="payment_condition == 'Credit'">
              <v-sheet class="pa-1">
                <label>Payment terms</label>
                <div class="details-text">
                  {{ payment_term_days }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Expected Delivery Days</label>
                <div class="details-text">
                  {{ expected_delivery_days }}
                </div>
              </v-sheet>
            </v-col>
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Packaging</label>
                <div class="details-text">
                  {{ packaging }}
                </div>
              </v-sheet>
            </v-col>
            
            
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Distance</label>
                <div class="details-text">
                  {{ distance }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Delivery</label>
                <div class="details-text">
                  {{ delivery }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Scheduled for</label>
                <div class="details-text">
                  {{ scheduled }}
                </div>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="3" v-if="auction_for == 'buy'">
              <v-sheet class="pa-1">
                <label>Shipping Address : </label> {{ shipping_address }}
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="3" v-if="auction_for == 'buy'">
              <v-sheet class="pa-1">
                <label>Delivery Pincode : </label> {{ delivery_pincode }}
              </v-sheet>
            </v-col>
          </v-row>
          <v-row class="row-of-auction-details ma-0">
            <v-col cols="12" sm="3">
              <v-sheet class="pa-1">
                <label>Start Date</label>
                <div class="details-text">
                  {{ start_date }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>Start Time </label>
                <div class="details-text">
                  {{ start_time }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>End Date</label>
                <div class="details-text">
                  {{ end_date }}
                </div>
              </v-sheet>
            </v-col>
           
           
            <v-col cols="12" md="3">
              <v-sheet class="pa-1">
                <label>End Time</label>
                <div class="details-text">
                  {{ end_time }}
                </div>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="auctionDetail = false"> Cancel </v-btn>
          <v-btn color="primary" @click="addUpdateAuction" class="px-4"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import { getDecodedString } from '../../utils/helper'
import {
  required,
  digits,
  email,
  max,
  min,
  regex,
  confirmed,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
extend("min", {
  ...min,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("confirmed", {
  ...confirmed,
  message: "Please make sure your password and confirm password are the same.",
});
export default {
  components: {
    dashlayout,
    ValidationObserver,
    ValidationProvider,
  },
  name: "AuctionAddEdit",
  inject: ["mySpinner"],
  data: () => ({
    quantityWarning: false,
    menu1: false,
    // menu2: false,
    menu3: false,
    // menu4: false,
    time: null,
    isTimingDisable: false,
    categoriesData: [],
    items: ["Programming", "Design", "Vue", "Vuetify"],
    distanceData: ["100 km - 300 km", "300 km - 500 km", "500 km - 700 km"],
    deliveryData: ["Ex-works", "FOR at site"],
    packagingData: ["Loose", "Packed"],
    paymentConditionData: ["Advanced", "Against delivery", "Credit"],
    scheduledData: [
      {
        key: "regular",
        value: "Regular",
      },
      {
        key: "immediate",
        value: "Immediate",
      },
    ],

    id: "",
    quantity: "",
    category_id: "",
    expected_delivery_days: "",
    expected_price: "",
    range_of_bid_amount: "",
    scheduled: "",
    distance: "",
    delivery: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    payment_condition: "",
    payment_term_days: "",
    auction_description: "",
    packaging: "",
    is_terms_accepted: "",
    user_id: "",
    temp_url: "",
    actionForData: [
      {
        key: "buy",
        value: "Buy",
      },
      {
        key: "sell",
        value: "Sell",
      },
    ],
    auction_for: "",
    startEndDate: "",
    endEndDate: "",

    startTimeData: [],
    endTimeData: [],

    maxRangeOfBidAmount: 0,
    auctionDetail: false,
    displayCatList: [],
    user: '',
    userData: [],
    shipping_address: '',
    delivery_pincode: '',
    branch: '',
    branchData: [],
  }),
  created() {
    this.getDropdownData();
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getAuctionData()
    }
    this.user_type = getDecodedString(localStorage.getItem('user_type')).replace(/["']/g, "")
    if(this.user_type == 'Admin'){
      this.getUserListData();
    }
  },
  computed: {
    allowedDates() {
      if (this.start_date != "") {
        return new Date(this.start_date)
          .toLocaleDateString("pt-br")
          .split("/")
          .reverse()
          .join("-");
      }
    },
    minMaxPaymentTerms() {
      if (this.payment_condition == "Advanced") {
        return [10, 100];
      } else if (this.payment_condition == "Credit") {
        return [1, 60];
      } else {
        return [0, 0];
      }
    }
  },
  methods: {
    async getUserListData() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getUserListData", {});
        if (response.http_status === 200) {
          var temp = [];
          response.data.forEach(ele => {
            temp.push({
              id: ele.id,
              name: ele.first_name+' '+ele.last_name
            });
          });
          this.userData = temp
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getUserBranches() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getUserBranches", {
          user_id: this.user          
        });
        if (response.http_status === 200) {
          this.branchData = response.data
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getDropdownData() {
      this.mySpinner.val = true;
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getAllCategory", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.categoriesData = response.data;
          var temp = [];
          this.categoriesData.forEach(ele => {
            temp[ele.id] = ele.name
          });
          this.displayCatList = temp
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    confirmAuctionSubmit(){
      this.auctionDetail = true
    },
    async addUpdateAuction() {
      this.mySpinner.val = true;
      try {
        let payload = {
          organization_id: this.user_type == 'Admin' ? this.branch : localStorage.getItem("selectedBranch"),
          user_id: this.user_id != "" ? this.user_id : localStorage.getItem("user_id"),
          id: this.id,
          quantity: this.quantity,
          category_id: this.category_id,
          expected_delivery_days: this.expected_delivery_days,
          expected_price: this.expected_price,
          range_of_bid_amount: this.range_of_bid_amount,
          distance: this.distance,
          delivery: this.delivery,
          scheduled: this.scheduled,
          start_date: this.start_date,
          start_time: this.start_time,
          end_date: this.end_date,
          end_time: this.end_time,
          payment_condition: this.payment_condition,
          auction_description: this.auction_description,
          packaging: this.packaging,
          is_terms_accepted: this.is_terms_accepted,
          auction_for: this.auction_for,
        };

        if (this.payment_condition == "Credit") {
          let tmp = { payment_term_days: this.payment_term_days };
          payload = { ...payload, ...tmp };
        }

        if (this.auction_for == "buy") {
          let tmp = { shipping_address: this.shipping_address };
          let tmp1 = { delivery_pincode: this.delivery_pincode };
          payload = { ...payload, ...tmp, ...tmp1 };
        }
        this.temp_url = this.id != "" ? this.user_type == 'Admin' ? "updateAdminAuction" : "updateAuction" : "createAuction"; 

        const response = await this.$store.dispatch(this.temp_url, payload);
        if (response.http_status === 200 || response.http_status === 201) {
          this.$toasted.success(response.message);
          // this.getDropdownData();
          this.mySpinner.val = false;
          if( this.user_type == 'Admin'){
            this.$router.push({ path: `/adminAuctionList` });
          }else{
            this.$router.push({ path: `/myAuction` });
          }
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getAuctionData() {
      this.mySpinner.val = true;
      try {
        const payload = {
          action: "VIEW",
          id: this.id,
        };
        const response = await this.$store.dispatch("viewAuction", payload);
        if (response.http_status === 200) {
          this.user_id = response.data.user_id;
          this.quantity = response.data.quantity;
          this.category_id = response.data.category_id;
          this.expected_delivery_days = response.data.expected_delivery_days;
          this.expected_price = response.data.expected_price;
          this.range_of_bid_amount = response.data.range_of_bid_amount;
          this.distance = response.data.distance;
          this.delivery = response.data.delivery;
          this.scheduled = response.data.scheduled;
          this.start_date = response.data.start_date;
          this.start_time = response.data.start_time;
          this.end_date = response.data.end_date;
          this.end_time = response.data.end_time;
          this.payment_term_days = response.data.payment_term_days;
          this.payment_condition = response.data.payment_condition;
          this.auction_description = response.data.auction_description;
          this.packaging = response.data.packaging;
          this.auction_for = response.data.auction_for;
          this.shipping_address = response.data.shipping_address;
          this.delivery_pincode = response.data.delivery_pincode;
          this.mySpinner.val = false;
          if(this.user_type == 'Admin') {
            this.user = response.data.user_id;
            this.getUserBranches();
            this.branch = response.data.organization_id;
          }
          this.editTimeSetTimes()
          this.changeExpactedBasePrice()
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    checkMultiPlyOf5() {
      if (this.range_of_bid_amount % 5 !== 0) {
        this.range_of_bid_amount = "";
      }
    },
    getMinStartDate() {
      return new Date()
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
    },
    getMaxStartDate() {
      let today = new Date();
      var lastDate = new Date(
        today.getFullYear(),
        today.getMonth() + 3,
        today.getDate()
      );

      return lastDate
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
    },
    selectStartDate() {
      this.start_time = "";
      this.end_time = "";

      this.end_date = "";
      this.startEndDate = new Date(this.start_date)
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      let selectedStartDate = new Date(this.start_date);
      var lastDate = new Date(
        selectedStartDate.getFullYear(),
        selectedStartDate.getMonth(),
        selectedStartDate.getDate() + 1
      );
      this.endEndDate = lastDate
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");

      const start = new Date();
      if (selectedStartDate.getDate() == start.getDate()) {
        start.setHours(start.getHours() + 1, 0, 0);
      } else {
        start.setHours(0, 0, 0);
      }
      const end = new Date();
      end.setHours(23, 30, 0);

      this.startTimeData = [];
      while (start <= end) {
        let selectBoxminutes = start
          .getMinutes()
          .toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
        let tmp =
          start.getHours() <= 9 ? "0" + start.getHours() : start.getHours();
        let selectBoxTime = tmp + ":" + selectBoxminutes;
        this.startTimeData.push(selectBoxTime);
        start.setMinutes(start.getMinutes() + 30);
      }
    },
    selectEndDate() {
      this.end_time = "";

      let tmpStartDate = new Date(this.start_date)
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      let tmpEndtDate = new Date(this.end_date)
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");

      let selectedStartTime = this.start_time.split(":");
      if (tmpStartDate == tmpEndtDate) {
        const start = new Date();
        start.setHours(selectedStartTime[0], selectedStartTime[1], 0);
        start.setMinutes(start.getMinutes() + 30);

        const end = new Date();
        end.setHours(23, 30, 0);

        this.endTimeData = [];
        while (start <= end) {
          let selectBoxminutes = start
            .getMinutes()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          let tmp =
            start.getHours() <= 9 ? "0" + start.getHours() : start.getHours();
          let selectBoxTime = tmp + ":" + selectBoxminutes;
          start.setMinutes(start.getMinutes() + 30);
          this.endTimeData.push(selectBoxTime);
        }
      } else {
        const start = new Date();
        start.setHours(0, 0, 0);

        const end = new Date();
        end.setHours(selectedStartTime[0], selectedStartTime[1], 0);
        // end.setMinutes(start.getMinutes() - 30)

        this.endTimeData = [];
        while (start <= end) {
          let selectBoxminutes = start
            .getMinutes()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          let tmp =
            start.getHours() <= 9 ? "0" + start.getHours() : start.getHours();
          let selectBoxTime = tmp + ":" + selectBoxminutes;
          start.setMinutes(start.getMinutes() + 30);
          this.endTimeData.push(selectBoxTime);
        }
      }
    },
    changeScheduled() {
      this.startTimeData = [];
      this.endTimeData = [];
      this.start_date = "";
      this.end_date = "";
      this.start_time = "";
      this.end_time = "";

      if (this.scheduled == "immediate") {
        

        const start = new Date()
        const end = new Date()

        // start.setHours(23, 0, 0)

        if(start.getHours() == 23){
          start.setDate(start.getDate() + 1)
          end.setDate(end.getDate() + 1)        
          start.setHours(0, 0, 0)
          end.setHours(23, 30, 0)
        }else{
          start.setHours(start.getHours() + 1, 0, 0)
          end.setHours(23, 30, 0)
        }

        while (start < end) {
          let selectBoxminutes = start.getMinutes().toLocaleString("en-US", {
                                                                    minimumIntegerDigits: 2,
                                                                    useGrouping: false,
                                                                  })
          let tmp = start.getHours() <= 9 ? "0" + start.getHours() : start.getHours()
          let selectBoxTime = tmp + ":" + selectBoxminutes
          this.startTimeData.push(selectBoxTime)
          start.setMinutes(start.getMinutes() + 30)

          let selectBoxEndtime = start
            .getMinutes()
            .toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });
          var tmpp = start.getHours() <= 9 ? "0" + start.getHours() : start.getHours()
          this.endTimeData.push(tmpp + ":" + selectBoxEndtime)
        }

        this.start_date = start
          .toLocaleDateString("pt-br")
          .split("/")
          .reverse()
          .join("-")
        this.end_date = end
          .toLocaleDateString("pt-br")
          .split("/")
          .reverse()
          .join("-")

        this.start_time = this.startTimeData[0]
        this.end_time = this.endTimeData[0]
      } else {
      }
    },
    changeQuantity(){
      if(Number.parseInt(this.quantity) < 20){
        this.quantityWarning = true;
        this.quantity = ''
      }
    },
    changeExpactedBasePrice(){ 
      this.expected_price == '' ? this.range_of_bid_amount = '' : ''
      if(this.expected_price != ''){
        let two_per = this.expected_price * 2 / 100
        this.maxRangeOfBidAmount = Math.floor(two_per/5) * 5
      }else{
        this.maxRangeOfBidAmount = 0
      }
    },
    gotoInquiryNOw(){
      localStorage.setItem('tmp_check_redirection', 'true')
      this.$router.push('/inquiry')
    },
    editTimeSetTimes() {
      let arrSD = this.start_date.split("-");
      let arrST = this.start_time.split(":");
      let SD = new Date(arrSD[0], arrSD[1] - 1, arrSD[2], arrST[0], arrST[1]);
      let now = new Date();
      // let arrED = this.end_date.split('-');
      // let arrET = this.end_time.split(':');
      // let ED = new Date(arrED[0], arrED[1]-1, arrED[2], arrET[0], arrET[1])
      // console.log(
      //   this.start_date,
      //   this.start_time,
      //   this.end_date,
      //   this.end_time
      // );
      this.startTimeData = [];
      if (now.getTime() >= SD.getTime()) {
        this.startTimeData.push(this.start_time);
        this.endTimeData.push(this.end_time);
        this.isTimingDisable = true;
      } else {
        if (this.scheduled == "immediate") {
          this.startTimeData.push(this.start_time);
          this.endTimeData.push(this.end_time);
        } else {
          this.isTimingDisable = false;

          /* Start Time Set function */
          let selectedStartDate = new Date(this.start_date);
          if (selectedStartDate.getDate() == now.getDate()) {
            now.setHours(now.getHours() + 1, 0, 0);
          } else {
            now.setHours(0, 0, 0);
          }
          const end = new Date();
          end.setHours(23, 30, 0);

          this.startTimeData = [];
          while (now <= end) {
            let selectBoxminutes = now
              .getMinutes()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              });
            let tmp =
              now.getHours() <= 9 ? "0" + now.getHours() : now.getHours();
            let selectBoxTime = tmp + ":" + selectBoxminutes;
            this.startTimeData.push(selectBoxTime);
            now.setMinutes(now.getMinutes() + 30);
          }

          /* End Time Set function */
          let tmpStartDate = new Date(this.start_date)
            .toLocaleDateString("pt-br")
            .split("/")
            .reverse()
            .join("-");
          let tmpEndtDate = new Date(this.end_date)
            .toLocaleDateString("pt-br")
            .split("/")
            .reverse()
            .join("-");

          let selectedStartTime = this.start_time.split(":");
          if (tmpStartDate == tmpEndtDate) {
            const start = new Date();
            start.setHours(selectedStartTime[0], selectedStartTime[1], 0);
            start.setMinutes(start.getMinutes() + 30);

            const end = new Date();
            end.setHours(23, 30, 0);

            this.endTimeData = [];
            while (start <= end) {
              let selectBoxminutes = start
                .getMinutes()
                .toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                });
              let tmp =
                start.getHours() <= 9
                  ? "0" + start.getHours()
                  : start.getHours();
              let selectBoxTime = tmp + ":" + selectBoxminutes;
              start.setMinutes(start.getMinutes() + 30);
              this.endTimeData.push(selectBoxTime);
            }
          } else {
            const start = new Date();
            start.setHours(0, 0, 0);

            const end = new Date();
            end.setHours(selectedStartTime[0], selectedStartTime[1], 0);
            // end.setMinutes(start.getMinutes() - 30)

            this.endTimeData = [];
            while (start <= end) {
              let selectBoxminutes = start
                .getMinutes()
                .toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                });
              let tmp = start.getHours() <= 9 ? "0" + start.getHours() : "";
              let selectBoxTime = tmp + ":" + selectBoxminutes;
              start.setMinutes(start.getMinutes() + 30);
              this.endTimeData.push(selectBoxTime);
            }
          }
        }
      }
    },
  },
};
</script>