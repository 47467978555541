import axiosServices from "@/utils/axios";
import { COMMONPAYLOAD, OTPCOMMONPAYLOAD, FORMDATAHEADER, APPKEY, API_URL,getDecodedToken } from '@/utils/helper'
import axios from "axios";
import axiosServicesFormData from "@/utils/axiosFormData";

const state = () => ({
    
})

const getters  = {

}

const actions = {
    getNotificationList({ commit },inputPage) {
        return axiosServices
            .post('user/notifications', {...inputPage,...COMMONPAYLOAD})
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    readAllNotification({ commit }, inputPage) {
       
        return axiosServices
            .post('user/notifications/read', COMMONPAYLOAD)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    readSingleNotification({ commit }, id) {
        return axiosServices
            .post(`user/notifications/${id}/read`, COMMONPAYLOAD)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
    unReadSingleNotification({ commit }, id) {
        return axiosServices
            .post(`user/notifications/${id}/unread`, COMMONPAYLOAD)
            .then((response) => {
                if (response && response.data) {
                    return response.data
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data)
                    throw error.response.data
            })
    },
}

const mutations = {

}

export default {
    actions,
    mutations,
    state,
    getters
}