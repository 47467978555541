<template>
    <v-app>
      <v-main class="website-wrapper">
        <v-toolbar class="elevation-0" style="border-bottom: 1px solid #e7e7e7">
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
  
          <div>
            <img width="150px" src="@/assets/images/logo.png" alt="" />
          </div>
  
          <v-spacer></v-spacer>
  
          <!-- <v-tabs class="web-header mr-4" color="#9DA73E">
            <v-tab>Home</v-tab>
            <v-tab>About Us</v-tab>
            <v-tab>Buyer</v-tab>
            <v-tab>Seller</v-tab>
            <v-tab>Blog</v-tab>
            <v-tab>Contact Us</v-tab>
          </v-tabs>
          <v-btn fab small elevation="0" color="#E3EFCE">
            <v-icon>mdi-account-outline</v-icon>
          </v-btn> -->
        </v-toolbar>
  
        <v-container class="my-16">
          <div class="about-video-section">
            <v-row>
              <v-col>
                <v-container class="term-condition">
                    <div class="m-title text-center">Term & Condition</div>
                  <v-list three-line subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <p class="mb-4">
                          This page contains important information regarding the
                          terms and conditions which apply to your account with
                          Bofuelbid Private Limited, (hereinafter referred to as
                          Bofuelbid). Access to your account and the usage of your
                          account is subject to your compliance with all the terms
                          and conditions set forth herein, read along with all
                          documents, including but not limited to; applications
                          forms and undertakings, signed by you during account
                          opening. Please read this page carefully and retain it
                          for future reference.
                        </p>
  
                        <p class="mb-4">
                          The website is owned, operated and maintained by
                          Bofuelbid Private Limited (hereinafter referred to as
                          “BOFUELBID”), a Company incorporated under the Companies
                          Act, 2013 having its Registered Office at 408, Vraj
                          Valencia, Behind Mahindrashowroom, Science City Sola
                          Road, Ahmedabad - 380060
                        </p>
  
                        <p class="mb-4">
                          Please note that the information contained herein is
                          subject to change without notice.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >General terms and conditions</v-list-item-title
                        >
                        <p class="mb-4">
                          Before availing of online trading services, the Client
                          shall complete the registration process as may be
                          prescribed from time to time. The Client shall follow
                          the instruction given in the website for registering
                          himself as a client.
                        </p>
                        <p class="mb-4">
                          The Client agrees that all transaction decisions are
                          based on the Client's own evaluation of financial
                          circumstances and transaction objectives. This extends
                          to any decisions made by the Client on the basis of any
                          information that may be made available on the web site
                          of BOFUELBID. The Client will not hold nor seek to hold
                          BOFUELBID or any of its officers, directors, partners,
                          employees, agents, subsidiaries, affiliates or business
                          associates liable for any trading losses, cost of damage
                          incurred by the Client consequent upon relying on
                          transaction information, research opinions or advice or
                          any other material/information whatsoever on the web
                          site, literature, brochure issued by BOFUELBID or any
                          other agency appointed/authorised by BOFUELBID. The
                          Client should seek independent professional advice
                          regarding the suitability of any transaction decisions.
                          The Client also acknowledges that employees of BOFUELBID
                          are not authorized to give any such advice and that the
                          Client will not solicit or rely upon any such advice
                          from BOFUELBID or any of its employees.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Security precaution and password</v-list-item-title
                        >
                        <p class="mb-4">
                          BOFUELBID will provide the client with a username and a
                          trading password which will enable him to avail of the
                          facilities of Online Trading through the BOFUELBID
                          website, over the telephone or in any such other manner
                          as may be permitted by BOFUELBID for availing of the
                          services. BOFUELBID may also provide the client with the
                          username and password for accessing its back-office for
                          various reports, etc. All terms regarding the use, reset
                          and modification of such password shall be governed by
                          information on the website.
                        </p>
                        <p class="mb-4">
                          Any Password can be reset by the You at any point of
                          time, by selecting the ‘Forgot Password’ link under the
                          login area. As soon as You select the ‘Forgot Password’
                          link, a reset link is sent to Your registered email ID,
                          where the same process of setting a unique password is
                          followed.
                        </p>
                        <p class="mb-4">
                          The Client shall be responsible for keeping the Username
                          and Password confidential and secure and shall be solely
                          responsible for all orders entered and transactions done
                          by any person whosoever through BOFUELBID’s Online Login
                          System using the Client’s Username and/or Password
                          whether or not such person was authorised to do so.
                        </p>
                        <p class="mb-4">
                          The Client shall immediately inform BOFUELBID of any
                          unauthorised use of the Client’s Username or Password
                          with full details of such unauthorised use including the
                          date of such unauthorised use, the manner in which it
                          was unauthorisedly used, the transactions effected
                          pursuant to such unauthorised use, etc.
                        </p>
                        <p class="mb-4">
                          The Client acknowledges that he is fully aware of and
                          understands the risks associated with availing of online
                          Login services through internet including the risk of
                          misuse and unauthorised use of his Username and/or
                          Password by a third party and the risk of a person
                          hacking into the Client’s account on BOFUELBID’s Online
                          Login System and unauthorisedly routing orders on behalf
                          of the Client through the System. The Client agrees that
                          he shall be fully liable and responsible for any and all
                          unauthorised use and misuse of his Password and/or
                          Username and also for any and all acts done by any
                          person through BOFUELBID’s Online Login System on the
                          Client’s Username in any manner whatsoever.
                        </p>
                        <p class="mb-4">
                          Without prejudice to the provisions mentioned herein
                          above, the Client shall immediately notify BOFUELBID in
                          writing with full details if: he discovers or suspects
                          unauthorised access through his Username, Password or
                          Account, he notices discrepancies that might be
                          attributable to unauthorised access, he forgets his
                          password or he discovers a security flaw in BOFUELBID’s
                          Online Login System.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Orders</v-list-item-title>
                        <p class="mb-4">
                          All orders for purchase, sale or other dealings in
                          Biofuels and other instructions routed through the
                          BOFUELBID’s Online Login System via the Client’s
                          Username shall be deemed to have been given by the
                          Client.
                        </p>
                        <p class="mb-4">
                          The client agrees to provide information relating to
                          customer user identification number, and such other
                          information as may be required while placing orders on
                          the telephone to determine the identity of the client.
                        </p>
                        <p class="mb-4">
                          The orders and instructions and all contracts and
                          transactions entered into pursuant thereto and the
                          settlement thereof will be in accordance with the
                          Applicable legal Provisions.
                        </p>
                        <p class="mb-4">
                          BOFUELBID may from time to time impose and vary limits
                          on the orders which the Client can place through
                          BOFUELBID’s online Login System (including exposure
                          limits, turnover limits, limits as to the number, value
                          and/or kind of Biofuels in respect of which orders can
                          be placed, the companies in respect of whose Biofuels
                          orders can be placed, etc.). The Client is aware and
                          agrees that BOFUELBID may need to vary or reduce the
                          limits or impose new limits urgently on the basis of the
                          BOFUELBID’s risk perception and other factors considered
                          relevant by BOFUELBID, and BOFUELBID may be unable to
                          inform the Client of such variation, reduction or
                          imposition in advance. The Client agrees that BOFUELBID
                          shall not be responsible for such variation, reduction
                          or imposition or the Client’s inability to route any
                          order through BOFUELBID’s Online Login System on account
                          of any such variation, reduction or imposition of
                          limits. The Client understands and agrees that BOFUELBID
                          may at any time, at its sole discretion and without
                          prior notice, prohibit or restrict the Client’s ability
                          to place orders or trade in Biofuels through BOFUELBID.
                        </p>
                        <p class="mb-4">
                          Though orders will generally be routed to the Applicable
                          legal’s computer systems within a few seconds from the
                          time the order is placed by the Client on BOFUELBID’s
                          Online Login System, BOFUELBID shall not be liable for
                          any delay in the execution of any order or for any
                          resultant loss on account of the delay.
                        </p>
                        <p class="mb-4">
                          The client agrees BOFUELBID may impose orderwise
                          surveillance or such other conditions as to orderwise
                          limits, etc. The client also understands that BOFUELBID
                          may impose various surveillances which may differ from
                          client to client on the basis of the BOFUELBID’s risk
                          perception and other factors considered relevant by
                          BOFUELBID.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Login, settlement and accounts</v-list-item-title
                        >
  
                        <p class="mb-4">
                          The client agrees that all orders placed through the
                          website shall be forwarded by the system to the
                          Applicable legal. All orders placed otherwise than
                          through the website shall be forwarded by the system to
                          Applicable legal terminals or any other order execution
                          mechanism at the discretion of BOFUELBID. In the event
                          that the order is placed during the Login hours, it
                          shall be routed to and executed on the market system.
                        </p>
                        <p class="mb-4">
                          Online confirmation will be sent to the client by
                          electronic mail or SMS after the execution of the order,
                          trade and this shall be deemed to be valid delivery
                          thereof by BOFUELBID. It shall be the responsibility of
                          the client to review immediately upon receipt, whether
                          delivered to him by electronic mail or any other
                          electronic means all confirmations of order,
                          transactions, or cancellations. It shall be the
                          responsibility of the client to follow up with BOFUELBID
                          for all such confirmations that are not received by him
                          within a stipulated time.
                        </p>
                        <p class="mb-4">
                          The client shall bring any errors in any report,
                          confirmation or contract note of executed trades
                          (including execution prices, orderts or quantities) to
                          BOFUELBID’s notice in writing by an electronic mail or
                          fax within twenty four hours of receipt of the concerned
                          report, confirmation or contract note. Any other
                          discrepancy in the confirmation or account shall be
                          notified by the client to BOFUELBID in writing via
                          electronic mail or fax within twenty four hours from the
                          time of receipt of the first notice. In all cases,
                          BOFUELBID shall have a right to accept or reject the
                          client’s objection.
                        </p>
                        <p class="mb-4">
                          There may be a delay in BOFUELBID receiving the reports
                          of transaction, status, from the respective applicable
                          legals or other persons in respect of or in connection
                          with which BOFUELBID has entered into contracts or
                          transactions on behalf of the clients. Accordingly
                          BOFUELBID may forward to the client late reports in
                          respect of such transactions that were previously
                          unreported to him as been expired, cancelled or
                          executed. The client shall not hold BOFUELBID
                          responsible for any losses suffered by the client on
                          account of any late reports, statements or any errors in
                          the report / statements computed by or received from any
                          applicable legal.
                        </p>
                        <p class="mb-4">
                          The client agrees that if, for any circumstance or for
                          any reason, the markets close before the acceptance of
                          the Order by the Applicable legal, the order may be
                          rejected. The client agrees further, that BOFUELBID may
                          reject Orders if the same are rejected by the Applicable
                          legal for any reason. In case of rejection of an order
                          due to rejection by the Applicable legal, the client
                          agrees that the order shall remain declined and shall
                          not be re-processed, in any event.
                        </p>
                        <p class="mb-4">
                          The client agrees that, if the order is not accepted on
                          the website for any reason, BOFUELBID shall have the
                          right to treat the order as having lapsed.
                        </p>
                        <p class="mb-4">
                          The client is aware that the electronic Login systems
                          either at the Applicable legal or in the office of
                          BOFUELBID offices are vulnerable to temporary
                          disruptions, breakdowns or failures. In the event of
                          non- execution of trade orders or trade cancellation due
                          to the happening of such events or vulnerabilities due
                          to failure / disruption / breakdown of system or link,
                          BOFUELBID shall be entitled to cancel relative
                          request/(s) with the Client and shall not be liable to
                          execute the desired transactions of the client’s. In
                          such event, BOFUELBID does not accept responsibility for
                          any losses incurred / that may be incurred by the Client
                          due to such eventualities which are beyond the control
                          of BOFUELBID.
                        </p>
                        <p class="mb-4">
                          BOFUELBID may at its sole discretion permit execution of
                          orders in respect of Biofuels, irrespective of the
                          amount in the balance of the account of the client.
                        </p>
                        <p class="mb-4">
                          The client agrees to abide with and be bound by all the
                          rules, regulations and bye-laws of the Applicable legal
                          as are in force pertaining to the transactions on his
                          behalf carried out by BOFUELBID and the orders placed by
                          him on the website or any other manner.
                        </p>
                        <p class="mb-4">
                          BOFUELBID shall not be responsible for any order, that
                          is made by the Client by mistake and every order that is
                          entered by the Client through the use of the allotted
                          user name and the security code(s) shall be deemed to be
                          a valid order for which the Client shall be fully
                          responsible.
                        </p>
                        <p class="mb-4">
                          Cancellation or modification of an order pursuant to the
                          client’s request in that behalf is not guaranteed. The
                          order will be cancelled or modified only if the client’s
                          request for cancellation and modification is received
                          and the order is successfully cancelled or modified
                          before it is executed. Market orders are subject to
                          immediate execution wherever possible.
                        </p>
                        <p class="mb-4">
                          The client shall not be entitled to presume an order
                          having been executed, cancelled or modified until a
                          confirmation from BOFUELBID is received by the client.
                          However, due to technical other factors the confirmation
                          may not be immediately transmitted to or received by the
                          client and such a delay shall not entitle the client to
                          presume that the order has not been executed cancelled
                          or modified unless and until BOFUELBID has so confirmed
                          in writing.
                        </p>
                        <p class="mb-4">
                          The pending orders shall be governed as per the
                          applicable legal systems, after the market is closed for
                          the day.
                        </p>
                        <p class="mb-4">
                          BOFUELBID may allow/disallow client from Login in any
                          transaction or class of Biofuels, and impose such
                          conditions including order-wise conditional Login for
                          Login as it may deem fit from time to time.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Maintenance of Login account</v-list-item-title
                        >
  
                        <p class="mb-4">
                          Money pay-in to BOFUELBID - The Client agrees that all
                          payments due to BOFUELBID will be made within the
                          specified time and in the event of any delay, BOFUELBID
                          may refuse, at their discretion, to carry out
                          transactions or closeout the position and the
                          costs/losses if any, thereof shall be borne solely and
                          completely by the client. All payments made to BOFUELBID
                          shall be from the account of the client and shall not to
                          be from any third party.
                        </p>
                        <p class="mb-4">
                          Money payout to BOFUELBID - Notwithstanding anything
                          contained in any other agreement or arrangement, if any,
                          between the parties hereto, the client hereby authorizes
                          BOFUELBID to release all payments due to him from the
                          Login account maintain with BOFUELBID, against specific
                          request in this behalf.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Representations and warranties</v-list-item-title
                        >
  
                        <p class="mb-4">
                          The Client hereby warrants that he is capable of
                          executing the present agreement and that the terms of
                          the present are not in contravention of any rights of
                          any party with whom such client has any agreements, at
                          any time prior to the execution of this agreement.
                        </p>
                        <p class="mb-4">
                          He agrees to provide and continue to provide all details
                          about themselves as may be required by BOFUELBID,
                          including but not restricted to PAN Number or Unique
                          Identification Number (issued by SEBI) , and states all
                          details and facts represented to BOFUELBID are true.
                        </p>
                        <p class="mb-4">
                          The Client is aware and acknowledges that Login over the
                          internet involves many uncertain factors and complex
                          hardware, software, systems, communication lines,
                          peripherals, etc., which are susceptible to
                          interruptions and dislocations; and the Online Login
                          Service of BOFUELBID may at any time be unavailable
                          without further notice. BOFUELBID and the Applicable
                          legal do not make any representation or warranty that
                          the Online Login Service of BOFUELBID will be available
                          to the Client at all times without any interruption. The
                          Client agrees that he shall not have any claim against
                          the Applicable legal or BOFUELBID on account of any
                          suspension, interruption, non-availability or
                          malfunctioning of the Online Login System or Service of
                          BOFUELBID or the Applicable legal's service or systems
                          for any reason whatsoever.
                        </p>
                        <p class="mb-4">
                          BOFUELBID states that it has complied with and will
                          continuously comply and if not proposes to comply with
                          all statutory and regulatory directions to offer the
                          Internet Login services through the website
                          Bofuelbid.com and for dealing in cash and derivatives
                          segment of the applicable legal.
                        </p>
                        <p class="mb-4">
                          The Client warrants that all or any of the Biofuels
                          deposited by him with BOFUELBID in respect of margin
                          requirements or otherwise, are owned by him and the
                          title thereof is clear and free of encumbrances.
                        </p>
                        <p class="mb-4">
                          The Client/s agree to indemnify and hold BOFUELBID
                          harmless against any loss that may be suffered by it,
                          its customers or a third party or any claim or action
                          that may be initiated by a third party which is in any
                          way the result of improper use of user ID and password
                          by the Client/s.
                        </p>
                        <p class="mb-4">
                          The Client hereby confirms and warrants that the Client
                          authorises BOFUELBID to take all such steps on the
                          Client’s behalf as may be required for provisions or to
                          complete or settle any transactions entered into through
                          or with BOFUELBID or executed by BOFUELBID on behalf of
                          the Client. However, nothing herein shall oblige
                          BOFUELBID to take such steps.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Fees and brokerage</v-list-item-title>
  
                        <p class="mb-4">
                          The Client agrees to pay Bofuelbid any brokerage,
                          commission, fees, charges and other taxes and
                          transaction charges as agreed upon and due from time to
                          time, as applied to such Client's account, transactions,
                          and Login ledger by Bofuelbid, for the services that the
                          Client receives from Bofuelbid.
                        </p>
                        <p class="mb-4">
                          All fees, brokerage, and charges on Bofuelbid's platform
                          are subject to change from time to time, subject to
                          complying with rules prescribed by the Applicable
                          legals, at Bofuelbid's discretion, with due notification
                          to the Client. Each time there is any change with
                          respect to fees, charges, and brokerage, Bofuelbid would
                          notify all Clients by an email and/or SMS to their
                          registered email ID and contact number with all details,
                          along with the date of such changes being effective.
                        </p>
                        <p class="mb-4">
                          An updated schedule of charges, brokerage, fees, towards
                          applicable services provided by Bofuelbid along with
                          taxes and other mandatory transaction charges is made
                          available on site.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Transaction or any other advice</v-list-item-title
                        >
  
                        <p class="mb-4">
                          The Client agrees that none of the services available on
                          the website shall amount to transaction advice on the
                          part of BOFUELBID.
                        </p>
                        <p class="mb-4">
                          The Client agrees that in the event of BOFUELBID or any
                          employee or official of BOFUELBID, providing any
                          information, recommendation or advice to the client, the
                          client may act upon the same at the sole risk and cost
                          of the client, and BOFUELBID shall not be liable or
                          responsible for the same.
                        </p>
                        <p class="mb-4">
                          BOFUELBID, and its officers, directors, partners,
                          employees, agents and affiliates will have no liability
                          with respect to any transaction decisions or
                          transactions of the client.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Programmes & Initiatives</v-list-item-title
                        >
  
                        <p class="mb-4">
                          Bofuelbid may from time to time have programmes to
                          promote engagement and education among clients.
                          Bofuelbid runs an initiative of a 60 day challenge where
                          clients participating and ending profitable after 60
                          days, shall be awarded a certificate of recognition.
                          Similarly, Bofuelbid encourages clients to refer their
                          friends and family to begin trading with Bofuelbid. In
                          all such cases, Bofuelbid reserves the absolute right to
                          change, withdraw, modify or suspend a part of or an
                          entire programme / initiative, without any prior notice
                          to any such user or client.
                        </p>
                        <p class="mb-4">
                          Bofuelbid’s decision on any condition of how any such
                          initiative or programme shall operate, would be final.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Miscellaneous</v-list-item-title>
  
                        <p class="mb-4">
                          BOFUELBID does not warrant that the service will be
                          uninterrupted or error-free. The service is provided in
                          an "as is" and "as available" basis without warranties
                          of any kind, either express or implied, including,
                          without limitation, those of merchantability and fitness
                          for a particular purpose. The client agrees that
                          BOFUELBID shall not be held responsible for delays in
                          transmission of orders due to breakdown of the system or
                          failure of communication facilities either due to the
                          fault of the systems of BOFUELBID or of the Applicable
                          legals or otherwise or for any other delay beyond the
                          reasonable control of BOFUELBID due to a breakdown or
                          failure of communication facilities or for any other
                          delay beyond the reasonable control of BOFUELBID.
                        </p>
                        <p class="mb-4">
                          All modification to this Agreement shall be made solely
                          at the discretion of BOFUELBID and shall be intimated to
                          the client by a suitable modification to the terms and
                          conditions or other applicable section on the website or
                          in any other manner.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Indemnity</v-list-item-title>
  
                        <p class="mb-4">
                          In the event of death or insolvency of the client,
                          winding up or liquidation, or their otherwise becoming
                          incapable of receiving and paying for or delivering or
                          transferring Biofuels which the client has ordered to be
                          bought or sold, BOFUELBID may close out the transaction
                          of the client and the client or his legal representative
                          shall be liable for any losses, costs and be entitled to
                          any surplus which may result therefrom.
                        </p>
                        <p class="mb-4">
                          The client is aware that authentication technologies and
                          strict Biofuels measures are required for internet Login
                          through order routed system and undertake to ensure that
                          the password of the client and /or their authorized
                          representatives are not revealed to any third party. The
                          client also agrees to indemnify BOFUELBID from any loss,
                          injury, claim or any action instituted against BOFUELBID
                          arising from the misuse of the password by any party.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Force majeure</v-list-item-title>
  
                        <p class="mb-4">
                          BOFUELBID shall not be responsible for delay or default
                          in the performance of their obligations due to
                          contingencies beyond their control, such as (including
                          but not limited to) losses caused directly or indirectly
                          by applicable legal or market rulings, suspension of
                          Login, fire, flood, civil commotion, earthquake, war,
                          strikes, failure of the systems, failure of the internet
                          links or government / regulatory action.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Severance</v-list-item-title>
  
                        <p class="mb-4">
                          In the event of any one or more of the provisions
                          contained in this Agreement becoming invalid, illegal or
                          unenforceable in any respect under any law for the time
                          being in force, the validity, legality and
                          enforceability of the remaining provisions contained
                          herein shall not in any way be prejudiced or affected
                          thereto.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Refund & cancellation policy</v-list-item-title
                        >
  
                        <p class="mb-4">
                          The Refund & Cancellation policy for all payments made
                          towards account opening or any other services using any
                          mode of payment shall stand as under:
                        </p>
                        <ul>
                          <li>
                            The Fees paid towards account opening charges for
                            enabling account, or any other services is
                            non-refundable.
                          </li>
                          <li>
                            In case you have paid the charges relating to account
                            opening multiple times, please create a ticket on our
                            email ID support@biofuelbid.com and we will initiate
                            the necessary procedure to refund your money.
                          </li>
                        </ul>
  
                        <p class="my-4">
                          Note: The completion of the refund procedure is subject
                          to agencies such as banks, payment gateways.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >User Redressal Mechanism</v-list-item-title
                        >
  
                        <p class="mb-4">
                          User Satisfaction being of utmost importance, for which
                          necessary mechanism and systems are in place. All the
                          User grievances are monitored and redressed by the
                          Compliance Officer of the Company who reports directly
                          to the Board of Directors and this essentially takes
                          care of the necessary escalation in case of any long
                          pending and serious compliant, otherwise the same are
                          taken care of by the Compliance Officer with the support
                          of the Compliance team. The clients can email their
                          grievances on the email id support@Bofuelbid.com which
                          is displayed on our website and the same also mentioned
                          on the contract notes and other correspondence if any
                          sent to the client from time to time. All the mails
                          received are duly monitored and redressed by the
                          Compliance Officer. The register of grievances is
                          centrally maintained for better control and monitoring,
                          Thus the grievances received in writing, mail, verbally,
                          etc. are centrally monitored and redressed and recorded
                          with all the necessary steps taken for redressal of the
                          same. Proper analysis is undertaken to analyse the
                          nature of complaints received, pattern of complaints and
                          concentration if any at a particular location, so that
                          every effort is taken to remove the root cause of
                          grievance by the client. Periodic visits and inspections
                          are undertaken for the branches and authorised partners
                          to get the feedback of clients on the issues. Depending
                          on the feedback received necessary corrective steps are
                          introduced into the system. For ensuring that the funds
                          received from the particular clients are properly
                          accounted for, no cheques and cash are collected, at
                          various locations or at the Head Office where
                          centralized accounting is maintained, by which it is
                          ensured that the funds are credited to the account of
                          the correct client and also from the particular accounts
                          mentioned in the client account master in the Back
                          office software maintained centrally. No other separate
                          software is maintained for maintaining client account
                          details. Thus every attempt is made to redress the
                          grievance/problems of the clients and introduce
                          systematic changes into the processes so that such
                          instances are avoided.
                        </p>
                      </v-list-item-content>
                    </v-list-item>
  
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Notice</v-list-item-title>
  
                        <p class="mb-4">
                          All notices, correspondences or communications issued
                          under this agreement shall be served in any one or more
                          of the following modes of communications and such notice
                          or communication shall be served at the ordinary place
                          of residence and/or last known web address / residing
                          address and / or at the ordinary business address of the
                          party to this agreement such as -
                        </p>
  
                        <ul>
                          <li>By hand</li>
                          <li>Delivery by post</li>
                          <li>By registered post</li>
                          <li>Under certificate of posting</li>
                          <li>By email or fax</li>
                          <li>
                            By affixing it on the door at the last known business
                            or residential address.
                          </li>
                          <li>
                            By oral communication to the party or on the last
                            known telephone number or on the recording machine of
                            such number.
                          </li>
                          <li>
                            By advertising in at least one prominent daily
                            newspaper having circulation in the area where the
                            last known business or residential address of the
                            party is situated.
                          </li>
                          <li>
                            By notice posted on the notice board of the Applicable
                            legal if no address is known. Any communication sent
                            by BOFUELBID to the Client shall be deemed to have
                            been properly delivered or served, if such
                            communication is returned on BOFUELBID as unclaimed /
                            refused / undelivered, if the same was sent in any one
                            more of the above modes of communication to the
                            ordinary place of residence and / or last known web
                            address /residing address and / or at the ordinary
                            business address of the party to this agreement.
                          </li>
                        </ul>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-container>
  
        <!-- <v-footer dark padless color="#3F2D1F" class="pa-16 pb-8">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <div>
                  <h3>Registered Address</h3>
                  <div>
                    408, Vraj Valencia, <br />
                    B/H Mahindra Showroom, <br />
                    Sarkhej - Gandhinagar Highway, <br />
                    Sola, Ahmedabad, Gujarat 380060
                  </div>
                </div>
                <div class="mt-8">
                  <h3>Email</h3>
                  <div>
                    support@biofuelbid.com
                  </div>
                </div>
              </v-col>
  
              <v-col cols="12" md="2">
                <div>
                  <h3>Information</h3>
                  <v-list-item-group class="ml-n4">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>About us</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Blog</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Contact</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Service</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              </v-col>
  
              <v-col cols="12" md="2">
                <div>
                  <h3>My Account</h3>
                  <v-list-item-group class="ml-n4">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>My Account</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Contact</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Shop</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Bid</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              </v-col>
  
              <v-col cols="12" md="2">
                <div>
                  <h3>Social Media</h3>
  
                  <div class="mt-2">
                    <v-btn icon>
                      <v-icon> mdi-facebook </v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon> mdi-instagram </v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon> mdi-whatsapp </v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon> mdi-gmail </v-icon>
                    </v-btn>
                  </div>
  
                  <div class="mt-8">
                    <h4 for="" class="ml-3 mb-1">Subscribe Us</h4>
                    <v-text-field
                      name="name"
                      id="id"
                      rounded
                      solo
                      light
                      append-icon="mdi-arrow-right-thin"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
  
              <v-col cols="12">
                <v-divider></v-divider>
                <div class="text-center grey--text mt-8">Copyright © 2023</div>
              </v-col>
            </v-row>
          </v-container>
        </v-footer> -->
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          {
            src: require("@/assets/images/slider-1.jpg"),
          },
          {
            src: require("@/assets/images/slider-1.jpg"),
          },
          {
            src: require("@/assets/images/slider-1.jpg"),
          },
          {
            src: require("@/assets/images/slider-1.jpg"),
          },
        ],
      };
    },
  };
  </script>
  
  <style>
  </style>