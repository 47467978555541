<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-book-edit-outline
            </v-icon>
            Purchase Invoices
          </div>
          <div class="btns-right">
            <DatePicker
              v-model="dateFilter"
              range
              @change="getInvoices"
              format="YYYY-MM-DD"
              valueType="YYYY-MM-DD"
              clearable
              class="mr-3"
            />
            <v-select
              :items="invoiceStatusList"
              v-model="invoiceStatus"
              label="Status"
              outlined
              dense
              chips
              multiple
              @change="getInvoices"
              class="d-inline-block mr-2 mb-0"
            ></v-select>
            <v-text-field
              v-model="search"
              outlined
              type="text"
              dense
              placeholder="Search..."
              @keypress.enter="getInvoices"
              class="d-inline-block mr-2 mb-0"
            ></v-text-field>
            <v-btn
              elevation="0"
              color="#9DA73E"
              dark
              class="px-2"
              @click="gotoAdd"
            >
              <v-icon> mdi-plus </v-icon> Add Invoices
            </v-btn>
          </div>
        </div>

        <div class="four-box-of-status">
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="listData"
                class="elevation-0"
                dense
              >
              <template v-slot:[`item.total_amount`]="{ item }">
                {{ item.total_amount | toCurrency }}
              </template>
                <template v-slot:[`item.start_date_time`]="{ item }">
                  {{ item.start_date_time }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="gotoEdit(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="downloadInvoice(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-download </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.status === 'Invoice Generated'"
                    fab
                    small
                    elevation="0"
                    color="transparent"
                    @click="confirmCancelInvoice(item.id)"
                  >
                    <v-icon color="#8f9742"> mdi-close </v-icon>
                  </v-btn>
                  <!-- <v-btn fab small elevation="0" color="transparent" @click="confirmDeleteAuction(item.id)">
                                          <v-icon color="red"> mdi-delete-outline </v-icon> 
                                      </v-btn> -->
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div class="d-flex">
                    <div v-if="item.status == 'Live'">
                      <v-chip
                        class="ma-2 blink-2"
                        color="red lighten-1"
                        text-color="white"
                        small
                      >
                        <v-avatar left
                          ><v-icon>mdi-access-point-check</v-icon></v-avatar
                        >
                        Live
                      </v-chip>
                    </div>
                    <div v-else-if="item.status == 'Closed'">
                      <v-icon color="primary" class="mr-1"
                        >mdi-check-bold</v-icon
                      >
                      <span style="font-size: 1rem">Completed</span>
                    </div>
                    <div v-else-if="item.status == 'Scheduled'">
                      <v-icon color="primary" class="mr-1"
                        >mdi-tag-check-outline</v-icon
                      >
                      <span style="font-size: 1rem">Upcoming</span>
                    </div>
                    <div v-else>
                      <!-- <v-icon color="primary" class="mr-1">mdi-tag-check-outline</v-icon> -->
                      <span style="font-size: 1rem">{{ item.status }}</span>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container-fluid>
    <ConformationBox
      v-if="isShowCloseDialog"
      :title="'Confirmation'"
      message="Are you sure want to cancel Invoice ?"
      @confirm="cancelInvoiceReason"
      @cancel="closeCancelFormModal"
      confirmText="Yes, Cancel"
    />
    <ConformationBox
      v-if="isShowDeleteDialog"
      :title="'Confirmation'"
      message="Are you sure want to delete ?"
      @confirm="confirmDeleteForm"
      @cancel="closeDeleteFormModal"
    />
    <v-dialog v-model="isShowCancelDialog" width="600" persistent>
      <v-card>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(cancelInvoice)">
            <v-card-title class="dialog-title justify-space-between">
              Reason
              <v-btn
                fab
                x-small
                elevation="0"
                color="transparent"
                @click="closeDialog"
              >
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pt-4">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Reason for Cancellation"
                    rules="required"
                  >
                    <label for=""
                      >Reason for Cancellation<span class="required">*</span>
                      <span style="color: #9da73e"
                        >(Maximum 150 Words)</span
                      ></label
                    >
                    <v-textarea
                      v-model="cancellationReason"
                      class="textarea_error"
                      rows="4"
                      hint=""
                      outlined
                      dense
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-4">
              <v-spacer></v-spacer>
              <div class="btns-right">
                <v-btn elevation="0" color="#9DA73E" dark type="submit">
                  Submit
                </v-btn>
              </div>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </dashlayout>
</template>
    
    <script>
import dashlayout from "@/components/layout/dashlayout.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import { required, digits, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import {
  // UUID,
  // AUTHENTIACTIONKEY,
  FOOTERPAGELIST,
  PAGINATIONOPTIONS,
  getEncodedStringBase64,
  API_URL,
  getDecodedToken,
  COMMONPAYLOAD,
  encryptPayload,
} from "@/utils/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";
setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
export default {
  components: {
    dashlayout,
    ConformationBox,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
  },
  name: "AuctionAddEdit",
  inject: ["mySpinner"],
  data: () => ({
    search: "",
    dialog: false,
    viewAuctionDetails: false,
    headers: [
      { text: "Invoice No.", value: "invoice_number" },
      { text: "Quantity", value: "total_quantity" },
      // { text: "Raw Material", value: "category_name" },
      { text: "Price", value: "total_amount" },
      { text: "Delivery", value: "delivery" },
      { text: "Update Date", value: "updated_at" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
    listData: [],
    total2: 0,
    options: PAGINATIONOPTIONS,
    footerList: {
      "items-per-page-options": [10, 20, 50, 100],
    },
    isShowCloseDialog: false,
    deleteRoleId: "",
    isShowLiveDialog: false,
    auction_number: "",
    dateFilter: [],
    isShowCancelDialog: false,
    cancellationReason: "",
    invoiceStatusList: ["Invoice Generated", "Archieved", "Cancelled"],
    invoiceStatus: [],
    isShowDeleteDialog: false,
  }),
  created() {
    this.getInvoices();
  },
  filters: {
    toCurrency: function (value) {
      var formatter = new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR'
      });
    return formatter.format(value);
    }
  },
  methods: {
    // confirmDeleteAuction(id) {
    //   this.isShowDeleteDialog = true;
    //   this.deleteRoleId = id;
    // },
    cancelInvoiceReason() {
      this.isShowCloseDialog = false;
      this.isShowCancelDialog = true;
    },
    closeDialog() {
      this.isShowCancelDialog = false;
      this.cancellationReason = "";
    },
    gotoAdd(id) {
      this.$router.push("/purchaseInvoicesAdd");
    },
    async cancelInvoice() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("cancelPurchaseInvoices", {
          id: this.deleteRoleId,
          status: "Cancelled",
          status_note: this.cancellationReason,
        });
        if (response.http_status === 200) {
          this.isShowCancelDialog = false;
          this.deleteRoleId = "";
          this.cancellationReason = "";
          this.getInvoices();
          this.$toasted.success(response.message);
          this.mySpinner.val = false;
        } else {
          this.isShowCloseDialog = false;
          this.deleteRoleId = "";
          this.cancellationReason = "";
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    gotoEdit(id) {
      this.$router.push({
        path: `/purchaseInvoicesEdit/${getEncodedStringBase64(id)}`,
      });
    },
    async getInvoices() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getPurchaseInvoices", {
          ...this.options,
          action: "LIST",
          search: this.search,
          status: this.invoiceStatus,
          start_date:
            this.dateFilter.length > 0
              ? this.dateFilter[0] != null
                ? this.dateFilter[0]
                : ""
              : "",
          end_date:
            this.dateFilter.length > 0
              ? this.dateFilter[1] != null
                ? this.dateFilter[1]
                : ""
              : "",
        });
        if (response.http_status === 200) {
          this.listData = response.data.data;
          this.total2 = response.data.total;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    confirmCancelInvoice(id) {
      this.isShowCloseDialog = true;
      this.deleteRoleId = id;
    },
    closeCancelFormModal() {
      this.isShowCloseDialog = false;
      this.deleteRoleId = "";
    },
    // closeDeleteFormModal() {
    //   this.isShowDeleteDialog = false;
    //   this.deleteRoleId = "";
    // },
    // async confirmDeleteForm() {
    //   try {
    //     this.mySpinner.val = true;
    //     const response = await this.$store.dispatch("deleteInvoices", {
    //       id: this.deleteRoleId,
    //     });
    //     if (response.http_status === 200) {
    //       this.isShowDeleteDialog = false;
    //       this.deleteRoleId = "";
    //       this.getInvoices();
    //       this.$toasted.success(response.message);
    //       this.mySpinner.val = false;
    //     } else {
    //       this.isShowDeleteDialog = false;
    //       this.deleteRoleId = "";
    //       this.$toasted.error(response.message);
    //       this.mySpinner.val = false;
    //     }
    //   } catch (e) {
    //     this.$toasted.error(e.message);
    //     this.mySpinner.val = false;
    //   }
    // },
    async downloadInvoice(id) {
      this.mySpinner.val = true;
      const token = getDecodedToken();
      let payload = {
        bfb_data: {
          ...COMMONPAYLOAD,
        },
      };
      payload = encryptPayload(payload);
      axios({
        url: `${API_URL}get-file/direct_purchase_invoice_document/${id}`,
        method: "POST",
        data: payload,
        headers: { authorization: "Bearer " + token },
        responseType: "blob",
      })
        .then((res) => {
          var FILE = window.URL.createObjectURL(
            new Blob([res.data], { type: res.headers["content-type"] })
          );
          var docUrl = document.createElement("a");
          docUrl.href = FILE;
          var extension = res.headers["content-type"].split("/");
          docUrl.setAttribute("download", `Invoice_document.${extension[1]}`);
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        })
        .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
        });
    },
  },
};
</script>