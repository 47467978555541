import axios from 'axios'
import router from '../router/index';

import {
    APPKEY,
    API_URL,
    OTPCOMMONPAYLOAD,
    getDecodedToken,
    getEncodedString,
    getDecodedRefreshToken,
    logoutUserFromaxios,
    encryptPayload,
    decryptPayload
} from '@/utils/helper'
// axiosServices.interceptors.response.use(
//     (response) => response,
//     (error) => Promise.reject(error || 'Wrong Services')
// )

// export default axiosServices

let isRefreshing = false
let failedQueue = []
// const API_URL = 'https://test-api.biofuelbid.com/public/'
// const API_URL = 'https://api.biofuelbid.com/public/'


const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })
    failedQueue = []
}
const axiosServices = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        language: 'english',
    },
})

axiosServices.interceptors.request.use(
    (config) => {
        config.headers = {
            'Content-Type': 'application/json',
            language: 'english',
            authorization: 'Bearer ' + getDecodedToken(),
        }
        if (config.data) {
            config.data = encryptPayload(config.data)
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

axiosServices.interceptors.response.use(
    (response) => {
        const decryptedJson = decryptPayload(response);
        return decryptedJson
        // return response
    },
    (err) => {
        const error = decryptPayload(err);
        const originalRequest = error.config
        if (error.response.data.http_status == 403) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                })
                    .then((token) => {
                        originalRequest.headers.authorization = `Bearer ${token}`
                        return axios(originalRequest)
                    })
                    .catch((error) => {
                        return Promise.reject(error)
                    })
            }
            isRefreshing = true
            return new Promise((resolve, reject) => {
                axiosServices
                    .post('renew-token', {
                        refresh_token: getDecodedRefreshToken(),
                        ...OTPCOMMONPAYLOAD
                    })
                    .then((response) => {
                        if(response.data.http_status === 200) {
                            localStorage.setItem(
                                'access_token',
                                getEncodedString(response.data.data.access_token)
                            )
                            localStorage.setItem(
                                'refresh_token',
                                getEncodedString(response.data.data.refresh_token)
                            )
                            axiosServices.defaults.headers.common.authorization = `Bearer ${response.data.data.access_token}`
                            originalRequest.headers.authorization = `Bearer ${response.data.data.access_token}`
                            processQueue(null, response.data.data.access_token)
                            resolve(axios(originalRequest))
                        }else{
                            localStorage.removeItem("access_token");
                            localStorage.removeItem("refresh_token");
                            localStorage.removeItem("is_admin");
                            localStorage.removeItem("is_buyer");
                            localStorage.removeItem("is_seller");
                            localStorage.removeItem("is_trader");
                            localStorage.removeItem("last_password_changed_at");
                            localStorage.removeItem("last_name");
                            localStorage.removeItem("userid");
                            localStorage.removeItem("useremail");
                            localStorage.removeItem("userphone");
                            localStorage.removeItem("registration_number");
                            localStorage.removeItem("otp_key");
                            localStorage.removeItem("first_name");
                            localStorage.removeItem("branches");
                            localStorage.removeItem("selectedBranch");
                            localStorage.removeItem("user_id");
                            localStorage.removeItem("user_type");
                            processQueue(response, null)
                            reject(response)
                            isRefreshing = false
                            router.push('/login');
                        }
                        
                    })
                    .catch((error) => {
                        processQueue(error, null)
                        reject(error)
                    })
                    .finally(() => {
                        isRefreshing = false
                    })
            })
        }
        return Promise.reject(error)
    }
)
export default axiosServices
