import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Loading from '@/components/common/Loading.vue'
// import loading from './plugins/loading'
import Toasted from 'vue-toasted'
import CKEditor from 'ckeditor4-vue';
import {Options} from 'vue-toasted'

import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component(VueCountdown.name, VueCountdown);

import '@/assets/css/style.css'

Vue.config.productionTip = false
Vue.use(Toasted,{
  duration : 5000,
  theme : 'bubble',
  position:'top-right',
  className: 'toaster',
  action : {
    text : 'close',
    
    onClick : (e, toastObject) => {
        toastObject.goAway(0);
    }
}
})
Vue.use(CKEditor)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
