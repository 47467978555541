<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-file-document-multiple-outline
            </v-icon>
            Purchase Orders
          </div>
          <div class="btns-right d-flex align-center flex-wrap">
            <div>
              <v-select v-model="search" :items="items" label="Select PO" hide-details="" outlined dense class="mr-4 mb-0"
                style="width: 200px;" @change="getPurchaseOrderList" item-text="key" item-value="value"></v-select>
            </div>
          </div>
        </div>

        <div class="four-box-of-status">
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table :headers="headers" :items="generatePOList" :options.sync="options" :server-items-length="total"
                class="elevation-0" :footer-props="footerList" dense>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn fab small elevation="0" color="transparent" @click="goTodetailScreen(item)">
                    <v-icon color="#8f9742"> mdi-pencil-outline </v-icon>
                  </v-btn>
                  <v-btn fab small elevation="0" color="transparent" @click="downloadPO(item)">
                    <v-icon color="red" > mdi-file-pdf-box</v-icon>
                  </v-btn>
                  <!-- <v-btn fab small elevation="0" color="transparent" @click="dialog=true">
                      <v-icon color="red"> mdi-delete-outline </v-icon>
                    </v-btn> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container-fluid>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="text-h6">
          Are you sure want to delete ?
        </v-card-title>

        <v-card-text> Lorem ipsum dolor sit amet consectetur. </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>

          <v-btn color="grey darken-1" text @click="dialog = false"> No </v-btn>

          <v-btn color="red darken-1" text @click="dialog = false" class="font-weight-bold">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="AddEditPO" width="600" persistent>
      <v-card>
        <v-card-title class="dialog-title justify-space-between"> {{ id !== '' ? 'Edit' : 'Add' }} PO
          <v-btn fab x-small elevation="0" color="transparent" @click="AddEditPO = false">
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">

          <v-row>
            <v-col cols="6" md="12">
              <label for="">PO Number<span class="required">*</span></label>
              <v-text-field outlined dense :error-messages="errors"></v-text-field>
            </v-col>
            <v-col cols="6" md="12">
              <label for="">Auction No<span class="required">*</span></label>
              <v-combobox :items="item" outlined dense></v-combobox>
            </v-col>
            <v-col cols="6" md="8">
              <label for="">Raw Material<span class="required">*</span></label>
              <v-text-field outlined dense :error-messages="errors"></v-text-field>
            </v-col>
            <v-col cols="6" md="4">
              <label for="">Amount<span class="required">*</span></label>
              <v-text-field outlined dense prefix="₹" :error-messages="errors"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4 px-4">
          <v-spacer></v-spacer>
          <div class="btns-right">
            <v-btn elevation="0" color="#9DA73E" dark @click="AddEditPO = false">
              Add PO
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </dashlayout>
</template>
  
<script>
import dashlayout from "../layout/dashlayout.vue";
import {
  FOOTERPAGELIST,
  PAGINATIONOPTIONS,
  getEncodedStringBase64,
  API_URL,
  COMMONPAYLOAD,
  getDecodedToken,
  FILEDOWNALOADHEADER 
} from "@/utils/helper";
import ConformationBox from "@/components/common/ConformationBox.vue";
import axios from "axios";
export default {
  components: { dashlayout, ConformationBox },
  name: "BlogList",
  inject: ["mySpinner"],
  created() {
    this.getPurchaseOrderList()
  },
  watch: {
  },
  methods: {
    async getPurchaseOrderList() {
      this.mySpinner.val = true;
      try {
        const response = await this.$store.dispatch("getPurchaseOrderListData", {
          ...this.options,
          list_type: this.search,
          organization_id: localStorage.getItem("selectedBranch"),
        });
        if (response.http_status === 200) {
          this.generatePOList = response.data.data;
          this.total = response.data.total;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    goTodetailScreen(item){
      if(this.search == 'generated_po'){
        this.$router.push({
        path: `/auction/auctioneer/${getEncodedStringBase64(
          item.auction_number
        )}`,
        });
      }else{
        this.$router.push({
        path: `/auction/participant/${getEncodedStringBase64(
          item.auction_number
        )}`,
      });
      }
    },
    async downloadPO(item){
      this.mySpinner.val = true;
      axios({
          url : `${API_URL}get-file/po_document/${item.id}`,
          method : 'POST',
          COMMONPAYLOAD,
          headers : {...FILEDOWNALOADHEADER},
          responseType: 'blob',
      }).then((res) => {
        if (res.data.type == "application/json") {
          this.$toasted.error('No record found.')
          this.mySpinner.val = false;
        }else{
          var FILE = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']}));
          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          var extension = res.headers['content-type'].split('/');
          docUrl.setAttribute('download', `po_document.${extension[1]}`);
          document.body.appendChild(docUrl);
          docUrl.click();
          this.mySpinner.val = false;
        }
          
      })
      .catch((e) => {
          this.$toasted.error(e.message);
          this.mySpinner.val = false;
      });
    }
  },
  data: () => ({
    AddEditPO: false,
    dialog: false,
    emailer: true,
    verifyemail: true,
    items: [{
      key : 'Generated Po',
      value: 'generated_po'
    },
    {
      key : 'Recieved PO',
      value: 'received_po'
    }],
    headers: [
      { text: "PO Number", value: "purchase_order_number" },
      { text: "Auction No.", value: "my_auction_number" },
      { text: "Raw Material", value: "item_name" },
      { text: "Amount", value: "total" },
      { text: "Created at", value: "created_at" },
      { text: "Action", value: "action", align: "right" },
    ],
    generatePOList: [],
    total: 0,
    options: PAGINATIONOPTIONS,
    footerList: {
      "items-per-page-options": [10, 20, 50, 100],
    },
    search : 'generated_po'
  }),
};
</script>
  
<style></style>