<template>
  <v-dialog v-model="addEditModule" width="450" persistent>
    <v-card>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addEditUserModule)">
          <v-card-title class="dialog-title justify-space-between">
            {{ id !== "" ? "Edit" : "Add" }} Role
            <v-btn
              fab
              x-small
              elevation="0"
              color="transparent"
              @click="closeDialog"
            >
              <v-icon color="white"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Role name"
                  rules="required"
                >
                  <label for="">Role Name<span class="required">*</span></label>
                  <v-text-field
                    v-model="roleName"
                    outlined
                    dense
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="User category"
                  rules="required"
                >
                  <label for=""
                    >Product Type<span class="required">*</span></label
                  >
                  <v-select
                    v-model="userCategory"
                    :items="productType"
                    outlined
                    dense
                    chips
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <div class="btns-right">
              <v-btn elevation="0" color="#9DA73E" dark large type="submit">
                {{ id !== "" ? "Edit" : "Add" }} Role
              </v-btn>
            </div>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
 
 <script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  inject: ["mySpinner"],
  props: {
    id: "",
  },
  data: () => ({
    roleName: "",
    userCategory: "",
    addEditModule: true,
    productType: ["Seller", "Buyer", "Trader", "Admin"],
    parentModuleList: [],
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  async created() {
    if (this.id != "") {
      this.getUserRoleDetails();
    }
  },
  methods: {
    async addEditUserModule() {
      this.mySpinner.val = true;
      try {
        if (this.id != "") {
          const payload = {
            role_name: this.roleName,
            id: this.id,
            product_type: this.userCategory,
          };
          const response = await this.$store.dispatch(
            "updateUserRole",
            payload
          );
          if (response.http_status === 200 || response.http_status === 201) {
            this.$toasted.success(response.data.message);
            this.$emit("cancel");
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        } else {
          const payload = {
            product_type: this.userCategory,
            role_name: this.roleName,
          };
          const response = await this.$store.dispatch(
            "createUserRole",
            payload
          );
          if (response.http_status === 200 || response.http_status === 201) {
            this.$toasted.success(response.data.message);
            this.$emit("cancel");
            this.mySpinner.val = false;
          } else {
            this.$toasted.error(response.message);
            this.mySpinner.val = false;
          }
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    // close dialog
    closeDialog() {
      this.$emit("cancel");
    },
    async getUserRoleDetails() {
      this.mySpinner.val = true;
      try {
        const payload = {
          id: this.id,
          action: "VIEW",
        };
        const response = await this.$store.dispatch("viewUserRole", payload);
        if (response.http_status === 200 || response.http_status === 201) {
          this.roleName = response.data.role_name;
          this.userCategory = response.data.product_type;
          this.$toasted.success(response.data.message);
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>
 
 <style>
</style>