<template>
  <weblayout>
    <v-carousel height="calc(100vh - 64px)" hide-delimiters>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <!-- <video width="100%" autoplay loop>
          <source src="@/assets/images/front-view-plant-growing-from-pellets.jpg" type="images/jpg" />
          Your browser does not support the video tag.
        </video> -->
        <v-row class="fill-height" align="center" justify="center">
          <div
            class="text-h3 text-center white--text"
            style="text-shadow: rgb(0 0 0) 1px 5px 8px"
          >
            Powering a sustainable <br />
            future with <strong> Biofuels.</strong>
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <!-- <div class="news-bar">
      <marquee behavior="" direction="">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum, architecto.
      </marquee>
    </div> -->

    <v-container class="my-16">
      <v-row>
        <v-col cols="12" md="5" class="justify-center d-flex">
          <img
            style="max-width: 440px; width: 100%"
            src="@/assets/images/about-img.73554a5d.png"
            alt=""
          />
        </v-col>
        <v-col cols="12" md="7" class="d-flex align-center">
          <div class="about-wrap">
            <div class="m-title">What we <strong>Offer</strong></div>

            <div>
              <p>
                At our biofuel company, we are dedicated to developing
                sustainable energy solutions through the use of biomass. Our
                team is made up of experts in the field of renewable energy,
                with a passion for finding new and innovative ways to reduce our
                dependence on fossil fuels.
              </p>
              <p>
                We specialize in the production of biofuels such as ethanol and
                biodiesel, using feedstocks such as corn, soybeans, and algae.
                Our state-of-the-art facilities and cutting-edge technology
                allow us to efficiently and effectively convert these resources
                into clean-burning fuels.
              </p>
              <p>
                In addition to producing biofuels, we also focus on research and
                development to improve the overall sustainability of our
                processes and to find new and cost-effective ways to produce
                biofuels.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="3">
            <div class="about-wrap">
              <div class="m-title">Insight <strong>BioFuel Bid</strong></div>
              <v-tabs
                vertical
                hide-slider
                style="width: 100%"
                v-model="tab"
                class="tabs-for-video"
              >
                <v-tab style="max-width: none; height: 70px !important">
                  <v-icon class="pr-5" size="30"
                    >mdi-account-cash-outline</v-icon
                  >
                  <div class="text-of-tab">Buyer</div>
                </v-tab>
                <v-tab style="max-width: none; height: 70px !important">
                  <v-icon class="pr-5" size="30"
                    >mdi-card-account-details-outline</v-icon
                  >
                  <div class="text-of-tab">Seller</div>
                </v-tab>
                <v-tab style="max-width: none; height: 70px !important">
                  <v-icon class="pr-5" size="30">mdi-gavel</v-icon>
                  <div class="text-of-tab">Bid</div>
                </v-tab>
              </v-tabs>
            </div>
          </v-col>
          <v-col cols="12" md="9">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <img
                  src="@/assets/images/video.png"
                  style="width: 100%"
                  alt=""
                />
              </v-tab-item>
              <v-tab-item>
                <img
                  src="@/assets/images/video.png"
                  style="width: 100%"
                  alt=""
                />
              </v-tab-item>
               <v-tab-item>
                <img
                  src="@/assets/images/video.png"
                  style="width: 100%"
                  alt=""
                />
              </v-tab-item> 
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </v-container> -->

    <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12" class="pb-0">
            <div class="about-wrap">
              <div class="m-title text-center">
                <strong>Coal Vs Bio Coal </strong>
              </div>

              <!-- <v-simple-table dense>
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-left">Product</th>
                      <th class="text-left">Indian Coal</th>
                      <th class="text-left">Imported Coal</th>
                      <th class="text-left">Bio Coal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Price Per TON</td>
                      <td>6000 to 6500</td>
                      <td>12500 to 16500</td>
                      <td>5000 to 13000</td>
                    </tr>

                    <tr>
                      <td>Ash Contain</td>
                      <td>15% to 30%</td>
                      <td>5% to 7%</td>
                      <td>2% to 5%</td>
                    </tr>
                    <tr>
                      <td>Moisture</td>
                      <td>30% to 60%</td>
                      <td>30% to 40%</td>
                      <td>8% to 15%</td>
                    </tr>
                    <tr>
                      <td>CV Value</td>
                      <td>2500 to 3100</td>
                      <td>5500 to 6500</td>
                      <td>38000 to 42000</td>
                    </tr>
                    <tr>
                      <td>Fixed Carbon</td>
                      <td>30% to 40%</td>
                      <td>40% to 45%</td>
                      <td>40% to 50%</td>
                    </tr>
                    <tr>
                      <td>Carbon Credit</td>
                      <td>NO</td>
                      <td>NO</td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td>Green Product</td>
                      <td>NO</td>
                      <td>NO</td>
                      <td>YES</td>
                    </tr>
                    <tr>
                      <td>Total Market In India</td>
                      <td>120 Million Ton per Year</td>
                      <td>160 Million Ton Per Year</td>
                      <td>2.5 Million Ton Production a Year</td>
                    </tr>
                    <tr>
                      <td>Year on Year Growth In India</td>
                      <td>Market is increasing by 14% a Year</td>
                      <td>Market is Increasing by 15% a Year</td>
                      <td>Market Need is 34 Million Ton a Year</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table> -->
            </div>
          </v-col>

          <v-col cols="12" md="4" style="transform: scale(0.95);">
            <v-card :loading="loading" class="mx-auto" elevation="1">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-card-title class="justify-center my-6 b-main-title">Indian Coal</v-card-title>

              <v-card-text class="box-details">
                <div>
                   <div class="b-title">Price Per TON</div>
                   <div class="b-details">₹ 6000 to 6500</div>
                </div>
                <div>
                   <div class="b-title">Ash Contain</div>
                   <div class="b-details">15% to 30%</div>
                </div>
                <div>
                   <div class="b-title">Moisture</div>
                   <div class="b-details">30% to 60%</div>
                </div>
                <div>
                   <div class="b-title">CV Value</div>
                   <div class="b-details">2500 to 3100</div>
                </div>
                <div>
                   <div class="b-title">Fixed Carbon</div>
                   <div class="b-details">30% to 40%</div>
                </div>
                <div>
                   <div class="b-title">Carbon Credit</div>
                   <div class="b-details">NO</div>
                </div>
                <div>
                   <div class="b-title">Green Product</div>
                   <div class="b-details">NO</div>
                </div>
                <div>
                   <div class="b-title">Total Market In India per Year	</div>
                   <div class="b-details">120 Million Ton</div>
                </div>
                <div>
                   <div class="b-title">Year on Year Growth In India	</div>
                   <div class="b-details">14% a Year</div>
                </div>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="4" style="transform: scale(1.05);">
            <v-card :loading="loading" class="mx-auto" elevation="1">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-card-title class="justify-center my-6 b-main-title" style="background-color: #9da73e;">Bio Coal</v-card-title>

              <v-card-text class="box-details">
                <div>
                   <div class="b-title">Price Per TON</div>
                   <div class="b-details">₹ 5000 to 13000</div>
                </div>
                <div>
                   <div class="b-title">Ash Contain</div>
                   <div class="b-details">2% to 5%</div>
                </div>
                <div>
                   <div class="b-title">Moisture</div>
                   <div class="b-details">8% to 10%</div>
                </div>
                <div>
                   <div class="b-title">CV Value</div>
                   <div class="b-details">3800 to 4200</div>
                </div>
                <div>
                   <div class="b-title">Fixed Carbon</div>
                   <div class="b-details">40% to 50%</div>
                </div>
                <div>
                   <div class="b-title">Carbon Credit</div>
                   <div class="b-details">YES</div>
                </div>
                <div>
                   <div class="b-title">Green Product</div>
                   <div class="b-details">YES</div>
                </div>
                <div>
                   <div class="b-title">Total Market In India per Year	</div>
                   <div class="b-details">2.5 Million Ton</div>
                </div>
                <div>
                   <div class="b-title">Year on Year Growth In India	</div>
                   <div class="b-details">Need is 34 Million Ton a Year</div>
                </div>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="4" style="transform: scale(0.95);">
            <v-card :loading="loading" class="mx-auto" elevation="1">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-card-title class="justify-center my-6 b-main-title">Imported Coal</v-card-title>

              <v-card-text class="box-details">
                <div>
                   <div class="b-title">Price Per TON</div>
                   <div class="b-details">₹ 12500 to 16500</div>
                </div>
                <div>
                   <div class="b-title">Ash Contain</div>
                   <div class="b-details">5% to 7%</div>
                </div>
                <div>
                   <div class="b-title">Moisture</div>
                   <div class="b-details">30% to 40%</div>
                </div>
                <div>
                   <div class="b-title">CV Value</div>
                   <div class="b-details">5500 to 6500</div>
                </div>
                <div>
                   <div class="b-title">Fixed Carbon</div>
                   <div class="b-details">40% to 45%</div>
                </div>
                <div>
                   <div class="b-title">Carbon Credit</div>
                   <div class="b-details">NO</div>
                </div>
                <div>
                   <div class="b-title">Green Product</div>
                   <div class="b-details">NO</div>
                </div>
                <div>
                   <div class="b-title">Total Market In India per Year	</div>
                   <div class="b-details">160 Million Ton</div>
                </div>
                <div>
                   <div class="b-title">Year on Year Growth In India	</div>
                   <div class="b-details">15% a Year</div>
                </div>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <!-- <v-container class="my-16">
      <div class="about-video-section">
        <v-row>
          <v-col cols="12" md="12">
            <div class="about-wrap">
              <div class="m-title text-center mb-4">
                <strong>Price Ex-Factory </strong>
              </div>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th class="text-left">Category</th>
                      <th class="text-left">CV Value</th>
                      <th class="text-left">March-2023</th>
                      <th class="text-left">April</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Ground Nut Shell</td>
                      <td>4200</td>
                      <td>8200</td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td>Saw Dust</td>
                      <td>4000</td>
                      <td>9000</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Jeera</td>
                      <td>3300</td>
                      <td>5500</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Cotton</td>
                      <td>3500</td>
                      <td>5500</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Agri Waste</td>
                      <td>3300</td>
                      <td>5200</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <span style="text-align: right;">Prices may vary depending on market( * T&C Apply )</span>
            </div>
            </v-col
          ></v-row
        >
      </div>
    </v-container> -->

    <v-container class="my-16">
      <v-row>
        <v-col cols="12">
          <div class="m-title text-center">
            Biofuel <strong>Products</strong>
          </div>
        </v-col>
      </v-row>
      <VueSlickCarousel v-bind="settings" v-if="categoryData.length > 0">
        <div v-for="item in categoryData" :key="item.id">
          <v-card class="mx-auto blog-box">
            <v-img
              class="white--text align-end"
              height="200px"
              :src="item.image_path"
            >
            </v-img>

            <div class="titile-and-price-section main-title">
              <div class="left-t">
                <div class="card-title">{{ item.name }}</div>
              </div>
              <div class="right-pr text-h5 font-weight-bold">
                ₹ {{ item.price }} / MT
                <v-chip
                  color="#d7dbb6"
                  class="d-block ml-auto"
                  small
                  style="width: fit-content"
                  >{{ currentMonthYear }}</v-chip
                >
              </div>
            </div>

            <div class="titile-and-price-section">
              <div class="left-t">
                <span>CV Value</span>
              </div>
              <div class="right-pr font-weight-bold">
                <span>{{ item.cv_value }}</span>
              </div>
            </div>

            <v-divider></v-divider>

            <v-card-text class="text--primary">
              <div class="text-right">
                <span>Price can be vary </span>
                <a href="/termcondition">Term & Condition</a>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <!-- <div>
            <v-card class="mx-auto blog-box">
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/peanut-shell.png"
            >
            </v-img>

            <div class="titile-and-price-section main-title">
              <div class="left-t">
                <div class="card-title">Ground Nut Shell</div>
              </div>
              <div class="right-pr text-h5 font-weight-bold">₹ 8500-9500 / MT
                <v-chip color="#d7dbb6" class="d-block ml-auto" small style="width: fit-content;">March-2023</v-chip>
              </div>
            </div>
            <div class="titile-and-price-section">
              <div class="left-t">
                <span>CV Value</span>
              </div>
              <div class="right-pr font-weight-bold">
                <span>4200</span>
              </div>
            </div>

            <v-divider></v-divider>

            <v-card-text class="text--primary">
              <div class="text-right">
                <span>Price can be vary </span>
                <a href="#">Term & Condition</a>
              </div>
            </v-card-text>
          </v-card>
          </div>
          <div>
            <v-card class="mx-auto blog-box">
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/agri-waste.png"
            >
            </v-img>

            <div class="titile-and-price-section main-title">
              <div class="left-t">
                <div class="card-title">Agri Waste</div>
              </div>
              <div class="right-pr text-h5 font-weight-bold">₹ 5000-6000 / MT
                <v-chip color="#d7dbb6" class="d-block ml-auto" small style="width: fit-content;">March-2023</v-chip>
              </div>
            </div>

            <div class="titile-and-price-section">
              <div class="left-t">
                <span>CV Value</span>
              </div>
              <div class="right-pr font-weight-bold">
                <span>3300</span>
              </div>
            </div>

            <v-divider></v-divider>

            <v-card-text class="text--primary">
              <div class="text-right">
                <span>Price can be vary </span>
                <a href="#">Term & Condition</a>
              </div>
            </v-card-text>
          </v-card>
          </div>
          <div>
            <v-card class="mx-auto blog-box">
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/cotton.png"
            >
            </v-img>

            <div class="titile-and-price-section main-title">
              <div class="left-t">
                <div class="card-title">Cotton Husk</div>
              </div>
              <div class="right-pr text-h5 font-weight-bold">₹ 5000-6000 / MT
                <v-chip color="#d7dbb6" class="d-block ml-auto" small style="width: fit-content;">March-2023</v-chip>
              </div>
            </div>

            <div class="titile-and-price-section">
              <div class="left-t">
                <span>CV Value</span>
              </div>
              <div class="right-pr font-weight-bold">
                <span>3500</span>
              </div>
            </div>

            <v-divider></v-divider>

            <v-card-text class="text--primary">
              <div class="text-right">
                <span>Price can be vary </span>
                <a href="#">Term & Condition</a>
              </div>
            </v-card-text>
          </v-card>
          </div>
          <div>
            <v-card class="mx-auto blog-box">
            <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/images/jeera.webp"
            >
            </v-img>

            <div class="titile-and-price-section main-title">
              <div class="left-t">
                <div class="card-title">Jeera</div>
              </div>
              <div class="right-pr text-h5 font-weight-bold">₹ 5000-6000 / MT
                <v-chip color="#d7dbb6" class="d-block ml-auto" small style="width: fit-content;">March-2023</v-chip>
              </div>
            </div>

            <div class="titile-and-price-section">
              <div class="left-t">
                <span>CV Value</span>
              </div>
              <div class="right-pr font-weight-bold">
                <span>3500</span>
              </div>
            </div>

            <v-divider></v-divider>

            <v-card-text class="text--primary">
              <div class="text-right">
                <span>Price can be vary </span>
                <a href="#">Term & Condition</a>
              </div>
            </v-card-text>
          </v-card>
          </div> -->
      </VueSlickCarousel>
    </v-container>

    <v-container class="my-16">
      <div class="blog-wrap">
        <div class="m-title text-center mb-8">
          Blogs of <strong>BioFuel Bid</strong>
        </div>
        <VueSlickCarousel v-bind="settings" v-if="blogData.length > 0">
          <div v-for="(data) in blogData" :key="data.slug">
            <BlogCard :blogInfo="data" :key="data.id" />
          </div>
        </VueSlickCarousel>
        <template v-if="blogData.length == 0">
          <v-row>
            <v-col cols="12" class="pb-10">
              <h3 class="text-center">No Blog Found</h3>
            </v-col>
          </v-row>
        </template>
        <v-row class="mt-10" v-if="blogData.length > 0">
          <v-col cols="12">
            <div class="text-center">
              <v-btn to="/blogs" color="#9da73e" dark> View All </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <!-- <v-container-fluid class="my-16">
      <div class="bg-for-counter">
        <v-row class="row-counter">
          <div>
            <div class="counter-wrap">
              <div class="counter-title">50 +</div>
              <div class="couter-desc">
                Types of commodities on <br />
                one platform
              </div>
            </div>
          </div>
          <div>
            <div class="counter-wrap">
              <div class="counter-title">6,80,000+ MT</div>
              <div class="couter-desc">
                Quantity sought (Buy/Sell) <br />
                on platform
              </div>
            </div>
          </div>
          <div>
            <div class="counter-wrap">
              <div class="counter-title">1250+</div>
              <div class="couter-desc">
                BiofuelBid <br />
                active members
              </div>
            </div>
          </div>
        </v-row>
      </div>
    </v-container-fluid>

    <v-container class="my-16">
      <div class="testimonial">
        <v-carousel hide-delimiter-background height="auto" hide-delimiters>
          <v-carousel-item>
            <div class="testimonial-wrap">
              <div class="user-img text-center mb-4">
                <img src="@/assets/images/user.png" alt="" />
              </div>
              <div class="user-cmt text-center">
                At our biofuel company, we are dedicated to developing
                sustainable energy solutions through the use of biomass. Our
                team is made up of experts in the field of renewable energy,
                with a passion for finding new and innovative ways to reduce our
                dependence on fossil fuels.
              </div>
              <div class="user-name grey--text text-center mb-4">
                Karik - Chancha Store
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="testimonial-wrap">
              <div class="user-img text-center mb-4">
                <img src="@/assets/images/user.png" alt="" />
              </div>
              <div class="user-cmt text-center">
                At our biofuel company, we are dedicated to developing
                sustainable energy solutions through the use of biomass. Our
                team is made up of experts in the field of renewable energy,
                with a passion for finding new and innovative ways to reduce our
                dependence on fossil fuels.
              </div>
              <div class="user-name grey--text text-center mb-4">
                Karik - Chancha Store
              </div>
            </div>
          </v-carousel-item>
          <v-carousel-item>
            <div class="testimonial-wrap">
              <div class="user-img text-center mb-4">
                <img src="@/assets/images/user.png" alt="" />
              </div>
              <div class="user-cmt text-center">
                At our biofuel company, we are dedicated to developing
                sustainable energy solutions through the use of biomass. Our
                team is made up of experts in the field of renewable energy,
                with a passion for finding new and innovative ways to reduce our
                dependence on fossil fuels.
              </div>
              <div class="user-name grey--text text-center mb-4">
                Karik - Chancha Store
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-container> -->
  </weblayout>
</template>
  
  <script>
import weblayout from "../layout/weblayout.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import BlogCard from "../dashboard/blogs/BlogCard.vue";
import {generateTheMetaInfo} from '@/utils/helper'
export default {
  components: { weblayout, VueSlickCarousel, BlogCard },
  inject: ["mySpinner"],
  data() {
    return {
      tab: null,
      items: [
        {
          src: require("@/assets/images/front-view-plant-growing-from-pellets.jpg"),
        },
        {
          src: require("@/assets/images/pellets-surface.jpg"),
        },
      ],
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      blogData: [],
      categoryData: [],
      currentMonthYear: "",
    };
  },
  metaInfo () {
    return generateTheMetaInfo('Home')
  },
  created() {
    this.getBlogList();
    this.getCategoryList();

    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date = new Date();

    this.currentMonthYear = months[date.getMonth()] + " " + date.getFullYear();
  },
  methods: {
    blog1() {
      this.$router.push("/nation-bioenergy-mission-2018");
    },
    blog2() {
      this.$router.push("/biofiels-and-government-policies-around-it");
    },
    blog3() {
      this.$router.push("/what-is-biofuel");
    },
    blog4() {
      this.$router.push("/carbon-credit-a-tool-to-incentive-use-of-biofuel");
    },
    async getCategoryList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getSliderCategoryList", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.categoryData = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
    async getBlogList() {
      try {
        this.mySpinner.val = true;
        const response = await this.$store.dispatch("getDashboardBlogList", {
          action: "ALL",
        });
        if (response.http_status === 200) {
          this.blogData = response.data;
          this.mySpinner.val = false;
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false;
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false;
      }
    },
  },
};
</script>
  
  <style>
</style>