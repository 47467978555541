<template>
    <v-dialog v-model="addEditModule" width="500" persistent>
        <v-card>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(addEditUserModule)">
                    <v-card-title class="dialog-title justify-space-between"> {{ id !== '' ? 'Edit' : 'Add' }} Item
                        <v-btn fab x-small elevation="0" color="transparent" @click="closeDialog">
                            <v-icon color="white">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="pt-4">

                        <v-row>
                            <v-col cols="12" md="12">
                                <validation-provider v-slot="{ errors }" name="Item name" rules="required">
                                    <label for="">Item Name <span class="required">*</span></label>
                                    <v-text-field v-model="item_name" outlined dense :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" name="Raw Material" rules="required">
                                    <label for="">Raw Material <span class="required">*</span></label>
                                    <v-select v-model="category_id" :items="categoriesData" item-value="id" item-text="name"
                                        dense outlined :error-messages="errors"></v-select>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" name="HSN" rules="required">
                                    <label for="">HSN <span class="required">*</span></label>
                                    <v-text-field v-model="hsn" outlined dense :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" name="CV value" rules="required">
                                    <label for="">CV value <span class="required">*</span></label>
                                    <v-text-field v-model="cv_value" outlined dense
                                        :error-messages="errors"></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" md="12">
                                <validation-provider v-slot="{ errors }" name="Description" rules="required">
                                    <label for="">Description <span class="required">*</span></label>
                                    <v-textarea v-model="description" class="textarea_error" rows="4" outlined dense :error-messages="errors"></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <v-spacer></v-spacer>
                        <div class="btns-right">
                            <v-btn elevation="0" color="#9DA73E" dark type="submit">
                                {{ id !== '' ? 'Edit' : 'Add' }} Item
                            </v-btn>
                        </div>
                    </v-card-actions>
                </form>
            </validation-observer>
        </v-card>
    </v-dialog>
</template>
 
<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})
export default {
    props: {
        id: ''
    },
    inject: ['mySpinner'],
    data: () => ({
        item_name: '',
        hsn: '',
        cv_value: '',
        category_id: '',
        categoriesData: [],
        description: '',
        addEditModule: true
    }),
    components: {
        ValidationObserver,
        ValidationProvider
    },
    async created() {
        this.getDropdownData()
        if (this.id != '') {
            this.getUserModuleDetails()
        }

    },
    methods: {
        async getDropdownData() {
            this.mySpinner.val = true;
            try {
                this.mySpinner.val = true;
                const response = await this.$store.dispatch("getAllCategory", {
                    action: "ALL",
                });
                if (response.http_status === 200) {
                    this.categoriesData = response.data;
                    var temp = [];
                    this.categoriesData.forEach(ele => {
                        temp[ele.id] = ele.name
                    });
                    this.displayCatList = temp
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
        async addEditUserModule() {
            this.mySpinner.val = true;
            try {
                if (this.id != '') {
                    const payload = {
                        item_name: this.item_name,
                        description: this.description,
                        hsn: this.hsn,
                        cv_value: this.cv_value,
                        category_id: this.category_id,
                        id: this.id,
                    }
                    const response = await this.$store.dispatch("updateItem", payload);
                    if (response.http_status === 200 || response.http_status === 201) {
                        this.$toasted.success(response.message);
                        this.$emit('success')
                        this.mySpinner.val = false;
                    } else {
                        this.$toasted.error(response.message);
                        this.mySpinner.val = false;
                    }
                } else {
                    const payload = {
                        item_name: this.item_name,
                        description: this.description,
                        hsn: this.hsn,
                        cv_value: this.cv_value,
                        category_id: this.category_id,
                    }
                    const response = await this.$store.dispatch("createItem", payload);
                    if (response.http_status === 200 || response.http_status === 201) {
                        this.$toasted.success(response.message);
                        this.$emit('success')
                        this.mySpinner.val = false;
                    } else {
                        this.$toasted.error(response.message);
                        this.mySpinner.val = false;
                    }
                }

            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        },
        // close dialog
        closeDialog() {
            this.$emit('cancel')
        },
        async getUserModuleDetails() {
            this.mySpinner.val = true;
            try {
                const payload = {
                    id: this.id,
                    action: 'VIEW',
                }
                const response = await this.$store.dispatch("viewItem", payload);
                if (response.http_status === 200 || response.http_status === 201) {
                    this.item_name = response.data.item_name
                    this.hsn = response.data.hsn
                    this.cv_value = response.data.cv_value
                    this.description = response.data.description
                    this.category_id = response.data.category_id
                    this.$toasted.success(response.data.message);
                    this.mySpinner.val = false;
                } else {
                    this.$toasted.error(response.message);
                    this.mySpinner.val = false;
                }
            } catch (e) {
                this.$toasted.error(e.message);
                this.mySpinner.val = false;
            }
        }
    }
}
</script>
 
<style></style>