<template>
  <dashlayout>
    <v-container-fluid fluid>
      <div class="dash-bg">
        <div class="box-title-with-btn">
          <div class="box-title">
            <v-icon size="24" color="#9DA73E" class="mr-2">
              mdi-book-edit-outline
            </v-icon>
            Upcoming Auction
          </div>
          <div class="btns-right">
          </div>
        </div>

        <div class="four-box-of-status">
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="auctionsData"
                class="elevation-0"
                dense
              >
                <template v-slot:[`item.start_date`]="{ item }">{{ item.start_date_time }}</template>
                <template v-slot:[`item.action`]="{ item }">
                  <!-- <v-btn fab small elevation="0" color="transparent" @click="confirmLiveAuction(item.auction_number)" v-if="item.status != 'Live'">
                    <v-icon color="red"> mdi-delete-outline </v-icon>
                  </v-btn> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container-fluid>
    <v-dialog v-model="isShowLiveDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h6">
         Are you sure want to Live Auction ?
        </v-card-title>  
        <v-card-text>
          <!-- Lorem ipsum dolor sit amet consectetur. -->
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirmLiveForm" class="font-weight-bold">
            Yes
          </v-btn>
          <v-btn color="grey darken-1" text @click="closeLiveFormModal">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>   
  </dashlayout>
</template>

<script>
import dashlayout from "../layout/dashlayout.vue";
import ConformationBox from "@/components/common/ConformationBox.vue";
import {FOOTERPAGELIST , PAGINATIONOPTIONS, getEncodedStringBase64} from '@/utils/helper'
export default {
  components: { dashlayout,ConformationBox },
  name: "UpcomingAuctionAddEdit",
  inject : ['mySpinner'],
  data: () => ({
    dialog:false,
    headers: [
      { text: "Auction No.", value: "auction_number" },
      { text: "Quantity", value: "quantity" },
      { text: "Raw Material", value: "category_name" },
      { text: "Expected/Base price", value: "expected_price" },
      { text: "Delivery", value: "auction_delivery" },
      { text: "Start Date", value: "start_date" },
      { text: "Action", value: "action", align: "right", sortable: false },
    ],
    auctionsData : [],
    total : 0,
    options : PAGINATIONOPTIONS,
    footerList : {
      'items-per-page-options':[10,20,50,100]
    },
    isShowLiveDialog: false,
    auction_number: '',
  }),
  created(){
    this.getAuctionList();
  },
  methods: {
    async getAuctionList() {
      this.mySpinner.val = true
      try {
        const response = await this.$store.dispatch("getDashboardAuctionList", {
          ...this.options,
          search: '',
          action : "LIST"
        });
        if (response.http_status === 200) {
          this.auctionsData = response.data.upcoming.data;
          this.total = response.data.upcoming.total;
          this.mySpinner.val = false
        } else {
          this.$toasted.error(response.message);
          this.mySpinner.val = false
        }
      } catch (e) {
        this.$toasted.error(e.message);
        this.mySpinner.val = false
      }
    },
    confirmLiveAuction(auction_number) {
        this.isShowLiveDialog = true
        this.auction_number = auction_number
    },
    closeLiveFormModal() {
        this.isShowLiveDialog = false
        this.auction_number = ''
    },
    async confirmLiveForm() {
        try {
            this.mySpinner.val = true;
            const response = await this.$store.dispatch("changeStatusAuction", { 
              auction_number: this.auction_number,
              status: 'Live',
              organization_id: localStorage.getItem("selectedBranch"),
              user_id: localStorage.getItem("user_id"),
            });
            if (response.http_status === 200) {
                this.isShowLiveDialog = false
                this.auction_number = ''
                this.getAuctionList()
                this.$toasted.success(response.message);
                this.mySpinner.val = false;
            } else {
                this.isShowLiveDialog = false
                this.auction_number = ''
                this.$toasted.error(response.message);
                this.mySpinner.val = false;
            }
        } catch (e) {
            this.$toasted.error(e.message);
            this.mySpinner.val = false;
        }
    }
  },
};
</script>

<style>
</style>